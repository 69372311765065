import React, { useEffect, useState } from "react";
import Select from "react-select";
import { connect } from "react-redux";
import "../CodeConfiguration/style.css";
import { Card, CardBody, Col, Container, Row, CardHeader, Label, Button, CardFooter } from "reactstrap";
import * as XLSX from "xlsx-js-style";
import { saveAs } from "file-saver";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import Loader from "../Loader/Loader";
import { getCountryComparisonDropdownRequest, getCountryComparisonTableRequest } from "../../store/actions";

const CountryComparison = (props) => {
  document.title = "Country Comparison ";
  const { countryComparisonDropdown, loading, countryComparisonTableData } = props;
  const [selectedVehicleGroup, setSelectedVehicleGroup] = useState<any>(null);
  const [VersionsId, setVersionsId] = useState<any>(null);
  const [Id, setId] = useState<any>(null);
  const [selectedVersion, setSelectedVersion] = useState(null);
  const [selectedVechicleModel1, setselectedVechicleModel1] = useState(null);
  const [selectedVehicleModel2, setSelectedVehicleModel2] = useState(null);
  const [selectedCountryGroup1, setSelectedCountryGroup1] = useState(null);
  const [selectedCountryGroup2, setSelectedCountryGroup2] = useState(null);
  const [formValidation, setFormValiodation] = useState<{[key: string]: string }>({});
  const LoginRoleData: any = localStorage.getItem("loginRoleAccess");
  const RoleResponse: any = JSON.parse(LoginRoleData);
  const Role = RoleResponse?.find((data) => data?.name === "CodeList")?.subModules?.find((val) => val?.submenuName === "CreatorRequest")?.access;

  const [formState, setFormState] = useState({
    VehicleGroupId: 0,
    VersionId: 0,
    VehicleModelId1: 0,
    VehicleModelId2: 0,
    CountryGroupId1: 0,
    CountryGroupId2: 0,
  });

  const [dropDownOptions, setDropDownOptions] = useState<any>({
    vehicleGroup: [],
    version: [],
    vehicleModel1: [],
    vehicleModel2: [],
    countryGroup1: [],
    countryGroup2: [],
  });

  useEffect(() => {
    props.getCountryComparisonDropdownRequest(formState);
  }, [formState]);

  useEffect(() => {
    if (!VersionsId) {
      setSelectedVersion(dropDownOptions.version[0]);
      setId(dropDownOptions?.version[0]?.id);
    }
  }, [formState, dropDownOptions]);

  useEffect(() => {
    if (
      countryComparisonDropdown &&
      Object.keys(countryComparisonDropdown).length !== 0
    ) {
      LoadDropDownList();
    }
  }, [countryComparisonDropdown]);

  const LoadDropDownList = () => {
    const vehicleGroupOptions = countryComparisonDropdown.vehicleGroups.map(
      (option) => ({
        value: option.value,
        label: option.value,
        id: option.id,
      })
    );

    const versionOptions = countryComparisonDropdown.versions.map((option) => ({
      value: option.value,
      label: option.value,
      id: option.id,
    }));

    const vehicleModelOptions1 = countryComparisonDropdown.vehicleModels1.map(
      (option) => ({
        value: option.value,
        label: option.value,
        id: option.id,
      })
    );

    const vehicleModelOptions2 = countryComparisonDropdown.vehicleModels2.map(
      (option) => ({
        value: option.value,
        label: option.value,
        id: option.id,
      })
    );

    const countryGroupOptions1 = countryComparisonDropdown.countryGroup1.map(
      (option) => ({
        value: option.value,
        label: option.value,
        id: option.id,
      })
    );

    const countryGroupOptions2 = countryComparisonDropdown.countryGroup2.map(
      (option) => ({
        value: option.value,
        label: option.value,
        id: option.id,
      })
    );
    setDropDownOptions({
      vehicleGroup: vehicleGroupOptions,
      version: versionOptions,
      vehicleModel1: vehicleModelOptions1,
      vehicleModel2: vehicleModelOptions2,
      countryGroup1: countryGroupOptions1,
      countryGroup2: countryGroupOptions2,
    });
  };

  const handleDropdownChange = (selectedOption: any, field: string) => {
    const updatedFormState = { ...formState };
    switch (field) {
      case "VehicleGroupId":
        updatedFormState.VehicleGroupId = selectedOption
          ? selectedOption.id
          : 0;
        setSelectedVehicleGroup(selectedOption);
        setSelectedVersion(null);
        setVersionsId(null);
        setselectedVechicleModel1(null);
        setSelectedVehicleModel2(null);
        setSelectedCountryGroup2(null);
        setSelectedCountryGroup1(null);
        updatedFormState.VersionId = 0;
        updatedFormState.VehicleModelId1 = 0;
        updatedFormState.VehicleModelId2 = 0;
        updatedFormState.CountryGroupId1 = 0;
        updatedFormState.CountryGroupId2 = 0;
        break;
      case "Versions":
        updatedFormState.VersionId = selectedOption ? selectedOption.id : 0;
        setVersionsId(selectedOption.id);
        setSelectedVersion(selectedOption);
        setselectedVechicleModel1(null);
        setSelectedVehicleModel2(null);
        setSelectedCountryGroup2(null);
        setSelectedCountryGroup1(null);
        updatedFormState.VehicleModelId1 = 0;
        updatedFormState.VehicleModelId2 = 0;
        updatedFormState.CountryGroupId1 = 0;
        updatedFormState.CountryGroupId2 = 0;
        break;
      case "VehicleModel1":
        updatedFormState.VehicleModelId1 = selectedOption
          ? selectedOption.id
          : 0;
        setselectedVechicleModel1(selectedOption);
        setSelectedCountryGroup1(null);
        break;
      case "VehicleModel2":
        updatedFormState.VehicleModelId2 = selectedOption
          ? selectedOption.id
          : 0;
        setSelectedVehicleModel2(selectedOption);
        setSelectedCountryGroup2(null);
        break;
      case "CountryGroup1":
        updatedFormState.CountryGroupId1 = selectedOption
          ? selectedOption.id
          : 0;
        setSelectedCountryGroup1(selectedOption);
        break;
      case "CountryGroup2":
        updatedFormState.CountryGroupId2 = selectedOption
          ? selectedOption.id
          : 0;
        setSelectedCountryGroup2(selectedOption);
        break;
      default:
        break;
    }
    setFormState(updatedFormState);
  };

  const handleViewCodeList = () => {
    const errors: any = {};

    if (!selectedVehicleGroup) {
      errors.VehicleGroupId = "Please select the Vehicle Group.";
    }
    if (!selectedVersion) {
      errors.VersionId = "Please select the Version.";
    }

    if (!selectedVechicleModel1) {
      errors.VehicleModelId1 = "Please select the Vehicle Model.";
    }

    if (!selectedCountryGroup1) {
      errors.CountryGroupId1 = "Please select the Country Group.";
    }
    if (!selectedCountryGroup2) {
      errors.CountryGroupId2 = "Please select the Country Group.";
    }
    if (!selectedVehicleModel2) {
      errors.VehicleModelId2 = "Please select the Vehicle Model.";
    }
    setFormValiodation(errors);
    if (Object.keys(errors).length === 0) {
      const data = {
        VehicleGroupId: formState?.VehicleGroupId,
        VersionId: formState?.VersionId || Id,
        VehicleModelId1: formState?.VehicleModelId1,
        VehicleModelId2: formState?.VehicleModelId2,
        CountryGroupId1: formState?.CountryGroupId1,
        CountryGroupId2: formState?.CountryGroupId2,
      };
      props.getCountryComparisonTableRequest(data);
    }
  };

  const handleClearDropdown = () => {
    setSelectedVehicleGroup(null);
    setSelectedVersion(null);
    setVersionsId(null);
    setselectedVechicleModel1(null);
    setSelectedVehicleModel2(null);
    setSelectedCountryGroup1(null);
    setSelectedCountryGroup2(null);
    setFormState({
      ...formState,
      VehicleGroupId: 0,
      VersionId: 0,
      VehicleModelId1: 0,
      VehicleModelId2: 0,
      CountryGroupId1: 0,
      CountryGroupId2: 0,
    });
    setTableData([]);
    setFormValiodation({});
  };

  const [tableData, setTableData] = useState<any>([]);
  
  useEffect(() => {
    setTableData(countryComparisonTableData);
  }, [countryComparisonTableData]);

  const staticHeader =
    tableData?.length > 0
      ? [
          "Code Group",
          "Code",
          "Code Description",
          "Notes",
          tableData[0].remarks?.[0]?.header,
          tableData[0].cr?.[0]?.header,
          tableData[0].cO_KEM?.[0]?.header,
        ]
      : [];

  const downloadExcel = () => {
    const table: any = document.getElementById("table data");
    const worksheet: any = XLSX.utils.table_to_sheet(table);
    const headerRange = XLSX.utils.decode_range(worksheet["!ref"]);
    const data: any = [];
    for (let row of table.rows) {
      const rowData: any = [];
      for (let cell of row.cells) {
        rowData.push(cell.innerText.trim() === "" ? "" : cell.innerText.trim());
      }
      data.push(rowData);
    }
    const maxColumns = headerRange.e.c;
    const maxRows = headerRange.e.r;
    for (let R = 0; R < 3; R++) {
      for (let C = 0; C <= maxColumns; C++) {
        const cellAddress = XLSX.utils.encode_cell({ r: R, c: C });
        if (!worksheet[cellAddress]) worksheet[cellAddress] = { v: " " };
        worksheet[cellAddress].s = {
          fill: {
            fgColor: { rgb: "333333" },
          },
          font: {
            color: { rgb: "FFFFFF" },
            bold: true,
          },
          alignment: {
            horizontal: "center",
            vertical: "center",
          },
          border: {
            top: { style: "thin", color: { rgb: "FFFFFF" } },
            bottom: { style: "thin", color: { rgb: "FFFFFF" } },
            left: { style: "thin", color: { rgb: "FFFFFF" } },
            right: { style: "thin", color: { rgb: "FFFFFF" } },
          },
        };
      }
    }
    for (let R = 4; R <= maxRows; R++) {
      for (let C = 6; C <= maxColumns; C++) {
        const cellAddress = XLSX.utils.encode_cell({ r: R, c: C });
        if (!worksheet[cellAddress].v) worksheet[cellAddress] = { v: " " };
        if (
          worksheet[cellAddress].v &&
          worksheet[cellAddress].v.trim() !== ""
        ) {
          worksheet[cellAddress].s = {
            fill: {
              fgColor: { rgb: "D3D3D3" },
            },
            alignment: {
              horizontal: "center",
              vertical: "center",
            },
            border: {
              top: { style: "thin", color: { rgb: "FFFFFF" } },
              bottom: { style: "thin", color: { rgb: "FFFFFF" } },
              left: { style: "thin", color: { rgb: "FFFFFF" } },
              right: { style: "thin", color: { rgb: "FFFFFF" } },
            },
          };
        } else {
          worksheet[cellAddress].s = {
            fill: {
              fgColor: { rgb: "FFC0CB" },
            },
            alignment: {
              horizontal: "center",
              vertical: "center",
            },
            border: {
              top: { style: "thin", color: { rgb: "FFFFFF" } },
              bottom: { style: "thin", color: { rgb: "FFFFFF" } },
              left: { style: "thin", color: { rgb: "FFFFFF" } },
              right: { style: "thin", color: { rgb: "FFFFFF" } },
            },
          };
        }
      }
    }
    for (let R = 2; R <= headerRange.e.r; ++R) {
      for (let C = headerRange.s.c; C <= headerRange.e.c; ++C) {
        const cellAddress = XLSX.utils.encode_cell({ r: R, c: C });
        if (!worksheet[cellAddress]) continue;

        worksheet[cellAddress].s = {
          ...worksheet[cellAddress].s,
          alignment: {
            vertical: "center",
            horizontal: "center",
          },
        };
      }
    }

    for (let R = 3; R <= headerRange.e.r; ++R) {
      for (let C = 0; C <= 5; ++C) {
        const cellAddress = XLSX.utils.encode_cell({ r: R, c: C });
        if (!worksheet[cellAddress]) continue;

        worksheet[cellAddress].s = {
          alignment: {
            vertical: "center",
            horizontal: "center",
            wrapText: true,
          },
        };
      }
    }

    const columnWidths: any = [];
    for (let C = headerRange.s.c; C <= headerRange.e.c; ++C) {
      let maxWidth = 40;
      for (let R = headerRange.s.r; R <= headerRange.e.r; ++R) {
        const cellAddress = XLSX.utils.encode_cell({ r: R, c: C });
        const cell = worksheet[cellAddress];
      }
      columnWidths.push({ wch: maxWidth });
    }

    const now = new Date();
    const dateTimeString = `Generated on: ${now.toLocaleDateString()} ${now.toLocaleTimeString()}`;
    const lastRowIndex = maxRows + 3;
    const dateTimeCellAddress = XLSX.utils.encode_cell({
      r: lastRowIndex,
      c: 0,
    });
    worksheet[dateTimeCellAddress] = { v: dateTimeString, t: "s" };

    worksheet[dateTimeCellAddress].s = {
      font: {
        color: { rgb: "000000" },
        bold: true,
      },
      alignment: {
        horizontal: "left",
        vertical: "center",
      },
    };

    worksheet["!ref"] = XLSX.utils.encode_range({
      s: { r: 0, c: 0 },
      e: { r: lastRowIndex, c: maxColumns },
    });

    worksheet["!cols"] = columnWidths;
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      workbook,
      worksheet,
      `sheet1-${now.getMonth() + 1}-${now.getDate()}-${now.getFullYear()}`
    );

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(
      blob,
      `Code_Lists_Excel_Report__${
        now.getMonth() + 1
      }-${now.getDate()}-${now.getFullYear()}_${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}.xlsx`
    );
  };

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Country Comparison" pageTitle="Code List" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className="card-bg">
                  <h4 className="card-title mb-0 ">Country Comparison</h4>{" "}
                </CardHeader>
                <CardBody>
                  <div>
                    <div>
                      <Row>
                        <Col lg={4} md={6}>
                          <div className="mb-3">
                            <Label
                              htmlFor="choices-single-default"
                              className="form-label text-muted"
                            >
                              Vehicle Group{" "}
                              <span className="text-danger">*</span>
                            </Label>
                            <Select
                              value={selectedVehicleGroup}
                              isMulti={false}
                              onChange={(e) => {
                                handleDropdownChange(e, "VehicleGroupId");
                              }}
                              options={dropDownOptions.vehicleGroup}
                            />
                            <span className="text-validation">
                              {formValidation.VehicleGroupId}
                            </span>
                          </div>
                        </Col>
                        <Col lg={4} md={6}>
                          <div className="mb-3">
                            <Label
                              htmlFor="choices-single-groups"
                              className="form-label text-muted"
                            >
                              Versions <span className="text-danger">*</span>
                            </Label>
                            <Select
                              value={selectedVersion}
                              isMulti={false}
                              closeMenuOnSelect={true}
                              onChange={(e) => {
                                handleDropdownChange(e, "Versions");
                              }}
                              options={dropDownOptions.version}
                            />
                            <span className="text-validation">
                              {formValidation.VersionId}
                            </span>
                          </div>
                        </Col>
                        <Col lg={4} md={6}>
                          <div className="mb-3">
                            <Label
                              htmlFor="choices-single-no-search"
                              className="form-label text-muted"
                            >
                              Vehicle Model 1
                              <span className="text-danger">*</span>
                            </Label>
                            <Select
                              isClearable={true}
                              isMulti={false}
                              closeMenuOnSelect={true}
                              value={selectedVechicleModel1}
                              onChange={(e) => {
                                handleDropdownChange(e, "VehicleModel1");
                              }}
                              options={dropDownOptions.vehicleModel1}
                            />
                            <span className="text-validation">
                              {formValidation.VehicleModelId1}
                            </span>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div>
                      <Row>
                        <Col lg={4} md={6}>
                          <div className="mb-3">
                            <Label
                              htmlFor="choices-single-default"
                              className="form-label text-muted"
                            >
                              Vehicle Model 2
                              <span className="text-danger">*</span>
                            </Label>
                            <Select
                              value={selectedVehicleModel2}
                              closeMenuOnSelect={true}
                              isMulti={false}
                              onChange={(e) => {
                                handleDropdownChange(e, "VehicleModel2");
                              }}
                              options={dropDownOptions.vehicleModel2}
                            />
                            <span className="text-validation">
                              {formValidation.VehicleModelId2}
                            </span>
                          </div>
                        </Col>
                        <Col lg={4} md={6}>
                          <div className="mb-3">
                            <Label
                              htmlFor="choices-single-groups"
                              className="form-label text-muted"
                            >
                              Country Group 1
                              <span className="text-danger">*</span>
                            </Label>
                            <Select
                              value={selectedCountryGroup1}
                              isMulti={false}
                              closeMenuOnSelect={true}
                              onChange={(e) => {
                                handleDropdownChange(e, "CountryGroup1");
                              }}
                              options={dropDownOptions.countryGroup1}
                            />
                            <span className="text-validation">
                              {formValidation.CountryGroupId1}
                            </span>
                          </div>
                        </Col>
                        <Col lg={4} md={6}>
                          <div className="mb-3">
                            <Label
                              htmlFor="choices-single-no-search"
                              className="form-label text-muted"
                            >
                              Country Group 2
                              <span className="text-danger">*</span>
                            </Label>
                            <Select
                              isClearable={true}
                              isMulti={false}
                              closeMenuOnSelect={true}
                              value={selectedCountryGroup2}
                              onChange={(e) => {
                                handleDropdownChange(e, "CountryGroup2");
                              }}
                              options={dropDownOptions.countryGroup2}
                            />
                            <span className="text-validation">
                              {formValidation.CountryGroupId2}
                            </span>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </CardBody>
                <CardFooter>
                  <div className="d-flex gap-3 justify-content-md-end ">
                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                      <Button
                        className="btn btn-primary"
                        type="button"
                        color="light"
                        onClick={() => handleClearDropdown()}
                      >
                        Clear
                      </Button>
                    </div>
                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                      <Button
                        className="btn btn-primary"
                        type="button"
                        color="success"
                        onClick={() => handleViewCodeList()}
                      >
                        Compare
                      </Button>
                    </div>
                  </div>
                </CardFooter>
              </Card>
            </Col>
          </Row>
          {tableData !== null &&
            tableData !== undefined &&
            tableData?.length > 0 && (
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardHeader className="align-items-center d-flex card-bg">
                      <h4 className="card-title mb-0 flex-grow-1">
                        View Codes
                      </h4>
                      <div className="flex-shrink-0">
                        <div className="form-check form-switch form-switch-right form-switch-md">
                          <div className="input-group gap-3 justify-content-md-end ">
                            <button
                              type="button"
                              className="btn btn-info"
                              onClick={() => downloadExcel()}
                            >
                              <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                              Export Excel
                            </button>
                          </div>
                        </div>
                      </div>
                    </CardHeader>
                    <CardBody className="card-padding">
                      <div className="live-preview">
                        <div
                          className="table-responsive table-card"
                          style={{ maxHeight: "700px" }}
                        >
                          <table
                            id="table data"
                            className="table align-middle table-bordered  table-striped-columns table-nowrap border-dark table-striped mb-0"
                          >
                            <thead
                              className="table-light"
                              style={{
                                position: "sticky",
                                top: "0",
                              }}
                            >
                              <tr>
                                {staticHeader?.map((header, index) => (
                                  <th
                                    key={index}
                                    className="center"
                                    scope="col"
                                    rowSpan={2}
                                  >
                                    {header}
                                  </th>
                                ))}
                                {tableData[0]?.vehicleCountryOption?.map(
                                  (vehicle, index) => (
                                    <th
                                      key={`vehicleGroup-${index}`}
                                      className="center"
                                      scope="col"
                                      colSpan={vehicle.countryOption.length}
                                    >
                                      {vehicle.vehicleModel}
                                    </th>
                                  )
                                )}
                              </tr>
                              <tr>
                                {tableData[0]?.vehicleCountryOption?.map(
                                  (vehicle) =>
                                    vehicle?.countryOption?.map(
                                      (country, index) => (
                                        <th
                                          key={`country-${index}`}
                                          className="center"
                                          scope="col"
                                        >
                                          {country.country.trim()}
                                        </th>
                                      )
                                    )
                                )}
                              </tr>
                            </thead>
                            <tbody>
                              {tableData?.map((data, index) => (
                                <tr key={index}>
                                  <td
                                    className="center"
                                    style={{ whiteSpace: "normal" }}
                                  >
                                    {" "}
                                    {data.codeGroup}
                                  </td>
                                  <td
                                    className="center"
                                    style={{ whiteSpace: "normal" }}
                                  >
                                    {data.code}
                                  </td>
                                  <td
                                    className="center"
                                    style={{ whiteSpace: "normal" }}
                                  >
                                    {data.codeDescription}
                                  </td>
                                  <td
                                    className="center"
                                    style={{ whiteSpace: "normal" }}
                                  >
                                    {data.notes}
                                  </td>
                                  <td
                                    className="center"
                                    style={{ whiteSpace: "normal" }}
                                  >
                                    {data.remarks?.[0]?.value}
                                  </td>
                                  <td
                                    className="center"
                                    style={{ whiteSpace: "normal" }}
                                  >
                                    {data.cr?.[0]?.value}
                                  </td>
                                  <td
                                    className="center"
                                    style={{ whiteSpace: "normal" }}
                                  >
                                    {data.cO_KEM?.[0]?.value}
                                  </td>
                                  {data.vehicleCountryOption?.map(
                                    (vehicle, index) =>
                                      vehicle.countryOption.map(
                                        (country, index) => (
                                          <td
                                            key={index}
                                            className="center"
                                            style={
                                              data.isChanged === true
                                                ? {
                                                    backgroundColor:
                                                      "#fafa0070",
                                                    whiteSpace: "normal",
                                                  }
                                                : { whiteSpace: "normal" }
                                            }
                                          >
                                            {country.option}
                                          </td>
                                        )
                                      )
                                  )}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="d-none code-view">
                        <pre
                          className="language-markup"
                          style={{ height: "275px" }}
                        >
                        </pre>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  countryComparisonDropdown: state.CodeListReducer.countryComparisonDropdown,
  countryComparisonTableData: state.CodeListReducer.countryComparisonTableData,
  loading: state.CodeListReducer.loading,
});

const mapDispatchToProps = (dispatch) => ({
  getCountryComparisonDropdownRequest: (data) =>
    dispatch(getCountryComparisonDropdownRequest(data)),
  getCountryComparisonTableRequest: (data) =>
    dispatch(getCountryComparisonTableRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CountryComparison);
