import { CodeListActionTypes } from "./actionType";

export const initCodeListModule = (data) => (
    {
        type: CodeListActionTypes.INIT_GET_CODE_LIST_MODULE,
    });

export const getDropdownCodeListAction = (data) => {
    return (
        {
            type: CodeListActionTypes.GET_DROPDOWN_CODE_LIST_REQUEST,
            data
        });
};

export const getDropdownCodeListSuccess = (data) => {
    return {
        type: CodeListActionTypes.GET_DROPDOWN_CODE_LIST_SUCCESS,
        data
    };
};

export const getDropdownCodeListFailure = (error) => ({
    type: CodeListActionTypes.GET_DROPDOWN_CODE_LIST_FAILED,
    error
})

export const getViewCodeListTableAction = (data) => {
    return (
        {
            type: CodeListActionTypes.GET_VIEW_CODE_LIST_TABLE_REQUEST,
            data
        });
}

export const getViewCodeListTableSuccess = (data) => {
    return {
        type: CodeListActionTypes.GET_VIEW_CODE_LIST_TABLE_SUCCESS,
        data
    };
}

export const getViewCodeListTableFailure = (error) => ({
    type: CodeListActionTypes.GET_VIEW_CODE_LIST_TABLE_FAILED,
    error
})

export const getDropdownVersionComparisonAction = (data) => {
    return (
        {
            type: CodeListActionTypes.GET_DROPDOWN_VERSION_COMPARISON_REQUEST,
            data
        });
}


export const getDropdownVersionComparisonSuccess = (data) => {
    return {
        type: CodeListActionTypes.GET_DROPDOWN_VERSION_COMPARISON_SUCCESS,
        data
    };
};

export const getDropdownVersionComparisonFailure = (error) => ({
    type: CodeListActionTypes.GET_DROPDOWN_VERSION_COMPARISON_FAILED,
    error
})

export const getVersionComparisonTableAction = (data) => {
    return (
        {
            type: CodeListActionTypes.GET_VERSION_COMPARISON_TABLE_REQUEST,
            data
        });
}

export const getVersionComparisonTableSuccess = (data) => {
    return {
        type: CodeListActionTypes.GET_VERSION_COMPARISON_TABLE_SUCCESS,
        data
    };
}

export const getVersionComparisonTableFailure = (error) => ({
    type: CodeListActionTypes.GET_VERSION_COMPARISON_TABLE_FAILED,
    error
})


export const getSubmitCodeListAction = (data) => {
    return (
        {
            type: CodeListActionTypes.SUBMIT_CODE_LIST_REQUEST,
            data
        });
}

export const getSubmitCodeListSuccess = (data) => {
    return {
        type: CodeListActionTypes.SUBMIT_CODE_LIST_SUCCESS,
        data
    };
}

export const getSubmitCodeListFailure = (error) => ({
    type: CodeListActionTypes.SUBMIT_CODE_LIST_FAILED,
    error
})

export const getCreatorTableAction = (data) => {
    return (
        {
            type: CodeListActionTypes.GET_CREATOR_TABLE_REQUEST,
            data
        });
}

export const getCreatorTableSuccess = (data) => {
    return {
        type: CodeListActionTypes.GET_CREATOR_TABLE_SUCCESS,
        data
    };
}

export const getCreatorTableFailure = (error) => ({
    type: CodeListActionTypes.GET_CREATOR_TABLE_FAILED,
    error
})

export const getCreatorViewTableRequest = (data) => {
    return (
        {
            type: CodeListActionTypes.GET_CREATOR_VIEW_TABLE_REQUEST,
            data
        });
}

export const getCreatorViewTableSuccess = (data) => {
    return {
        type: CodeListActionTypes.GET_CREATOR_VIEW_TABLE_SUCCESS,
        data
    };
}

export const getCreatorViewTableFailure = (error) => ({
    type: CodeListActionTypes.GET_CREATOR_VIEW_TABLE_FAILED,
    error
})

export const getCreatorEditTableRequest = (data) => {
    return (
        {
            type: CodeListActionTypes.GET_CREATOR_EDIT_TABLE_REQUEST,
            data
        });
}

export const getCreatorEditTableSuccess = (data) => {
    return {
        type: CodeListActionTypes.GET_CREATOR_EDIT_TABLE_SUCCESS,
        data
    };
}

export const getCreatorEditTableFailure = (error) => ({
    type: CodeListActionTypes.GET_CREATOR_EDIT_TABLE_FAILED,
    error
})

export const getSubmitEditCodeList = (data) => {
    return (
        {
            type: CodeListActionTypes.SUBMIT_EDIT_CODE_LIST_REQUEST,
            data
        });
}

export const getSubmitEditCodeListSuccess = (data) => {
    return {
        type: CodeListActionTypes.SUBMIT_EDIT_CODE_LIST_SUCCESS,
        data
    };
}

export const getSubmitEditCodeListFailure = (error) => ({
    type: CodeListActionTypes.SUBMIT_EDIT_CODE_LIST_FAILED,
    error
})

export const getSaveEditCodeList = (data) => {
    return (
        {
            type: CodeListActionTypes.SAVE_EDIT_CODE_LIST_REQUEST,
            data
        });
}

export const getSaveEditCodeListSuccess = (data) => {
    return {
        type: CodeListActionTypes.SAVE_EDIT_CODE_LIST_SUCCESS,
        data
    };
}

export const getSaveEditCodeListFailure = (error) => ({
    type: CodeListActionTypes.SAVE_EDIT_CODE_LIST_FAILED,
    error
})

export const getWorkflowTableAction = (data) => {
    return (
        {
            type: CodeListActionTypes.GET_WORKFLOW_TABLE_REQUEST,
            data
        });
}

export const getWorkflowTableSuccess = (data) => {
    return {
        type: CodeListActionTypes.GET_WORKFLOW_TABLE_SUCCESS,
        data
    };
}

export const getWorkflowTableFailure = (error) => ({
    type: CodeListActionTypes.GET_WORKFLOW_TABLE_FAILED,
    error
})

export const getWorkflowCombineAction = (data) => {
    return (
        {
            type: CodeListActionTypes.GET_WORKFLOW_COMBINE_REQUEST,
            data
        });
}

export const getWorkflowCombineSuccess = (data) => {
    return {
        type: CodeListActionTypes.GET_WORKFLOW_COMBINE_SUCCESS,
        data
    };
}

export const getWorkflowCombineFailure = (error) => ({
    type: CodeListActionTypes.GET_WORKFLOW_COMBINE_FAILED,
    error
})

export const getViewChangesDecisionAction = (data) => {
    return (
        {
            type: CodeListActionTypes.GET_VIEW_CHANGES_DECISION_REQUEST,
            data
        });
}

export const getViewChangesDecisionSuccess = (data) => {
    return {
        type: CodeListActionTypes.GET_VIEW_CHANGES_DECISION_SUCCESS,
        data
    };
}

export const getViewChangesDecisionFailure = (error) => ({
    type: CodeListActionTypes.GET_VIEW_CHANGES_DECISION_FAILED,
    error
})

export const getCountryComparisonDropdownRequest = (data) => {
    return (
        {
            type: CodeListActionTypes.GET_COUNTRY_COMPARISON_DROPDOWN_REQUEST,
            data
        });
}

export const getCountryComparisonDropdownSuccess = (data) => {
    return {
        type: CodeListActionTypes.GET_COUNTRY_COMPARISON_DROPDOWN_SUCCESS,
        data
    };
}

export const getCountryComparisonDropdownFailure = (error) => ({
    type: CodeListActionTypes.GET_COUNTRY_COMPARISON_DROPDOWN_FAILED,
    error
})

export const getCountryComparisonTableRequest = (data) => {
    return (
        {
            type: CodeListActionTypes.GET_COUNTRY_COMPARISON_TABLE_REQUEST,
            data
        });
}

export const getCountryComparisonTableSuccess = (data) => {
    return {
        type: CodeListActionTypes.GET_COUNTRY_COMPARISON_TABLE_SUCCESS,
        data
    };
}

export const getCountryComparisonTableFailure = (error) => ({
    type: CodeListActionTypes.GET_COUNTRY_COMPARISON_TABLE_FAILED,
    error
})

export const getCodeWorkflowTrackerTableRequest = (data) => {
    return (
        {
            type: CodeListActionTypes.GET_CODEWORKFLOWTRACKER_TABLEDATA_REQUEST,
            data
        });
}

export const getCodeWorkflowTrackerTableSucess = (data) => {
    return {
        type: CodeListActionTypes.GET_CODEWORKFLOWTRACKER_TABLEDATA_SUCCESS,
        data
    };
}

export const getCodeWorkflowTrackerTableFailure = (error) => ({
    type: CodeListActionTypes.GET_CODEWORKFLOWTRACKER_TABLEDATA_FAILED,
    error
})

export const codeListExcelExportAction = (data) => {
    return (
        {
            type: CodeListActionTypes.CODE_LIST_EXCEL_EXPORT_REQUEST,
            data
        });
}

export const codeListExcelExportSucess = (data) => {
    return {
        type: CodeListActionTypes.CODE_LIST_EXCEL_EXPORT_SUCCESS,
        data
    };
}

export const codeListExcelExportFailure = (error) => ({
    type: CodeListActionTypes.CODE_LIST_EXCEL_EXPORT_FAILED,
    error
})

export const codeListHistoryAction = (data) => {
    return (
        {
            type: CodeListActionTypes.CODE_LIST_HISTORY_REQUEST,
            data
        });
}

export const codeListHistorySucess = (data) => {
    return {
        type: CodeListActionTypes.CODE_LIST_HISTORY_SUCCESS,
        data
    };
}

export const codeListHistoryFailure = (error) => ({
    type: CodeListActionTypes.CODE_LIST_HISTORY_FAILED,
    error
})

export const codeListPdfExportAction = (data) => {
    return (
        {
            type: CodeListActionTypes.CODE_LIST_PDF_EXPORT_REQUEST,
            data
        });
}

export const codeListPdfExportSuccess = (data) => {
    return {
        type: CodeListActionTypes.CODE_LIST_PDF_EXPORT_SUCCESS,
        data
    };
}

export const codeListPdfExportFailure = (error) => ({
    type: CodeListActionTypes.CODE_LIST_PDF_EXPORT_FAILED,
    error
})
