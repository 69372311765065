import { AuthenticationActionTypes } from "./actionType";

const initialState = {
    isLoading: false,
    loading: false,
    items: [],
    errors: {},
    getLoginData: {},
    refreshTokenData: {},

}

const AuthenticationReducer = (state = initialState, action) => {
    const response = action.data;
    switch (action.type) {
        case AuthenticationActionTypes.INIT_GET_AUTHENDICATION_MODULE:
            return {
                ...state, loading: false, isLoading: false, items: [], getLoginData: {}, refreshTokenData: {},
            }
        case AuthenticationActionTypes.LOGIN_REQUEST:
            return { ...state, loading: true, getLoginData: {} }
        case AuthenticationActionTypes.LOGIN_SUCCESS:
            return { ...state, loading: false, getLoginData: response }
        case AuthenticationActionTypes.LOGIN_FAILED:
            return { ...state, loading: false, errors: action.error }

        case AuthenticationActionTypes.REFRESH_TOKEN_REQUEST:
            return { ...state, loading: true, refreshTokenData: {} }
        case AuthenticationActionTypes.REFRESH_TOKEN_SUCCESS:
            return { ...state, loading: false, refreshTokenData: response }
        case AuthenticationActionTypes.REFRESH_TOKEN_FAILED:
            return { ...state, loading: false, errors: action.error }

        default: {
            return state;
        }
    }
}

export default AuthenticationReducer;
