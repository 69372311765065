import React from "react";
import Starter from "../pages/Pages/Starter/Starter";
import Maintenance from "../pages/Pages/Maintenance/Maintenance";
import Login from "../pages/Authentication/Login";
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import UserProfile from "../pages/Authentication/user-profile";
import CodeList from "../pages/CodeList/CodeList";
import EditCodeList from "../pages/CodeList/EditCodeList";
import SanCheck from "../pages/CodeBuilder/SanCheck";
import TruthTableGenerator from "../pages/CodeBuilder/TruthTableGenerator";
import BooleanLogicCreation from "../pages/CodeBuilder/BooleanLogicCreation";
import CodeConfiguration from "../pages/CodeConfiguration/CodeConfiguration";
import VehicleTypes from "../pages/Masters/VehicleType";
import CountryGroup from "../pages/Masters/CountryGroup";
import AddCountry from "../pages/Masters/AddCountry";
import AddOptions from "../pages/Masters/AddOptions";
import VehicleGroups from "../pages/Masters/VehicleGroup";
import CodeRuleGenerator from "../pages/CodeBuilder/CodeRuleGenerator";
import SelectCodeCombination from "../pages/CodeBuilder/SelectCodeCombination";
import CodeGroup from "../pages/Masters/CodeGroup";
import VehicleCategory from "../pages/Masters/VehicleCategory";
import Tonnage from "../pages/Masters/Tonnage";
import VehicleModel from "../pages/Masters/VehicleModel";
import PackageSubitem from "../pages/Masters/PackageSubitem";
import TargetMatrix from "../pages/Masters/TargetMatrix";
import PackageMaster from "../pages/Masters/PackageMaster";
import CountryComparison from "../pages/CodeList/CountryComparison";
import CodeWorkflowTracker from "../pages/CodeList/CodeWorkflowTracker";
import Code from "../pages/Masters/Code";
import WorkFlowRequest from "../pages/CodeList/WorkFlowRequest";
import CreatorRequest from "../pages/CodeList/CreatorRequest";
import VersionComparisons from "../pages/CodeList/VersionComparisons";
import VehicleCountryGroupMapping from "../pages/Masters/VehicleCountryGroupMapping";
import ViewChanges from "../pages/CodeList/ViewChanges";
import CreateUser from "../pages/UserManagement/CreateUser";
import RoleCreate from "../pages/UserManagement/RoleCreate";
import Access from "../pages/UserManagement/Access";
import RoleAccessMapping from "../pages/UserManagement/RoleAccessMapping";
import CodeRequest from "../pages/Masters/CodeRequest";
import CodeAnalytics from "../pages/CodeAnalytics/CodeAnalytics";
import CodeListUI from "../pages/CodeList/CodeListUI"; 
import CodeListHistory from "../pages/CodeList/CodeListHistory";
import Faq from "../pages/Faq/Faq";
import FaqQuestions from "../pages/Faq/FaqQuestions";
import FileUploader from "../pages/CodeBuilder/FileUploader";

const authProtectedRoutes = [
  { path: "/code-list", component: <CodeList /> },
  { path: "/code-list-history", component: <CodeListHistory /> },
  { path: "/code-list-ui", component: <CodeListUI /> },
  { path: "/version-comparisons", component: <VersionComparisons /> },
  { path: "/code-rule-generator", component: <CodeRuleGenerator /> },
  { path: "/val", component: <Maintenance /> },
  { path: "/edit-code-list", component: <EditCodeList /> },
  { path: "/code-configuration", component: <CodeConfiguration /> },
  { path: "/san-check", component: <SanCheck /> },
  { path: "/boolean-logic-creation", component: <BooleanLogicCreation /> },
  { path: "/code-builder-fileupload", component: <FileUploader /> },
  { path: "/truth-table-generator", component: <TruthTableGenerator /> },
  { path: "/view-changes", component: <ViewChanges /> },
  { path: "/view-changes/:groupId/:reqId/:flowReq", component: <ViewChanges /> },
  { path: "/workflow-request", component: <WorkFlowRequest /> },
  { path: "/creator-request", component: <CreatorRequest /> },
  { path: "/vehicle-type", component: <VehicleTypes /> },
  { path: "/country-group", component: <CountryGroup /> },
  { path: "/add-country", component: <AddCountry /> },
  { path: "/add-options", component: <AddOptions /> },
  { path: "/vehicle-group", component: <VehicleGroups /> },
  { path: "/vehicle-group", component: <VehicleGroups /> },
  { path: "/code-combination", component: <SelectCodeCombination /> },
  { path: "/code-group", component: <CodeGroup /> },
  { path: "/vehicle-category", component: <VehicleCategory /> },
  { path: "/tonnage", component: <Tonnage /> },
  { path: "/vehicle-model", component: <VehicleModel /> },
  { path: "/package-subitem", component: <PackageSubitem /> },
  { path: "/target-matrix", component: <TargetMatrix /> },
  { path: "/package-master", component: <PackageMaster /> },
  { path: "/code", component: <Code /> },
  { path: "/Code-request", component: <CodeRequest /> },
  { path: "/Code-request/:mailId", component: <CodeRequest /> },
  { path: "/Country-comparison", component: <CountryComparison /> },
  { path: "/code-workflow-tracker", component: <CodeWorkflowTracker /> },
  { path: "/vehicle-countrygroup-mapping", component: <VehicleCountryGroupMapping /> },
  { path: "/create-user", component: <CreateUser /> },
  { path: "/role-create", component: <RoleCreate /> },
  { path: "/access", component: <Access /> },
  { path: "/role-access-mapping", component: <RoleAccessMapping /> },
  { path: "/code-analytics", component: <CodeAnalytics /> },
  { path: "/faq", component: <FaqQuestions /> },
  { path: "/faq-video", component: <Faq /> },
  { path: "/pages-starter", component: <Starter /> },
  { path: "/profile", component: <UserProfile /> },
];

const publicRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/register", component: <Register /> },
  { path: "/pages-maintenance", component: <Maintenance /> },
];

export { authProtectedRoutes, publicRoutes };
