import React, { useState, useEffect } from 'react';
import { Input } from 'reactstrap';
import Select from "react-select";
import { Card, CardBody, Col, Container, Row, CardHeader, Label, Button } from 'reactstrap';
import { Pagination } from '@mui/material';
import { connect } from "react-redux";
import Loader from '../Loader/Loader';
import { getAllVehicleGroupDropdownAction, getAllCodeGroupAction, insertOrUpdateCodeGroupAction, deleteCodeGroupAction } from '../../store/actions';
import BreadCrumb from "../../Components/Common/BreadCrumb";
import DeleteModal from '../DeleteModel/DeleteModel';

const CodeGroup = (props: any) => {
    document.title = "Code Group";
    const { getVehicleGroupDropdown, getAllCodeGroup, insertOrUpdateCodeGroup, deleteCodeGroup, loading } = props;
    const [selectedGroup2, setSelectedGroup2] = useState<any>(null);
    const [openModel, setOpenModel] = useState<boolean>(false);
    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    const [todo, setTodo] = useState<any>(null);
    const errors: any = {}
    const [formValidation, setFormValiodation] = useState<any>({});
    const [vehicleDropDownData, setVehicleDropDownData] = useState<any>(null);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [getAllCodeGroups, setGetAllCodeGroups] = useState<any>(null);
    const [editData, setEditData] = useState<any>({});
    const [codeList, setCodeList] = useState<any>("");
    const [searchKeyword, setSearchKeyword] = useState("");
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [editId, seteditId] = useState<any>("");
    const [addBtn, setAddBtn] = useState<boolean>(false);
    const [sort, setSort] = useState("");
    const recordsPerPage = 10;
    const totalPages = Math.ceil(Number(totalCount) / recordsPerPage);
    const LoginRoleData = localStorage.getItem("loginRoleAccess")
    const RoleResponse = JSON.parse(LoginRoleData);
    const Role = RoleResponse?.find(data => data?.name === "Master")?.subModules?.find(val => val?.submenuName === "CodeGroup")?.access

    const Vehicle = vehicleDropDownData?.map((item: any) => (
        {
            label: item.value,
            value: item.value,
            id: item.id,
            isSelected: item.isSelected
        }
    )
    ) || [];

    const handleEdit = (code: any) => {
        setAddBtn(true);
        seteditId(code?.id);
        setCodeList(code?.vehicleGroupId);
        setSelectedGroup2({
            id: code?.vehicleGroupId,
            label: code?.vehicleGroupValue,
            value: code?.vehicleGroupValue,
            isSelected: code?.isActive,
        })
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setOpenModel(true);
        setEditData(code)
    }

    const handleAdd = () => {
        setOpenModel(true);
        setAddBtn(true);
    }

    const handleSave = () => {
        if (!editData.name) {
            errors.name = 'Please fill the field'
        }
        if (!codeList) {
            errors.codeList = 'Please select vehicle group'
        }
        const vehicle = {
            id: editId ? editId : 0,
            vehicleGroupId: codeList,
            name: editData.name,
        };
        setFormValiodation(errors);
        setCurrentPage(editId ? currentPage : 1)
        if (editData.name && codeList) {
            props.insertOrUpdateCodeGroupAction(vehicle);
        }
    }

    useEffect(() => {
        if (insertOrUpdateCodeGroup?.httpStatusCode === 200) {
            setAddBtn(false);
            setOpenModel(false);
            handleClear();
            seteditId("");
            props.getAllCodeGroupAction({ page: currentPage, search: searchKeyword, sort: sort, OrderByColumnName: "" });
            setTotalCount(getAllCodeGroup?.totalRowsCount);
        }
    }, [insertOrUpdateCodeGroup])

    const handleClear = () => {
        setEditData({});
        setCodeList('');
        setSelectedGroup2(null);
        setFormValiodation({});
    }

    const handleCancle = () => {
        handleClear();
        seteditId("");
        setOpenModel(false);
        setAddBtn(false);
    }

    const handleDeleteTodo = () => {
        props.deleteCodeGroupAction(todo);
        setDeleteModal(false);
        setCurrentPage(1);
    };

    const onClickTodoDelete = (todo: any) => {
        setTodo(todo);
        setDeleteModal(true);
    };

    const onClickPagination = (event, value) => {
        setCurrentPage(value);
    };

    const onSearchChange = (event) => {
        setSearchKeyword(event.target.value);
        props.getAllCodeGroupAction({ page: "1", search: event.target.value, sort: sort, OrderByColumnName: "" })
        setCurrentPage(1);
    };

    const onSortChange = (keys: any) => {
        const newSortOrder = sort === 'asc' ? 'desc' : 'asc';
        setSort(newSortOrder);
        props.getAllCodeGroupAction({ page: currentPage, search: searchKeyword, sort: newSortOrder, OrderByColumnName: keys, SortDirection: newSortOrder });
    };

    function handleVehicle(selectedGroup2: any) {
        setSelectedGroup2(selectedGroup2);
        setCodeList(selectedGroup2?.id)
    }

    useEffect(() => {
        props.getAllVehicleGroupDropdownAction();
    }, [])

    useEffect(() => {
        setTotalCount(getAllCodeGroup?.totalRowsCount);
    }, [getAllCodeGroup])

    useEffect(() => {
        setVehicleDropDownData(getVehicleGroupDropdown?.data)
    }, [getVehicleGroupDropdown])

    useEffect(() => {
        props.getAllCodeGroupAction({ page: currentPage, search: searchKeyword, sort: sort, OrderByColumnName: "" });
        setTotalCount(getAllCodeGroup?.totalRowsCount);
    }, [currentPage, deleteCodeGroup]);

    useEffect(() => {
        setGetAllCodeGroups(getAllCodeGroup)
    }, [getAllCodeGroup])

    return (
        <React.Fragment>
            {loading && <Loader />}
            <DeleteModal
                show={deleteModal}
                onDeleteClick={() => handleDeleteTodo()}
                onCloseClick={() => setDeleteModal(false)}
            />
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Code Group" pageTitle="Masters" />
                    {openModel &&
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardHeader className='card-bg'><h4 className="card-title mb-0 ">Add Code Group</h4> </CardHeader>
                                    <CardBody>
                                        <div>
                                            <div>
                                                <Row>
                                                    <Col lg={4} md={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="choices-single-no-search" className="form-label text-muted">Vehicle Group <span style={{ color: 'red' }}>*</span></Label>
                                                            <Select
                                                                isClearable={true}
                                                                value={selectedGroup2}
                                                                onChange={(e) => {
                                                                    handleVehicle(e);
                                                                }}
                                                                options={Vehicle}
                                                            />
                                                            <span className='text-validation'>{formValidation.codeList}</span>
                                                        </div>
                                                    </Col>
                                                    <Col lg={4} md={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="choices-single-no-search" className="form-label text-muted">Name <span style={{ color: 'red' }}>*</span></Label>
                                                            <Input type="text" className="form-control" id="placeholderInput" placeholder="Enter name" value={editData.name ? editData.name : ""} onChange={(e) => setEditData({ ...editData, name: e.target.value })} />
                                                            <span className='text-validation'>{formValidation.name}</span>
                                                        </div>
                                                    </Col>

                                                    <Col lg={4} md={6}>
                                                        <div className="d-grid gap-2 d-md-flex justify-content-md-end pt-4">
                                                            <Button className="btn btn-primary mb-2" type="button" color='success' onClick={() => handleSave()}>Save</Button>
                                                            <Button className="btn btn-primary mb-2" type="button" color='light' onClick={handleClear}>Clear</Button>
                                                            <Button className="btn btn-primary mb-2" type="button" color='light' onClick={handleCancle}>Cancel</Button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    }
                    <Row>
                        <Col xl={12}>
                            <Card>
                                <CardHeader className="align-items-center d-flex card-bg">
                                    <h4 className="card-title mb-0 flex-grow-1">Code Group List</h4>
                                    <div className="flex-shrink-0">
                                        <div className="form-check form-switch form-switch-right form-switch-md">
                                            {Role && Role?.createAccess && <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                {!addBtn && <Button className="btn btn-primary" type="button" color='success' onClick={() => handleAdd()}>+ Add</Button>}
                                            </div>}
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-sm-end">
                                        <div className="search-box ms-2">
                                            <input type="text" className="form-control search" placeholder="Search..." onChange={onSearchChange} />
                                            <i className="ri-search-line search-icon"></i>
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardBody className='card-padding'>
                                    <div className="live-preview">
                                        <div className="table-responsive table-card">
                                            <table className="table align-middle table-nowrap table-striped mb-0">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th scope="col">S.No</th>
                                                        <th scope="col">Vehicle Group <i className=" bx bx-sort me-3" style={{ color: "#182b45" }} onClick={() => onSortChange('vehicleGroupValue')} ></i></th>
                                                        <th scope="col">Name <i className=" bx bx-sort me-3" style={{ color: "#182b45" }} onClick={() => onSortChange('name')} ></i></th>
                                                        {(Role && Role?.editAccess || Role && Role?.deleteAccess) && <th scope="col" style={{ width: 50 }}>Action</th>}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {getAllCodeGroups?.dataRows && getAllCodeGroups?.dataRows?.length > 0 ? (
                                                        getAllCodeGroups?.dataRows?.map((code: any, index: number) => (
                                                            <tr key={code?.id}>
                                                                <td>{(currentPage - 1) * recordsPerPage + index + 1}</td>
                                                                <td>{code?.vehicleGroupValue}</td>
                                                                <td>{code?.name}</td>
                                                                {(Role && Role?.editAccess || Role && Role?.deleteAccess) &&
                                                                    <td>
                                                                        <div className="hstack gap-2">
                                                                            {Role && Role?.editAccess && <button className="btn btn-sm btn-soft-info edit-list" onClick={() => handleEdit(code)}>
                                                                                <i className="ri-pencil-fill align-bottom" />
                                                                            </button>
                                                                            }
                                                                            {Role && Role?.deleteAccess && <button className="btn btn-sm btn-soft-danger remove-list" onClick={() => onClickTodoDelete(code?.id)}>
                                                                                <i className="ri-delete-bin-5-fill align-bottom" />
                                                                            </button>
                                                                            }
                                                                        </div>
                                                                    </td>
                                                                }
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td className='no-data-foun' colSpan={10} >No data found</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="d-none code-view">
                                        <pre className="language-markup" style={{ "height": "275px" }}>
                                            <code>
                                            </code>
                                        </pre>
                                    </div>
                                    {totalCount > 10 &&
                                        <Row className="g-4 mt-3">
                                            <Col className="col-sm">
                                                <div className="d-flex justify-content-sm-end">
                                                    <Pagination count={totalPages} page={currentPage} onChange={onClickPagination} shape="rounded" />
                                                </div>
                                            </Col>
                                        </Row>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state: any) => ({
    getVehicleGroupDropdown: state.MasterReducer.getVehicleGroupDropdown,
    getAllCodeGroup: state.MasterReducer.getAllCodeGroup,
    insertOrUpdateCodeGroup: state.MasterReducer.insertOrUpdateCodeGroup,
    deleteCodeGroup: state.MasterReducer.deleteCodeGroup,
    loading: state.MasterReducer.loading
})

const mapDispatchToProps = (dispatch: any) => ({
    getAllVehicleGroupDropdownAction: (data: any) => {
        dispatch(getAllVehicleGroupDropdownAction(data))
    },
    getAllCodeGroupAction: (data: any) => {
        dispatch(getAllCodeGroupAction(data))
    },
    insertOrUpdateCodeGroupAction: (data: any) => {
        dispatch(insertOrUpdateCodeGroupAction(data))
    },
    deleteCodeGroupAction: (data: any) => {
        dispatch(deleteCodeGroupAction(data))
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(CodeGroup);
