import React, { useEffect, useState } from "react";
import {
  Input,
  Button,
  Col,
  Container,
  Row,
  CardHeader,
  CardBody,
  Card,
} from "reactstrap";
import Select from "react-select";
import DualListBox from "react-dual-listbox";
import "react-dual-listbox/lib/react-dual-listbox.css";
import BreadCrumb from "../../Components/Common/BreadCrumb";

interface CodeOption {
  label: string;
  value: string | number;
}

interface TruthTableRow {
  context: Record<string, boolean>;
  resultString: string;
}

interface FormValidation {
  deCoder?: string;
}

const TruthTable = ({ finalCodes, filteredRows }) => {
  return (
    <Row className="align-items-center g-3 w-100">
      <div className="live-preview">
        <div
          className="table-responsive table-card"
          style={{ maxHeight: "700px" }}
        >
          <table className="table align-middle table-striped mb-0">
            <thead className="table-light">
              <tr>
                {finalCodes.map((code) => (
                  <th key={code}>{code}</th>
                ))}
                <th>Result</th>
              </tr>
            </thead>
            <tbody>
              {filteredRows.map(({ context, resultString }, index) => (
                <tr key={index}>
                  {finalCodes.map((code) => (
                    <td key={code}>{context[code] ? "1" : "0"}</td>
                  ))}
                  <td>{resultString}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Row>
  );
};

const TruthTableGenerator = () => {
  const [inputCodes, setInputCodes] = useState<string | null>(null);
  const [finalCodes, setFinalCodes] = useState<string[]>([]);
  const [formValidation, setFormValiodation] = useState<FormValidation>({});
  const [selectedCodeValues, setSelectedCodeValues] = useState<Record<string, number>>({});
  const [filteredRows, setFilteredRows] = useState<TruthTableRow[]>([]);
  const [selectedCombinations, setSelectedCombinations] = useState<CodeOption[]>([]);
  const [readOnlyValue, setReadOnlyValue] = useState<string>("");
  const [showTruthTable, setShowTruthTable] = useState<boolean>(true);
  const [selectedMultiSelect, setSelectedMultiSelect] = useState<number[]>([]);
  const errors: FormValidation = {};

  const handleCodes = () => {
    if (!inputCodes) {
      errors.deCoder = "Please fill the field";
    }
    setFormValiodation(errors);
    if (inputCodes) {
      const separatedCode = inputCodes.match(/[+-]?\w+/g) || [];
      const cleanedCodes = separatedCode.map(code => code.replace(/^[+-]/, ''));
      const uniqueCodes = [...new Set(cleanedCodes)];
      setFinalCodes(uniqueCodes);
    }
  };

  const handleSelectedCodes = (code, value) => {
    setSelectedCodeValues((prev) => {
      if (prev[code] === value) {
        const updatedValues = { ...prev };
        delete updatedValues[code];
        return updatedValues;
      } else {
        return {
          ...prev,
          [code]: value,
        };
      }
    });
  };

  const combinationOptions = filteredRows.map(({ resultString }, index) => ({
    label: resultString,
    value: index,
  }));

  const handleCombinationChange = (selectedOptions) => {
    setSelectedCombinations(selectedOptions);

    const combinedValue = selectedOptions
      .map((option) => option.label)
      .join(" / ");

    setReadOnlyValue(combinedValue);
  };

  const handleMultiSelectChange = (selectedIndices) => {
    const labels = combinationOptions
      .filter((option) => selectedIndices.includes(option.value))
      .map((option) => option.label)
      .filter((label) => label)
      .join(" / ");

    setSelectedMultiSelect(selectedIndices);
    setReadOnlyValue(labels);
  };

  useEffect(() => {
    if (finalCodes.length > 0) {
      generateFilteredTruthTable();
    }
  }, [selectedCodeValues, finalCodes]);

  const generateFilteredTruthTable = () => {
    const valuesArray = finalCodes;
    const rows = Math.pow(2, valuesArray.length);
    const filtered: TruthTableRow[] = [];

    for (let i = 0; i < rows; i++) {
      const context = {};
      let resultString = "";
      let match = true;

      valuesArray.forEach((code, j) => {
        const value = (i & (1 << j)) !== 0;
        context[code] = value;
        resultString += value ? `+${code}` : `-${code}`;

        if (selectedCodeValues[code] !== undefined) {
          if (selectedCodeValues[code] === 1 && !value) {
            match = false;
          }
          if (selectedCodeValues[code] === 0 && value) {
            match = false;
          }
        }
      });
      if (match) {
        filtered.push({ context, resultString });
      }
    }
    setFilteredRows(filtered);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Truth Table Generator" pageTitle="Code Builder" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className="align-items-center d-flex p-2">
                  <h4 className="card-title mb-0 flex-grow-1">
                    Input Logical Expression
                  </h4>
                </CardHeader>
                <CardBody>
                  <div className="live-preview p-2">
                    <div className="table-responsive table-card">
                      <Row className="align-items-center g-3 w-100">
                        <div className="input-group">
                          <Input
                            type="text"
                            className="form-control"
                            onChange={({ target }) =>
                              setInputCodes(target.value)
                            }
                          />
                          <span>
                            <Button
                              className="input-group-text btn btn-primary btn-custom-blue"
                              onClick={handleCodes}
                              type="submit"
                              color="light"
                            >
                              Get Codes
                            </Button>
                          </span>
                        </div>
                        <span className="text-validation">
                          {formValidation.deCoder}
                        </span>
                      </Row>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {finalCodes.length > 0 && (
            <Row>
              <Col xl={12}>
                <Card>
                  <CardHeader className="align-items-center d-flex p-2">
                    <h4 className="card-title mb-0 flex-grow-1">
                      Select Codes
                    </h4>
                  </CardHeader>
                  <CardBody>
                    <div className="input-group gap-4">
                      {finalCodes.map((code, index) => (
                        <div key={index} className="input-step step-primary">
                          <Button
                            type="button"
                            className="minus"
                            onClick={() => handleSelectedCodes(code, 0)}
                            style={{ background: "#f06548" }}
                          >
                            –
                          </Button>
                          <span
                            className={
                              selectedCodeValues[code] === 1
                                ? "code mx-3 text-success"
                                : selectedCodeValues[code] === 0
                                  ? "code mx-3 text-danger"
                                  : "code mx-3 text-muted"
                            }
                          >
                            {code}
                          </span>
                          <Button
                            type="button"
                            className="plus"
                            onClick={() => handleSelectedCodes(code, 1)}
                            style={{ background: "#0ab39c" }}
                          >
                            +
                          </Button>
                        </div>
                      ))}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          {filteredRows.length > 0 && (
            <Row>
              <Col xl={12}>
                <Card>
                  <CardHeader className="align-items-center d-flex p-2">
                    <h4 className="card-title mb-0 flex-grow-1">
                      {showTruthTable
                        ? "Truth Table"
                        : "Multi Select Truth Table"}
                    </h4>
                    <div className="list-grid-nav hstack gap-1">
                      <Button
                        id="grid-view-button"
                        className={
                          showTruthTable
                            ? "input-group-text btn btn-primary btn-custom-blue"
                            : "btn btn-soft-info nav-link btn-icon fs-14 active filter-button"
                        }
                        onClick={() => setShowTruthTable(false)}
                      >
                        <i className="ri-grid-fill"></i>
                      </Button>
                      <Button
                        id="list-view-button"
                        className={
                          !showTruthTable
                            ? "input-group-text btn btn-primary btn-custom-blue"
                            : "btn btn-soft-info nav-link btn-icon fs-14 active filter-button"
                        }
                        onClick={() => setShowTruthTable(true)}
                      >
                        <i className="ri-list-unordered"></i>
                      </Button>
                    </div>
                  </CardHeader>
                  <CardBody>
                    {showTruthTable ? (
                      <TruthTable
                        finalCodes={finalCodes}
                        filteredRows={filteredRows}
                      />
                    ) : (
                      <div>
                        <DualListBox
                          options={combinationOptions}
                          selected={selectedMultiSelect}
                          onChange={(e) => handleMultiSelectChange(e)}
                          icons={{
                            moveLeft: (
                              <span
                                className="mdi mdi-chevron-left"
                                key="key"
                              />
                            ),
                            moveAllLeft: [
                              <span
                                className="mdi mdi-chevron-double-left"
                                key="key"
                              />,
                            ],
                            moveRight: (
                              <span
                                className="mdi mdi-chevron-right"
                                key="key"
                              />
                            ),
                            moveAllRight: [
                              <span
                                className="mdi mdi-chevron-double-right"
                                key="key"
                              />,
                            ],
                            moveDown: (
                              <span
                                className="mdi mdi-chevron-down"
                                key="key"
                              />
                            ),
                            moveUp: (
                              <span className="mdi mdi-chevron-up" key="key" />
                            ),
                            moveTop: (
                              <span
                                className="mdi mdi-chevron-double-up"
                                key="key"
                              />
                            ),
                            moveBottom: (
                              <span
                                className="mdi mdi-chevron-double-down"
                                key="key"
                              />
                            ),
                          }}
                        />
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          {finalCodes.length > 0 && (
            <Row>
              <Col xl={12}>
                <Card>
                  <CardHeader className="align-items-center d-flex p-2">
                    <h4 className="card-title mb-0 flex-grow-1">
                      Combination Result
                    </h4>
                  </CardHeader>
                  <CardBody>
                    {showTruthTable ? (
                      <div className="group">
                        <Select
                          isMulti={true}
                          options={combinationOptions}
                          onChange={handleCombinationChange}
                          value={selectedCombinations}
                          closeMenuOnSelect={false}
                          styles={{
                            valueContainer: (base) => ({ ...base, maxHeight: 50, overflowY: 'auto' }),
                          }}
                        />
                        <Input
                          type="text"
                          className="form-control mt-3"
                          value={readOnlyValue}
                          placeholder="Select codes to combine ..."
                          readOnly
                        />
                      </div>
                    ) : (
                      <div className="group">
                        <Input
                          type="text"
                          className="form-control mt-3"
                          value={readOnlyValue}
                          placeholder="Select codes to combine ..."
                          readOnly
                        />
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default TruthTableGenerator;
