export const UserManagementActionTypes = {
    INIT_GET_USER_MANAGEMENT_MODULE: "INIT_GET_USER_MANAGEMENT_MODULE",

    GET_ALL_ROLE_REQUEST: "GET_ALL_ROLE_REQUEST",
    GET_ALL_ROLE_SUCCESS: "GET_ALL_ROLE_SUCCESS",
    GET_ALL_ROLE_FAILED: "GET_ALL_ROLE_FAILED",

    INSERT_OR_UPDATE_ROLE_REQUEST: "INSERT_OR_UPDATE_ROLE_REQUEST",
    INSERT_OR_UPDATE_ROLE_SUCCESS: "INSERT_OR_UPDATE_ROLE_SUCCESS",
    INSERT_OR_UPDATE_ROLE_FAILED: "INSERT_OR_UPDATE_ROLE_FAILED",

    DELETE_ROLE_REQUEST: "DELETE_ROLE_REQUEST",
    DELETE_ROLE_SUCCESS: "DELETE_ROLE_SUCCESS",
    DELETE_ROLE_FAILED: "DELETE_ROLE_FAILED",

    GET_ALL_CREATE_USER_REQUEST: "GET_ALL_CREATE_USER_REQUEST",
    GET_ALL_CREATE_USER_SUCCESS: "GET_ALL_CREATE_USER_SUCCESS",
    GET_ALL_CREATE_USER_FAILED: "GET_ALL_CREATE_USER_FAILED",

    GET_ALL_ACCESS_REQUEST: "GET_ALL_ACCESS_REQUEST",
    GET_ALL_ACCESS_SUCCESS: "GET_ALL_ACCESS_SUCCESS",
    GET_ALL_ACCESS_FAILED: "GET_ALL_ACCESS_FAILED",

    GET_ACCESS_BY_GROUP_REQUEST: "GET_ACCESS_BY_GROUP_REQUEST",
    GET_ACCESS_BY_GROUP_SUCCESS: "GET_ACCESS_BY_GROUP_SUCCESS",
    GET_ACCESS_BY_GROUP_FAILED: "GET_ACCESS_BY_GROUP_FAILED",

    INSERT_OR_UPDATE_ACCESS_REQUEST: "INSERT_OR_UPDATE_ACCESS_REQUEST",
    INSERT_OR_UPDATE_ACCESS_SUCCESS: "INSERT_OR_UPDATE_ACCESS_SUCCESS",
    INSERT_OR_UPDATE_ACCESS_FAILED: "INSERT_OR_UPDATE_ACCESS_FAILED",

    DELETE_ACCESS_REQUEST: "DELETE_ACCESS_REQUEST",
    DELETE_ACCESS_SUCCESS: "DELETE_ACCESS_SUCCESS",
    DELETE_ACCESS_FAILED: "DELETE_ACCESS_FAILED",

    GET_ALL_USER_DROP_DOWN_REQUEST: "GET_ALL_USER_DROP_DOWN_REQUEST",
    GET_ALL_USER_DROP_DOWN_SUCCESS: "GET_ALL_USER_DROP_DOWN_SUCCESS",
    GET_ALL_USER_DROP_DOWN_FAILED: "GET_ALL_USER_DROP_DOWN_FAILED",

    INSERT_OR_UPDATE_CREATE_USER_REQUEST: "INSERT_OR_UPDATE_CREATE_USER_REQUEST",
    INSERT_OR_UPDATE_CREATE_USER_SUCCESS: "INSERT_OR_UPDATE_CREATE_USER_SUCCESS",
    INSERT_OR_UPDATE_CREATE_USER_FAILED: "INSERT_OR_UPDATE_CREATE_USER_FAILED",

    GET_ROLE_ACCESS_MAPPING_DROP_DOWN_REQUEST: "GET_ROLE_ACCESS_MAPPING_DROP_DOWN_REQUEST",
    GET_ROLE_ACCESS_MAPPING_DROP_DOWN_SUCCESS: "GET_ROLE_ACCESS_MAPPING_DROP_DOWN_SUCCESS",
    GET_ROLE_ACCESS_MAPPING_DROP_DOWN_FAILED: "GET_ROLE_ACCESS_MAPPING_DROP_DOWN_FAILED",

    INSERT_OR_UPDATE_ROLE_ACCESS_MAPPING_REQUEST: "INSERT_OR_UPDATE_ROLE_ACCESS_MAPPING_REQUEST",
    INSERT_OR_UPDATE_ROLE_ACCESS_MAPPING_SUCCESS: "INSERT_OR_UPDATE_ROLE_ACCESS_MAPPING_SUCCESS",
    INSERT_OR_UPDATE_ROLE_ACCESS_MAPPING_FAILED: "INSERT_OR_UPDATE_CREATE_USER_FAILED",

    GET_ROLE_ACCESS_MAPPING_REQUEST: "GET_ROLE_ACCESS_MAPPING_REQUEST",
    GET_ROLE_ACCESS_MAPPING_SUCCESS: "GET_ROLE_ACCESS_MAPPING_SUCCESS",
    GET_ROLE_ACCESS_MAPPING_FAILED: "GET_ROLE_ACCESS_MAPPING_FAILED",
}
