import React, { useEffect, useState } from 'react';
import { Input } from 'reactstrap';
import { connect } from "react-redux";
import { Card, CardBody, Col, Container, Row, CardHeader, Label, Button } from 'reactstrap';
import { Pagination } from '@mui/material';
import Loader from "../Loader/Loader";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import DeleteModal from '../DeleteModel/DeleteModel';
import { getAllVehicleTypeAction, insertOrUpdateVehicleTypeAction, deleteVehicleTypeAction } from '../../store/actions';

const VehicleType = (props: any) => {
    document.title = "Vehicle Type";
    const { getAllVehicleType, insertOrUpdateVehicleType, deleteVehicleType, loading } = props;
    const [formValidation, setFormValidation] = useState<any>({});
    const [openModel, setOpenModel] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [todo, setTodo] = useState<any>(null);
    const [getAllVehicleTypes, setgetAllVehicleTypes] = useState<any>(null);
    const [totalCount, setTotalCount] = useState<any>("");
    const [sort, setSort] = useState("");
    const [currentPage, setCurrentPage] = useState<any>(1);
    const [searchKeyword, setSearchKeyword] = useState("");
    const [editData, setEditData] = useState<any>({});
    const recordsPerPage = 10;
    const totalPages = Math.ceil(Number(totalCount) / recordsPerPage);
    const LoginRoleData = localStorage.getItem("loginRoleAccess")
    const RoleResponse = JSON.parse(LoginRoleData);
    const Role = RoleResponse?.find(data => data?.name === "Master")?.subModules?.find(val => val?.submenuName === "VehicleType")?.access

    const handleEdit = (type: any) => {
        setOpenModel(true);
        setEditData(type);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const handleAdd = () => {
        setOpenModel(true);
    }

    const handleSave = () => {
        if (editData.name) {
            const vehicle = {
                id: editData?.id,
                name: editData?.name
            };
            setCurrentPage(editData?.id ? currentPage : 1)
            props.insertOrUpdateVehicleTypeAction(vehicle);
        } else {
            setFormValidation({ vehicleType: "Please fill the field" });
        }
    }

    const handleDeleteTodo = (id: any) => {
        setDeleteModal(false);
        props.deleteVehicleTypeAction({ id });
        setCurrentPage(1);
        setTotalCount(getAllVehicleType?.totalRowsCount);
    };

    const onClickTodoDelete = (id: any) => {
        setTodo(id);
        setDeleteModal(true);
    };

    const onSortChange = (keys: any) => {
        const newSortOrder = sort === 'asc' ? 'desc' : 'asc';
        setSort(newSortOrder);
        props.getAllVehicleTypeAction({ page: currentPage, search: searchKeyword, sort: newSortOrder, OrderByColumnName: keys });
    };

    const onSearchChange = (event) => {
        setSearchKeyword(event.target.value);
        props.getAllVehicleTypeAction({ page: "1", search: event.target.value, sort: sort, OrderByColumnName: "" });
        setCurrentPage(1);
    };

    const handleClear = () => {
        setEditData({});
    }

    const onClickPagination = (event, value) => {
        setCurrentPage(value);
    };

    useEffect(() => {
        props.getAllVehicleTypeAction({ page: currentPage, search: searchKeyword, sort: sort, OrderByColumnName: "" });
    }, [currentPage, deleteVehicleType]);

    useEffect(() => {
        setgetAllVehicleTypes(getAllVehicleType);
        setTotalCount(getAllVehicleType?.totalRowsCount);
    }, [getAllVehicleType])

    useEffect(() => {
        if (insertOrUpdateVehicleType?.httpStatusCode == 200) {
            props.getAllVehicleTypeAction({ page: currentPage, search: searchKeyword, sort: sort, OrderByColumnName: "" });
            setOpenModel(false);
            setFormValidation({});
            setEditData({});
        }
    }, [insertOrUpdateVehicleType])

    return (
        <React.Fragment>
            {loading && <Loader />}
            <DeleteModal
                show={deleteModal}
                onDeleteClick={() => handleDeleteTodo(todo)}
                onCloseClick={() => setDeleteModal(false)}
            />
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Vehicle Type" pageTitle="Masters" />
                    {openModel &&
                        <Row>
                            <Col xl={2}></Col>
                            <Col xl={8}>
                                <Card>
                                    <CardHeader className='card-bg'><h4 className="card-title mb-0 ">Add Vehicle Type</h4> </CardHeader>
                                    <CardBody>
                                        <div>
                                            <div>
                                                <Row>
                                                    <Col xl={6} md={6}>
                                                        <div>
                                                            <Label htmlFor="placeholderInput" className="form-label">Add Vehicle Type <span style={{ color: 'red' }}>*</span></Label>
                                                            <Input type="text" className="form-control" id="placeholderInput" placeholder="Enter vehicle type" value={editData.name || ''} onChange={(e) => setEditData({ ...editData, name: e.target.value })} />
                                                            <span className="text-validation">{formValidation.vehicleType}</span>
                                                            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col lg={4} md={6}>
                                                        <div className="pt-4">
                                                            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                                <Button className="btn btn-primary mb-2 btn-custom-blue" type="button" color='light' onClick={() => handleSave()}>Save</Button>
                                                                <Button className="btn btn-primary me-3 mb-2 btn-custom-blue" type="button" color='light' onClick={handleClear}>Clear</Button>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>}
                    <Row>
                        <Col xl={12}>
                            <Card>
                                <CardHeader className="align-items-center d-flex card-bg">
                                    <h4 className="card-title mb-0 flex-grow-1">Vehicle Types</h4>
                                    <div className="flex-shrink-0">
                                        <div className="form-check form-switch form-switch-right form-switch-md">
                                            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                {Role && Role?.createAccess && <Button className="btn btn-primary btn-custom-blue" type="button" color='light' onClick={() => handleAdd()}>+ Add</Button>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-sm-end">
                                        <div className="search-box ms-2">
                                            <input type="text" className="form-control search" placeholder="Search..." onChange={onSearchChange} />
                                            <i className="ri-search-line search-icon"></i>
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardBody className='card-padding'>
                                    <div className="live-preview">
                                        <div className="table-responsive table-card">
                                            <table className="table align-middle table-nowrap table-striped-columns mb-0">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th scope="col">S.No <i className=" bx bx-sort me-3" style={{ color: "#182b45" }} ></i></th>
                                                        <th scope="col">Vehicle Type <i onClick={() => onSortChange('name')} className=" bx bx-sort me-3" style={{ color: "#182b45" }} ></i></th>
                                                        {(Role && Role?.editAccess || Role && Role?.deleteAccess) && <th scope="col" style={{ width: 50 }}>Action</th>}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {getAllVehicleTypes?.dataRows && getAllVehicleTypes?.dataRows?.length > 0 ? (
                                                        getAllVehicleTypes?.dataRows?.map((type, index) => (
                                                            <tr key={type?.id}>
                                                                <td>{(currentPage - 1) * recordsPerPage + index + 1}</td>
                                                                <td>{type?.name}</td>
                                                                {(Role && Role?.editAccess || Role && Role?.deleteAccess) &&
                                                                    <td>
                                                                        <div className="hstack gap-2">
                                                                            {Role && Role?.editAccess &&
                                                                                <button className="btn btn-sm btn-soft-info edit-list" onClick={() => handleEdit(type)}>
                                                                                    <i className="ri-pencil-fill align-bottom" />
                                                                                </button>}
                                                                            {Role && Role?.deleteAccess &&
                                                                                <button className="btn btn-sm btn-soft-danger remove-list" onClick={() => onClickTodoDelete(type?.id)}>
                                                                                    <i className="ri-delete-bin-5-fill align-bottom" />
                                                                                </button>}
                                                                        </div>
                                                                    </td>}
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td className='no-data-foun' colSpan={10} >No data found</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>

                                        </div>
                                    </div>
                                    {totalCount > 10 &&
                                        <Row className="g-4 mt-3">
                                            <Col className="col-sm">
                                                <div className="d-flex justify-content-sm-end">
                                                    <Pagination count={totalPages} page={currentPage} onChange={onClickPagination} shape="rounded" />
                                                </div>
                                            </Col>
                                        </Row>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({
    getAllVehicleType: state.MasterReducer.getAllVehicleType,
    insertOrUpdateVehicleType: state.MasterReducer.insertOrUpdateVehicleType,
    deleteVehicleType: state.MasterReducer.deleteVehicleType,
    getLoginData: state.AuthenticationReducer?.getLoginData?.data?.userRoleAccessList,
    loading: state.MasterReducer.loading
})

const mapDispatchToProps = (dispatch) => ({
    getAllVehicleTypeAction: (data) => {
        dispatch(getAllVehicleTypeAction(data))
    },
    insertOrUpdateVehicleTypeAction: (data) => {
        dispatch(insertOrUpdateVehicleTypeAction(data))
    },
    deleteVehicleTypeAction: (data) => {
        dispatch(deleteVehicleTypeAction(data))
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(VehicleType);
