import { UserManagementActionTypes } from "./actionType";

const initialState = {
    isLoading: false,
    loading: false,
    items: [],
    errors: {},
    getAllRole: {},
    insertOrUpdateRole: {},
    deleteRole: {},
    getAllCreateUser: {},
    getAllAccess: {},
    getAllAccessByGroup: {},
    insertOrUpdateAccess: {},
    deleteAccess: {},
    getAllUserDropdown: {},
    insertOrUpdateCreateUser: {},
    getRollAccessMappingDropDown: {},
    insertOrUpdateRoleAccessMapping: {},
    getRollAccessMapping: {},
}

const UserManagementReducer = (state = initialState, action) => {
  const response = action.data;
  switch (action.type) {
    case UserManagementActionTypes.INIT_GET_USER_MANAGEMENT_MODULE:
      return {
        ...state,
        loading: false,
        isLoading: false,
        items: [],
        getAllRole: {},
        insertOrUpdateRole: {},
        deleteRole: {},
        getAllCreateUser: {},
        getAllAccess: {},
        getAllAccessByGroup: {},
        insertOrUpdateAccess: {},
        deleteAccess: {},
        getAllUserDropdown: {},
        insertOrUpdateCreateUser: {},
        getRollAccessMappingDropDown: {},
        insertOrUpdateRoleAccessMapping: {},
        getRollAccessMapping: {},
      };
    case UserManagementActionTypes.GET_ALL_ROLE_REQUEST:
      return { ...state, loading: true, getAllRole: {} };
    case UserManagementActionTypes.GET_ALL_ROLE_SUCCESS:
      return { ...state, loading: false, getAllRole: response.data };
    case UserManagementActionTypes.GET_ALL_ROLE_FAILED:
      return { ...state, loading: false, errors: action.error };

    case UserManagementActionTypes.INSERT_OR_UPDATE_ROLE_REQUEST:
      return { ...state, loading: true, insertOrUpdateRole: {} };
    case UserManagementActionTypes.INSERT_OR_UPDATE_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
        insertOrUpdateRole: response?.payload,
      };
    case UserManagementActionTypes.INSERT_OR_UPDATE_ROLE_FAILED:
      return { ...state, loading: false, errors: action.error };

    case UserManagementActionTypes.DELETE_ROLE_REQUEST:
      return { ...state, loading: true, deleteRole: {} };
    case UserManagementActionTypes.DELETE_ROLE_SUCCESS:
      return { ...state, loading: false, deleteRole: response.data };
    case UserManagementActionTypes.DELETE_ROLE_FAILED:
      return { ...state, loading: false, errors: action.error };

    case UserManagementActionTypes.GET_ALL_CREATE_USER_REQUEST:
      return { ...state, loading: true, getAllCreateUser: {} };
    case UserManagementActionTypes.GET_ALL_CREATE_USER_SUCCESS:
      return { ...state, loading: false, getAllCreateUser: response.data };
    case UserManagementActionTypes.GET_ALL_CREATE_USER_FAILED:
      return { ...state, loading: false, errors: action.error };

    case UserManagementActionTypes.GET_ALL_ACCESS_REQUEST:
      return { ...state, loading: true, getAllAccess: {} };
    case UserManagementActionTypes.GET_ALL_ACCESS_SUCCESS:
      return { ...state, loading: false, getAllAccess: response.data };
    case UserManagementActionTypes.GET_ALL_ACCESS_FAILED:
      return { ...state, loading: false, errors: action.error };

    case UserManagementActionTypes.GET_ACCESS_BY_GROUP_REQUEST:
      return { ...state, loading: true, getAllAccessByGroup: {} };
    case UserManagementActionTypes.GET_ACCESS_BY_GROUP_SUCCESS:
      return { ...state, loading: false, getAllAccessByGroup: response.data };
    case UserManagementActionTypes.GET_ACCESS_BY_GROUP_FAILED:
      return { ...state, loading: false, errors: action.error };

    case UserManagementActionTypes.INSERT_OR_UPDATE_ACCESS_REQUEST:
      return { ...state, loading: true, insertOrUpdateAccess: {} };
    case UserManagementActionTypes.INSERT_OR_UPDATE_ACCESS_SUCCESS:
      return {
        ...state,
        loading: false,
        insertOrUpdateAccess: response?.payload,
      };
    case UserManagementActionTypes.INSERT_OR_UPDATE_ACCESS_FAILED:
      return { ...state, loading: false, errors: action.error };

    case UserManagementActionTypes.DELETE_ACCESS_REQUEST:
      return { ...state, loading: true, deleteAccess: {} };
    case UserManagementActionTypes.DELETE_ACCESS_SUCCESS:
      return { ...state, loading: false, deleteAccess: response.data };
    case UserManagementActionTypes.DELETE_ACCESS_FAILED:
      return { ...state, loading: false, errors: action.error };

    case UserManagementActionTypes.GET_ALL_USER_DROP_DOWN_REQUEST:
      return { ...state, loading: true, getAllUserDropdown: {} };
    case UserManagementActionTypes.GET_ALL_USER_DROP_DOWN_SUCCESS:
      return { ...state, loading: false, getAllUserDropdown: response.data };
    case UserManagementActionTypes.GET_ALL_USER_DROP_DOWN_FAILED:
      return { ...state, loading: false, errors: action.error };

    case UserManagementActionTypes.INSERT_OR_UPDATE_CREATE_USER_REQUEST:
      return { ...state, loading: true, insertOrUpdateCreateUser: {} };
    case UserManagementActionTypes.INSERT_OR_UPDATE_CREATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        insertOrUpdateCreateUser: response?.payload,
      };
    case UserManagementActionTypes.INSERT_OR_UPDATE_CREATE_USER_FAILED:
      return { ...state, loading: false, errors: action.error };

    case UserManagementActionTypes.GET_ROLE_ACCESS_MAPPING_DROP_DOWN_REQUEST:
      return { ...state, loading: true, getRollAccessMappingDropDown: {} };
    case UserManagementActionTypes.GET_ROLE_ACCESS_MAPPING_DROP_DOWN_SUCCESS:
      return {
        ...state,
        loading: false,
        getRollAccessMappingDropDown: response.data,
      };
    case UserManagementActionTypes.GET_ROLE_ACCESS_MAPPING_DROP_DOWN_FAILED:
      return { ...state, loading: false, errors: action.error };

    case UserManagementActionTypes.INSERT_OR_UPDATE_ROLE_ACCESS_MAPPING_REQUEST:
      return { ...state, loading: true, insertOrUpdateRoleAccessMapping: {} };
    case UserManagementActionTypes.INSERT_OR_UPDATE_ROLE_ACCESS_MAPPING_SUCCESS:
      return {
        ...state,
        loading: false,
        insertOrUpdateRoleAccessMapping: response?.payload,
      };
    case UserManagementActionTypes.INSERT_OR_UPDATE_ROLE_ACCESS_MAPPING_FAILED:
      return { ...state, loading: false, errors: action.error };

    case UserManagementActionTypes.GET_ROLE_ACCESS_MAPPING_REQUEST:
      return { ...state, loading: true, getRollAccessMapping: {} };
    case UserManagementActionTypes.GET_ROLE_ACCESS_MAPPING_SUCCESS:
      return { ...state, loading: false, getRollAccessMapping: response.data };
    case UserManagementActionTypes.GET_ROLE_ACCESS_MAPPING_FAILED:
      return { ...state, loading: false, errors: action.error };

    default: {
      return state;
    }
  }
};

export default UserManagementReducer;
