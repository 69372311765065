import React, { useEffect, useState } from "react";
import { Button, CardBody, Col, Container, Row, CardHeader, Label, Card, CardFooter } from "reactstrap";
import Select from "react-select";
import * as XLSX from "xlsx-js-style";
import { saveAs } from "file-saver";
import { connect } from "react-redux";
import Loader from "../Loader/Loader";
import "../CodeConfiguration/style.css";
import { getDropdownVersionComparisonAction, getVersionComparisonTableAction } from "../../store/actions";
import BreadCrumb from "../../Components/Common/BreadCrumb";

const VersionComparisons = (props: any) => {
  document.title = "Version Comparisons";
  const { dropdownVersionComparison, versionTableData, loading } = props;
  const [selectedVechicle, setSelectedVechicle] = useState<any>(null);
  const [selectedVechicleVersion1, setSelectedVechicleVersion1] = useState<any>(null);
  const [selectedVechicleVersion2, setSelectedVechicleVersion2] = useState<any>(null);
  const errors: { [key: string]: string } = {};
  const [formValidation, setFormValiodation] = useState<{[key: string]: string }>({});
  const [showTableList, setShowTableList] = useState(false);
  const [options, setOptions] = useState<any>([]);
  const [tableData, setTableData] = useState<any>([]);
  const [additionalInfo, setAdditionalInfo] = useState<any>([]);

  useEffect(() => {
    props.getDropdownVersionComparisonAction(
      selectedVechicle ? selectedVechicle?.id : 0
    );
  }, []);

  useEffect(() => {
    setOptions(dropdownVersionComparison);
  }, [dropdownVersionComparison]);

  useEffect(() => {
    if (loading) {
      setShowTableList(false);
    }
  }, [loading]);

  const vehicleGroup = options?.vehicleGroup?.map((item) => ({
    value: item.value,
    label: item.value,
    id: item.id,
  }));

  const allVersions = options?.version?.map((item) => ({
    value: item.value,
    label: item.value,
    id: item.id,
  }));

  const filteredVersion1Options = allVersions?.filter(
    (version) => version.value !== selectedVechicleVersion2?.value
  );

  const filteredVersion2Options = allVersions?.filter(
    (version) => version.value !== selectedVechicleVersion1?.value
  );

  const handlevechicleGroupChange = (selectedOption) => {
    if (selectedOption) {
      setSelectedVechicle(selectedOption);
      props.getDropdownVersionComparisonAction(selectedOption?.id);
    }
  };

  const handleCompare = () => {
    if (selectedVechicle === null) {
      errors.vehicleGroup = "Please select the vehicle group";
    }
    if (selectedVechicleVersion1 === null) {
      errors.version1 = "Please select the version";
    }
    if (selectedVechicleVersion2 === null) {
      errors.version2 = "Please select the version";
    }
    setFormValiodation(errors);
    if (Object.keys(errors).length === 0) {
      const dataToSave = {
        VehicleGroup: selectedVechicle?.id,
        Versions: [selectedVechicleVersion1?.id, selectedVechicleVersion2?.id],
      };
      props.getVersionComparisonTableAction(dataToSave);
    }
  };

  useEffect(() => {
    if (
      tableData &&
      Object.keys(tableData).length > 0 &&
      selectedVechicle !== null
    ) {
      setShowTableList(true);
    }
  }, [tableData]);

  const handleClear = () => {
    setSelectedVechicle(null);
    setSelectedVechicleVersion1(null);
    setSelectedVechicleVersion2(null);
    setShowTableList(false);
    setFormValiodation({});
    props.getDropdownVersionComparisonAction(0);
  };

  useEffect(() => {
    if (versionTableData) {
      setTableData(versionTableData.codeVehicleRemarks);
      setAdditionalInfo(versionTableData.versionsDetails);
    }
  }, [versionTableData]);

  const vehicleHeaders =
    tableData && tableData.length > 0
      ? tableData[0].vehicleCountryGroup.map((vehicle) => vehicle.vehicleModel)
      : [];

  const countryGroup =
    tableData?.length > 0
      ? tableData[0]?.vehicleCountryGroup.map((head) =>
          head.countryGroupList.map((head) => head.countryGroup)
        )
      : [];

  const version =
    tableData?.length > 0
      ? tableData[0]?.vehicleCountryGroup[0]?.countryGroupList[0]?.versionOption.map(
          (head) => head.version
        )
      : [];

  const downloadExcel = () => {
    const table: any = document.getElementById("table data");
    const worksheet: any = XLSX.utils.table_to_sheet(table ?? {});
    const headerRange = XLSX.utils.decode_range(worksheet["!ref"]);
    const data: any = [];
    for (let row of table.rows) {
      const rowData: any = [];
      for (let cell of row.cells) {
        rowData.push(cell.innerText.trim() === "" ? "" : cell.innerText.trim());
      }
      data.push(rowData);
    }
    const maxColumns = headerRange.e.c;
    const maxRows = headerRange.e.r;
    for (let R = 0; R < 3; R++) {
      for (let C = 0; C <= maxColumns; C++) {
        const cellAddress = XLSX.utils.encode_cell({ r: R, c: C });
        if (!worksheet[cellAddress]) worksheet[cellAddress] = { v: " " };
        worksheet[cellAddress].s = {
          fill: {
            fgColor: { rgb: "333333" },
          },
          font: {
            color: { rgb: "FFFFFF" },
            bold: true,
          },
          alignment: {
            horizontal: "center",
            vertical: "center",
          },
          border: {
            top: { style: "thin", color: { rgb: "FFFFFF" } },
            bottom: { style: "thin", color: { rgb: "FFFFFF" } },
            left: { style: "thin", color: { rgb: "FFFFFF" } },
            right: { style: "thin", color: { rgb: "FFFFFF" } },
          },
        };
      }
    }
    for (let R = 3; R <= maxRows; R++) {
      for (let C = 10; C <= maxColumns; C++) {
        const cellAddress = XLSX.utils.encode_cell({ r: R, c: C });
        if (!worksheet[cellAddress].v) worksheet[cellAddress] = { v: " " };
        if (
          worksheet[cellAddress].v &&
          worksheet[cellAddress].v.trim() !== ""
        ) {
          worksheet[cellAddress].s = {
            fill: {
              fgColor: { rgb: "D3D3D3" },
            },
            alignment: {
              horizontal: "center",
              vertical: "center",
            },
            border: {
              top: { style: "thin", color: { rgb: "FFFFFF" } },
              bottom: { style: "thin", color: { rgb: "FFFFFF" } },
              left: { style: "thin", color: { rgb: "FFFFFF" } },
              right: { style: "thin", color: { rgb: "FFFFFF" } },
            },
          };
        } else {
          worksheet[cellAddress].s = {
            fill: {
              fgColor: { rgb: "FFC0CB" },
            },
            alignment: {
              horizontal: "center",
              vertical: "center",
            },
            border: {
              top: { style: "thin", color: { rgb: "FFFFFF" } },
              bottom: { style: "thin", color: { rgb: "FFFFFF" } },
              left: { style: "thin", color: { rgb: "FFFFFF" } },
              right: { style: "thin", color: { rgb: "FFFFFF" } },
            },
          };
        }
      }
    }
    for (let R = 2; R <= headerRange.e.r; ++R) {
      for (let C = headerRange.s.c; C <= headerRange.e.c; ++C) {
        const cellAddress = XLSX.utils.encode_cell({ r: R, c: C });
        if (!worksheet[cellAddress]) continue;

        worksheet[cellAddress].s = {
          ...worksheet[cellAddress].s,
          alignment: {
            vertical: "center",
            horizontal: "center",
          },
        };
      }
    }
    for (let R = 3; R <= headerRange.e.r; ++R) {
      for (let C = 0; C <= 5; ++C) {
        const cellAddress = XLSX.utils.encode_cell({ r: R, c: C });
        if (!worksheet[cellAddress]) continue;

        worksheet[cellAddress].s = {
          alignment: {
            vertical: "center",
            horizontal: "center",
            wrapText: true,
          },
        };
      }
    }
    const columnWidths: any = [];
    for (let C = headerRange.s.c; C <= headerRange.e.c; ++C) {
      let maxWidth = 40;
      for (let R = headerRange.s.r; R <= headerRange.e.r; ++R) {
        const cellAddress = XLSX.utils.encode_cell({ r: R, c: C });
        const cell = worksheet[cellAddress];
      }
      columnWidths.push({ wch: maxWidth });
    }
    const now = new Date();
    const dateTimeString = `Generated on: ${now.toLocaleDateString()} ${now.toLocaleTimeString()}`;
    const lastRowIndex = maxRows + 3; // New row index for date/time
    const dateTimeCellAddress = XLSX.utils.encode_cell({
      r: lastRowIndex,
      c: 0,
    });
    worksheet[dateTimeCellAddress] = { v: dateTimeString, t: "s" };
    worksheet[dateTimeCellAddress].s = {
      font: {
        color: { rgb: "000000" },
        bold: true,
      },
      alignment: {
        horizontal: "left",
        vertical: "center",
      },
    };
    worksheet["!ref"] = XLSX.utils.encode_range({
      s: { r: 0, c: 0 },
      e: { r: lastRowIndex, c: maxColumns },
    });
    worksheet["!cols"] = columnWidths;
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      workbook,
      worksheet,
      `sheet1-${now.getMonth() + 1}-${now.getDate()}-${now.getFullYear()}`
    );
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    saveAs(
      blob,
      `Version_Comparisons_Excel_Report_${
        now.getMonth() + 1
      }-${now.getDate()}-${now.getFullYear()}_${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}.xlsx`
    );
  };

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Version Comparisons" pageTitle="Code List" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0">Version Comparisons</h4>
                </CardHeader>
                <CardBody>
                  <div>
                    <div>
                      <Row>
                        <Col lg={4} md={6}>
                          <div className="mb-3">
                            <Label
                              htmlFor="choices-single-default"
                              className="form-label text-muted"
                            >
                              Vehicle Group
                            </Label>
                            <Select
                              value={selectedVechicle}
                              isMulti={false}
                              options={vehicleGroup}
                              onChange={(selectedOption) =>
                                handlevechicleGroupChange(selectedOption)
                              }
                            />
                            <span className="text-validation">
                              {formValidation.vehicleGroup}
                            </span>
                          </div>
                        </Col>
                        <Col lg={4} md={6}>
                          <div className="mb-3">
                            <Label
                              htmlFor="choices-single-groups"
                              className="form-label text-muted"
                            >
                              Version 1
                            </Label>
                            <Select
                              value={selectedVechicleVersion1}
                              isMulti={false}
                              onChange={(selectedOption) =>
                                setSelectedVechicleVersion1(selectedOption)
                              }
                              options={filteredVersion1Options}
                            />
                            <span className="text-validation">
                              {formValidation.version1}
                            </span>
                          </div>
                        </Col>
                        <Col lg={4} md={6}>
                          <div className="mb-3">
                            <Label
                              htmlFor="choices-single-groups"
                              className="form-label text-muted"
                            >
                              Version 2
                            </Label>
                            <Select
                              value={selectedVechicleVersion2}
                              isMulti={false}
                              onChange={(selectedOption) =>
                                setSelectedVechicleVersion2(selectedOption)
                              }
                              options={filteredVersion2Options}
                            />
                            <span className="text-validation">
                              {formValidation.version2}
                            </span>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </CardBody>
                <CardFooter>
                  <div>
                    <div>
                      <div className="d-grid gap-2 d-md-flex justify-content-md-end ">
                        <Button
                          className="btn btn-primary"
                          type="button"
                          color="light"
                          onClick={() => {
                            handleClear();
                          }}
                        >
                          Clear
                        </Button>
                        <Button
                          className="btn btn-primary"
                          type="button"
                          color="success"
                          onClick={() => {
                            handleCompare();
                          }}
                        >
                          Compare
                        </Button>
                      </div>
                    </div>
                  </div>
                </CardFooter>
              </Card>
            </Col>
          </Row>
          {showTableList && additionalInfo.length > 0 && (
            <Row>
              <Col lg={12}>
                <Card>
                  <CardHeader>
                    <h4 className="card-title mb-0">Version Information</h4>
                  </CardHeader>

                  <CardBody>
                    <div className="d-flex justify-content-between">
                      {additionalInfo.map((info, index) => (
                        <div
                          key={index}
                          style={{ marginBottom: "20px", width: "40%" }}
                        >
                          <span className="d-flex gap-5">
                            <p>
                              <strong>Version:</strong> {info.version}
                            </p>
                            <p>
                              <strong>Requester:</strong>{" "}
                              {info.requester.join(", ")}
                            </p>
                            {info.combinedBy && (
                              <p>
                                <strong>Combined By:</strong>
                                {info.combinedBy}
                              </p>
                            )}
                          </span>
                          <p>
                            <strong>Approvers:</strong>{" "}
                          </p>
                          <table className="table align-middle table-striped table-striped-columns table-bordered border-dark mb-0">
                            <thead
                              className="table-light"
                              style={{
                                position: "sticky",
                                top: "0",
                                zIndex: "1",
                              }}
                            >
                              <tr>
                                <th scope="col" className="center">
                                  Role
                                </th>
                                <th scope="col" className="center">
                                  Name
                                </th>{" "}
                                <th scope="col" className="center">
                                  Reviwed On
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {info.approvers.map((approver, i) => (
                                <tr key={`${index}-${i}`}>
                                  <td className="center">{approver.role}</td>
                                  <td className="center">
                                    {approver.approverName}
                                  </td>
                                  <td className="center">
                                    {approver.reviewedOn}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      ))}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          {showTableList && (
            <Row>
              <Col xl={12}>
                <Card>
                  <CardHeader className="align-items-center d-flex card-bg">
                    <h4 className="card-title mb-0 flex-grow-1">
                      Comparison Table
                    </h4>
                    <div className="flex-shrink-0">
                      <div className="form-check form-switch form-switch-right form-switch-md">
                        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                          <button
                            type="button"
                            className="btn btn-info"
                            onClick={() => downloadExcel()}
                          >
                            <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                            Export Excel
                          </button>
                        </div>
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody className="card-padding">
                    <div className="live-preview">
                      <div
                        className="table-responsive table-card"
                        style={{ maxHeight: "700px", minHeight: "300px" }}
                      >
                        <table
                          id="table data"
                          className="table align-middle table-striped table-striped-columns table-bordered border-dark mb-0"
                        >
                          <thead
                            className="table-light"
                            style={{
                              position: "sticky",
                              top: "0",
                              zIndex: "1",
                            }}
                          >
                            <tr>
                              <th scope="col" rowSpan={3} className="center">
                                Code Group
                              </th>
                              <th scope="col" rowSpan={3} className="center">
                                Code Description
                              </th>
                              <th scope="col" rowSpan={3} className="center">
                                Code
                              </th>
                              <th scope="col" rowSpan={3} className="center">
                                Notes
                              </th>
                              {tableData[0]?.remarks
                                ?.map((r) => r.header)
                                .map((header, index) => (
                                  <th
                                    key={index}
                                    scope="col"
                                    rowSpan={3}
                                    className="center"
                                  >
                                    {header}
                                  </th>
                                ))}
                              {tableData[0]?.cr
                                ?.map((r) => r.header)
                                .map((header, index) => (
                                  <th
                                    key={index}
                                    scope="col"
                                    rowSpan={3}
                                    className="center"
                                  >
                                    {header}
                                  </th>
                                ))}
                              {tableData[0]?.cO_KEM
                                ?.map((r) => r.header)
                                .map((header, index) => (
                                  <th
                                    key={index}
                                    scope="col"
                                    rowSpan={3}
                                    className="center"
                                  >
                                    {header}
                                  </th>
                                ))}
                              {vehicleHeaders.map(
                                (vehicleName, vehicleIndex) => {
                                  const colSpan = tableData.map((data) =>
                                    data.vehicleCountryGroup[
                                      vehicleIndex
                                    ]?.countryGroupList
                                      .map(
                                        (country) =>
                                          country.versionOption.length
                                      )
                                      .reduce((acc, curr) => acc + curr, 0)
                                  )[0];
                                  return (
                                    <th
                                      scope="col"
                                      key={vehicleIndex}
                                      colSpan={colSpan}
                                      className="center"
                                    >
                                      {vehicleName}
                                    </th>
                                  );
                                }
                              )}
                            </tr>
                            <tr>
                              {countryGroup.map((head) =>
                                head.map((final, index) => (
                                  <th
                                    scope="col"
                                    key={index}
                                    colSpan={2}
                                    className="center"
                                  >
                                    {final}
                                  </th>
                                ))
                              )}
                            </tr>
                            <tr>
                              {countryGroup.map((head) =>
                                head.map((final) =>
                                  version.map((head, index) => (
                                    <th
                                      scope="col"
                                      key={index}
                                      className="center"
                                    >
                                      {head}
                                    </th>
                                  ))
                                )
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {tableData !== null &&
                            typeof tableData === "object" &&
                            tableData?.length > 0 ? (
                              tableData.map((data, idx) => (
                                <tr key={idx}>
                                  <td className="center">{data.codeGroup}</td>
                                  <td className="center">
                                    {data.codeDescription}
                                  </td>
                                  <td className="center">{data.code}</td>
                                  <td className="center">{data.Notes}</td>
                                  {data.remarks.map((remark, idx) => (
                                    <td key={idx} className="center">
                                      {remark.Value}
                                    </td>
                                  ))}
                                  {data.cO_KEM.map((remark, idx) => (
                                    <td key={idx} className="center">
                                      {remark.Value}
                                    </td>
                                  ))}
                                  {data.cr.map((remark, idx) => (
                                    <td key={idx} className="center">
                                      {remark.Value}
                                    </td>
                                  ))}
                                  {data.vehicleCountryGroup.flatMap(
                                    (vehicle, vehicleIdx) =>
                                      vehicle.countryGroupList.flatMap(
                                        (country, countryIdx) => {
                                          const versionOption =
                                            country.versionOption;
                                          const hasMismatch =
                                            versionOption.length === 2 &&
                                            versionOption[0].option !==
                                              versionOption[1].option;
                                          return versionOption.map(
                                            (version, versionIdx) => (
                                              <td
                                                key={`${idx}-${vehicleIdx}-${countryIdx}-${versionIdx}`}
                                                className="center"
                                                style={{
                                                  backgroundColor: hasMismatch
                                                    ? "yellow"
                                                    : "transparent",
                                                  color: hasMismatch
                                                    ? "red"
                                                    : "black",
                                                }}
                                              >
                                                {version.option}
                                              </td>
                                            )
                                          );
                                        }
                                      )
                                  )}
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan={8} className="text-center">
                                  No Data
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  dropdownVersionComparison: state.CodeListReducer.dropdownVersionComparison,
  versionTableData: state.CodeListReducer.versionTableData,
  loading: state.CodeListReducer.loading,
});

const mapDispatchToProps = (dispatch) => ({
  getDropdownVersionComparisonAction: (data) => {
    dispatch(getDropdownVersionComparisonAction(data));
  },
  getVersionComparisonTableAction: (data) => {
    dispatch(getVersionComparisonTableAction(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(VersionComparisons);
