import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { Card, CardBody, Col, Container, Input, Label, Row, Button, Form } from 'reactstrap';
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { loginsAction } from '../../store/actions';
import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import { loginUser } from "../../store/actions";
import withRouter from '../../Components/Common/withRouter';
import { createSelector } from 'reselect';
import { useNavigate } from "react-router-dom";
import Loader from '../Loader/Loader';

const Login = (props: any) => {
    const { getLoginData, loader } = props;
    const history = useNavigate();
    const dispatch = useDispatch();
    const selectLayoutState = (state) => state.Account;
    const selectLayoutProperties = createSelector(
        selectLayoutState,
        (layout) => ({
            user: layout.user,
            errorMsg: layout.errorMsg,
            loading: layout.loading,
            error: layout.error,
        })
    );
    const {
        user,
        errorMsg,
        loading,
        error
    } = useSelector(selectLayoutProperties);
    
    const [userLogin, setUserLogin] = useState<any>([]);
    const [passwordShow, setPasswordShow] = useState(false);
    const [loginData, setLoginData] = useState<any>({});
    const errors: any = {}
    const [formValidation, setFormValiodation] = useState<any>({});

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: userLogin.email || "admin@themesbrand.com" || '',
            password: userLogin.password || "123456" || '',
        },
        validationSchema: Yup.object({
            email: Yup.string().required("Please Enter Your Email"),
            password: Yup.string().required("Please Enter Your Password"),
        }),
        onSubmit: (values) => {
            dispatch(loginUser(values, props.router.navigate));
        }
    });

    document.title = "D-Code";

    const onOpenSign = () => {
        const errors = {};
        if (!loginData.emailId) {
            errors.emailId = 'Please fill the emailId';
        } else if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(loginData.emailId)) {
            errors.emailId = 'Please fill a valid email address';
        }
        if (!loginData.password) {
            errors.password = 'Please fill the password';
        }
        setFormValiodation(errors);
        if (Object.keys(errors).length === 0) {
            const data = {
                emailId: loginData.emailId,
                password: loginData.password,
                returnURL: ""
            };
            props.loginsAction(data);
        }
    };
    

    useEffect(() => {
        if (getLoginData?.payload?.httpStatusCode === 200) {
            const codeBuilderAccess = getLoginData?.data?.userRoleAccessList?.find(role => role.name === "CodeBuilder")?.hasAccess;
            const codeListAccess = getLoginData?.data?.userRoleAccessList?.find(role => role.name === "CodeList")?.hasAccess;
            if (codeListAccess) {
                history("/code-list");
            } else if (codeBuilderAccess) {
                history("/boolean-logic-creation");
            } else {
                history("/code-configuration");
            }
            localStorage.setItem('loginRoleAccess', JSON.stringify(getLoginData?.data?.userRoleAccessList));
            localStorage.setItem('authToken', getLoginData?.data?.jwt);
            localStorage.setItem('refreshToken', getLoginData?.data?.refreshToken);
            localStorage.setItem('UsersName', getLoginData?.data?.userName);
            localStorage.setItem('RolesRes', JSON.stringify(getLoginData?.data?.userRoles));
        }
    }, [getLoginData])

    return (
        <React.Fragment>
              {loader && <Loader />}
            <ParticlesAuth>
                <div className="auth-page-content">
                    <Container>
                        <Row>
                        </Row>
                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="mt-4">
                                    <CardBody className="p-4">
                                        <div className="text-center mt-2">
                                            <h5 className="text-primary">Welcome Back !</h5>
                                            <p className="text-muted">Sign in to continue</p>
                                        </div>
                                        <div className="p-2 mt-4">
                                            <Form
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    validation.handleSubmit();
                                                    return false;
                                                }}
                                                action="#">

                                                <div className="mb-3">
                                                    <Label htmlFor="email" className="form-label">Email</Label>
                                                    <Input
                                                        name="email"
                                                        className="form-control"
                                                        placeholder="Enter email"
                                                        type="email"
                                                        onChange={(e) => setLoginData({ ...loginData, emailId: e.target.value })}
                                                    />
                                                    <span className='text-validation'>{formValidation.emailId}</span>
                                                </div>
                                                <div className="mb-3">
                                                    <div className="float-end">
                                                    </div>
                                                    <Label className="form-label" htmlFor="password-input">Password</Label>
                                                    <div className="position-relative auth-pass-inputgroup mb-3">
                                                        <Input
                                                            name="password"
                                                            type={passwordShow ? "text" : "password"}
                                                            className="form-control pe-5"
                                                            placeholder="Enter Password"
                                                            onChange={(e) => setLoginData({ ...loginData, password: e.target.value })}
                                                        />
                                                            <span className='text-validation'>{formValidation.password}</span>
                                                        <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" id="password-addon" onClick={() => setPasswordShow(!passwordShow)}><i className="ri-eye-fill align-middle"></i></button>
                                                    </div>
                                                </div>

                                                <div className="form-check">
                                                    <Input className="form-check-input" type="checkbox" value="" id="auth-remember-check" />
                                                    <Label className="form-check-label" htmlFor="auth-remember-check">Remember me</Label>
                                                </div>
                                                <div className="mt-4">
                                                    <Button color="success" className="btn btn-success w-100" onClick={() => onOpenSign()}>
                                                        Sign In
                                                    </Button>
                                                </div>
                                            </Form>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </ParticlesAuth>
        </React.Fragment>
    );
};

const mapStateToProps = (state: any) => ({
    getLoginData: state.AuthenticationReducer.getLoginData,
    loader: state.AuthenticationReducer.loading
})

const mapDispatchToProps = (dispatch: any) => ({
    loginsAction: (data: any) => {
        dispatch(loginsAction(data))
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));
