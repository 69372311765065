import { takeEvery, call, put } from "redux-saga/effects";
import { toast } from 'react-toastify';
import { getModelSuccess, getModelFailure, getCountrySuccess, getCountryFailure, bindCodeBOMSuccess, bindCodeBOMFailure, updateCodelistCodeBOMSuccess, updateCodelistCodeBOMFailure, codeConfiguratiorExcelExportSuccess, codeConfiguratiorExcelExportFailure, getConfiguratorVehicleGroupSuccess, getConfiguratorVehicleGroupFailure } from "./action";
import { CodeConfigurationActionTypes } from "./actionType";
import { ConfigAPI } from "../../helpers/url_helper.js";
import { baseAPI } from "../../helpers/api_helper.js"

function* workerGetModuleDetails(action) {
  try {
    const uri = ConfigAPI.GET_INPUT_MODELS_DATA_URL;
    const { data } = yield call(baseAPI.get, `${uri}`);
    if (data.httpStatusCode === 200) {
      yield put(
        getModelSuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      yield put(getModelFailure(data));
    }
  } catch (error) {
    yield put(getModelFailure(error));
  }
}

function* workerGetCountryDetails(action) {
  try {
    const uri = ConfigAPI.GET_INPUT_COUNTRY_DATA_URL;
    const { data } = yield call(baseAPI.get, `${uri}`);
    if (data.httpStatusCode === 200) {
      yield put(
        getCountrySuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      yield put(getCountryFailure(data));
    }
  } catch (error) {
    yield put(getCountryFailure(error));
  }
}

function* workerBindBOMListDetails(action) {
  const request = action.data;
  const headers = {
    "Content-Type": "multipart/form-data",
  };
  try {
    const uri = ConfigAPI.BIND_BINDCODELIST_AND_CODEBOM_URL;
    const { data } = yield call(baseAPI.post, `${uri}`, request, { headers });
    if (data.httpStatusCode === 200) {
      if (data.httpStatusMessage === "No Content to Display") {
        toast.warning(data.httpStatusMessage, { position: "bottom-right" });
      } else {
        toast.success(data.httpStatusMessage, { position: "bottom-right" });
      }
      yield put(
        bindCodeBOMSuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.error(data.httpStatusMessage, { position: "bottom-right" });
      yield put(bindCodeBOMFailure(data));
    }
  } catch (error) {
    yield put(bindCodeBOMFailure(error));
  }
}

function* workerupdateCodelistCodeBOMDetails(action) {
  const request = action.data;
  try {
    const uri = ConfigAPI.UPDATE_CODELIST_CODEBOM_URL;
    const { data } = yield call(baseAPI.post, `${uri}`, request);
    if (data.httpStatusCode === 200) {
      toast.success(data.httpStatusMessage, { position: "bottom-right" });
      yield put(
        updateCodelistCodeBOMSuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.error(data.httpStatusMessage, { position: "bottom-right" });
      yield put(updateCodelistCodeBOMFailure(data));
    }
  } catch (error) {
    yield put(updateCodelistCodeBOMFailure(error));
  }
}

function* workercodeConfiguratiorExcelExportDetails(action) {
  const request = action.data;
  try {
    const uri = ConfigAPI.CODE_CONFIGURATION_EXCELEXPORT_URL;
    const { data } = yield call(baseAPI.post, `${uri}`, request);
    if (data.httpStatusCode === 200) {
      toast.success(data.httpStatusMessage, { position: "bottom-right" });
      yield put(
        codeConfiguratiorExcelExportSuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.error(data.httpStatusMessage, { position: "bottom-right" });
      yield put(codeConfiguratiorExcelExportFailure(data));
    }
  } catch (error) {
    yield put(codeConfiguratiorExcelExportFailure(error));
  }
}

function* workerGetConfiguratorVehicleGroupDetails(action) {
  const request = action.data;
  try {
    const uri = `${
      ConfigAPI.GET_CONFIGURATION_VEHICLE_GROUP_URL
    }?vehicleGroupId=${request ? request : 0}`;
    const { data } = yield call(baseAPI.get, `${uri}`);
    if (data.httpStatusCode === 200) {
      yield put(
        getConfiguratorVehicleGroupSuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      yield put(getConfiguratorVehicleGroupFailure(data));
    }
  } catch (error) {
    yield put(getConfiguratorVehicleGroupFailure(error));
  }
}

function* CodeConfigurationSaga() {
  yield takeEvery(CodeConfigurationActionTypes.GET_INPUT_MODEL_REQUEST, workerGetModuleDetails);
  yield takeEvery(CodeConfigurationActionTypes.GET_INPUT_COUNTRY_REQUEST, workerGetCountryDetails);
  yield takeEvery(CodeConfigurationActionTypes.BING_CODE_BOM_REQUEST, workerBindBOMListDetails);
  yield takeEvery(CodeConfigurationActionTypes.UPDATE_CODE_LIST_CODEBOM_REQUEST, workerupdateCodelistCodeBOMDetails);
  yield takeEvery(CodeConfigurationActionTypes.CODE_CONFIGURATIOR_EXCELEXPORT_REQUEST, workercodeConfiguratiorExcelExportDetails);
  yield takeEvery(CodeConfigurationActionTypes.GET_CONFIGURATOR_VEHICLE_GROUP_REQUEST, workerGetConfiguratorVehicleGroupDetails);
}

export default CodeConfigurationSaga;
