import React, { useEffect, useState } from "react";
import { Input, Button, Col, Container, Row, CardHeader, CardBody, Card, Label, Offcanvas, OffcanvasHeader, OffcanvasBody } from "reactstrap";
import Select from "react-select";
import SimpleBar from "simplebar-react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import Loader from '../Loader/Loader';
import { getBooleanLogicCheckAction, getBooleanLogicCodeGenerationAction, getCodeBuilderVehicleGroupAction, getCodesDropDownAction, initCodeBuilderModule } from "../../store/actions";

const ItemTypes = {
  CODE: "code",
};

const DraggableCodeButton = ({ text, disabled, onSelect, selectedCodes, ondragging }) => {
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: ItemTypes.CODE,
      item: { texts: selectedCodes },
      canDrag: !disabled,
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [disabled, selectedCodes]
  );

  useEffect(() => {
    ondragging(isDragging);
  }, [isDragging]);

  const handleSelect = () => {
    onSelect(text);
  };

  const isSelected = selectedCodes.includes(text);

  return (
    <button
      ref={drag}
      className="btn btn-outline-primary dragButton"
      style={{
        opacity: isDragging ? 0.5 : 1,
        cursor: disabled ? "no-drop" : "grab",
        marginBottom: "10px",
        backgroundColor: isSelected ? "#182b45" : "",
        color: isSelected ? "white" : "",
        zIndex: 0,
      }}
      onClick={handleSelect}
      onMouseDown={handleSelect}
    >
      {text}
    </button>
  );
};

const DropInput = ({ value, onChange, onDrop, ondelete, highlight }) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: ItemTypes.CODE,
    drop: (item: any) => {
      if (item?.texts) {
        item?.texts.forEach((text: any) => {
          onDrop(text);
        });
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  const handleKeyDown = (e: any) => {
    if (e.key === "Backspace") {
      e.preventDefault();
      const items = value.split(/[+,\/]+/).filter(Boolean);
      items.pop();
      const newValue = items.join(value.match(/[+,\/]+/)?.[0] || ",");
      ondelete({ target: { value: newValue } });
    }
  };

  const style = highlight
    ? {
      outline: "1px solid #000000",
      boxShadow: "0 0 10px #787777",
    }
    : {};

  return (
    <input
      ref={drop}
      value={value}
      onChange={onChange}
      onKeyDown={handleKeyDown}
      readOnly
      type="text"
      className="form-control"
      placeholder="Drop your code here ..."
      style={style}
    />
  );
};

interface AndRow {
  label: string;
  value: string;
}

interface NotRow {
  label: string;
  value: string;
}

interface OrRow {
  value: string;
  orValue: string;
  andKeyValueRows: AndRow[];
  notKeyValueRows: NotRow[];
}

const BooleanLogicCreation = (props: any) => {
  document.title = "BooleanLogicCreation";
  const history = useNavigate();
  const [inputCodes, setInputCodes] = useState<string | null>(null);
  const [finalCodes, setFinalCodes] = useState<string[]>([]);
  const [Dragging, setDragging] = useState(false);
  const [selectedCodes, setSelectedCodes] = useState<string[]>([]);
  const [formValidation, setFormValidation] = useState<{ deCoder?: string }>({});
  const [booleanModifiedCombination, setBooleanModifiedCombination] = useState<any>(null);
  const [orRows, setOrRows] = useState<OrRow[]>([
    {
      value: "New combination",
      orValue: "",
      andKeyValueRows: [
        { label: "All required", value: "" },
        { label: "Any one required", value: "" },
      ],
      notKeyValueRows: [
        { label: "Flow-if any one not come", value: "" },
        { label: "Flow-if all not come", value: "" },
      ],
    },
  ]);

  const errors: { deCoder?: string } = {};
  const { getBooleanModifiedCombination, getBooleanlogicCode, getCodeBuilderVehicleGroup, getCodesDropdownData, loading } = props;
  const [gropDownVehicleList, setGropDownVehicleList] = useState<any>(null);
  const [selectedVehicleGroup, setSelectedVehicleGroup] = useState<any>("");
  const [vehicleGroupList, setVehicleGroupList] = useState<any>("");
  const [searchQuery, setSearchQuery] = useState("");
  const [codesDropdown, setCodesDropdown] = useState<any>([]);
  const [codeDescription, setCodeDescription] = useState<any>("");

  const Vehicle = gropDownVehicleList?.map((item) => ({
      label: item.value,
      value: item.value,
      id: item.id,
      isSelected: item.isSelected,
    })) || [];

  const Models = getCodesDropdownData?.data?.codeModel?.map((item) => ({
      label: item.value,
      value: item.value,
      id: item.id,
      isSelected: item.isSelected,
    })) || [];

  const handleVehicleGroup = (selectedOptions) => {
    setSelectedVehicleGroup(selectedOptions);
    setVehicleGroupList(selectedOptions?.id);
    const data = {
      searchQuery: searchQuery,
      vehicleGroupId: selectedOptions?.id,
    };
    props.getCodesDropDownAction(data);
  };

  const handleInputChange = (inputValue) => {
    setSearchQuery(inputValue);
  };

  useEffect(() => {
    setBooleanModifiedCombination(getBooleanModifiedCombination);
  }, [getBooleanModifiedCombination]);

  useEffect(() => {
    props.initCodeBuilderModule();
  }, [])

  useEffect(() => {
    setCodeDescription(getBooleanlogicCode.codeDescription);
    setFinalCodes(getBooleanlogicCode?.codeDescription?.map((desc) => desc.code));
  }, [getBooleanlogicCode]);

  const mergeJson = (original, incoming) => {
    const json1 = JSON.parse(JSON.stringify(original));
    const json2 = JSON.parse(JSON.stringify(incoming));
    return [...json2];
  };

  useEffect(() => {
    if (Object.keys(getBooleanlogicCode)?.length > 0) {
      const mergedJson = mergeJson(orRows, getBooleanlogicCode.combinationValue);
      setOrRows(mergedJson);
    }
  }, [getBooleanlogicCode]);

  const handleCodes = () => {
    if (!inputCodes) {
      errors.deCoder = "Please fill the field";
    }
    if (!vehicleGroupList) {
      errors.vehicleGroup = "Please select vehicle group";
    }
    setFormValidation(errors);
    if (inputCodes && vehicleGroupList) {
      const separatedCode = inputCodes.match(/[+-]?\w+/g) || [];
      const cleanedCodes = separatedCode.map((code: string) =>
        code.replace(/^[+-]/, "")
      );
      const uniqueCodes = [...new Set(cleanedCodes)];

      const datas = {
        booleanLogic: inputCodes,
        vehicleGroupId: vehicleGroupList,
        codes: codesDropdown.map((code) => code.label.split(" -")[0]),
      };
      props.getBooleanLogicCodeGenerationAction(datas);
    }
  };

  const handleOrChange = (value: string, orIndex: number) => {
    setOrRows((prevOrRows) =>
      prevOrRows.map((row, index) =>
        index === orIndex
          ? {
            ...row,
            orValue: row.orValue ? `${row.orValue},${value}` : value,
          }
          : row
      )
    );
  };

  const handleOrDelete = (value: string, orIndex: number) => {
    setOrRows((prevOrRows) =>
      prevOrRows.map((row, index) =>
        index === orIndex ? { ...row, orValue: value } : row
      )
    );
  };

  const handleAndChange = (
    value: string,
    orIndex: number,
    andIndex: number
  ) => {
    setOrRows((prevOrRows) =>
      prevOrRows.map((row, index) =>
        index === orIndex
          ? {
            ...row,
            andKeyValueRows: row.andKeyValueRows.map((andRow, i) =>
              i === andIndex
                ? {
                  ...andRow,
                  value:
                    andRow.label === "All required"
                      ? andRow.value
                        ? `${andRow.value}+${value}`
                        : value
                      : andRow.value
                        ? `${andRow.value}/${value}`
                        : value,
                }
                : andRow
            ),
          }
          : row
      )
    );
  };

  const handleAndDelete = (value: string, orIndex: number, andIndex: number) => {
    setOrRows((prevOrRows) =>
      prevOrRows.map((row, index) =>
        index === orIndex
          ? {
            ...row,
            andKeyValueRows: row.andKeyValueRows.map((andRow, i) =>
              i === andIndex ? { ...andRow, value: value } : andRow
            ),
          }
          : row
      )
    );
  };

  const handleNotChange = (value: string, orIndex: number, notIndex: number) => {
    setOrRows((prevOrRows) =>
      prevOrRows.map((row, index) =>
        index === orIndex
          ? {
            ...row,
            notKeyValueRows: row.notKeyValueRows.map((notRow, i) =>
              i === notIndex
                ? {
                  ...notRow,
                  value:
                    notRow.label === "Flow-if all not come"
                      ? notRow.value
                        ? `${notRow.value}+${value}`
                        : value
                      : notRow.value
                        ? `${notRow.value}/${value}`
                        : value,
                } : notRow
            ),
          }
          : row
      )
    );
  };

  const handleNotDelete = (
    value: string,
    orIndex: number,
    notIndex: number
  ) => {
    setOrRows((prevOrRows) =>
      prevOrRows.map((row, index) =>
        index === orIndex
          ? {
            ...row,
            notKeyValueRows: row.notKeyValueRows.map((notRow, i) =>
              i === notIndex ? { ...notRow, value: value } : notRow
            ),
          }
          : row
      )
    );
  };

  const addOrRow = (index: number) => {
    setOrRows((prevOrRows) => [...prevOrRows, prevOrRows[index]]);
  };

  const deleteOrRow = (orIndex: number) => {
    const newOrRows = [...orRows];
    newOrRows.splice(orIndex, 1);
    setOrRows(newOrRows);
  };

  const addAndRow = (orIndex: number, label: string) => {
    setOrRows((prevOrRows) =>
      prevOrRows.map((row, index) =>
        index === orIndex
          ? {
            ...row,
            andKeyValueRows: [
              ...row.andKeyValueRows,
              { label: label, value: "" },
            ],
          }
          : row
      )
    );
  };

  const deleteAndRow = (orIndex: number, andIndex: number) => {
    const newOrRows = [...orRows];
    newOrRows[orIndex].andKeyValueRows.splice(andIndex, 1);
    setOrRows(newOrRows);
  };

  const addNotRow = (orIndex: number, label: string) => {
    setOrRows((prevOrRows) =>
      prevOrRows.map((row, index) =>
        index === orIndex
          ? {
            ...row,
            notKeyValueRows: [
              ...row.notKeyValueRows,
              { label: label, value: "" },
            ],
          }
          : row
      )
    );
  };

  const deleteNotRow = (orIndex: number, notIndex: number) => {
    const newOrRows = [...orRows];
    newOrRows[orIndex].notKeyValueRows.splice(notIndex, 1);
    setOrRows(newOrRows);
  };

  const logTableContents = () => {
    const transformedData = orRows.map(
      ({ orValue, andKeyValueRows, notKeyValueRows }) => ({
        oR_NewCombinationInput: orValue,
        and_AllRequired: andKeyValueRows.find(
          (row) => row.label === "All required"
        )?.value,
        and_AnyOneRequired: andKeyValueRows.find(
          (row) => row.label === "Any one required"
        )?.value,
        not_AnyoneNotCome: notKeyValueRows.find(
          (row) => row.label === "Flow-if any one not come"
        )?.value,
        noT_AllNotCome: notKeyValueRows.find(
          (row) => row.label === "Flow-if all not come"
        )?.value,
      })
    );
    props.getBooleanLogicCheckAction(orRows);
    if (orRows.some((row) => !row.orValue)) {
      console.error("Some values in the table are empty");
    } else {
      props.getBooleanLogicCheckAction(orRows);
    }
  };

  const handleCodeCombination = () => {
    history("/code-combination");
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (!event.target.closest(".btn-outline-primary")) {
        setSelectedCodes([]);
      }
    };
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    props.getCodeBuilderVehicleGroupAction();
  }, []);

  useEffect(() => {
    setGropDownVehicleList(getCodeBuilderVehicleGroup?.data);
  }, [getCodeBuilderVehicleGroup]);

  const handleSelectCode = (code: string) => {
    setSelectedCodes((prevSelected) => {
      const newSelected = [...prevSelected];
      if (!newSelected.includes(code)) {
        newSelected.push(code);
      }
      return newSelected;
    });
  };

  const handleSanCheckScreen = () => {
    history("/san-check", { state: { data: booleanModifiedCombination?.reformationBooleanLogic, vehicleGroupId: vehicleGroupList } });
  };

  const handleDeselectCode = (code: string) => {
    setSelectedCodes((prevSelected) =>
      prevSelected.filter((selectedCode) => selectedCode !== code)
    );
  };

  const SplitCodeCombinations = ({ data }: { data: any[] }) => {
    const handleCodeSplit = (codeCombination: string) => {
      return codeCombination
        .split(/(?=\+)/)
        .map((segment) => segment.trim())
        .filter(Boolean);
    };

    const getBackgroundColor = (segment: string) => {
      return segment.startsWith("+-")
        ? "btn btn-soft-danger btn-border-danger"
        : "btn btn-soft-success btn-border-success";
    };

    const splitSegment = (segment: string) => {
      const parts: string[] = [];
      let temp = "";
      for (let i = 0; i < segment?.length; i++) {
        if (segment[i] === "+" && segment[i + 1] === "-") {
          if (temp) parts.push(temp);
          parts.push("+-");
          temp = "";
          i++;
        } else if (segment[i] === "+" || segment[i] === "-") {
          if (temp) parts.push(temp);
          parts.push(segment[i]);
          temp = "";
        } else {
          temp += segment[i];
        }
      }
      if (temp) parts.push(temp);
      return parts;
    };

    return (
      <div>
        {data?.map((item, index) => {
          const segments = handleCodeSplit(item?.codeCombination || "");
          return (
            <div key={index} className="mb-1">
              <Card className="mb-1">
                <CardBody className="p-2">
                  <div className="flex-grow-1 ms-3 shadow-none">
                    <div className="mt-2 d-flex">
                      {segments.map((segment, idx) => (
                        <div key={idx} className="p-0 my-1">
                          {splitSegment(segment).map((part, partIdx) => (
                            <React.Fragment key={partIdx}>
                              {part.match(/[\+\-\+\-]/) ? (
                                <span className="me-3 mx-3 font-strenth">
                                  {part}
                                </span>
                              ) : (
                                <span
                                  style={{ cursor: "default" }}
                                  className={`rounded px-2 px-1 ${getBackgroundColor(
                                    segment
                                  )}`}
                                >
                                  {part}
                                </span>
                              )}
                            </React.Fragment>
                          ))}
                        </div>
                      ))}
                    </div>
                    <p className="text-muted mb-0">
                      {item?.codeCombinationDescription}
                    </p>
                  </div>
                </CardBody>
              </Card>
            </div>
          );
        })}
      </div>
    );
  };

  const handleDragging = (event: any) => {
    setDragging(event);
  };

  const [open, setOpen] = useState("0");

  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  const [openSide, setOpenSide] = useState(false);
  const toggleLeftCanvas = () => {
    setOpenSide(!openSide);
  };

  const handleCodeDropdown = (selectedOptions) => {
    setCodesDropdown(selectedOptions);
  };

  return (
    <React.Fragment>
        {loading && <Loader />}
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Boolean Logic Creation" pageTitle="Code Builder" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className="align-items-center d-flex p-2">
                  <h4 className="card-title mb-0 flex-grow-1">
                    Input Logical Expression
                  </h4>
                  <div className="flex-shrink-0">
                    <div className="form-check form-switch form-switch-right form-switch-md">
                      <div className="input-group gap-3 justify-content-md-end ">
                        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                          <Button
                            className="input-group-text btn btn-primary btn-custom-blue"
                            onClick={handleCodeCombination}
                            type="submit"
                          >
                            Go To Code Combination
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <div>
                    <div>
                      <Row>
                        <Col lg={3} md={4}>
                          <div className="mb-3">
                            <Label
                              htmlFor="choices-single-default"
                              className="form-label text-muted"
                            >
                              Vehicle Group
                              <span className="text-danger">*</span>
                            </Label>
                            <Select
                              value={selectedVehicleGroup}
                              onChange={(e) => { handleVehicleGroup(e) }}
                              styles={{
                                valueContainer: (base) => ({
                                  ...base,
                                  maxHeight: 50,
                                  overflowY: "auto",
                                }),
                              }}
                              options={Vehicle}
                            />
                            <span className="text-validation">
                              {formValidation.vehicleGroup}
                            </span>
                          </div>
                        </Col>
                        <Col lg={5} md={4}>
                          <div className="mb-3">
                            <Label
                              htmlFor="choices-single-default"
                              className="form-label text-muted"
                            >
                              Input Logical Expression
                              <span className="text-danger">*</span>
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              onChange={({ target }) => setInputCodes(target.value)}
                            />
                            <span className="text-validation">
                              {formValidation.deCoder}
                            </span>
                          </div>
                        </Col>
                        <Col lg={4} md={6}>
                          <div className="mb-3">
                            <Label
                              htmlFor="choices-single-groups"
                              className="form-label text-muted"
                            >
                              Code
                            </Label>
                            <Select
                              value={codesDropdown}
                              isMulti={true}
                              closeMenuOnSelect={false}
                              onChange={(e) => { handleCodeDropdown(e) }}
                              onInputChange={(inputValue) => handleInputChange(inputValue)}
                              styles={{
                                valueContainer: (base) => ({
                                  ...base,
                                  maxHeight: 50,
                                  overflowY: "auto",
                                }),
                              }}
                              options={Models}
                            />
                            <span className="text-validation">
                            </span>
                          </div>
                        </Col>
                      </Row>
                      <div>
                        <Row>
                          <div>
                            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                              <span>
                                <Button
                                  className="input-group-text btn btn-primary"
                                  onClick={handleCodes}
                                  type="submit"
                                  color="success"
                                >
                                  Get Codes
                                </Button>
                              </span>
                            </div>
                          </div>
                        </Row>
                      </div>
                    </div>  
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {finalCodes?.length > 0 && orRows?.length > 0 && (
            <Row>
              <Col xl={12}>
                <Card>
                  <CardHeader className="align-items-center d-flex p-2">
                    <h4 className="card-title mb-0 flex-grow-1">
                      Boolean Logic
                    </h4>
                    <div>
                      <div className="d-none d-md-block mx-3">
                        <div className="list-grid-nav hstack gap-1">
                          <Button
                            id="grid-view-button"
                            className="input-group-text btn btn-primary"
                            onClick={toggleLeftCanvas}
                            color="light"
                          >
                            Description
                          </Button>
                        </div>
                      </div>
                      <Offcanvas
                        isOpen={openSide}
                        toggle={toggleLeftCanvas}
                        direction="end"
                        className="offcanvas-end border-0"
                      >
                        <OffcanvasHeader
                          className="d-flex align-items-center  bg-gradient p-3 offcanvas-header-dark"
                          toggle={toggleLeftCanvas}
                          style={{ backgroundColor: "#0f1c3e" }}
                        >
                          <span className="m-0 me-2 text-white" >
                            Code Description
                          </span>
                        </OffcanvasHeader>
                        <OffcanvasBody className="p-0" style={{ overflow: "auto", scrollbarWidth: "none" }}>
                          {codeDescription?.map((code, index) => (
                            <div
                              key={index}
                              className="mb-3 p-3 border rounded-3 bg-white shadow-sm cursor-pointer"
                              style={{ transition: "transform 0.2s", }}
                              onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.02)")}
                              onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
                            >
                              <p className="mb-0 mx-2">
                                <span
                                  className="fw-bold "
                                  style={{ color: "#0f1c3e" }}
                                >
                                  {code.code}
                                </span>{" "}
                                :
                                <span className="text-muted mx-2">
                                  {code.codeDescription}
                                </span>
                              </p>
                            </div>
                          ))}
                        </OffcanvasBody>
                      </Offcanvas>
                    </div>
                    <div className="list-grid-nav hstack gap-1">
                      <Button
                        id="grid-view-button"
                        className="input-group-text btn btn-primary"
                        onClick={logTableContents}
                        color="success"
                      >
                        Get Code Combinations
                      </Button>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <div className="d-flex ">
                      <div
                        className="input-group"
                        style={{
                          flexWrap: "wrap",
                          width: "15%",
                          maxHeight: "600px",
                          overflowX: "unset",
                          overflowY: "scroll",
                          backgroundColor: "#fbfbfb",
                        }}
                      >
                        <DndProvider backend={HTML5Backend}>
                          {finalCodes.map((code, index) => (
                            <div
                              key={index}
                              style={{ flexBasis: "calc(20% - 20px)", color: "white" }}
                              className="m-3"
                            >
                              <DraggableCodeButton
                                key={index}
                                text={code}
                                disabled={false}
                                ondragging={handleDragging}
                                onSelect={handleSelectCode}
                                selectedCodes={selectedCodes}
                              />
                            </div>
                          ))}
                        </DndProvider>
                      </div>
                      <div className="live-preview m-4">
                        <DndProvider backend={HTML5Backend}>
                          <div
                            className="table-responsive table-card"
                            style={{ maxHeight: "700px" }}
                          >
                            <table className="table align-middle table-bordered  table-striped-columns table-nowrap border-dark table-striped mb-0">
                              <thead className="table-light">
                                <tr>
                                  <th className="center" colSpan={3}>
                                    OR
                                  </th>
                                  <th className="center" colSpan={3}>
                                    AND
                                  </th>
                                  <th className="center" colSpan={3}>
                                    NOT
                                  </th>
                                </tr>
                                <tr>
                                  <th className="center">Selections</th>
                                  <th className="center">Input Codes</th>
                                  <th className="center">Actions</th>
                                  <th className="center">Selections</th>
                                  <th className="center">Input Codes</th>
                                  <th className="center">Actions</th>
                                  <th className="center">Selections</th>
                                  <th className="center">Input Codes</th>
                                  <th className="center">Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {orRows?.map((orRow, orIndex) => (
                                  <tr key={orIndex}>
                                    <td>
                                      <span className="text-muted fw-bold">
                                        New combination
                                      </span>
                                    </td>
                                    <td>
                                      <DropInput
                                        value={orRow.orValue}
                                        onChange={(e) => handleOrChange(e.target.value, orIndex)}
                                        ondelete={(e) => handleOrDelete(e.target.value, orIndex)}
                                        onDrop={(text) => {
                                          handleOrChange(text, orIndex);
                                          handleDeselectCode(text);
                                        }}
                                        highlight={Dragging}
                                      />
                                    </td>
                                    <td>
                                      <div className="d-flex gap-3">
                                        <button
                                          className="input-group-text btn btn-primary btn-custom-blue"
                                          onClick={() => addOrRow(orIndex)}
                                        >
                                          +
                                        </button>
                                        <button
                                          className="input-group-text btn btn-primary btn-custom-blue"
                                          onClick={() => deleteOrRow(orIndex)}
                                          style={orRows?.length === 1 ? { display: "none" } : {}}
                                        >
                                          -
                                        </button>
                                      </div>
                                    </td>
                                    <td colSpan={3}>
                                      {orRow.andKeyValueRows.map(
                                        (andRow, andIndex) => {
                                          const rowsWithSameLabel = orRow.andKeyValueRows.filter((row) => row.label === andRow.label);
                                          return (
                                            <div
                                              key={andIndex}
                                              className="d-flex mb-3"
                                            >
                                              <div
                                                style={{ flex: 1 }}
                                                className="me-2 ms-2"
                                              >
                                                <span className="text-muted fw-bold">
                                                  {andRow.label}
                                                </span>
                                              </div>
                                              <div style={{ flex: 2 }}>
                                                <DropInput
                                                  value={andRow.value}
                                                  onChange={(e) => handleAndChange(e.target.value, orIndex, andIndex)}
                                                  ondelete={(e) => handleAndDelete(e.target.value, orIndex, andIndex)}
                                                  onDrop={(text) => {
                                                    handleAndChange(text, orIndex, andIndex);
                                                    handleDeselectCode(text);
                                                  }}
                                                  highlight={Dragging}
                                                />
                                              </div>
                                              <div
                                                style={{ flex: 1 }}
                                                className="d-flex justify-content-center gap-3"
                                              >
                                                <button
                                                  className="input-group-text btn btn-primary btn-custom-blue"
                                                  onClick={() => addAndRow(orIndex, andRow.label)}
                                                >
                                                  +
                                                </button>
                                                <button
                                                  className="input-group-text btn btn-primary btn-custom-blue"
                                                  onClick={() => deleteAndRow(orIndex, andIndex)}
                                                  style={rowsWithSameLabel?.length === 1 ? { display: "none" } : {}}
                                                >
                                                  -
                                                </button>
                                              </div>
                                            </div>
                                          );
                                        }
                                      )}
                                    </td>
                                    <td colSpan={3}>
                                      {orRow.notKeyValueRows.map(
                                        (notRow, notIndex) => {
                                          const rowsWithSameLabel = orRow.notKeyValueRows.filter((row) => row.label === notRow.label);
                                          return (
                                            <div
                                              key={notIndex}
                                              className="d-flex mb-3"
                                            >
                                              <div
                                                style={{ flex: 1 }}
                                                className="me-2 ms-2"
                                              >
                                                <span className="text-muted fw-bold">
                                                  {notRow.label}
                                                </span>
                                              </div>
                                              <div style={{ flex: 2 }}>
                                                <DropInput
                                                  value={notRow.value}
                                                  onChange={(e) => handleNotChange(e.target.value, orIndex, notIndex)}
                                                  ondelete={(e) => handleNotDelete(e.target.value, orIndex, notIndex)}
                                                  onDrop={(text) => {
                                                    handleNotChange(text, orIndex, notIndex);
                                                    handleDeselectCode(text);
                                                  }}
                                                  highlight={Dragging}
                                                />
                                              </div>
                                              <div
                                                style={{ flex: 1 }}
                                                className="d-flex justify-content-center gap-3"
                                              >
                                                <button
                                                  className="input-group-text btn btn-primary btn-custom-blue"
                                                  onClick={() => addNotRow(orIndex, notRow.label)}
                                                >
                                                  +
                                                </button>
                                                <button
                                                  className="input-group-text btn btn-primary btn-custom-blue"
                                                  onClick={() => deleteNotRow(orIndex, notIndex)}
                                                  style={rowsWithSameLabel?.length === 1 ? { display: "none" } : {}}
                                                >
                                                  -
                                                </button>
                                              </div>
                                            </div>
                                          );
                                        }
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </DndProvider>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          {booleanModifiedCombination?.modifiedTruthCodeCombination?.length >
            0 &&
            finalCodes?.length > 0 && (
              <>
                <Card>
                  <CardHeader className="align-items-center d-flex">
                    <h4 className="card-title mb-0 flex-grow-1">
                      Code Combination with Description
                    </h4>
                  </CardHeader>
                  <CardBody className="card-padding">
                    <SimpleBar
                      className="pe-2 me-n1"
                      style={{ minHeight: "100px" }}
                    >
                      <SplitCodeCombinations
                        data={booleanModifiedCombination?.modifiedTruthCodeCombination}
                      />
                    </SimpleBar>
                  </CardBody>
                </Card>
                <Col xl={12}>
                  <Card>
                    <CardHeader className="align-items-center d-flex p-2">
                      <h4 className="card-title mb-0 flex-grow-1">
                        Re-Formed Boolean Logic
                      </h4>
                      <div className="list-grid-nav hstack gap-1">
                        <Button
                          id="grid-view-button"
                          className="input-group-text btn btn-primary"
                          onClick={handleSanCheckScreen}
                          color="success"
                        >
                          San Check
                        </Button>
                      </div>
                    </CardHeader>
                    <CardBody>
                      <div className="live-preview p-2">
                        <div className="table-responsive table-card">
                          <Row className="align-items-center g-3 w-100">
                            <div className="input-group">
                              <Input
                                type="text"
                                value={booleanModifiedCombination?.reformationBooleanLogic}
                                className="form-control"
                              />
                            </div>
                          </Row>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </>
            )}
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  getBooleanModifiedCombination: state?.CodeBuilderReducer?.getBooleanModifiedCombination,
  getBooleanlogicCode: state?.CodeBuilderReducer?.getBooleanlogicCode,
  getCodeBuilderVehicleGroup: state?.CodeBuilderReducer?.getCodeBuilderVehicleGroup,
  getCodesDropdownData: state?.CodeBuilderReducer?.getCodesDropdownData,
  loading: state?.CodeBuilderReducer?.loading
});

const mapDispatchToProps = (dispatch: any) => ({
  getBooleanLogicCheckAction: (data: any) =>
    dispatch(getBooleanLogicCheckAction(data)),
  getBooleanLogicCodeGenerationAction: (data: any) =>
    dispatch(getBooleanLogicCodeGenerationAction(data)),
  getCodeBuilderVehicleGroupAction: (data: any) =>
    dispatch(getCodeBuilderVehicleGroupAction(data)),
  getCodesDropDownAction: (data: any) => dispatch(getCodesDropDownAction(data)),
  initCodeBuilderModule: (data: any) => dispatch(initCodeBuilderModule(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BooleanLogicCreation);
