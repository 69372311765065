import React, { useState, useEffect } from 'react';
import { Input } from 'reactstrap';
import Select from "react-select";
import { connect } from "react-redux";
import { Card, CardBody, Col, Container, Row, CardHeader, Label, Button } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { Pagination } from '@mui/material';
import DeleteModal from '../DeleteModel/DeleteModel';
import RejectModel from '../RejectModel/RejectModel';
import BreadCrumb from "../../Components/Common/BreadCrumb";
import Loader from '../Loader/Loader';
import { getAllCodeRequestAction, getAllVehicleGroupDropdownAction, insertOrUpdateCodeRequestAction, deleteCodeAction, rejectCodeRequestAction, getCodeGroupByVehicleGroupAction, getAllCodeRequestMailIdAction } from '../../store/actions';

const CodeRequest = (props: any) => {
    document.title = "Code Request";
    const { getAllCodeRequest, getVehicleGroupDropdown, insertOrUpdateCodeRequest, deleteCode, rejectCodeRequest, getCodeGroupByVehicleGroup, getAllCodeRequestMailId, loading } = props;
    const { mailId } = useParams();
    const [selectedGroup2, setSelectedGroup2] = useState<any>(null);
    const [selectedGroup3, setSelectedGroup3] = useState<any>(null);
    const [openModel, setOpenModel] = useState<boolean>(false);
    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    const [rejectModal, setRejectModal] = useState<boolean>(false);
    const [todo, setTodo] = useState(null);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [getAllCodeLists, setGetAllCodeLists] = useState<any>(null);
    const [sort, setSort] = useState("");
    const recordsPerPage = 10;
    const totalPages = Math.ceil(Number(totalCount) / recordsPerPage);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [searchKeyword, setSearchKeyword] = useState("");
    const [allCodeGroupDropdownDatas, setAllCodeGroupDropdownDatas] = useState<any>(null);
    const [editId, seteditId] = useState<any>("");
    const [editData, setEditData] = useState<any>({ project: "", isDesignerCheckedWithDICV: false });
    const errors: any = []
    const [formValidation, setFormValiodation] = useState<any>([]);
    const [groupId, setgroupId] = useState<any>([]);
    const [codeId, setCodeId] = useState<any>("");
    const [addBtn, setAddBtn] = useState<boolean>(false);
    const [editIconClick, seteditIconClick] = useState<boolean>(true);
    const [approveStatus, setApproveStatus] = useState<any>("Open");
    const [rows, setRows] = useState([
        { project: '', description: '', code: 'New', codeGroupId: null }
    ]);
    const LoginRoleData = localStorage.getItem("loginRoleAccess")
    const RoleResponse = JSON.parse(LoginRoleData);
    const userRole = JSON.parse(localStorage.getItem("RolesRes"));
    const Role = RoleResponse?.find(data => data?.name === "Master")?.subModules?.find(val => val?.submenuName === "CodeRequest")?.access

    const Group = allCodeGroupDropdownDatas?.map((item: any) => (
        {
            label: item.value,
            value: item.value,
            id: item.id,
            isSelected: item.isSelected
        }
    )
    ) || [];

    const CodeGroup = getCodeGroupByVehicleGroup && getCodeGroupByVehicleGroup.length > 0 && getCodeGroupByVehicleGroup?.map((item: any) => ({
        label: item.groupName,
        options: item.accessList.map((data: any) => ({
            label: data.value,
            value: data.value,
            id: data.id,
            isSelected: data.isSelected
        }))
    })) || [];

    const handleEdit = (code: any) => {
        setRows(code?.codeRequestCodesDetails?.map((val) => ({
            listId: val?.id,
            project: val?.project,
            code: val?.code,
            description: val?.description,
            codeGroupId: {
                id: val?.codeGroupId,
                isSelected: val?.isActive,
                label: val?.codeGroupValue,
                value: val?.codeGroupValue
            }
        })));
        seteditIconClick(false);
        setAddBtn(false);
        setApproveStatus(code?.status)
        // setgroupId(code?.vehicleGroupId);
        setCodeId(code?.codeGroupId);
        setOpenModel(true);
        setEditData(code);
        seteditId(code?.id);
        setgroupId(code?.vehicleGroupDetails?.map((item: any) => ({ vehicleGroupId: item?.vehicleGroupId })))
        setSelectedGroup2(code?.vehicleGroupDetails?.map((val: any) => ({
            id: val?.id,
            label: val?.vehicleGroupValue,
            value: val?.vehicleGroupValue,
            isSelected: val?.isActive,
        })) || []
        );
        setSelectedGroup3({
            id: code?.codeGroupId,
            label: code?.codeGroupValue,
            value: code?.codeGroupValue,
            isSelected: code?.isActive,
        });
        props.getCodeGroupByVehicleGroupAction({ vehicleGroupIds: code?.vehicleGroupDetails?.map((item: any) => (item?.vehicleGroupId)) })
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const handleAdd = () => {
        setOpenModel(true);
        setAddBtn(true);
        const currentDate = new Date();
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        const formattedDate = currentDate.toLocaleDateString('en-US', options);
        setEditData({ requestedOn: formattedDate, code: "New" });
    }

    const addRow = () => {
        setRows([...rows, { project: '', description: '', code: 'New', codeGroupId: null }]);
    };

    const removeRow = (index) => {
        const updatedRows = rows.filter((_, i) => i !== index);
        setRows(updatedRows);
    };

    const handleSave = () => {
        let errors = {};
        if (!editData?.cause) {
            errors.cause = 'Please fill the field';
        }
        if (!editData?.details) {
            errors.details = 'Please fill the field';
        }
        if (!(groupId.length > 0)) {
            errors.groupId = 'Please select vehicle group';
        }
        rows.forEach((row, index) => {
            if (!row.project) {
                errors[`project_${index}`] = 'Please fill the project field';
            }
            if (!row.description) {
                errors[`description_${index}`] = 'Please fill the description field';
            }
            if (!row.code) {
                errors[`code_${index}`] = 'Please fill the code field';
            }
            if (!row.codeGroupId) {
                errors[`codeGroupId_${index}`] = 'Please select the code group';
            }
        });
        setFormValiodation(errors);
        const Data = {
            cause: editData.cause,
            details: editData.details,
            status: "",
            isDesignerCheckedWithDICV: editData.isDesignerCheckedWithDICV,
            vehicleGroupDetails: groupId,
            codeRequestCodesDetails: rows.map((data1) => {
                return {
                    description: data1.description,
                    project: data1.project,
                    codeGroupId: data1.codeGroupId?.id,
                };
            }),
        };
        setCurrentPage(editId ? currentPage : 1);
        if (
            editData?.cause &&
            editData.details &&
            groupId.length > 0 &&
            Object.keys(errors).length === 0
        ) {
            props.insertOrUpdateCodeRequestAction(Data);
        }
    };

    const handleApprove = () => {
        let errors = {};
        if (!editData?.cause) {
            errors.cause = 'Please fill the field';
        }
        if (!editData?.details) {
            errors.details = 'Please fill the field';
        }
        if (!(groupId.length > 0)) {
            errors.groupId = 'Please select vehicle group';
        }
        rows.forEach((row, index) => {
            if (!row.project) {
                errors[`project_${index}`] = 'Please fill the project field';
            }
            if (!row.description) {
                errors[`description_${index}`] = 'Please fill the description field';
            }
            if (!row.code) {
                errors[`code_${index}`] = 'Please fill the code field';
            }
            if (!row.codeGroupId) {
                errors[`codeGroupId_${index}`] = 'Please select the code group';
            }
        });
        setFormValiodation(errors);
        const datas = {
            id: editData?.id,
            codeRequestNo: editData?.codeRequestNo,
            cause: editData?.cause,
            details: editData?.details,
            status: "Closed",
            createdBy: editData?.createdBy,
            createdOn: editData?.createdOn,
            isDesignerCheckedWithDICV: editData.isDesignerCheckedWithDICV,
            vehicleGroupDetails: groupId,
            codeRequestCodesDetails: rows?.map((data1) => {
                return {
                    id: data1?.listId,
                    codeRequestId: editData?.id,
                    description: data1?.description,
                    project: data1?.project,
                    codeGroupId: data1.codeGroupId?.id,
                    code: data1?.code
                };
            })
        }
        if (
            editData?.cause &&
            editData.details &&
            groupId.length > 0 &&
            Object.keys(errors).length === 0
        ) {
            props.rejectCodeRequestAction(datas);
            setCurrentPage(1)
        }
    }

    useEffect(() => {
        if (mailId) {
            props.getAllCodeRequestMailIdAction({ mailId })
        }
    }, [])

    useEffect(() => {
        if (getAllCodeRequestMailId.httpStatusCode === 200) {
            setRows(getAllCodeRequestMailId?.data?.codeRequestCodesDetails?.map((val) => ({
                listId: val?.id,
                project: val?.project,
                code: val?.code,
                description: val?.description,
                codeGroupId: {
                    id: val?.codeGroupId,
                    isSelected: val?.isActive,
                    label: val?.codeGroupValue,
                    value: val?.codeGroupValue
                }
            })));
            seteditIconClick(false);
            setAddBtn(false);
            setApproveStatus(getAllCodeRequestMailId?.data?.status)
            // setgroupId(code?.vehicleGroupId);
            setCodeId(getAllCodeRequestMailId?.data?.codeGroupId);
            setOpenModel(true);
            setEditData(getAllCodeRequestMailId?.data);
            seteditId(getAllCodeRequestMailId?.data?.id);
            setgroupId(getAllCodeRequestMailId?.data?.vehicleGroupDetails?.map((item: any) => ({ vehicleGroupId: item?.vehicleGroupId })))
            setSelectedGroup2(getAllCodeRequestMailId?.data?.vehicleGroupDetails?.map((val: any) => ({
                id: val?.id,
                label: val?.vehicleGroupValue,
                value: val?.vehicleGroupValue,
                isSelected: val?.isActive,
            })) || []
            );
            setSelectedGroup3({
                id: getAllCodeRequestMailId?.data?.codeGroupId,
                label: getAllCodeRequestMailId?.data?.codeGroupValue,
                value: getAllCodeRequestMailId?.data?.codeGroupValue,
                isSelected: getAllCodeRequestMailId?.data?.isActive,
            });
            props.getCodeGroupByVehicleGroupAction({ vehicleGroupIds: getAllCodeRequestMailId?.data?.vehicleGroupDetails?.map((item: any) => (item?.vehicleGroupId)) })
        }
    }, [getAllCodeRequestMailId])

    useEffect(() => {
        if (insertOrUpdateCodeRequest?.httpStatusCode === 200 || rejectCodeRequest?.httpStatusCode === 200) {
            setOpenModel(false);
            setEditData({});
            setSelectedGroup2(null);
            setFormValiodation({});
            seteditId("");
            setgroupId([]);
            setSelectedGroup3(null);
            setCodeId("");
            setApproveStatus("Open");
            setAddBtn(false);
            props.getAllCodeRequestAction({ page: currentPage, search: searchKeyword, sort: sort, OrderByColumnName: "" });
            setTotalCount(getAllCodeRequest?.totalRowsCount);
            setRows([{ project: '', description: '', code: 'New', codeGroupId: null }]);
        }
    }, [insertOrUpdateCodeRequest, rejectCodeRequest])

    const handleClear = () => {
        const currentDate = new Date();
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        const formattedDate = currentDate.toLocaleDateString('en-US', options);
        setEditData({ requestedOn: formattedDate, code: "New" });
        setSelectedGroup2(null);
        setSelectedGroup3(null);
        setCodeId("");
        setFormValiodation({});
        seteditId("");
        setgroupId([]);
        setRows([{ project: '', description: '', code: 'New', codeGroupId: null }]);
    }

    const handleCancle = () => {
        handleClear();
        setOpenModel(false);
        setApproveStatus("Open");
        setAddBtn(false);
        seteditIconClick(true);
    }

    function handleSelectGroups2(selectedGroup2) {
        setSelectedGroup2(selectedGroup2);
        const ids = selectedGroup2?.map((dataId) => dataId?.id);
        const saveData = selectedGroup2?.map((dataId) => ({ vehicleGroupId: dataId?.id }));
        setgroupId(saveData);
        props.getCodeGroupByVehicleGroupAction({ vehicleGroupIds: ids })
    }

    const handleDeleteTodo = () => {
        setDeleteModal(false);
        props.deleteCodeAction(todo);
        setCurrentPage(1)
    };

    const handleRejectTodo = () => {
        setRejectModal(false);
        const datas = {
            id: editData?.id,
            codeRequestNo: editData?.codeRequestNo,
            cause: editData?.cause,
            details: editData?.details,
            status: "Rejected",
            createdBy: editData?.createdBy,
            createdOn: editData?.createdOn,
            isDesignerCheckedWithDICV: editData.isDesignerCheckedWithDICV,
            vehicleGroupDetails: groupId,
        }
        props.rejectCodeRequestAction(datas);
        setCurrentPage(1)
    };

    const onClickTodoReject = (todo: any) => {
        if (!editData?.codeRequestNo) {
            errors.coderequest = 'Please select the field'
        }
        if (!editData?.cause) {
            errors.cause = 'Please fill the field'
        }
        if (!editData?.details) {
            errors.details = 'Please select field'
        }
        if (!(groupId.length > 0)) {
            errors.groupId = 'Please select vehicle group';
        }
        setFormValiodation(errors);
        if (editData?.codeRequestNo && editData?.cause && editData?.details && groupId.length > 0) {
            setRejectModal(true);
        }
    };

    const onClickPagination = (event, value) => {
        setCurrentPage(value);
    };

    const onSearchChange = (event: any) => {
        setSearchKeyword(event.target.value);
        props.getAllCodeRequestAction({ page: "1", search: event.target.value, sort: sort, OrderByColumnName: "" })
        setCurrentPage(1);
    };

    const onSortChange = (keys: any) => {
        const newSortOrder = sort === 'asc' ? 'desc' : 'asc';
        setSort(newSortOrder);
        props.getAllCodeRequestAction({ page: currentPage, search: searchKeyword, sort: newSortOrder, OrderByColumnName: keys, SortDirection: newSortOrder });
    };

    const handleCheckboxChange = () => {
        setEditData((prevData) => ({
            ...prevData,
            isDesignerCheckedWithDICV: !prevData.isDesignerCheckedWithDICV,
        }));
    };

    // useEffect(() => {
    //     const currentDate = new Date();
    //     const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    //     const formattedDate = currentDate.toLocaleDateString('en-US', options);
    //     setEditData({ requestedOn: formattedDate, code: "New" });
    // }, [getAllCodeRequest])

    useEffect(() => {
        props.getAllCodeRequestAction({ page: currentPage, search: searchKeyword, sort: sort, OrderByColumnName: "" });
        setTotalCount(getAllCodeRequest?.totalRowsCount);
    }, [currentPage, deleteCode])

    useEffect(() => {
        setTotalCount(getAllCodeRequest?.totalRowsCount);
    }, [getAllCodeRequest])

    useEffect(() => {
        setGetAllCodeLists(getAllCodeRequest)
    }, [getAllCodeRequest])

    useEffect(() => {
        props.getAllVehicleGroupDropdownAction();
    }, [])

    useEffect(() => {
        setAllCodeGroupDropdownDatas(getVehicleGroupDropdown?.data)
    }, [getVehicleGroupDropdown])

    return (
        <React.Fragment>
            {loading && <Loader />}
            <DeleteModal
                show={deleteModal}
                onDeleteClick={() => handleDeleteTodo()}
                onCloseClick={() => setDeleteModal(false)}
            />
            <RejectModel
                show={rejectModal}
                onDeleteClick={() => handleRejectTodo()}
                onCloseClick={() => setRejectModal(false)}
            />
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Code Request" pageTitle="Masters" />
                    {openModel &&
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardHeader className='card-bg'><h4 className="card-title mb-0 ">Add Code Request</h4> </CardHeader>
                                    <CardBody>
                                        <div>
                                            <div>
                                                <Row>
                                                    <Col lg={4} md={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="choices-single-no-search" className="form-label text-muted">Requested Date <span style={{ color: 'red' }}>*</span></Label>
                                                            <Input type="text" className="form-control" id="placeholderInput" placeholder="Enter date" disabled value={editData.requestedOn ? editData.requestedOn : ""} onChange={(e) => setEditData({ ...editData, requestedOn: e.target.value })} />
                                                            <span className='text-validation'>{formValidation?.date}</span>
                                                        </div>
                                                    </Col>
                                                    <Col lg={4} md={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="choices-single-no-search" className="form-label text-muted">Reason For Change <span style={{ color: 'red' }}>*</span></Label>
                                                            <Input type="text" className="form-control" id="placeholderInput" placeholder="Enter cause" disabled={(((userRole?.includes("Admin")) && (approveStatus === "Open") && (!!editId)) || (addBtn)) ? false : true} value={editData.cause ? editData.cause : ""} onChange={(e) => setEditData({ ...editData, cause: e.target.value })} />
                                                            <span className='text-validation'>{formValidation?.cause}</span>
                                                        </div>
                                                    </Col>
                                                    <Col lg={4} md={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="choices-single-no-search" className="form-label text-muted">Details <span style={{ color: 'red' }}>*</span></Label>
                                                            <Input type="text" className="form-control" id="placeholderInput" placeholder="Enter details" disabled={(((userRole?.includes("Admin")) && (approveStatus === "Open") && (!!editId)) || (addBtn)) ? false : true} value={editData.details ? editData.details : ""} onChange={(e) => setEditData({ ...editData, details: e.target.value })} />
                                                            <span className='text-validation'>{formValidation?.details}</span>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg={4} md={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="choices-single-no-search" className="form-label text-muted">Vehicle Group <span style={{ color: 'red' }}>*</span></Label>
                                                            <Select
                                                                isMulti={true}
                                                                // isDisabled={(((userRole?.includes("Admin")) && (approveStatus === "Open") && (!!editId)) || (addBtn)) ? false : true}
                                                                isDisabled={!(editId) || ((userRole?.includes("Designer"))) ? false : true}
                                                                isClearable={true}
                                                                value={selectedGroup2}
                                                                onChange={(e) => {
                                                                    handleSelectGroups2(e);
                                                                }}
                                                                options={Group}
                                                                styles={{
                                                                    valueContainer: (base) => ({
                                                                        ...base,
                                                                        maxHeight: 50,
                                                                        overflowY: "auto",
                                                                    }),
                                                                }}
                                                            />
                                                            <span className='text-validation'>{formValidation?.groupId}</span>
                                                        </div>
                                                    </Col>
                                                    <Col lg={1} md={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="choices-single-no-search" className="form-label text-muted">DICV Code List</Label>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="checkAll" value="option4" />
                                                            </div>
                                                            <span className='text-validation'>{formValidation?.dicv}</span>
                                                        </div>
                                                    </Col>
                                                    <Col lg={1} md={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="choices-single-no-search" className="form-label text-muted">Global Code Book</Label>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="checkAll" value="option4" />
                                                            </div>
                                                            <span className='text-validation'>{formValidation?.global}</span>
                                                        </div>
                                                    </Col>
                                                    <Col lg={2} md={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="choices-single-no-search" className="form-label text-muted">IsDesignerCheckedWithDICV</Label>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="checkAll" value="option4"
                                                                    checked={editData.isDesignerCheckedWithDICV}
                                                                    onChange={handleCheckboxChange}
                                                                />
                                                            </div>
                                                            <span className='text-validation'>{formValidation?.global}</span>
                                                        </div>
                                                    </Col>
                                                    {editId && <Col lg={4} md={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="choices-single-no-search" className="form-label text-muted">Code Request Number <span style={{ color: 'red' }}>*</span></Label>
                                                            <Input type="text" className="form-control" id="placeholderInput" placeholder="Enter code request number" disabled={true} value={editData.codeRequestNo ? editData.codeRequestNo : ""} onChange={(e) => setEditData({ ...editData, codeRequestNo: e.target.value })} />
                                                            <span className='text-validation'>{formValidation?.coderequest}</span>
                                                        </div>
                                                    </Col>}
                                                </Row>
                                                <>
                                                    {rows?.map((row, index) => (
                                                        <Row key={index}>
                                                            <Col lg={3} md={6}>
                                                                <div className="mb-3">
                                                                    <Label htmlFor="choices-single-no-search" className="form-label text-muted">
                                                                        Project <span style={{ color: 'red' }}>*</span>
                                                                    </Label>
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="placeholderInput"
                                                                        placeholder="Enter Code project"
                                                                        disabled={((userRole?.includes("Admin")) && (approveStatus === "Open") && (!!editId)) || addBtn ? false : true}
                                                                        value={row.project}
                                                                        onChange={(e) => {
                                                                            const updatedRows = [...rows];
                                                                            updatedRows[index].project = e.target.value;
                                                                            setRows(updatedRows);
                                                                        }}
                                                                    />
                                                                    <span className='text-validation'>{formValidation?.[`project_${index}`]}</span>
                                                                </div>
                                                            </Col>
                                                            <Col lg={3} md={6}>
                                                                <div className="mb-3">
                                                                    <Label htmlFor="choices-single-no-search" className="form-label text-muted">
                                                                        Code Group <span style={{ color: 'red' }}>*</span>
                                                                    </Label>
                                                                    <Select
                                                                        isDisabled={((userRole?.includes("Admin")) && (approveStatus === "Open") && (!!editId)) || addBtn ? false : true}
                                                                        isClearable={true}
                                                                        value={row?.codeGroupId}
                                                                        onChange={(e) => {
                                                                            const updatedRows = [...rows];
                                                                            updatedRows[index].codeGroupId = e;
                                                                            setRows(updatedRows);
                                                                        }}
                                                                        options={CodeGroup}
                                                                    />
                                                                    <span className='text-validation'>{formValidation?.[`codeGroupId_${index}`]}</span>
                                                                </div>
                                                            </Col>
                                                            <Col lg={2} md={6}>
                                                                <div className="mb-3">
                                                                    <Label htmlFor="choices-single-no-search" className="form-label text-muted">
                                                                        Code <span style={{ color: 'red' }}>*</span>
                                                                    </Label>
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="placeholderInput"
                                                                        placeholder="Enter Code"
                                                                        maxLength={5}
                                                                        disabled={((userRole?.includes("Admin")) && (approveStatus === "Open") && (!!editId)) || !(addBtn) ? false : true}
                                                                        value={row.code}
                                                                        onChange={(e) => {
                                                                            const updatedRows = [...rows];
                                                                            updatedRows[index].code = e.target.value;
                                                                            setRows(updatedRows);
                                                                        }}
                                                                    />
                                                                    <span className='text-validation'>{formValidation?.[`code_${index}`]}</span>
                                                                </div>
                                                            </Col>
                                                            <Col lg={3} md={6}>
                                                                <div className="mb-3">
                                                                    <Label htmlFor="choices-single-no-search" className="form-label text-muted">
                                                                        Description <span style={{ color: 'red' }}>*</span>
                                                                    </Label>
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="placeholderInput"
                                                                        placeholder="Enter Code description"
                                                                        disabled={((userRole?.includes("Admin")) && (approveStatus === "Open") && (!!editId)) || addBtn ? false : true}
                                                                        value={row.description}
                                                                        onChange={(e) => {
                                                                            const updatedRows = [...rows];
                                                                            updatedRows[index].description = e.target.value;
                                                                            setRows(updatedRows);
                                                                        }}
                                                                    />
                                                                    <span className='text-validation'>{formValidation?.[`description_${index}`]}</span>
                                                                </div>
                                                            </Col>
                                                            {(approveStatus === "Open") && ((userRole?.includes("Admin")) || (userRole?.includes("Designer"))) &&
                                                                <Col lg={1} md={6}>
                                                                    <div className="pt-4">
                                                                        <Button
                                                                            color=""
                                                                            id="createnewmsg"
                                                                            className="btn btn-soft-success btn-sm me-1"
                                                                            onClick={addRow}
                                                                        >
                                                                            <i className="ri-add-line align-bottom"></i>
                                                                        </Button>
                                                                        <Button
                                                                            color=""
                                                                            id="createnewmsg"
                                                                            className="btn btn-soft-success btn-sm"
                                                                            onClick={() => removeRow(index)}
                                                                            disabled={rows.length === 1}
                                                                        >
                                                                            <i className="ri-subtract-line align-bottom"></i>
                                                                        </Button>
                                                                    </div>
                                                                </Col>}
                                                        </Row>
                                                    ))}
                                                </>
                                                <Row>
                                                    <Col lg={8} md={6}>
                                                    </Col>
                                                    {!editId && <Col lg={4} md={6}>
                                                        <div className="mb-3 pt-4">
                                                            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                                <Button className="btn btn-primary mb-2" type="button" color='success' onClick={() => handleSave()}>Save</Button>
                                                                <Button className="btn btn-primary mb-2" type="button" color='light' onClick={handleClear}>Clear</Button>
                                                                <Button className="btn btn-primary mb-2" type="button" color='light' onClick={handleCancle}>Cancel</Button>
                                                            </div>
                                                        </div>
                                                    </Col>}
                                                    {editId &&
                                                        <Col lg={4} md={6}>
                                                            <div className="mb-3 pt-4">
                                                                <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                                    {(approveStatus === "Open") && ((userRole?.includes("Admin"))) &&
                                                                        <>
                                                                            <Button className="btn btn-primary mb-2" type="button" color='success' onClick={() => handleApprove()}>Approve</Button>
                                                                            <Button className="btn btn-primary mb-2" type="button" color='danger' onClick={onClickTodoReject}>Reject</Button>
                                                                        </>
                                                                    }
                                                                    <Button className="btn btn-primary mb-2" type="button" color='light' onClick={handleCancle}>Cancel</Button>
                                                                </div>
                                                            </div>
                                                        </Col>}
                                                </Row>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    }
                    <Row>
                        <Col xl={12}>
                            <Card>
                                <CardHeader className="align-items-center d-flex card-bg">
                                    <h4 className="card-title mb-0 flex-grow-1">Code Request List</h4>
                                    <div className="flex-shrink-0">
                                        <div className="form-check form-switch form-switch-right form-switch-md">
                                            {Role && Role?.createAccess && <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                {(!addBtn && editIconClick) && <Button className="btn btn-primary" type="button" color='success' onClick={() => handleAdd()}>+ Add</Button>}
                                            </div>}
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-sm-end">
                                        <div className="search-box ms-2">
                                            <input type="text" className="form-control search" placeholder="Search..." onChange={onSearchChange} />
                                            <i className="ri-search-line search-icon"></i>
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardBody className='card-padding'>
                                    <div className="live-preview">
                                        <Row className="g-4 mb-4">
                                        </Row>
                                        <div className="table-responsive table-card">
                                            <table className="table align-middle table-nowrap table-striped mb-0">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th scope="col">S.No </th>
                                                        <th scope="col">Requested On<i className=" bx bx-sort me-3" style={{ color: "#182b45" }} onClick={() => onSortChange('requestedOn')}></i></th>
                                                        <th scope="col">Code Request No <i className=" bx bx-sort me-3" style={{ color: "#182b45" }} onClick={() => onSortChange('codeRequestNo')}></i></th>
                                                        {/* <th scope="col">Vehicle Group <i className=" bx bx-sort me-3" style={{ color: "#182b45" }} onClick={() => onSortChange('vehicleGroupValue')}></i></th>
                                                        <th scope="col">Code Group <i className=" bx bx-sort me-3" style={{ color: "#182b45" }} onClick={() => onSortChange('codeGroupValue')}></i></th>
                                                        <th scope="col">Description <i className=" bx bx-sort me-3" style={{ color: "#182b45" }} onClick={() => onSortChange('description')}></i></th> */}
                                                        <th scope="col">Reason For Change <i className=" bx bx-sort me-3" style={{ color: "#182b45" }} onClick={() => onSortChange('cause')}></i></th>
                                                        <th scope="col">Details <i className=" bx bx-sort me-3" style={{ color: "#182b45" }} onClick={() => onSortChange('details')}></i></th>
                                                        {/* <th scope="col">Code <i className=" bx bx-sort me-3" style={{ color: "#182b45" }} onClick={() => onSortChange('code')}></i></th> */}
                                                        <th scope="col">Status <i className=" bx bx-sort me-3" style={{ color: "#182b45" }} onClick={() => onSortChange('status')}></i></th>
                                                        {/* {(Role && Role?.editAccess || Role && Role?.deleteAccess) &&  */}
                                                        <th scope="col" style={{ width: 50 }}>Action</th>
                                                        {/* } */}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {getAllCodeLists?.dataRows && getAllCodeLists?.dataRows?.length > 0 ? (
                                                        getAllCodeLists?.dataRows?.map((code: any, index: number) => (
                                                            <tr key={code?.id}>
                                                                <td>{(currentPage - 1) * recordsPerPage + index + 1}</td>
                                                                <td>{code?.requestedOn}</td>
                                                                <td>{code?.codeRequestNo}</td>
                                                                {/* <td>{code?.vehicleGroupValue}</td>
                                                                <td>{code?.codeGroupValue}</td>
                                                                <td>{code?.description}</td> */}
                                                                <td>{code?.cause}</td>
                                                                <td>{code?.details}</td>
                                                                {/* <td>{code?.code ? code?.code : "-"}</td> */}
                                                                <td>{code?.status}</td>
                                                                {/* {(Role && Role?.editAccess || Role && Role?.deleteAccess) && */}
                                                                <td>
                                                                    <div className="hstack gap-2">
                                                                        {/* {Role && Role?.editAccess && */}
                                                                        <button className="btn btn-sm btn-soft-danger remove-list" onClick={() => handleEdit(code)}>
                                                                            <i className="ri-eye-fill align-bottom" />
                                                                        </button>
                                                                        {/* } */}
                                                                    </div>
                                                                </td>
                                                                {/* } */}
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td className='no-data-foun' colSpan={15} >No data found</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="d-none code-view">
                                        <pre className="language-markup" style={{ "height": "275px" }}>
                                            <code>
                                            </code>
                                        </pre>
                                    </div>
                                    {totalCount > 10 &&
                                        <Row className="g-4 mt-3">
                                            <Col className="col-sm">
                                                <div className="d-flex justify-content-sm-end">
                                                    <Pagination count={totalPages} page={currentPage} onChange={onClickPagination} shape="rounded" />
                                                </div>
                                            </Col>
                                        </Row>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state: any) => ({
    getAllCodeRequest: state.MasterReducer.getAllCodeRequest,
    getVehicleGroupDropdown: state.MasterReducer.getVehicleGroupDropdown,
    insertOrUpdateCodeRequest: state.MasterReducer.insertOrUpdateCodeRequest,
    deleteCode: state.MasterReducer.deleteCode,
    rejectCodeRequest: state.MasterReducer.rejectCodeRequest,
    getCodeGroupByVehicleGroup: state.MasterReducer.getCodeGroupByVehicleGroup,
    getAllCodeRequestMailId: state.MasterReducer.getAllCodeRequestMailId,
    loading: state.MasterReducer.loading
})

const mapDispatchToProps = (dispatch: any) => ({
    getAllCodeRequestAction: (data: any) => {
        dispatch(getAllCodeRequestAction(data))
    },
    getAllVehicleGroupDropdownAction: (data: any) => {
        dispatch(getAllVehicleGroupDropdownAction(data))
    },
    insertOrUpdateCodeRequestAction: (data: any) => {
        dispatch(insertOrUpdateCodeRequestAction(data))
    },
    deleteCodeAction: (data: any) => {
        dispatch(deleteCodeAction(data))
    },
    rejectCodeRequestAction: (data: any) => {
        dispatch(rejectCodeRequestAction(data))
    },
    getCodeGroupByVehicleGroupAction: (data: any) => {
        dispatch(getCodeGroupByVehicleGroupAction(data))
    },
    getAllCodeRequestMailIdAction: (data: any) => {
        dispatch(getAllCodeRequestMailIdAction(data))
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(CodeRequest);
