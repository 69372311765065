import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
} from "reactstrap";
import { Pagination } from "@mui/material";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import {
  getCreatorEditTableRequest,
  getCreatorTableAction,
  getCreatorViewTableRequest,
} from "../../store/actions";

const CreatorRequest = (props) => {
  document.title = "Creator Request";
  const { creatorRequestTableData, creatorEditChangeTableData, loading } = props;
  const navigate = useNavigate();
  const [expandedRows, setExpandedRows] = useState<number[]>([]);
  const [data, setData] = useState<any>([]);
  const [goToEdit, setGoToEdit] = useState(false);
  const [totalCount, setTotalCount] = useState<any>("");
  const [currentPage, setCurrentPage] = useState<any>(1);
  const [userId, setUserId] = useState<any>(1);
  const [search, setSearch] = useState<any>("");
  const [sort, setSort] = useState("asc");
  const [sortBy, setSortBy] = useState("");
  const recordsPerPage = 10;
  const totalPages = Math.ceil(Number(totalCount) / recordsPerPage);
  const LoginRoleData = localStorage.getItem("loginRoleAccess")
  const RoleResponse = JSON.parse(LoginRoleData);
  const Role = RoleResponse?.find(data => data?.name === "CodeList")?.subModules?.find(val => val?.submenuName === "CreatorRequest")?.access

  const onClickPagination = (event, value) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    setTotalCount(data.totalRowsCount);
  }, [data]);

  const toggleRow = (index: number) => {
    if (expandedRows.includes(index)) {
      setExpandedRows(expandedRows.filter((row) => row !== index));
    } else {
      setExpandedRows([...expandedRows, index]);
    }
  };

  const onSortChange = (keys: any) => {
    const newSortOrder = sort === "asc" ? "desc" : "asc";
    setSort(newSortOrder);
    setSortBy(keys);
  };

  useEffect(() => {
    props.getCreatorTableAction({ currentPage, userId, search, sort, sortBy });
  }, [currentPage, sort, search, sortBy]);

  useEffect(() => {
    if (Object.keys(creatorRequestTableData).length !== 0) {
      const data = creatorRequestTableData;
      setData(data);
    }
  }, [creatorRequestTableData]);

  const handleView = (vehicleId, requestId) => {
    navigate('/view-changes', {
      state: {
        VehicleGroupId: vehicleId,
        RequestId: requestId,
        IsWorkflowRequest: false
      }
    });
  };

  const handleEdit = (vechicleid, requestId) => {
    props.getCreatorEditTableRequest({
      VehicleGroupId: vechicleid,
      RequestId: requestId,
    });
    setGoToEdit(true);
  };

  useEffect(() => {
    if (Object.keys(creatorEditChangeTableData).length !== 0 && goToEdit) {
      setGoToEdit(false);
      navigate(`/edit-code-list`);
    }
  }, [creatorEditChangeTableData]);

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Creator Request" pageTitle="Code List" />
          <Row>
            <Col xl={12}>
              <Card>
                <CardHeader className="align-items-center d-flex card-bg">
                  <h4 className="card-title mb-0 flex-grow-1">Requests List</h4>
                  <div className="d-flex justify-content-sm-end">
                    <div className="search-box ms-2">
                      <input
                        type="text"
                        className="form-control search"
                        placeholder="Search..."
                        onChange={(e) => setSearch(e.target.value)}
                      />
                      <i className="ri-search-line search-icon"></i>
                    </div>
                  </div>
                </CardHeader>
                <CardBody className="card-padding">
                  <div className="live-preview">
                    <div
                      className="table-responsive table-card"
                    >
                      <table className="table align-middle table-bordered  table-striped table-striped-columns mb-0">
                        <thead
                          className="table-light"
                          style={{ position: "sticky", top: "0", zIndex: "1" }}
                        >
                          <tr>
                            <th></th>
                            <th>
                              Requested On
                              <i
                                onClick={() => onSortChange("requestedOn")}
                                className=" bx bx-sort me-3"
                                style={{ color: "#182b45", cursor: "pointer" }}
                              ></i>
                            </th>
                            <th>
                              Status
                              <i
                                onClick={() => onSortChange("status")}
                                className=" bx bx-sort me-3"
                                style={{ color: "#182b45", cursor: "pointer" }}
                              ></i>
                            </th>
                            <th>
                              Request Version
                              <i
                                onClick={() => onSortChange("requestedVersion")}
                                className=" bx bx-sort me-3"
                                style={{ color: "#182b45", cursor: "pointer" }}
                              ></i>
                            </th>
                            <th>
                              Vehicle Group
                              <i
                                onClick={() => onSortChange("vehicleGroupName")}
                                className=" bx bx-sort me-3"
                                style={{ color: "#182b45", cursor: "pointer" }}
                              ></i>
                            </th>
                            {(Role && Role?.editAccess || Role && Role?.viewAccess) && <th>Action</th>}
                          </tr>
                        </thead>
                        {data?.dataRows?.length === 0 || data.length === 0 ? (
                          <tbody>
                            <tr>
                              <td colSpan={8} className="text-center">
                                No Data
                              </td>
                            </tr>
                          </tbody>
                        ) : (
                          <tbody>
                            {data?.dataRows?.map((item: any, index: any) => (
                              <React.Fragment key={index}>
                                <tr>
                                  <td>
                                    <Button
                                      className="btn btn-primary btn-custom-blue"
                                      type="button"
                                      color="light"
                                      onClick={() => toggleRow(index)}
                                      style={
                                        { ["--vz-btn-padding-y"]: "6px" } as any
                                      }
                                    >
                                      {expandedRows.includes(index) ? "-" : "+"}
                                    </Button>
                                  </td>
                                  <td>{item.requestedOn}</td>
                                  <td>{item.status}</td>
                                  <td>{item.requestedVersion}</td>
                                  <td>{item.vehicleGroupName}</td>
                                  <td>
                                    <div className="hstack gap-2 gap-5">
                                      {Role && Role?.editAccess &&
                                        <button
                                          className="btn btn-md btn-soft-info edit-list"
                                          title="Edit Code List"
                                          data-bs-toggle="tooltip"
                                          data-bs-placement="top"
                                          disabled={item.isEditable === false}
                                          onClick={() => handleEdit(
                                            item.vehicleGroupId,
                                            item.requestId
                                          )
                                          }
                                        >
                                          <i className="ri-pencil-fill align-bottom" />
                                        </button>}
                                      {Role && Role?.viewAccess &&
                                        <button
                                          className="btn btn-md btn-soft-warning remove-list"
                                          title="View Changes In Code List"
                                          data-bs-toggle="tooltip"
                                          data-bs-placement="top"
                                          onClick={() => handleView(
                                            item.vehicleGroupId,
                                            item.requestId
                                          )
                                          }
                                        >
                                          <i className="ri-eye-fill align-bottom" />
                                        </button>}
                                    </div>
                                  </td>
                                </tr>
                                {expandedRows.includes(index) && (
                                  <tr>
                                    <td></td>
                                    <td colSpan={8}>
                                      <table className="table align-middle table-bordered table-nowrap table-striped mb-0">
                                        <thead className="table-light">
                                          <tr>
                                            <th>Country Group</th>
                                            <th>Vehicle</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {item.countryVehicleList.map(
                                            (Vehicle, i) => (
                                              <tr key={i}>
                                                <td>{Vehicle.country}</td>
                                                <td>{Vehicle.vehicle}</td>
                                              </tr>
                                            )
                                          )}
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                )}
                              </React.Fragment>
                            ))}
                          </tbody>
                        )}
                      </table>
                    </div>
                  </div>
                  {totalCount > 10 && (
                    <Row className="g-4 mt-3">
                      <Col className="col-sm">
                        <div className="d-flex justify-content-sm-end">
                          <Pagination
                            count={totalPages}
                            page={currentPage}
                            onChange={onClickPagination}
                            shape="rounded"
                          />
                        </div>
                      </Col>
                    </Row>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  creatorRequestTableData: state.CodeListReducer.creatorRequestTableData,
  creatorEditChangeTableData: state.CodeListReducer.creatorEditChangeTableData,
  loading: state.CodeListReducer.loading,
});

const mapDispatchToProps = (dispatch: any) => ({
  getCreatorTableAction: (data: any) => dispatch(getCreatorTableAction(data)),
  getCreatorViewTableRequest: (data: any) => dispatch(getCreatorViewTableRequest(data)),
  getCreatorEditTableRequest: (data: any) => dispatch(getCreatorEditTableRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreatorRequest);
