import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

//Import Icons
import FeatherIcon from "feather-icons-react";

const Navdata = () => {
  const history = useNavigate();
  const RoleAccess = useSelector(
    (state) =>
      state.AuthenticationReducer?.getLoginData?.data?.userRoleAccessList
  );
  const MasterRole = RoleAccess?.find(
    (data) => data?.name === "Master"
  )?.hasAccess;
  //state data
  const [isDashboard, setIsDashboard] = useState(false);
  const [isApps, setIsApps] = useState(false);
  const [isAuth, setIsAuth] = useState(false);
  const [isPages, setIsPages] = useState(false);
  const [isBaseUi, setIsBaseUi] = useState(false);
  const [isAdvanceUi, setIsAdvanceUi] = useState(false);
  const [isForms, setIsForms] = useState(false);
  const [isTables, setIsTables] = useState(false);
  const [isCharts, setIsCharts] = useState(false);
  const [isIcons, setIsIcons] = useState(false);
  const [isMaps, setIsMaps] = useState(false);
  const [isMultiLevel, setIsMultiLevel] = useState(false);

  // Apps
  const [isCodeList, setIsCodeList] = useState(false);
  const [isCodeBuilder, setIsCodeBuilder] = useState(false);
  const [dCodeAnalytics , setdCodeAnalytics] = useState(false);
  const [isEmail, setEmail] = useState(false);
  const [isSubEmail, setSubEmail] = useState(false);
  const [isEcommerce, setIsEcommerce] = useState(false);
  const [isProjects, setIsProjects] = useState(false);
  const [isTasks, setIsTasks] = useState(false);
  const [isCRM, setIsCRM] = useState(false);
  const [isCrypto, setIsCrypto] = useState(false);
  const [isInvoices, setIsInvoices] = useState(false);
  const [isSupportTickets, setIsSupportTickets] = useState(false);
  const [isNFTMarketplace, setIsNFTMarketplace] = useState(false);
  const [isJobs, setIsJobs] = useState(false);
  const [isJobList, setIsJobList] = useState(false);
  const [isCandidateList, setIsCandidateList] = useState(false);

  // Authentication
  const [isSignIn, setIsSignIn] = useState(false);
  const [isSignUp, setIsSignUp] = useState(false);
  const [isPasswordReset, setIsPasswordReset] = useState(false);
  const [isPasswordCreate, setIsPasswordCreate] = useState(false);
  const [isLockScreen, setIsLockScreen] = useState(false);
  const [isLogout, setIsLogout] = useState(false);
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [isVerification, setIsVerification] = useState(false);
  const [isError, setIsError] = useState(false);

  // Pages
  const [isProfile, setIsProfile] = useState(false);
  const [isLanding, setIsLanding] = useState(false);

  // Charts
  const [isApex, setIsApex] = useState(false);

  // Multi Level
  const [isLevel1, setIsLevel1] = useState(false);
  const [isLevel2, setIsLevel2] = useState(false);

  const [iscurrentState, setIscurrentState] = useState("Dashboard");

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");

    if (iscurrentState !== "CodeBuilder") {
      setIsCodeBuilder(false);
    }
    if (iscurrentState !== "Landing") {
      setIsLanding(false);
    }
    if (iscurrentState !== "Forms") {
      setIsForms(false);
    }
    if (iscurrentState !== "Tables") {
      setIsTables(false);
    }
    if (iscurrentState !== "Charts") {
      setIsCharts(false);
    }
    if (iscurrentState !== "Icons") {
      setIsIcons(false);
    }
    if (iscurrentState !== "Maps") {
      setIsMaps(false);
    }
    if (iscurrentState !== "MuliLevel") {
      setIsMultiLevel(false);
    }
    if (iscurrentState !== "Codelist") {
      setIsCodeList(false);
    }
    if (iscurrentState === "Widgets") {
      history("/widgets");
      document.body.classList.add("twocolumn-panel");
    }
  }, [
    history,
    iscurrentState,
    isDashboard,
    isApps,
    isAuth,
    isPages,
    isBaseUi,
    isAdvanceUi,
    isForms,
    isTables,
    isCharts,
    isIcons,
    isMaps,
    isMultiLevel,
  ]);

  const menuItems = [
    {
      label: "Menu",
      isHeader: true,
    },
    {
      id: "CodeList",
      label: "Code List",
      icon: <FeatherIcon icon="briefcase" className="icon-dual" />,
      link: "",
      stateVariables: isCodeList,
      click: function (e) {
        e.preventDefault();
        setIsCodeList(!isCodeList);
        setIscurrentState("Codelist");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "CodeList",
          label: "Code List",
          link: "/code-list",
          parentId: "CodeList",
        },
        {
          id: "VersionComparsion",
          label: "Version Comparison",
          link: "/version-comparisons",
          parentId: "CodeList",
        },
        // {
        //     id: "TemperaryEditCodeList",
        //     label: "Temperary Edit Code List",
        //     link: "/edit-code-list",
        //     parentId: "CodeList",
        // },
        // {
        //     id: "TemperaryViewChanges",
        //     label: "Temperary View Changes",
        //     link: "/view-changes",
        //     parentId: "CodeList",
        // },

        {
          id: "CreatorRequest",
          label: "Creator Request",
          link: "/creator-request",
          parentId: "CodeList",
        },
        {
          id: "WorkFlowRequest",
          label: "WorkFlow Request",
          link: "/workflow-request",
          parentId: "CodeList",
        },
        {
          id: "CountryComparison",
          label: "Country Comparison",
          link: "/Country-comparison",
          parentId: "CodeList",
        },
        {
          id: "CodeWorkflowTracker",
          label: "Code Workflow Tracker",
          link: "/code-workflow-tracker",
          parentId: "CodeList",
        },
        {
          id: "CodeList",
          label: "CodeList History",
          link: "/code-list-history",
          parentId: "CodeList",
        },
      ],
    },
    {
      id: "CodeBuilder",
      label: "Code Builder",
      icon: <FeatherIcon icon="layers" className="icon-dual" />,
      link: "",
      stateVariables: isCodeBuilder,
      click: function (e) {
        e.preventDefault();
        setIsCodeBuilder(!isCodeBuilder);
        setIscurrentState("CodeBuilder");
        updateIconSidebar(e);
      },
      subItems: [
        // {
        //     id: "coderulegenerator",
        //     label: "Code Rule Generator",
        //     link: "/code-rule-generator",
        //     parentId: "CodeBuilder",
        // },
        // {
        //     id: "sancheck",
        //     label: "San Check",
        //     link: "/san-check",
        //     parentId: "CodeBuilder",
        // },
        // {
        //     id: "TruthTableGenerator",
        //     label: "Truth Table Generator",
        //     link: "/truth-table-generator",
        //     parentId: "CodeBuilder",
        // },
        {
          id: "BooleanLogicCreation",
          label: "Boolean Logic Creation",
          link: "/boolean-logic-creation",
          parentId: "CodeBuilder",
        },
        {
          id: "FileUploader",
          label: "File Uploader",
          link: "/code-builder-fileupload",
          parentId: "CodeBuilder",
        },
        // {
        //     id: "selectcoderule",
        //     label: "Select Code Rule",
        //     link: "/val",
        //     parentId: "CodeBuilder",
        // },
      ],
    },
    {
      id: "CodeConfigurator",
      label: "Code Configuration",
      icon: <FeatherIcon icon="settings" className="icon-dual" />,
      link: "",
      stateVariables: isDashboard,
      click: function (e) {
        e.preventDefault();
        setIsDashboard(!isDashboard);
        setIscurrentState("Dashboard");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "CodeConfigurator",
          label: "Code Configuration",
          link: "/code-configuration",
          parentId: "CodeConfigurator",
        },
      ],
    },
    {
      id: "Master",
      label: "Masters",
      icon: <FeatherIcon icon="sliders" className="icon-dual" />,
      link: "",
      stateVariables: isDashboard,
      click: function (e) {
        e.preventDefault();
        setIsDashboard(!isDashboard);
        setIscurrentState("Masters");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "Code",
          label: "Code",
          link: "/code",
          parentId: "Master",
        },
        {
          id: "CodeRequest",
          label: "Code Request",
          link: "/code-request",
          parentId: "Master",
        },
        {
          id: "CodeGroup",
          label: "Code Group",
          link: "/code-group",
          parentId: "Master",
        },
        {
          id: "Country",
          label: "Country",
          link: "/add-country",
          parentId: "Master",
        },
        {
          id: "CountryGroup",
          label: "Country Group",
          link: "/country-group",
          parentId: "Master",
        },
        {
          id: "Option",
          label: "Options",
          link: "/add-options",
          parentId: "Master",
        },
        // {
        //     id: "PackageMaster",
        //     label: "Package Master",
        //     link: "/package-master",
        //     parentId: "Master",
        // },
        // {
        //     id: "PackageSubitem",
        //     label: "Package Subitem",
        //     link: "/package-subitem",
        //     parentId: "Master",
        // },
        // {
        //     id: "TargetMatrix",
        //     label: "Target Matrix",
        //     link: "/target-matrix",
        //     parentId: "Master",
        // },
        {
          id: "Tonnage",
          label: "Tonnage",
          link: "/tonnage",
          parentId: "Master",
        },
        {
          id: "VehicleCategory",
          label: "Vehicle Category",
          link: "/vehicle-category",
          parentId: "Master",
        },
        {
          id: "VehicleCountryGroupMapping",
          label: "Vehicle CountryGroup Mapping",
          link: "/vehicle-countrygroup-mapping",
          parentId: "Master",
        },
        {
          id: "VehicleGroup",
          label: "Vehicle Group",
          link: "/vehicle-group",
          parentId: "Master",
        },
        {
          id: "VehicleModel",
          label: "Vehicle Model",
          link: "/vehicle-model",
          parentId: "Master",
        },
        {
          id: "VehicleType",
          label: "Vehicle Type",
          link: "/vehicle-type",
          parentId: "Master",
        },
      ],
    },
    {
      id: "UserManagement",
      label: "User Management",
      icon: <FeatherIcon icon="users" className="icon-dual" />,
      link: "",
      stateVariables: isDashboard,
      click: function (e) {
        e.preventDefault();
        setIsDashboard(!isDashboard);
        setIscurrentState("Dashboard");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "User",
          label: "User",
          link: "/create-user",
          parentId: "UserManagement",
        },
        {
          id: "Role",
          label: "Role",
          link: "/role-create",
          parentId: "UserManagement",
        },
        {
          id: "Access",
          label: "Access",
          link: "/access",
          parentId: "UserManagement",
        },
        {
          id: "RoleAccessMapping",
          label: "Role Access Mapping",
          link: "/role-access-mapping",
          parentId: "UserManagement",
        },
      ],
    },
    {
      id: "FAQ",
      label: "FAQ",
      icon: <FeatherIcon icon="image" className="icon-dual" />,
      link: "",
      stateVariables: isDashboard,
      click: function (e) {
        e.preventDefault();
        setIsDashboard(!isDashboard);
        setIscurrentState("Dashboard");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "FAQ",
          label: "FAQ",
          link: "/faq",
          parentId: "Faq",
        }
      ],
    },
    {
      label: "pages",
      isHeader: true,
    },
    {
      id: "DCodeAnalytics",
      label: "D-Code Analytics",
      icon: <FeatherIcon icon="bar-chart" className="icon-dual" />,
      link: "",
      stateVariables: dCodeAnalytics,
      click: function (e) {
        e.preventDefault();
        setdCodeAnalytics(!dCodeAnalytics);
        setIscurrentState("DCodeAnalytics");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "DCodeAnalytics",
          label: "D-Code Analytics",
          link: "/code-analytics",
          parentId: "DCodeAnalytics",
        },
      ],
    },
  ];
  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
