import React, { useEffect, useState } from "react";
import {
  Button,
  CardBody,
  Col,
  Container,
  Row,
  CardHeader,
  Label,
  Card,
  CardFooter,
} from "reactstrap";
import { useLocation, useParams } from 'react-router-dom';
import Select from "react-select";
import { connect } from "react-redux";
import Loader from "../Loader/Loader";
import { useNavigate } from "react-router-dom";
import "../CodeConfiguration/style.css";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { getViewChangesDecisionAction, getCreatorViewTableRequest } from "../../store/actions";

const ViewChanges = (props: any) => {
  document.title = "Version Comparisons";
  const { creatorViewChangeTableData, loading, viewChangesDecisionData } = props;
  const [tableData, setTableData] = useState<any>([]);
  const [displayChangeStatus, setDisplayChangeStatus] = useState<any>(false);
  const [requestid, setRequestid] = useState<any>(0);
  const [remarksdata, setRemarksdata] = useState<any>("");
  const [isEditable, setIsEditable] = useState<any>(false);
  const location = useLocation();
  const { VehicleGroupId, RequestId, IsWorkflowRequest } = location.state || {};
  const { groupId, reqId, flowReq } = useParams();
  const userRole = JSON.parse(localStorage.getItem("RolesRes"));
  const navigate = useNavigate();
  const changeDropdown =
    creatorViewChangeTableData?.options?.map((option) => ({
      value: option.value,
      label: option.value,
      id: option.id,
    })) || [];

  useEffect(() => {
    props.getCreatorViewTableRequest({
      VehicleGroupId: groupId ? groupId : VehicleGroupId,
      RequestId: reqId ? reqId : RequestId,
      IsWorkflowRequest: flowReq ? flowReq : IsWorkflowRequest,
    });
  }, [])

  useEffect(() => {
    if (Object.keys(creatorViewChangeTableData).length !== 0) {
      setTableData(creatorViewChangeTableData.codeVehicleRemarks);
      setDisplayChangeStatus(creatorViewChangeTableData.isWorkflowRequest);
      setRequestid(creatorViewChangeTableData.requestId);
      setIsEditable(creatorViewChangeTableData.isEditable);
    }
  }, [creatorViewChangeTableData]);

  const staticHeader = [
    "Code Group",
    "Code",
    "Code Description",
    "Notes",
    ...(tableData[0] && tableData[0].remarks
      ? tableData[0].remarks.map((r) => r.header)
      : []),
    ...(tableData[0] && tableData[0].cr
      ? tableData[0].cr.map((r) => r.header)
      : []),
    ...(tableData[0] && tableData[0].cO_KEM
      ? tableData[0].cO_KEM.map((r) => r.header)
      : []),
  ];

  const vehicleHeaders =
    tableData && tableData.length > 0
      ? tableData[0].vehicleCountryGroup.map((vehicle) => vehicle.vehicleModel)
      : [];

  const countryGroup =
    tableData?.length > 0
      ? tableData[0]?.vehicleCountryGroup.map((head) =>
        head.countryGroupList.map((head) => head.countryGroup)
      )
      : [];

  const version =
    tableData?.length > 0
      ? tableData[0]?.vehicleCountryGroup[0]?.countryGroupList[0]?.versionOption.map(
        (head) => head.version
      )
      : [];

  const handleBack = () => {
    ((userRole?.includes("Designer")) || (userRole?.includes("Admin"))) ? navigate("/creator-request") : navigate("/workflow-request");
  };

  const [gotoWorkflow, setGotoWorkflow] = useState<any>(false);
  const handleChangeStatus = (status: string) => {
    props.getViewChangesDecisionAction({
      workFlowStatus: status,
      requestId: requestid,
      comments: remarksdata,
      ChangeRequestTransactionInputModel: changedData,
    });
    setGotoWorkflow(true);
  };

  useEffect(() => {
    if (
      viewChangesDecisionData?.payload?.httpStatusCode === 200 &&
      gotoWorkflow
    ) {
      setGotoWorkflow(false);
      navigate(-1);
    }
  }, [viewChangesDecisionData]);

  const [changedData, setChangedData] = useState<any[]>([]);

  const handleChangesChange = (
    value: any,
    id: string,
    location: string,
    codeID: string
  ) => {
    const isExist = changedData.some(
      (item) => item.codeId === codeID
    );
    const filteredTableData = tableData?.filter((item) =>
      item.codeId === codeID
    );
    if (!isExist && filteredTableData) {
      setChangedData((prevState) => [
        ...prevState,
        ...filteredTableData.map((item) => {
          const crValues = item.cr
            .slice(1)
            .map((subItem) => subItem.value)
            .join(",");
          const coKemValues = item.cO_KEM
            .slice(1)
            .map((subItem) => subItem.value)
            .join(",");
          const remarksValues = item.remarks
            .slice(1)
            .map((subItem) => subItem.value)
            .join(",");

          return {
            codeId: item.codeId,
            [location]: value, // Set the updated value only for the specified `location`
            ...(location === "remarks" ? { cr: crValues, coKem: coKemValues } : {}),
            ...(location === "cr" ? { remarks: remarksValues, coKem: coKemValues } : {}),
            ...(location === "coKem" ? { remarks: remarksValues, cr: crValues } : {}),
            vehicleCountryGroupMappingId:
              item.vehicleCountryGroup
                .flatMap((vehicle) =>
                  vehicle.countryGroupList.map(
                    (country) => country.vehicleCountryGroupId
                  )
                )[0] ?? "",
            id:
              item.vehicleCountryGroup
                .flatMap((vehicle) =>
                  vehicle.countryGroupList.flatMap((country) =>
                    country.versionOption
                      .map((version) => version.changeRequestTransactionId)
                      .filter((id) => id !== 0)
                  )
                )[0] ?? "",
            optionId: 0,
          };
        }),
      ]);
    } else {
      const index = changedData.findIndex(
        (item) => item.codeId === codeID
      );
      setChangedData((prevState) => {
        const updatedState = [...prevState];
        updatedState[index][location] = value;
        return updatedState;
      });
    }
  };

  const handleSelectChange = (
    selectedOption,
    mainIndex,
    subIndex1,
    subIndex2,
    subIndex3,
    codeId,
    uniqueID,
    optionID,
    changeID
  ) => {
    const isExist = changedData.some(
      (item) => item.codeId === codeId
    );
    if (isExist) {
      const isexistData = changedData.filter(
        (item) => item.codeId === codeId
      )
      const isOptionZero = isexistData.some(
        (item) =>
          item.optionId === 0
      );
      if (isOptionZero) {
        const isOptionZeroData = isexistData.filter(
          (item) =>
            item.optionId === 0
        )
        const index = isOptionZeroData.findIndex(
          (item) => item.codeId === codeId
        );
        setChangedData((prevState) => {
          const updatedState = [...prevState];
          updatedState[index].optionId = optionID;
          updatedState[index].vehicleCountryGroupMappingId = uniqueID;
          updatedState[index].id = changeID;
          return updatedState;
        });
      } else {
        const isNotOptionZeroData = isexistData.filter(
          (item) =>
            item.optionId !== 0
        )
        const index = isNotOptionZeroData.findIndex(
          (item) => item.codeId === codeId
        );
        const rowData = isNotOptionZeroData[index];
        const oldData = changedData.filter(
          (item) => item.codeId === codeId && item.vehicleCountryGroupMappingId === uniqueID && item.id === changeID
        );
        if (oldData.length > 0) {
          const index = changedData.findIndex(
            (item) => item.codeId === codeId
          );
          setChangedData((prevState) => {
            const updatedState = [...prevState];
            updatedState[index].optionId = optionID;
            updatedState[index].vehicleCountryGroupMappingId = uniqueID;
            updatedState[index].id = changeID;
            return updatedState;
          });
        } else {
          setChangedData((prevState) => {
            return [
              ...prevState,
              {
                codeId: rowData.codeId,
                vehicleCountryGroupMappingId: uniqueID,
                id: changeID,
                optionId: optionID,
                remarks: rowData.remarks,
                cr: rowData.cr,
                coKem: rowData.coKem,
              },
            ]
          })
        }
      }
    } else {
      const filteredTableData = tableData?.filter((item) =>
        codeId === item.codeId
      );
      setChangedData((prevState) => [
        ...prevState,
        ...filteredTableData.map((item) => {
          const crValues = item.cr
            .slice(1)
            .map((subItem) => subItem.value)
            .join(",");
          const coKemValues = item.cO_KEM
            .slice(1)
            .map((subItem) => subItem.value)
            .join(",");
          const remarksValues = item.remarks
            .slice(1)
            .map((subItem) => subItem.value)
            .join(",");
          return {
            codeId: item.codeId,
            vehicleCountryGroupMappingId: uniqueID,
            id: changeID,
            optionId: optionID,
            remarks: remarksValues,
            cr: crValues,
            coKem: coKemValues,
          };
        }),
      ]);
    }

    setTableData((prevState) =>
      prevState.map((item, index) => {
        if (index === mainIndex) {
          return {
            ...item,
            vehicleCountryGroup: item.vehicleCountryGroup.map(
              (vehicle, vIdx) => {
                if (vIdx === subIndex1) {
                  return {
                    ...vehicle,
                    countryGroupList: vehicle.countryGroupList.map(
                      (country, cIdx) => {
                        if (cIdx === subIndex2) {
                          return {
                            ...country,
                            versionOption: country.versionOption.map(
                              (version, vOptIdx) => {
                                if (vOptIdx === subIndex3) {
                                  return {
                                    ...version,
                                    option: selectedOption.value,
                                  };
                                }
                                return version;
                              }
                            ),
                          };
                        }
                        return country;
                      }
                    ),
                  };
                }
                return vehicle;
              }
            ),
          };
        }
        return item;
      })
    );
  };

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="View Changes" pageTitle="Code List" />
          <Row>
            <Col lg={12}>
              {displayChangeStatus &&
                tableData !== null &&
                typeof tableData === "object" &&
                tableData?.length > 0 ? (
                <Card>
                  <CardHeader className="card-bg">
                    <h4 className="card-title mb-0 ">Change Status</h4>{" "}
                  </CardHeader>
                  <CardBody>
                    <div>
                      <Row>
                        <Col lg={4} md={6}>
                          <div className="mb-3">
                            <Label
                              htmlFor="choices-single-default"
                              className="form-label text-muted"
                            >
                              Remarks
                            </Label>
                            <textarea
                              className="form-control"
                              id="exampleFormControlTextarea5"
                              rows={3}
                              onChange={(e) => setRemarksdata(e.target.value)}
                            ></textarea>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                  <CardFooter>
                    <div className="d-flex gap-3 justify-content-md-end ">
                      <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                        <Button
                          className="btn btn-primary btn-custom-blue"
                          type="button"
                          color="light"
                          onClick={() => handleChangeStatus("Approved")}
                        >
                          Approve
                        </Button>
                      </div>
                      <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                        <Button
                          className="btn btn-primary btn-custom-blue"
                          type="button"
                          color="light"
                          onClick={() => handleChangeStatus("Rejected")}
                        >
                          Reject
                        </Button>
                      </div>
                    </div>
                  </CardFooter>
                </Card>
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col xl={12}>
              <Card>
                <CardHeader className="align-items-center d-flex card-bg">
                  <h4 className="card-title mb-0 flex-grow-1">
                    Comparison Table
                  </h4>
                  <div className="flex-shrink-0">
                    <div className="form-check form-switch form-switch-right form-switch-md">
                      <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                        <Button
                          className="btn btn-primary btn-custom-blue"
                          type="button"
                          color="light"
                          onClick={() => handleBack()}
                        >
                          Back
                        </Button>
                      </div>
                    </div>
                  </div>
                </CardHeader>
                <CardBody className="card-padding">
                  <div className="live-preview">
                    <div
                      className="table-responsive table-card"
                      style={{ maxHeight: "700px", minHeight: "300px" }}
                    >
                      <table className="table align-middle table-striped table-striped-columns table-bordered table-nowrap border-dark mb-0">
                        <thead
                          className="table-light"
                          style={{
                            position: "sticky",
                            top: "0",
                            zIndex: "1",
                          }}
                        >
                          <tr>
                            {staticHeader.map((head, index) => (
                              <th
                                scope="col"
                                key={index}
                                rowSpan={3}
                                className="center"
                              >
                                {head}
                              </th>
                            ))}
                            {vehicleHeaders.map((vehicleName, vehicleIndex) => {
                              const colSpan = tableData.map((data) =>
                                data.vehicleCountryGroup[
                                  vehicleIndex
                                ]?.countryGroupList
                                  .map(
                                    (country) => country.versionOption.length
                                  )
                                  .reduce((acc, curr) => acc + curr, 0)
                              )[0];
                              return (
                                <th
                                  scope="col"
                                  key={vehicleIndex}
                                  colSpan={colSpan}
                                  className="center"
                                >
                                  {vehicleName}
                                </th>
                              );
                            })}
                          </tr>
                          <tr>
                            {countryGroup.map((head) =>
                              head.map((final, index) => (
                                <th
                                  scope="col"
                                  key={index}
                                  colSpan={2}
                                  className="center"
                                >
                                  {final}
                                </th>
                              ))
                            )}
                          </tr>
                          <tr>
                            {countryGroup.map((head) =>
                              head.map((final) =>
                                version.map((head, index) => (
                                  <th
                                    scope="col"
                                    key={index}
                                    className="center"
                                  >
                                    {head}
                                  </th>
                                ))
                              )
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {tableData !== null &&
                            typeof tableData === "object" &&
                            tableData?.length > 0 ? (
                            tableData.map((data, idx) => {
                              const hasMismatch = data.vehicleCountryGroup
                                .flatMap((vehicle, vehicleIdx) =>
                                  vehicle.countryGroupList.flatMap(
                                    (country, countryIdx) => {
                                      const versionOption =
                                        country.versionOption;
                                      const hasMismatch =
                                        versionOption.length === 2 &&
                                        versionOption[0].option !==
                                        versionOption[1].option;
                                      return hasMismatch;
                                    }
                                  )
                                )
                                .some((val) => val);
                              return (
                                <tr key={idx}>
                                  <td className="center">{data.codeGroup}</td>
                                  <td className="center">{data.code}</td>
                                  <td className="center"> {data.codeDescription} </td>
                                  <td className="center">{data.Notes}</td>
                                  {data.remarks.map((remark, index) => (
                                    <td key={index} className="center">
                                      {remark.header === "Changes" &&
                                        isEditable &&
                                        displayChangeStatus &&
                                        hasMismatch ? (
                                        <textarea
                                          className="form-control"
                                          defaultValue={remark.value}
                                          style={{ width: "15vh" }}
                                          onChange={(e) =>
                                            handleChangesChange(
                                              e.target.value,
                                              idx,
                                              "remarks",
                                              data.codeId
                                            )
                                          }
                                        />
                                      ) : (
                                        <span>{remark.value}</span>
                                      )}
                                    </td>
                                  ))}
                                  {data.cr.map((remark, index) => (
                                    <td key={index} className="center">
                                      {remark.header === "Changes" &&
                                        isEditable &&
                                        displayChangeStatus &&
                                        hasMismatch ? (
                                        <textarea
                                          className="form-control"
                                          defaultValue={remark.value}
                                          style={{ width: "15vh" }}
                                          onChange={(e) => handleChangesChange(e.target.value, idx, "cr", data.codeId)}
                                        />
                                      ) : (
                                        <span>{remark.value}</span>
                                      )}
                                    </td>
                                  ))}
                                  {data.cO_KEM.map((remark, index) => (
                                    <td key={index} className="center">
                                      {remark.header === "Changes" &&
                                        isEditable &&
                                        displayChangeStatus &&
                                        hasMismatch ? (
                                        <textarea
                                          className="form-control"
                                          defaultValue={remark.value}
                                          style={{ width: "15vh" }}
                                          onChange={(e) => handleChangesChange(e.target.value, idx, "coKem", data.codeId)
                                          }
                                        />
                                      ) : (
                                        <span>{remark.value}</span>
                                      )}
                                    </td>
                                  ))}
                                  {data.vehicleCountryGroup.flatMap(
                                    (vehicle, vehicleIdx) =>
                                      vehicle.countryGroupList.flatMap(
                                        (country, countryIdx) => {
                                          const versionOption =
                                            country.versionOption;
                                          const hasMismatch =
                                            versionOption.length === 2 &&
                                            versionOption[0].option !==
                                            versionOption[1].option;

                                          return versionOption.map(
                                            (version, versionIdx) => (
                                              <td
                                                key={`${idx}-${vehicleIdx}-${countryIdx}-${versionIdx}`}
                                                className="center"
                                                style={{
                                                  backgroundColor: hasMismatch
                                                    ? "yellow"
                                                    : "transparent", // Highlight mismatches
                                                  color: hasMismatch
                                                    ? "red"
                                                    : "black",
                                                }}
                                              >
                                                {version.version ===
                                                  "Changes" &&
                                                  isEditable &&
                                                  displayChangeStatus &&
                                                  hasMismatch ? (
                                                  <Select
                                                    className="form-control"
                                                    value={changeDropdown.find(
                                                      (option) =>
                                                        option.value ===
                                                        version.option
                                                    )}
                                                    onChange={(e) =>
                                                      handleSelectChange(
                                                        e,
                                                        idx,
                                                        vehicleIdx,
                                                        countryIdx,
                                                        versionIdx,
                                                        data.codeId,
                                                        country.vehicleCountryGroupId,
                                                        changeDropdown.find(
                                                          (option) =>
                                                            option.value ===
                                                            e.value
                                                        ).id,
                                                        version.changeRequestTransactionId
                                                      )
                                                    }
                                                    options={changeDropdown}
                                                  />
                                                ) : (
                                                  <span>{version.option}</span>
                                                )}
                                              </td>
                                            )
                                          );
                                        }
                                      )
                                  )}
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan={6} className="text-center">
                                No Data Found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  creatorViewChangeTableData: state.CodeListReducer.creatorViewChangeTableData,
  viewChangesDecisionData: state.CodeListReducer.viewChangesDecisionData,
  loading: state.CodeListReducer.loading,
});

const mapDispatchToProps = (dispatch) => ({
  getViewChangesDecisionAction: (data) =>
    dispatch(getViewChangesDecisionAction(data)),
  getCreatorViewTableRequest: (data: any) =>
    dispatch(getCreatorViewTableRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewChanges);
