import React, { useState, useEffect } from 'react';
import Select from "react-select";
import { connect } from "react-redux";
import { Card, CardBody, Col, Container, Row, CardHeader, Label, Button } from 'reactstrap';
import { Pagination } from '@mui/material';
import Loader from '../Loader/Loader';
import DeleteModal from '../DeleteModel/DeleteModel';
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { getRollAccessMappingDropDownAction, insertOrUpdateRoleAccessMappingAction, getRoleAccessMappingAction } from '../../store/actions';

const RoleAccessMapping = (props: any) => {
    document.title = "RoleAccessMapping";
    const { getRollAccessMappingDropDown, insertOrUpdateRoleAccessMapping, getRollAccessMapping, loading } = props;
    const errors: any = {}
    const [formValidation, setFormValiodation] = useState<any>({});
    const [selectedGroup1, setSelectedGroup1] = useState<any>(null);
    const [selectedGroup2, setSelectedGroup2] = useState<any>(null);
    const [openModel, setOpenModel] = useState<boolean>(false);
    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    const [getroleData, setGetroleData] = useState<any>(null);
    const [getAccessData, setGetAccessData] = useState<any>(null);
    const [accessId, setAccessId] = useState<any>([]);
    const [roleId, setRoleId] = useState(null);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [editId, seteditId] = useState<any>("");
    const [sort, setSort] = useState("asc");
    const recordsPerPage = 10;
    const totalPages = Math.ceil(Number(totalCount) / recordsPerPage);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [searchKeyword, setSearchKeyword] = useState("");
    const [roleAccessMappingData, setRoleAccessMappingData] = useState<any>(null);
    const LoginRoleData = localStorage.getItem("loginRoleAccess")
    const RoleResponse = JSON.parse(LoginRoleData);
    const Roles = RoleResponse?.find(data => data?.name === "UserManagement")?.subModules?.find(val => val?.submenuName === "RoleAccessMapping")?.access

    const Role = getroleData?.map((item: any) => (
        {
            label: item.value,
            value: item.value,
            id: item.id,
            isSelected: item.isSelected
        }
    )
    ) || [];

    const Access = getAccessData?.map((item: any) => ({
        label: item.groupName,
        options: item.accessList.map((data: any) => ({
            label: data.value,
            value: data.value,
            id: data.id,
            isSelected: data.isSelected
        }))
    })) || [];

    const handleEdit = (role: any) => {
        setRoleId(role?.roleId)
        setSelectedGroup1({
            id: role?.roleId,
            isSelected: role?.isActive,
            label: role?.roleName,
            value: role?.roleName
        })
        setSelectedGroup2(
            role?.roleAccessList.map((val: any) => ({
                id: val?.id,
                label: val?.value,
                value: val?.value,
                isSelected: val?.isSelected,
            })) || []
        );
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setAccessId(role?.roleAccessList?.map((item: any) => (item?.id)))
        seteditId(role?.id);
        setOpenModel(true);
    }

    useEffect(() => {
        if (insertOrUpdateRoleAccessMapping?.httpStatusCode === 200) {
            setOpenModel(false);
            handleClear();
            props.getRoleAccessMappingAction({ page: currentPage, search: searchKeyword, sort: sort, OrderByColumnName: "" })
        }
    }, [insertOrUpdateRoleAccessMapping])

    const handleClear = () => {
        setSelectedGroup1(null);
        setFormValiodation({});
        seteditId("");
        setAccessId([]);
        setSelectedGroup2(null);
        setRoleId(null);
    }

    const handleAdd = () => {
        setOpenModel(true);
    }

    const onClickPagination = (event, value) => {
        setCurrentPage(value);
    };

    const handleSave = () => {
        if (!roleId) {
            errors.roleId = 'Please select role'
        }
        if (!accessId?.length > 0) {
            errors.accessId = 'Please select access'
        }
        setFormValiodation(errors);
        const data = {
            id: editId ? editId : 0,
            RoleId: roleId,
            RoleName: "",
            AcccessIds: accessId
        };
        if (roleId && accessId?.length > 0) {
            props.insertOrUpdateRoleAccessMappingAction(data);
            setOpenModel(true);
        }
    }

    function handleSelectGroups1(selectedGroup1: any) {
        setSelectedGroup1(selectedGroup1);
        setRoleId(selectedGroup1?.id);
    }

    function handleSelectGroups2(selectedGroup2) {
        setAccessId(selectedGroup2?.map((item: any) => (item.id)))
        setSelectedGroup2(selectedGroup2);
    }

    const onSearchChange = (event) => {
        setSearchKeyword(event.target.value);
        props.getRoleAccessMappingAction({ page: "1", search: event.target.value, sort: sort })
        setCurrentPage(1);
    };

    const onSortChange = (keys: any) => {
        const newSortOrder = sort === 'asc' ? 'desc' : 'asc';
        setSort(newSortOrder);
        props.getRoleAccessMappingAction({ page: currentPage, search: searchKeyword, sort: newSortOrder, OrderByColumnName: keys, SortDirection: newSortOrder });
    };

    const handleDeleteTodo = () => {
        setDeleteModal(false);
    };

    useEffect(() => {
        props.getRollAccessMappingDropDownAction()
    }, [])

    useEffect(() => {
        setGetroleData(getRollAccessMappingDropDown?.roleDropDown)
        setGetAccessData(getRollAccessMappingDropDown?.accessDropDownModel)
    }, [getRollAccessMappingDropDown])

    useEffect(() => {
        props.getRoleAccessMappingAction({ page: currentPage, search: searchKeyword, sort: sort, OrderByColumnName: "" });
        setTotalCount(getRollAccessMapping?.totalRowsCount);
    }, [currentPage])

    useEffect(() => {
        setTotalCount(getRollAccessMapping?.totalRowsCount);
    }, [getRollAccessMapping])

    useEffect(() => {
        setRoleAccessMappingData(getRollAccessMapping)
    }, [getRollAccessMapping])

    return (
        <React.Fragment>
            {loading && <Loader />}
            <DeleteModal
                show={deleteModal}
                onDeleteClick={() => handleDeleteTodo()}
                onCloseClick={() => setDeleteModal(false)}
            />
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Role Access Mapping" pageTitle="Masters" />
                    {openModel &&
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardHeader className='card-bg'><h4 className="card-title mb-0 ">Role Access Mapping</h4> </CardHeader>
                                    <CardBody>
                                        <div>
                                            <div>
                                                <Row>
                                                    <Col lg={4} md={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="choices-single-no-search" className="form-label text-muted">Role <span style={{ color: 'red' }}>*</span></Label>
                                                            <Select
                                                                isClearable={true}
                                                                value={selectedGroup1}
                                                                onChange={(e) => {
                                                                    handleSelectGroups1(e);
                                                                }}
                                                                options={Role}
                                                            />
                                                            <span className='text-validation'>{formValidation.roleId}</span>
                                                        </div>
                                                    </Col>
                                                    <Col lg={4} md={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="choices-single-no-search" className="form-label text-muted">Access <span style={{ color: 'red' }}>*</span></Label>
                                                            <Select
                                                                isClearable={true}
                                                                value={selectedGroup2}
                                                                onChange={(e) => {
                                                                    handleSelectGroups2(e);
                                                                }}
                                                                isMulti
                                                                closeMenuOnSelect={false}
                                                                styles={{
                                                                    valueContainer: (base) => ({ ...base, maxHeight: 50, overflowY: 'auto' }),
                                                                }}
                                                                options={Access}
                                                            />
                                                            <span className='text-validation'>{formValidation.accessId}</span>
                                                        </div>
                                                    </Col>
                                                    <Col lg={4} md={6}>
                                                        <div className="mb-3">
                                                            <div className="d-grid gap-2 d-md-flex justify-content-md-end pt-4">
                                                                <Button className="btn btn-primary mb-2" type="button" color='success' onClick={() => handleSave()}>Save</Button>
                                                                <Button className="btn btn-primary me-2 mb-2" type="button" color='light' onClick={handleClear}>Clear</Button>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>

                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    }
                    <Row>
                        <Col xl={12}>
                            <Card>
                                <CardHeader className="align-items-center d-flex card-bg">
                                    <h4 className="card-title mb-0 flex-grow-1">Role Access Mapping List</h4>
                                    <div className="flex-shrink-0">
                                        <div className="form-check form-switch form-switch-right form-switch-md">
                                            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                <Button className="btn btn-primary" type="button" color='success' onClick={() => handleAdd()}>+ Add</Button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-sm-end">
                                        <div className="search-box ms-2">
                                            <input type="text" className="form-control search" placeholder="Search..." onChange={onSearchChange} />
                                            <i className="ri-search-line search-icon"></i>
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardBody className='card-padding'>
                                    <div className="live-preview">
                                        <div className="table-responsive table-card">
                                            <table className="table align-middle table-nowrap table-striped-columns mb-0">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th scope="col">S.No </th>
                                                        <th scope="col">Role <i className=" bx bx-sort me-3" style={{ color: "#182b45" }} onClick={() => onSortChange('roleName')}></i></th>
                                                        <th scope="col">Access <i className=" bx bx-sort me-3" style={{ color: "#182b45" }} onClick={() => onSortChange('roleAccessList')}></i></th>
                                                        {(Roles && Roles?.editAccess || Roles && Roles?.editAccess) && <th scope="col" style={{ width: 50 }}>Action</th>}
                                                    </tr>
                                                </thead>
                                                <tbody>{roleAccessMappingData?.dataRows && roleAccessMappingData?.dataRows?.length > 0 ? (
                                                    roleAccessMappingData?.dataRows?.map((role: any, index: number) => (
                                                        <tr key={role?.id}>
                                                            <td>{index + 1}</td>
                                                            <td>{role?.roleName}</td>
                                                            <td>{role?.roleAccessList?.map(val => val?.value).filter(Boolean).join(', ')}</td>
                                                            {(Roles && Roles?.editAccess || Roles && Roles?.editAccess) &&
                                                                <td>
                                                                    <div className="hstack gap-2">
                                                                        {Roles && Roles?.editAccess && <button className="btn btn-sm btn-soft-info edit-list" onClick={() => handleEdit(role)}>
                                                                            <i className="ri-pencil-fill align-bottom" />
                                                                        </button>}
                                                                    </div>
                                                                </td>}
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td className='no-data-foun' colSpan={10} >No data found</td>
                                                    </tr>
                                                )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="d-none code-view">
                                        <pre className="language-markup" style={{ "height": "275px" }}>
                                            <code>
                                            </code>
                                        </pre>
                                    </div>
                                    {totalCount > 10 &&
                                        <Row className="g-4 mt-3">
                                            <Col className="col-sm">
                                                <div className="d-flex justify-content-sm-end">
                                                    <Pagination count={totalPages} page={currentPage} onChange={onClickPagination} shape="rounded" />
                                                </div>
                                            </Col>
                                        </Row>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state: any) => ({
    getRollAccessMappingDropDown: state.UserManagementReducer.getRollAccessMappingDropDown,
    insertOrUpdateRoleAccessMapping: state.UserManagementReducer.insertOrUpdateRoleAccessMapping,
    getRollAccessMapping: state.UserManagementReducer.getRollAccessMapping,
    loading: state.UserManagementReducer.loading
})

const mapDispatchToProps = (dispatch: any) => ({
    getRollAccessMappingDropDownAction: (data: any) => {
        dispatch(getRollAccessMappingDropDownAction(data))
    },
    insertOrUpdateRoleAccessMappingAction: (data: any) => {
        dispatch(insertOrUpdateRoleAccessMappingAction(data))
    },
    getRoleAccessMappingAction: (data: any) => {
        dispatch(getRoleAccessMappingAction(data))
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(RoleAccessMapping);
