import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CardFooter } from "reactstrap";
import { Pagination } from '@mui/material';
import Select from "react-select";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import Loader from "../Loader/Loader";
import { connect } from "react-redux";
import { Card, CardBody, Col, Container, Row, CardHeader, Label, Button } from "reactstrap";
import { getDropdownCodeListAction, getViewCodeListTableAction, codeListExcelExportAction, codeListHistoryAction } from "../../store/actions";
import "../CodeConfiguration/style.css";

const CodeListHistory = (props) => {
    document.title = "CodeListHistory";
    const { dropdownCodeList, loading, codeListExcelExport, codeListHistory } = props;
    const [selectedVehicleGroup, setSelectedVehicleGroup] = useState<any>(null);
    const [selectedVehicleCategory, setSelectedVehicleCategory] = useState([]);
    const [selectedTonnage, setSelectedTonnage] = useState([]);
    const [selectedVehicleModel, setSelectedVehicleModel] = useState([]);
    const [selectedMarketCluster, setSelectedMarketCluster] = useState([]);
    const [selectedCodeGroup, setSelectedCodeGroup] = useState([]);
    const [selectedCode, setSelectedCode] = useState([]);
    const [showTableList, setShowTableList] = useState(false);
    const errors: { [key: string]: string } = {};
    const [view, setView] = useState<boolean>(false);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [getAllCodeListHistory, setGetAllCodeListHistory] = useState<any>(null);
    const [sort, setSort] = useState("");
    const [searchKeyword, setSearchKeyword] = useState("");
    const recordsPerPage = 10;
    const totalPages = Math.ceil(Number(totalCount) / recordsPerPage);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [formValidation, setFormValiodation] = useState<{
        [key: string]: string;
    }>({});
    const LoginRoleData = localStorage.getItem("loginRoleAccess")
    const RoleResponse = JSON.parse(LoginRoleData);
    const userRole = JSON.parse(localStorage.getItem("RolesRes"));
    const Role = RoleResponse?.find(data => data?.name === "CodeList")?.subModules?.find(val => val?.submenuName === "CreatorRequest")?.access

    const [formState, setFormState] = useState({
        VehicleGroupId: 0,
        VehicleCategory: [],
        Tonnage: [],
        VehicleModel: [],
        MarketCluster: [],
        CodeGroup: [],
        Code: []
    });

    const [dropDownOptions, setDropDownOptions] = useState<any>({
        vehicleGroup: [],
        vehicleCategory: [],
        tonnage: [],
        vehicalModel: [],
        marketCluster: [],
        codeGroup: [],
        code: [],
    });

    useEffect(() => {
        props.getDropdownCodeListAction(formState);
    }, [formState]);

    const onClickPagination = (event, value) => {
        const datas = {
            VehicleGroupId: formState?.VehicleGroupId ? formState?.VehicleGroupId : 0,
            VehicleCategory: formState?.VehicleCategory,
            Tonnage: formState?.Tonnage,
            VehicleModel: formState?.VehicleModel,
            MarketCluster: formState?.MarketCluster,
            CodeGroup: formState?.CodeGroup,
            Code: formState?.Code,
            pageSize: recordsPerPage,
            orderByColumnName: "",
            sortDirection: "",
            gridFilterKey: "",
            pageNumber: value
        }
        props.codeListHistoryAction(datas);
        setCurrentPage(value);
    };

    const onSortChange = (keys: any) => {
        const newSortOrder = sort === 'asc' ? 'desc' : 'asc';
        setSort(newSortOrder);
        const datas = {
            VehicleGroupId: formState?.VehicleGroupId ? formState?.VehicleGroupId : 0,
            VehicleCategory: formState?.VehicleCategory,
            Tonnage: formState?.Tonnage,
            VehicleModel: formState?.VehicleModel,
            MarketCluster: formState?.MarketCluster,
            CodeGroup: formState?.CodeGroup,
            Code: formState?.Code,
            pageSize: recordsPerPage,
            orderByColumnName: keys,
            sortDirection: newSortOrder,
            gridFilterKey: "",
            pageNumber: currentPage
        }
        props.codeListHistoryAction(datas);
    };

    const onSearchChange = (event: any) => {
        setSearchKeyword(event.target.value);
        const datas = {
            VehicleGroupId: formState?.VehicleGroupId ? formState?.VehicleGroupId : 0,
            VehicleCategory: formState?.VehicleCategory,
            Tonnage: formState?.Tonnage,
            VehicleModel: formState?.VehicleModel,
            MarketCluster: formState?.MarketCluster,
            CodeGroup: formState?.CodeGroup,
            Code: formState?.Code,
            pageSize: recordsPerPage,
            orderByColumnName: "",
            sortDirection: sort,
            gridFilterKey: event.target.value,
            pageNumber: 1
        }
        props.codeListHistoryAction(datas);
        setCurrentPage(1);
    };

    useEffect(() => {
        if (dropdownCodeList && Object.keys(dropdownCodeList).length !== 0) {
            LoadDropDownList();
        }
    }, [dropdownCodeList]);

    const LoadDropDownList = () => {
        const vehicleGroupOptions = dropdownCodeList.vehicleGroup.map((option) => ({
            value: option.value,
            label: option.value,
            id: option.id,
        }));
        const vehicleCategoryOptions = dropdownCodeList.vehicleCategory.map(
            (option) => ({
                value: option.value,
                label: option.value,
                id: option.id,
            })
        );
        const tonnageOptions = dropdownCodeList.tonnage.map((option) => ({
            value: option.value,
            label: option.value,
            id: option.id,
        }));
        const vehicalModelOptions = dropdownCodeList.vehicalModel.map((option) => ({
            value: option.value,
            label: option.value,
            id: option.id,
        }));
        const marketClusterOptions = dropdownCodeList.marketCluster.map(
            (option) => ({
                value: option.value,
                label: option.value,
                id: option.id,
            })
        );
        const codeGroupOptions = dropdownCodeList.codeGroup.map((option) => ({
            value: option.value,
            label: option.value,
            id: option.id,
        }));
        const codeOptions = dropdownCodeList.code.map((option) => ({
            value: option.value,
            label: option.value,
            id: option.id,
        }));

        setDropDownOptions({
            vehicleGroup: vehicleGroupOptions,
            vehicleCategory: vehicleCategoryOptions,
            tonnage: tonnageOptions,
            vehicalModel: vehicalModelOptions,
            marketCluster: marketClusterOptions,
            codeGroup: codeGroupOptions,
            code: codeOptions,
        });
    };

    const handleDropdownChange = (selectedOption: any, field: string) => {
        const updatedFormState = { ...formState };
        switch (field) {
            case "VehicleGroupId":
                updatedFormState.VehicleGroupId = selectedOption
                    ? selectedOption.id
                    : 0;
                updatedFormState.VehicleCategory = [];
                updatedFormState.Tonnage = [];
                updatedFormState.VehicleModel = [];
                updatedFormState.CodeGroup = [];
                updatedFormState.Code = [];
                setSelectedVehicleGroup(selectedOption);
                setSelectedVehicleCategory([]);
                setSelectedTonnage([]);
                setSelectedVehicleModel([]);
                setSelectedCodeGroup([]);
                setSelectedCode([]);
                break;
            case "VehicleCategory":
                updatedFormState.VehicleCategory = selectedOption
                    ? selectedOption.map((opt) => opt.id)
                    : [];
                updatedFormState.Tonnage = [];
                updatedFormState.VehicleModel = [];
                setSelectedVehicleCategory(selectedOption);
                setSelectedTonnage([]);
                setSelectedVehicleModel([]);
                break;
            case "Tonnage":
                updatedFormState.Tonnage = selectedOption
                    ? selectedOption.map((opt) => opt.id)
                    : [];
                updatedFormState.VehicleModel = [];
                setSelectedTonnage(selectedOption);
                setSelectedVehicleModel([]);
                break;
            case "VehicleModel":
                updatedFormState.VehicleModel = selectedOption
                    ? selectedOption.map((opt) => opt.id)
                    : [];
                setSelectedVehicleModel(selectedOption);
                break;
            case "MarketCluster":
                updatedFormState.MarketCluster = selectedOption
                    ? selectedOption.map((opt) => opt.id)
                    : [];
                setSelectedMarketCluster(selectedOption);
                break;
            case "CodeGroup":
                updatedFormState.CodeGroup = selectedOption
                    ? selectedOption.map((opt) => opt.id)
                    : [];
                updatedFormState.Code = [];
                setSelectedCodeGroup(selectedOption);
                setSelectedCode([]);
                break;
            case "Code":
                updatedFormState.Code = selectedOption
                    ? selectedOption.map((opt) => opt.id)
                    : [];
                setSelectedCode(selectedOption);
                break;
            default:
                break;
        }
        setFormState(updatedFormState);
    };

    const handleViewCodeList = () => {
        if (formState.VehicleGroupId === 0) {
            errors.VehicleGroupId = "Please select Vehicle Group";
        }
        if (formState.VehicleCategory.length === 0) {
            errors.VehicleCategory = "Please select Vehicle Category";
        }
        setFormValiodation(errors);
        if (Object.keys(errors).length === 0) {
            const datas = {
                VehicleGroupId: formState?.VehicleGroupId ? formState?.VehicleGroupId : 0,
                VehicleCategory: formState?.VehicleCategory,
                Tonnage: formState?.Tonnage,
                VehicleModel: formState?.VehicleModel,
                MarketCluster: formState?.MarketCluster,
                CodeGroup: formState?.CodeGroup,
                Code: formState?.Code,
                pageSize: recordsPerPage,
                orderByColumnName: "",
                sortDirection: "",
                gridFilterKey: "",
                pageNumber: 1
            }
            props.codeListHistoryAction(datas);
            setShowTableList(true);
        }
    };

    const handleClearDropdown = () => {
        setSelectedVehicleGroup(null);
        setSelectedVehicleCategory([]);
        setSelectedTonnage([]);
        setSelectedVehicleModel([]);
        setSelectedMarketCluster([]);
        setSelectedCodeGroup([]);
        setSelectedCode([]);
        setShowTableList(false);
        setFormState({
            ...formState,
            VehicleGroupId: 0,
            VehicleCategory: [],
            Tonnage: [],
            VehicleModel: [],
            MarketCluster: [],
            CodeGroup: [],
            Code: [],
        });
    };

    useEffect(() => {
        if (codeListExcelExport?.httpStatusCode === 200) {
            window.location.href = codeListExcelExport?.data
        }
    }, [codeListExcelExport])

    const hangleViewChange = () => {
        setView(!view);
    }

    useEffect(() => {
        setTotalCount(codeListHistory?.data?.totalRowsCount);
    }, [codeListHistory])

    useEffect(() => {
        setGetAllCodeListHistory(codeListHistory?.data)
    }, [codeListHistory]);

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Code List" pageTitle="Code List" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader className="align-items-center d-flex card-bg">
                                    <h4 className="card-title mb-0 flex-grow-1">
                                        List Search
                                    </h4>
                                    <div className="flex-shrink-0">
                                        <div className="form-check form-switch form-switch-right form-switch-md">
                                            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                {showTableList && !loading && <p><Link onClick={hangleViewChange} to="#" className="link-success link-offset-2 text-decoration-underline link-underline-opacity-25 link-underline-opacity-100-hover">{view ? "Table View" : "Grid View"}</Link></p>}
                                            </div>
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <div>
                                        <div>
                                            <Row>
                                                <Col lg={4} md={6}>
                                                    <div className="mb-3">
                                                        <Label
                                                            htmlFor="choices-single-default"
                                                            className="form-label text-muted"
                                                        >
                                                            Vehicle Group{" "}
                                                            <span className="text-danger">*</span>
                                                        </Label>
                                                        <Select
                                                            value={selectedVehicleGroup}
                                                            isMulti={false}
                                                            onChange={(e) => { handleDropdownChange(e, "VehicleGroupId") }}
                                                            options={dropDownOptions.vehicleGroup}
                                                        />
                                                        <span className="text-validation">
                                                            {formValidation.VehicleGroupId}
                                                        </span>
                                                    </div>
                                                </Col>
                                                <Col lg={4} md={6}>
                                                    <div className="mb-3">
                                                        <Label
                                                            htmlFor="choices-single-groups"
                                                            className="form-label text-muted"
                                                        >
                                                            Vehicle Category{" "}
                                                            <span className="text-danger">*</span>
                                                        </Label>
                                                        <Select
                                                            value={selectedVehicleCategory}
                                                            isMulti={true}
                                                            closeMenuOnSelect={false}
                                                            styles={{
                                                                valueContainer: (base) => ({
                                                                    ...base,
                                                                    maxHeight: 50,
                                                                    overflowY: "auto",
                                                                }),
                                                            }}
                                                            onChange={(e) => { handleDropdownChange(e, "VehicleCategory") }}
                                                            options={dropDownOptions.vehicleCategory}
                                                        />
                                                        <span className="text-validation">
                                                            {formValidation.VehicleCategory}
                                                        </span>
                                                    </div>
                                                </Col>
                                                <Col lg={4} md={6}>
                                                    <div className="mb-3">
                                                        <Label
                                                            htmlFor="choices-single-no-search"
                                                            className="form-label text-muted"
                                                        >
                                                            Tonnage
                                                        </Label>
                                                        <Select
                                                            isClearable={true}
                                                            isMulti={true}
                                                            closeMenuOnSelect={false}
                                                            styles={{
                                                                valueContainer: (base) => ({
                                                                    ...base,
                                                                    maxHeight: 50,
                                                                    overflowY: "auto",
                                                                }),
                                                            }}
                                                            value={selectedTonnage}
                                                            onChange={(e) => { handleDropdownChange(e, "Tonnage") }}
                                                            options={dropDownOptions.tonnage}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div>
                                            <Row>
                                                <Col lg={4} md={6}>
                                                    <div className="mb-3">
                                                        <Label
                                                            htmlFor="choices-single-default"
                                                            className="form-label text-muted"
                                                        >
                                                            Vehicle Model
                                                        </Label>
                                                        <Select
                                                            value={selectedVehicleModel}
                                                            closeMenuOnSelect={false}
                                                            isMulti={true}
                                                            styles={{
                                                                valueContainer: (base) => ({
                                                                    ...base,
                                                                    maxHeight: 50,
                                                                    overflowY: "auto",
                                                                }),
                                                            }}
                                                            onChange={(e) => { handleDropdownChange(e, "VehicleModel") }}
                                                            options={dropDownOptions.vehicalModel}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col lg={4} md={6}>
                                                    <div className="mb-3">
                                                        <Label
                                                            htmlFor="choices-single-groups"
                                                            className="form-label text-muted"
                                                        >
                                                            Market cluster
                                                        </Label>
                                                        <Select
                                                            value={selectedMarketCluster}
                                                            isMulti={true}
                                                            closeMenuOnSelect={false}
                                                            styles={{
                                                                valueContainer: (base) => ({
                                                                    ...base,
                                                                    maxHeight: 50,
                                                                    overflowY: "auto",
                                                                }),
                                                            }}
                                                            onChange={(e) => { handleDropdownChange(e, "MarketCluster") }}
                                                            options={dropDownOptions.marketCluster}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col lg={4} md={6}>
                                                    <div className="mb-3">
                                                        <Label
                                                            htmlFor="choices-single-no-search"
                                                            className="form-label text-muted"
                                                        >
                                                            Code Group
                                                        </Label>
                                                        <Select
                                                            isClearable={true}
                                                            isMulti={true}
                                                            closeMenuOnSelect={false}
                                                            styles={{
                                                                valueContainer: (base) => ({
                                                                    ...base,
                                                                    maxHeight: 50,
                                                                    overflowY: "auto",
                                                                }),
                                                            }}
                                                            value={selectedCodeGroup}
                                                            onChange={(e) => { handleDropdownChange(e, "CodeGroup") }}
                                                            options={dropDownOptions.codeGroup}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col lg={4} md={6}>
                                                    <div className="mb-3">
                                                        <Label
                                                            htmlFor="choices-single-no-sorting"
                                                            className="form-label text-muted"
                                                        >
                                                            Code
                                                        </Label>
                                                        <Select
                                                            value={selectedCode}
                                                            isMulti={true}
                                                            closeMenuOnSelect={false}
                                                            styles={{
                                                                valueContainer: (base) => ({
                                                                    ...base,
                                                                    maxHeight: 50,
                                                                    overflowY: "auto",
                                                                }),
                                                            }}
                                                            onChange={(e) => { handleDropdownChange(e, "Code"); }}
                                                            options={dropDownOptions.code}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </CardBody>
                                <CardFooter>
                                    <div className="d-flex gap-3 justify-content-md-end ">
                                        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                            <Button
                                                className="btn btn-primary"
                                                type="button"
                                                color="light"
                                                onClick={() => handleClearDropdown()}
                                            >
                                                Clear
                                            </Button>
                                        </div>
                                        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                            <Button
                                                className="btn btn-primary"
                                                type="button"
                                                color="success"
                                                onClick={() => handleViewCodeList()}
                                            >
                                                Search Code List
                                            </Button>
                                        </div>
                                    </div>
                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>
                    {/* {getAllCodeListHistory?.dataRows?.length > 0 && */}
                    <Row>
                        <Col xl={12}>
                            <Card>
                                <CardHeader className="align-items-center d-flex card-bg">
                                    <h4 className="card-title mb-0 flex-grow-1">Code List History</h4>
                                    <div className="d-flex justify-content-sm-end">
                                        <div className="search-box ms-2">
                                            <input type="text" className="form-control search" placeholder="Search..." onChange={onSearchChange} />
                                            <i className="ri-search-line search-icon"></i>
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardBody className='card-padding'>
                                    <div className="live-preview">
                                        <div className="table-responsive table-card">
                                            <table className="table align-middle table-nowrap table-striped mb-0">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th scope="col">S.No </th>
                                                        <th scope="col">Codes <i className=" bx bx-sort me-3" style={{ color: "#182b45" }} onClick={() => onSortChange('code')}></i></th>
                                                        <th scope="col">Workflow Person <i className=" bx bx-sort me-3" style={{ color: "#182b45" }} onClick={() => onSortChange('approverUserName')}></i></th>
                                                        <th scope="col">Status <i className=" bx bx-sort me-3" style={{ color: "#182b45" }} onClick={() => onSortChange('status')}></i></th>
                                                        <th scope="col">Date <i className=" bx bx-sort me-3" style={{ color: "#182b45" }} onClick={() => onSortChange('approvedOn')}></i></th>
                                                        <th scope="col">CO Kem <i className=" bx bx-sort me-3" style={{ color: "#182b45" }} onClick={() => onSortChange('coKem')}></i></th>
                                                        <th scope="col">Remarks <i className=" bx bx-sort me-3" style={{ color: "#182b45" }} onClick={() => onSortChange('remarks')}></i></th>
                                                        <th scope="col">Vehicle Group <i className=" bx bx-sort me-3" style={{ color: "#182b45" }} onClick={() => onSortChange('vehicleName')}></i></th>
                                                        <th scope="col"> Country <i className=" bx bx-sort me-3" style={{ color: "#182b45" }} onClick={() => onSortChange('vehicleName')}></i></th>
                                                        <th scope="col"> From Version <i className=" bx bx-sort me-3" style={{ color: "#182b45" }} onClick={() => onSortChange('vehicleName')}></i></th>
                                                        <th scope="col"> To Version <i className=" bx bx-sort me-3" style={{ color: "#182b45" }} onClick={() => onSortChange('vehicleName')}></i></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {getAllCodeListHistory?.dataRows && getAllCodeListHistory?.dataRows?.length > 0 ? (
                                                        getAllCodeListHistory?.dataRows?.map((code: any, index: number) => (
                                                            <tr key={code?.id}>
                                                                <td>{index + 1}</td>
                                                                <td>{code?.code}</td>
                                                                <td>{code?.approverUserName}</td>
                                                                <td>{code?.status}</td>
                                                                <td>{code?.approvedOn}</td>
                                                                <td>{code?.coKem}</td>
                                                                <td>{code?.remarks}</td>
                                                                <td>{code?.vehicleName}</td>
                                                                <td>{code?.country}</td>
                                                                <td>{code?.fromVersion}</td>
                                                                <td>{code?.toVersion}</td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td className='no-data-foun' colSpan={10} >No data found</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="d-none code-view">
                                        <pre className="language-markup" style={{ "height": "275px" }}>
                                            <code>
                                            </code>
                                        </pre>
                                    </div>
                                    {totalCount > 10 &&
                                        <Row className="g-4 mt-3">
                                            <Col className="col-sm">
                                                <div className="d-flex justify-content-sm-end">
                                                    <Pagination count={totalPages} page={currentPage} onChange={onClickPagination} shape="rounded" />
                                                </div>
                                            </Col>
                                        </Row>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    {/* } */}
                </Container>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({
    dropdownCodeList: state.CodeListReducer.dropdownCodeList,
    loading: state.CodeListReducer.loading,
    codeListExcelExport: state.CodeListReducer.codeListExcelExport,
    codeListHistory: state.CodeListReducer.codeListHistory,
});

const mapDispatchToProps = (dispatch) => ({
    getDropdownCodeListAction: (data) => {
        dispatch(getDropdownCodeListAction(data));
    },
    getViewCodeListTableAction: (data) => {
        dispatch(getViewCodeListTableAction(data));
    },
    codeListExcelExportAction: (data) => {
        dispatch(codeListExcelExportAction(data));
    },
    codeListHistoryAction: (data) => {
        dispatch(codeListHistoryAction(data));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(CodeListHistory);
