import { takeEvery, call, put } from "redux-saga/effects";
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';
import { baseAPI } from "../../helpers/api_helper.js"
import { getDropdownCodeListSuccess, getDropdownCodeListFailure, getDropdownVersionComparisonSuccess, getDropdownVersionComparisonFailure, getVersionComparisonTableSuccess, getVersionComparisonTableFailure, getViewCodeListTableSuccess, getViewCodeListTableFailure, getSubmitCodeListSuccess, getSubmitCodeListFailure, getCreatorTableSuccess, getCreatorTableFailure, getCreatorViewTableSuccess, getCreatorViewTableFailure, getCreatorEditTableSuccess, getCreatorEditTableFailure, getSubmitEditCodeListSuccess, getSubmitEditCodeListFailure, getSaveEditCodeListSuccess, getSaveEditCodeListFailure, getWorkflowTableSuccess, getWorkflowTableFailure, getWorkflowCombineSuccess, getWorkflowCombineFailure, getViewChangesDecisionSuccess, getViewChangesDecisionFailure, getCountryComparisonDropdownSuccess, getCountryComparisonDropdownFailure, getCountryComparisonTableSuccess, getCountryComparisonTableFailure, getCodeWorkflowTrackerTableSucess, getCodeWorkflowTrackerTableFailure, codeListExcelExportSucess, codeListExcelExportFailure, codeListHistorySucess, codeListHistoryFailure, codeListPdfExportSuccess, codeListPdfExportFailure } from "./action";
import { CodeListActionTypes } from "./actionType";
import { ConfigAPI } from "../../helpers/url_helper.js";

function* workerGetDropdownCodeList(action) {
  const request = action.data;
  try {
    const uri = ConfigAPI.GET_DROPDOWN_CODE_LIST_URL;
    const { data } = yield call(baseAPI.post, `${uri}`, request);
    if (data.httpStatusCode === 200) {
      yield put(
        getDropdownCodeListSuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      yield put(getDropdownCodeListFailure(data));
    }
  } catch (error) {
    yield put(getDropdownCodeListFailure(error));
  }
}

function* workerGetViewCodeListTableData(action) {
  const request = action.data;
  try {
    const uri = ConfigAPI.GET_VIEW_CODE_LIST_TABLE_URL;
    const { data } = yield call(baseAPI.post, `${uri}`, request);
    if (data.httpStatusCode === 200) {
      toast.success(data.httpStatusMessage, { position: "bottom-right" });
      yield put(
        getViewCodeListTableSuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      yield put(getViewCodeListTableFailure(data));
    }
  } catch (error) {
    yield put(getViewCodeListTableFailure(error));
  }
}

function* workerGetDropdownVersionComparison(action) {
  const request = action.data;
  try {
    const uri = ConfigAPI.GET_DROPDOWN_VERSION_COMPARISON_URL;
    const { data } = yield call(baseAPI.get, `${uri}${request}`);
    if (data.httpStatusCode === 200) {
      yield put(
        getDropdownVersionComparisonSuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      yield put(getDropdownVersionComparisonFailure(data));
    }
  } catch (error) {
    yield put(getDropdownVersionComparisonFailure(error));
  }
}

function* workerGetVersionComparisonTableData(action) {
  const request = action.data;
  try {
    const uri = ConfigAPI.GET_VERSION_COMPARISON_TABLE_URL;
    const { data } = yield call(baseAPI.post, `${uri}`, request);
    if (data.httpStatusCode === 200) {
      toast.success(data.httpStatusMessage, { position: "bottom-right" });
      yield put(
        getVersionComparisonTableSuccess({
          payload: data.data,
          data: data.data,
        })
      );
    } else {
      toast.warn(data.httpStatusMessage, { position: "bottom-right" });
      yield put(getVersionComparisonTableFailure(data));
    }
  } catch (error) {
    yield put(getVersionComparisonTableFailure(error));
  }
}

function* workerSubmitCodeList(action) {
  const request = action.data;
  try {
    const uri = ConfigAPI.SUBMIT_CODE_LIST_URL;
    const { data } = yield call(baseAPI.post, `${uri}`, request);
    if (data.httpStatusCode === 200) {
      toast.success(data.httpStatusMessage, { position: "bottom-right" });
      yield put(
        getSubmitCodeListSuccess({
          payload: data,
          data: data,
        })
      );
    } else {
      toast.warn(data.httpStatusMessage, { position: "bottom-right" });
      yield put(getSubmitCodeListFailure(data));
    }
  } catch (error) {
    yield put(getSubmitCodeListFailure(error));
  }
}

function* workerGetCreatorTableData(action) {
  const request = action.data;
  try {
    const uri = ConfigAPI.GET_CREATOR_TABLE_URL;
    const { data } = yield call(
      baseAPI.get,
      `${uri}${request.userId}&PageNumber=${request.currentPage}&GridFilterKey=${request.search}&SortDirection=${request.sort}&OrderByColumnName=${request.sortBy}`,
      request
    );
    if (data.httpStatusCode === 200) {
      yield put(
        getCreatorTableSuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.warn(data.httpStatusMessage, { position: "bottom-right" });
      yield put(getCreatorTableFailure(data));
    }
  } catch (error) {
    yield put(getCreatorTableFailure(error));
  }
}

function* workerGetCreatorViewTableData(action) {
  const request = action.data;
  try {
    const uri = ConfigAPI.GET_CREATOR_VIEW_TABLE_URL;
    const { data } = yield call(
      baseAPI.get,
      `${uri}?VehicleGroupId=${request.VehicleGroupId}&RequestId=${request.RequestId}&IsWorkflowRequest=${request.IsWorkflowRequest}`
    );
    if (data.httpStatusCode === 200) {
      toast.success(data.httpStatusMessage, { position: "bottom-right" });
      yield put(
        getCreatorViewTableSuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.warn(data.httpStatusMessage, { position: "bottom-right" });
      yield put(getCreatorViewTableFailure(data));
    }
  } catch (error) {
    yield put(getCreatorViewTableFailure(error));
  }
}

function* workerGetCreatorEditTableData(action) {
  const request = action.data;
  try {
    const uri = ConfigAPI.GET_CREATOR_EDIT_TABLE_URL;
    const { data } = yield call(baseAPI.post, `${uri}`, request);
    if (data.httpStatusCode === 200) {
      toast.success(data.httpStatusMessage, { position: "bottom-right" });
      yield put(
        getCreatorEditTableSuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.warn(data.httpStatusMessage, { position: "bottom-right" });
      yield put(getCreatorEditTableFailure(data));
    }
  } catch (error) {
    yield put(getCreatorEditTableFailure(error));
  }
}

function* workerSubmitEditCodeList(action) {
  const request = action.data;
  try {
    const uri = ConfigAPI.SUBMIT_EDIT_CODE_LIST_URL;
    const { data } = yield call(baseAPI.post, `${uri}`, request);
    if (data.httpStatusCode === 200) {
      toast.success(data.httpStatusMessage, { position: "bottom-right" });
      yield put(
        getSubmitEditCodeListSuccess({
          payload: data,
          data: data,
        })
      );
    } else {
      toast.warn(data.httpStatusMessage, { position: "bottom-right" });
      yield put(getSubmitEditCodeListFailure(data));
    }
  } catch (error) {
    yield put(getSubmitEditCodeListFailure(error));
  }
}

function* workerSaveEditCodeList(action) {
  const request = action.data;
  try {
    const uri = ConfigAPI.SAVE_EDIT_CODE_LIST_URL;
    const { data } = yield call(baseAPI.post, `${uri}`, request);
    if (data.httpStatusCode === 200) {
      toast.success(data.httpStatusMessage, { position: "bottom-right" });
      yield put(
        getSaveEditCodeListSuccess({
          payload: data,
          data: data,
        })
      );
    } else {
      toast.warn(data.httpStatusMessage, { position: "bottom-right" });
      yield put(getSaveEditCodeListFailure(data));
    }
  } catch (error) {
    yield put(getSaveEditCodeListFailure(error));
  }
}

function* workerGetWorkflowTableData(action) {
  const request = action.data;
  try {
    const uri = ConfigAPI.GET_WORKFLOW_TABLE_URL;
    const { data } = yield call(
      baseAPI.get,
      `${uri}${request.currentPage}&GridFilterKey=${request.search}&SortDirection=${request.sort}&OrderByColumnName=${request.sortBy}`
    );
    if (data.httpStatusCode === 200) {
      toast.success(data.httpStatusMessage, { position: "bottom-right" });
      yield put(
        getWorkflowTableSuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.warn(data.httpStatusMessage, { position: "bottom-right" });
      yield put(getWorkflowTableFailure(data));
    }
  } catch (error) {
    yield put(getWorkflowTableFailure(error));
  }
}

function* workerGetWorkflowCombineData(action) {
  const request = action.data;
  try {
    const uri = ConfigAPI.GET_WORKFLOW_COMBINE_URL;
    const { data } = yield call(baseAPI.post, `${uri}`, request);
    if (data.httpStatusCode === 200) {
      toast.success(data.httpStatusMessage, { position: "bottom-right" });
      yield put(
        getWorkflowCombineSuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.warn(data.httpStatusMessage, { position: "bottom-right" });
      yield put(getWorkflowCombineFailure(data));
    }
  } catch (error) {
    yield put(getWorkflowCombineFailure(error));
  }
}

function* workerGetViewChangesDecisionData(action) {
  const request = action.data;
  try {
    const uri = ConfigAPI.GET_VIEW_CHANGES_DECISION_URL;
    const { data } = yield call(baseAPI.post, `${uri}`, request);
    if (data.httpStatusCode === 200) {
      toast.success(data.httpStatusMessage, { position: "bottom-right" });
      yield put(
        getViewChangesDecisionSuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.warn(data.httpStatusMessage, { position: "bottom-right" });
      yield put(getViewChangesDecisionFailure(data));
    }
  } catch (error) {
    yield put(getViewChangesDecisionFailure(error));
  }
}

function* workerGetCountryComparisonDropdownData(action) {
  const request = action.data;
  try {
    const uri = ConfigAPI.GET_COUNTRY_COMPARISON_DROPDOWN_URL;
    const { data } = yield call(baseAPI.post, `${uri}`, request);
    if (data.httpStatusCode === 200) {
      yield put(
        getCountryComparisonDropdownSuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.warn(data.httpStatusMessage, { position: "bottom-right" });
      yield put(getCountryComparisonDropdownFailure(data));
    }
  } catch (error) {
    yield put(getCountryComparisonDropdownFailure(error));
  }
}

function* workerGetCountryComparisonTableData(action) {
  const request = action.data;
  try {
    const uri = ConfigAPI.GET_COUNTRY_COMPARISON_TABLE_URL;
    const { data } = yield call(baseAPI.post, `${uri}`, request);
    if (data.httpStatusCode === 200) {
      toast.success(data.httpStatusMessage, { position: "bottom-right" });
      yield put(
        getCountryComparisonTableSuccess({
          payload: data,
          data: data.dataList,
        })
      );
    } else {
      toast.warn(data.httpStatusMessage, { position: "bottom-right" });
      yield put(getCountryComparisonTableFailure(data));
    }
  } catch (error) {
    yield put(getCountryComparisonTableFailure(error));
  }
}

function* workerCodeWorkflowTrackerTableData(action) {
  const request = action.data;
  try {
    const uri = ConfigAPI.CODELIST_WORKFLOWTRACKER_TABLE;
    const { data } = yield call(baseAPI.post, `${uri}`, request);
    if (data.httpStatusCode === 200) {
      toast.success(data.httpStatusMessage, { position: "bottom-right" });
      yield put(
        getCodeWorkflowTrackerTableSucess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.warn(data.httpStatusMessage, { position: "bottom-right" });
      yield put(getCodeWorkflowTrackerTableFailure(data));
    }
  } catch (error) {
    yield put(getCodeWorkflowTrackerTableFailure(error));
  }
}

function* workerCodeListExcelExport(action) {
  const request = action.data;
  try {
    const uri = ConfigAPI.CODELIST_EXCEL_EXPORT_URL;
    const { data } = yield call(baseAPI.post, `${uri}`, request);
    if (data.httpStatusCode === 200) {
      toast.success(data.httpStatusMessage, { position: "bottom-right" });
      yield put(
        codeListExcelExportSucess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.error(data.httpStatusMessage, { position: "bottom-right" });
      yield put(codeListExcelExportFailure(data));
    }
  } catch (error) {
    yield put(codeListExcelExportFailure(error));
  }
}

function* workerCodeListHistory(action) {
  const request = action.data;
  try {
    const uri = ConfigAPI.CODELIST_HISTORY_URL;
    const { data } = yield call(baseAPI.post, `${uri}`, request);
    if (data.httpStatusCode === 200) {
      toast.success(data.httpStatusMessage, { position: "bottom-right" });
      yield put(
        codeListHistorySucess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.error(data.httpStatusMessage, { position: "bottom-right" });
      yield put(codeListHistoryFailure(data));
    }
  } catch (error) {
    yield put(codeListHistoryFailure(error));
  }
}

function* workerCodeListPdfExport(action) {
  const request = action.data;
  try {
    const uri = ConfigAPI.CODELIST_PDF_EXPORT_URL;
    const { data } = yield call(baseAPI.post, `${uri}`, request, {
      responseType: "blob",
    });
    saveAs(data, "Code List.pdf");
    if (data.httpStatusCode === 200) {
      saveAs(data.data, "Code List.pdf");
      toast.success(data.httpStatusMessage, { position: "bottom-right" });
      yield put(
        codeListPdfExportSuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.error(data.httpStatusMessage, { position: "bottom-right" });
      yield put(codeListPdfExportFailure(data));
    }
  } catch (error) {
    yield put(codeListPdfExportFailure(error));
  }
}

export function* CodeListSaga() {
    yield takeEvery(CodeListActionTypes.GET_DROPDOWN_CODE_LIST_REQUEST, workerGetDropdownCodeList);
    yield takeEvery(CodeListActionTypes.GET_VIEW_CODE_LIST_TABLE_REQUEST, workerGetViewCodeListTableData);
    yield takeEvery(CodeListActionTypes.GET_DROPDOWN_VERSION_COMPARISON_REQUEST, workerGetDropdownVersionComparison);
    yield takeEvery(CodeListActionTypes.GET_VERSION_COMPARISON_TABLE_REQUEST, workerGetVersionComparisonTableData);
    yield takeEvery(CodeListActionTypes.SUBMIT_CODE_LIST_REQUEST, workerSubmitCodeList);
    yield takeEvery(CodeListActionTypes.GET_CREATOR_TABLE_REQUEST, workerGetCreatorTableData);
    yield takeEvery(CodeListActionTypes.GET_CREATOR_VIEW_TABLE_REQUEST, workerGetCreatorViewTableData);
    yield takeEvery(CodeListActionTypes.GET_CREATOR_EDIT_TABLE_REQUEST, workerGetCreatorEditTableData);
    yield takeEvery(CodeListActionTypes.SUBMIT_EDIT_CODE_LIST_REQUEST, workerSubmitEditCodeList);
    yield takeEvery(CodeListActionTypes.SAVE_EDIT_CODE_LIST_REQUEST, workerSaveEditCodeList);
    yield takeEvery(CodeListActionTypes.GET_WORKFLOW_TABLE_REQUEST, workerGetWorkflowTableData);
    yield takeEvery(CodeListActionTypes.GET_WORKFLOW_COMBINE_REQUEST, workerGetWorkflowCombineData);
    yield takeEvery(CodeListActionTypes.GET_VIEW_CHANGES_DECISION_REQUEST, workerGetViewChangesDecisionData);
    yield takeEvery(CodeListActionTypes.GET_COUNTRY_COMPARISON_DROPDOWN_REQUEST, workerGetCountryComparisonDropdownData);
    yield takeEvery(CodeListActionTypes.GET_COUNTRY_COMPARISON_TABLE_REQUEST, workerGetCountryComparisonTableData);
    yield takeEvery(CodeListActionTypes.GET_CODEWORKFLOWTRACKER_TABLEDATA_REQUEST, workerCodeWorkflowTrackerTableData);
    yield takeEvery(CodeListActionTypes.CODE_LIST_EXCEL_EXPORT_REQUEST, workerCodeListExcelExport);
    yield takeEvery(CodeListActionTypes.CODE_LIST_HISTORY_REQUEST, workerCodeListHistory);
    yield takeEvery(CodeListActionTypes.CODE_LIST_PDF_EXPORT_REQUEST, workerCodeListPdfExport);
}

export default CodeListSaga;
