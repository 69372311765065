//REGISTER
export const POST_FAKE_REGISTER = "/auth/signup";

//LOGIN
export const POST_FAKE_LOGIN = "/auth/signin";
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login";
export const POST_FAKE_PASSWORD_FORGET = "/auth/forgot-password";
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd";
export const SOCIAL_LOGIN = "/social-login";

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile";
export const POST_EDIT_PROFILE = "/user";

// Calendar
export const GET_EVENTS = "/events";
export const GET_CATEGORIES = "/categories";
export const GET_UPCOMMINGEVENT = "/upcommingevents";
export const ADD_NEW_EVENT = "/add/event";
export const UPDATE_EVENT = "/update/event";
export const DELETE_EVENT = "/delete/event";

// Chat
export const GET_DIRECT_CONTACT = "/chat";
export const GET_MESSAGES = "/messages";
export const ADD_MESSAGE = "add/message";
export const GET_CHANNELS = "/channels";
export const DELETE_MESSAGE = "delete/message";

//Mailbox
export const GET_MAIL_DETAILS = "/mail";
export const DELETE_MAIL = "/delete/mail";

// Ecommerce
// Product
export const GET_PRODUCTS = "/apps/product";
export const DELETE_PRODUCT = "/apps/product";
export const ADD_NEW_PRODUCT = "/apps/product";
export const UPDATE_PRODUCT = "/apps/product";

// Orders
export const GET_ORDERS = "/apps/order";
export const ADD_NEW_ORDER = "/apps/order";
export const UPDATE_ORDER = "/apps/order";
export const DELETE_ORDER = "/apps/order";

// Customers
export const GET_CUSTOMERS = "/apps/customer";
export const ADD_NEW_CUSTOMER = "/apps/customer";
export const UPDATE_CUSTOMER = "/apps/customer";
export const DELETE_CUSTOMER = "/apps/customer";

// Sellers
export const GET_SELLERS = "/sellers";

// Project list
export const GET_PROJECT_LIST = "/project/list";

// Task
export const GET_TASK_LIST = "/apps/task";
export const ADD_NEW_TASK = "/apps/task";
export const UPDATE_TASK = "/apps/task";
export const DELETE_TASK = "/apps/task";

// CRM
// Conatct
export const GET_CONTACTS = "/apps/contact";
export const ADD_NEW_CONTACT = "/apps/contact";
export const UPDATE_CONTACT = "/apps/contact";
export const DELETE_CONTACT = "/apps/contact";

// Companies
export const GET_COMPANIES = "/apps/company";
export const ADD_NEW_COMPANIES = "/apps/company";
export const UPDATE_COMPANIES = "/apps/company";
export const DELETE_COMPANIES = "/apps/company";

// Lead
export const GET_LEADS = "/apps/lead";
export const ADD_NEW_LEAD = "/apps/lead";
export const UPDATE_LEAD = "/apps/lead";
export const DELETE_LEAD = "/apps/lead";

// Deals
export const GET_DEALS = "/deals";

// Crypto
export const GET_TRANSACTION_LIST = "/transaction-list";
export const GET_ORDRER_LIST = "/order-list";

// Invoice
export const GET_INVOICES = "/apps/invoice";
export const ADD_NEW_INVOICE = "/apps/invoice";
export const UPDATE_INVOICE = "/apps/invoice";
export const DELETE_INVOICE = "/apps/invoice";

// TicketsList
export const GET_TICKETS_LIST = "/apps/ticket";
export const ADD_NEW_TICKET = "/apps/ticket";
export const UPDATE_TICKET = "/apps/ticket";
export const DELETE_TICKET = "/apps/ticket";

// Dashboard Analytics

// Sessions by Countries
export const GET_ALL_DATA = "/all-data";
export const GET_HALFYEARLY_DATA = "/halfyearly-data";
export const GET_MONTHLY_DATA = "/monthly-data";

// Audiences Metrics
export const GET_ALLAUDIENCESMETRICS_DATA = "/allAudiencesMetrics-data";
export const GET_MONTHLYAUDIENCESMETRICS_DATA = "/monthlyAudiencesMetrics-data";
export const GET_HALFYEARLYAUDIENCESMETRICS_DATA =
  "/halfyearlyAudiencesMetrics-data";
export const GET_YEARLYAUDIENCESMETRICS_DATA = "/yearlyAudiencesMetrics-data";

// Users by Device
export const GET_TODAYDEVICE_DATA = "/todayDevice-data";
export const GET_LASTWEEKDEVICE_DATA = "/lastWeekDevice-data";
export const GET_LASTMONTHDEVICE_DATA = "/lastMonthDevice-data";
export const GET_CURRENTYEARDEVICE_DATA = "/currentYearDevice-data";

// Audiences Sessions by Country
export const GET_TODAYSESSION_DATA = "/todaySession-data";
export const GET_LASTWEEKSESSION_DATA = "/lastWeekSession-data";
export const GET_LASTMONTHSESSION_DATA = "/lastMonthSession-data";
export const GET_CURRENTYEARSESSION_DATA = "/currentYearSession-data";

// Dashboard CRM

// Balance Overview
export const GET_TODAYBALANCE_DATA = "/todayBalance-data";
export const GET_LASTWEEKBALANCE_DATA = "/lastWeekBalance-data";
export const GET_LASTMONTHBALANCE_DATA = "/lastMonthBalance-data";
export const GET_CURRENTYEARBALANCE_DATA = "/currentYearBalance-data";

// Deal type
export const GET_TODAYDEAL_DATA = "/todayDeal-data";
export const GET_WEEKLYDEAL_DATA = "/weeklyDeal-data";
export const GET_MONTHLYDEAL_DATA = "/monthlyDeal-data";
export const GET_YEARLYDEAL_DATA = "/yearlyDeal-data";

// Sales Forecast

export const GET_OCTSALES_DATA = "/octSales-data";
export const GET_NOVSALES_DATA = "/novSales-data";
export const GET_DECSALES_DATA = "/decSales-data";
export const GET_JANSALES_DATA = "/janSales-data";

// Dashboard Ecommerce
// Revenue
export const GET_ALLREVENUE_DATA = "/allRevenue-data";
export const GET_MONTHREVENUE_DATA = "/monthRevenue-data";
export const GET_HALFYEARREVENUE_DATA = "/halfYearRevenue-data";
export const GET_YEARREVENUE_DATA = "/yearRevenue-data";

// Dashboard Crypto
// Portfolio
export const GET_BTCPORTFOLIO_DATA = "/btcPortfolio-data";
export const GET_USDPORTFOLIO_DATA = "/usdPortfolio-data";
export const GET_EUROPORTFOLIO_DATA = "/euroPortfolio-data";

// Market Graph
export const GET_ALLMARKETDATA_DATA = "/allMarket-data";
export const GET_YEARMARKET_DATA = "/yearMarket-data";
export const GET_MONTHMARKET_DATA = "/monthMarket-data";
export const GET_WEEKMARKET_DATA = "/weekMarket-data";
export const GET_HOURMARKET_DATA = "/hourMarket-data";

// Dashboard Crypto
// Project Overview
export const GET_ALLPROJECT_DATA = "/allProject-data";
export const GET_MONTHPROJECT_DATA = "/monthProject-data";
export const GET_HALFYEARPROJECT_DATA = "/halfYearProject-data";
export const GET_YEARPROJECT_DATA = "/yearProject-data";

// Project Status
export const GET_ALLPROJECTSTATUS_DATA = "/allProjectStatus-data";
export const GET_WEEKPROJECTSTATUS_DATA = "/weekProjectStatus-data";
export const GET_MONTHPROJECTSTATUS_DATA = "/monthProjectStatus-data";
export const GET_QUARTERPROJECTSTATUS_DATA = "/quarterProjectStatus-data";

// Dashboard NFT
// Marketplace
export const GET_ALLMARKETPLACE_DATA = "/allMarketplace-data";
export const GET_MONTHMARKETPLACE_DATA = "/monthMarketplace-data";
export const GET_HALFYEARMARKETPLACE_DATA = "/halfYearMarketplace-data";
export const GET_YEARMARKETPLACE_DATA = "/yearMarketplace-data";

// Project
export const ADD_NEW_PROJECT = "/add/project";
export const UPDATE_PROJECT = "/update/project";
export const DELETE_PROJECT = "/delete/project";

// Pages > Team
export const GET_TEAMDATA = "/teamData";
export const DELETE_TEAMDATA = "/delete/teamData";
export const ADD_NEW_TEAMDATA = "/add/teamData";
export const UPDATE_TEAMDATA = "/update/teamData";

// File Manager
// Folder
export const GET_FOLDERS = "/folder";
export const DELETE_FOLDER = "/delete/folder";
export const ADD_NEW_FOLDER = "/add/folder";
export const UPDATE_FOLDER = "/update/folder";

// File
export const GET_FILES = "/file";
export const DELETE_FILE = "/delete/file";
export const ADD_NEW_FILE = "/add/file";
export const UPDATE_FILE = "/update/file";

// To do
export const GET_TODOS = "/todo";
export const DELETE_TODO = "/delete/todo";
export const ADD_NEW_TODO = "/add/todo";
export const UPDATE_TODO = "/update/todo";

// To do Project
export const GET_PROJECTS = "/projects";
export const ADD_NEW_TODO_PROJECT = "/add/project";

//JOB APPLICATION
export const GET_APPLICATION_LIST = "/application-list";

//JOB APPLICATION
export const GET_API_KEY = "/api-key";

// export const INPUT_BOOLEAN_LOGIC_SEARCH = "/CodeBuilder/GetBooleanCombination";

export const ConfigAPI = {
  LOGIN_URL: "/api/login/login",
  REFRESH_TOKEN_URL: "/api/login/refreshtoken",
  INPUT_BOOLEAN_LOGIC_SEARCH: "api/CodeBuilder/GetBooleanCombination",
  GET_INPUT_MODELS_DATA_URL: "api/CodeConfigurator/GetVarientList",
  GET_INPUT_COUNTRY_DATA_URL: "api/CodeConfigurator/GetCountryList",
  BIND_BINDCODELIST_AND_CODEBOM_URL: "api/CodeConfigurator/BindCodelistAndCodeBOM",
  GET_MODIFIDED_COMBINATION_URL: "api/CodeBuilder/GetModifiedCombination",
  GET_BAU_MASTER_DATA_URL: "api/SanCheck/getBauMaster",
  GET_SANCHECK_OLDCODE_URL: "api/SanCheck/getSanCheckOldCode",
  GET_SANCHECK_VARIENT_URL: "api/SanCheck/getVarient",
  UPDATE_CODELIST_CODEBOM_URL: "api/CodeConfigurator/UpdateCodeListCodeBom",
  CODE_CONFIGURATION_EXCELEXPORT_URL: "api/CodeConfigurator/ExcelExport",
  SANCHECK_EXCELEXPORT_URL: "api/SanCheck/getsancheckendresult",
  GET_CODES_DROPDOWN_URL: "api/CodeBuilder/getrelatedcodesdropdown?Code=",
  GET_SELECTED_CODE_TRUTHTABLE_URL: "api/CodeBuilder/GetSelectedCodeTruthTable",
  GET_BOOLEANLOGIC_CODEBUILDER_DATA_URL: "api/CodeBuilder/getOperatorTruthTabelData",
  GET_BOOLEANLOGIC_LOGICCHECK_URL: "api/CodeBuilder/GetCombinationForTruthTable",
  INSERT_OR_UPDATE_COUNTRYGROUP_URL: "/api/countryGroup/insertOrUpdate",
  GET_COUNTRYGROUP_BY_ID_URL: "api/countryGroup/getCountryGroupById",
  GET_ALL_COUNTRYGROUP_URL: "api/countryGroup/getAllCountryGroup",
  DELETE_COUNTRYGROUP_URL: "api/countryGroup/delete",
  GET_ALL_COUNTRY_URL: "api/country/getAllCountry",
  INSERT_OR_UPDATE_COUNTRY_URL: "/api/country/insertOrUpdate",
  DELETE_COUNTRY_URL: "api/country/delete",
  CODELIST_WORKFLOWTRACKER_TABLE:"/api/CodeList/getcodeinapprovalpending",
  GET_COUNTRY_GROUP_GROPDOWN_URL: "/api/countryGroup/getCountryGroup",
  GET_ALL_COUNTRY_TYPE_URL: "/api/vehicleType/getAllVehicletype",
  INSERT_OR_UPDATE_VEHICLE_TYPE_URL: "/api/vehicleType/insertOrUpdate",
  DELETE_VEHICLE_TYPE_URL: "/api/vehicleType/delete",
  GET_ALL_VEHICLE_GROUP_URL: "/api/vehicleGroup/getAllVehicleGroup",
  GET_VEHICLE_GROUP_DROPDOWN_URL: "/api/vehicleGroup/getAllVehicleGroupDropDown",
  INSERT_OR_UPDATE_VEHICLE_GROUP_URL: "/api/vehicleGroup/insertOrUpdate",
  GET_COUNTRY_COMPARISON_TABLE_URL:"/api/versioncomparison/CountryComparison",
  DELETE_VEHICLE_GROUP_URL: "/api/vehicleGroup/delete",
  GET_ALL_CODE_GROUP_URL: "/api/codeGroup/getAllCodeGroup",
  INSERT_OR_UPDATE_CODE_GROUP_URL: "/api/codeGroup/insertOrUpdate",
  DELETE_CODE_GROUP_URL: "/api/codeGroup/delete",
  GET_ALL_VEHICLE_CATEGORY_URL: "/api/vehicleCategory/getAllVehicleCategory",
  INSERT_OR_UPDATE_VEHICLE_CATEGORY_URL: "/api/vehicleCategory/insertOrUpdate",
  DELETE_VEHICLE_CATEGORY_URL: "/api/vehicleCategory/delete",
  GET_ALL_OPTIONS_URL: "/api/options/getAllOptions",
  INSERT_OR_UPDATE_OPTIONS_URL: "/api/options/insertOrUpdate",
  GET_COUNTRY_COMPARISON_DROPDOWN_URL:"/api/versioncomparison/GetCountryComparisonDropdown",
  DELETE_OPTIONS_URL: "/api/options/delete",
  GET_ALL_VEHICLE_MODEL_URL: "/api/vehicleModel/getAllVehicleModel",
  // GET_All_TONNAGE_DROPDOWN_URL: "/api/tonnage/getAllTonnageDropDown",
  // GET_All_CATEGORY_BY_TONNAGE_DROPDOWN_URL: "/api/vehicleModel/GetVehicleCategoriesByTonnage",
  GET_TONNAGE_BY_VEHICLE_CATEGORY_DROPDOWN_URL: "/api/tonnage/GetTonnageByVehicleCategory",
  GET_All_CATEGORY_BY_VEHICLEGROUP_DROPDOWN_URL: "/api/vehicleCategory/GetVehicleCategoriesByVehicleGroup",

  GET_VEHICALE_GROUP_BY_CATEGORY_DROPDOWN_URL: "/api/tonnage/GetVehicleGroupBasedOnVehicleCategory",
  INSERT_OR_UPDATE_VEHICLE_MODEL_URL: "/api/vehicleModel/insertOrUpdate",
  DELETE_VEHICLE_MODEL_URL: "/api/vehicleModel/delete",
  GET_ALL_TARGET_MATRIX_URL: "/api/targetMatrixMasters/getAllTargetMatrixMaster",
  GET_VEHICLE_MODEL_DROPDOWN_URL: "/api/vehicleModel/getAllVehicleModelDropDown",
  // GET_VEHICALE_GROUP_BY_VEHICLE_MODEL_DROPDOWN_URL: "/api/vehicleModel/GetVehicleGroupByVehicleModel",
  GET_VEHICALE_MODEL_BY_VEHICLE_GROUP_DROPDOWN_URL: "/api/vehicleModel/GetVehicleModelByVehicleGroup",

  GET_ALL_TONNAGE_URL: "/api/tonnage/getAllTonnage",
  GET_ALL_VEHICLE_CATEGORY_DROPDOWN_URL: "/api/vehicleCategory/getAllVehicleCategoryDropDown",
  GET_VEHICLE_GROUP_BY_VEHICLE_CATEGORY_URL: "/api/tonnage/GetVehicleGroupBasedOnVehicleCategory",
  INSERT_OR_UPDATE_TONNAGE_URL: "/api/tonnage/insertOrUpdate",
  INSERT_OR_UPDATE_TARGET_MATRIX_URL: "/api/targetMatrixMasters/insertOrUpdate",
  DELETE_TARGET_MATRIX_URL: "/api/targetMatrixMasters/delete",
  GET_DROPDOWN_CODE_LIST_URL: "/api/codelist/GetDropdown",
  SUBMIT_CODE_LIST_URL: "/api/ChangeRequest/VehicleCountryRequestMapping",
  GET_CREATOR_TABLE_URL: "/api/ChangeRequest/getAllChangeRequest?UserId=",
  GET_CREATOR_VIEW_TABLE_URL: "/api/versioncomparison/ViewChanges",
  GET_CREATOR_EDIT_TABLE_URL: "/api/changerequest/getselectedcodelist",
  SUBMIT_EDIT_CODE_LIST_URL:"/api/ChangeRequest/InsertOrUpdateChangeRequestTransaction",
  SAVE_EDIT_CODE_LIST_URL: "/api/ChangeRequest/InsertOrUpdateChangeRequestTransaction",
  GET_WORKFLOW_TABLE_URL: "/api/ChangeRequest/getAllWorkflowRequest?PageNumber=",
  GET_WORKFLOW_COMBINE_URL: "/api/changerequest/combainrequest",
  GET_VIEW_CHANGES_DECISION_URL:"/api/ChangeRequest/UpdateWorkflow",
  GET_VIEW_CODE_LIST_TABLE_URL:"/api/codelist/getcodelist",
  DELETE_TONNAGE_URL: "/api/tonnage/delete",
  GET_DROPDOWN_VERSION_COMPARISON_URL: "/api/versioncomparison/getdropdown?vehiclegroupid=",
  GET_VERSION_COMPARISON_TABLE_URL:"/api/versioncomparison/VersionComparison",
  GET_ALL_PACKAGE_MASTER_URL: "/api/packageMaster/getAllPackageMaster",
  GET_ALL_PACKAGE_SUBITEM_URL: "/api/packageSubItem/getAllPackageSubItem",
  GET_ALL_PACKAGE_MASTER_DROPDOWN_URL: "/api/packageMaster/getAllPackageMasterDropDown",  
  INSERT_OR_UPDATE_PACKAGE_SUBITEM_URL: "/api/packageSubItem/insertOrUpdate",
  GET_VEHICLE_GROUP_BY_PACKAGE_URL: "/api/packageMaster/getVehicleGroupByPackage",
  DELETE_PACKAGE_SUBITEM_URL: "/api/packageSubItem/delete",
  GET_ALL_CODE_URL: "/api/code/getAllCode",
  GET_ALL_CODE_GROUP_DROPDOWN_URL: "/api/codeGroup/getAllCodeGroupDropDown",  
  INSERT_OR_UPDATE_CODE_URL: "/api/code/insertOrUpdate",
  DELETE_CODE_URL: "/api/code/delete",
  GET_ALL_VEHICLE_COUNTRY_GROUP_MAPPING_URL: "/api/vehicleCountryGroupMapping/getAllVehicleCountryGroupMapping",
  INSERT_OR_UPDATE_VEHICLE_COUNTRY_GROUP_MAPPING_URL: "/api/vehicleCountryGroupMapping/insertOrUpdate",
  DELETE_VEHICLE_COUNTRY_GROUP_MAPPING_URL: "/api/vehicleCountryGroupMapping/delete",
  DELETE_PACKAGE_MASTER_URL: "/api/packageMaster/delete",
  INSERT_OR_UPDATE_PACKAGE_MASTER_URL: "/api/packageMaster/insertOrUpdate",
  GET_ALL_VEHICLE_TYPE_DROPDOWN_URL :"/api/vehicleType/getAllVehicleTypeDropDown",
  GET_ALL_ROLE_URL: "/api/role/getAllRole",
  INSERT_OR_UPDATE_ROLE_URL: "/api/role/insert",
  DELETE_ROLE_URL: "/api/role/delete",
  GET_ALL_CREATE_USER: "/api/user/getalluser",
  GET_ALL_ACCESS_URL: "/api/access/getAllAccess",
  GET_ACCESS_BY_GROUP_URL: "/api/access/getdropdownforaccess",
  INSERT_OR_UPDATE_ACCESS_URL: "/api/access/insert",
  DELETE_ACCESS_URL: "/api/access/delete",
  GET_USER_DROP_DOWN_URL: "/api/user/getuserdropdowns",
  INSERT_OR_UPDATE_CREATE_USER_URL: "/api/user/insertOrUpdate",
  GET_ROLE_ACCESS_MAPPING_URL: "/api/role/getroleaccessmappingdropdown",
  INSERT_OR_UPDATE_ROLE_ACCESS_MAPPING_URL: "/api/role/insertroleaccessmapping",
  ROLE_ACCESS_MAPPING_LIST_URL: "/api/role/getAllRoleAccessMapping",
  GET_ALL_CODE_REQUEST_URL: "/api/codeRequest/getAllCodeRequest",
  INSERT_OR_UPDATE_CODE_REQUEST_URL: "/api/codeRequest/insert",
  DELETE_CODE_REQUEST_URL: "/api/codeRequest/approveOrRejectCodeRequest",
  GET_CONFIGURATION_VEHICLE_GROUP_URL: "/api/codeconfigurator/GetDropdown",
  GET_CODEBUILDER_VEHICLE_GROUP_URL: "/api/VehicleGroup/getAllVehicleGroupDropDown",
  GET_CODE_GROUP_BY_VEHICLE_GROUP_URL: "/api/codeGroup/getCodeGroupByVehicleGroup",
  GET_MAIL_CODE_REQUEST_URL: "/api/codeRequest/getCodeRequestById",
  CODELIST_EXCEL_EXPORT_URL: "/api/codelist/ExcelExport",
  CODELIST_HISTORY_URL: "/api/codelist/getcodehistroy",
  CODELIST_PDF_EXPORT_URL: "/api/codelist/PdfExport",
  MERGE_EXCEL_URL: "/api/sancheck/MergeExcel",
}
