import React, { useState, useEffect } from 'react';
import Select from "react-select";
import { connect } from "react-redux";
import { Card, CardBody, Col, Container, Row, CardHeader, Label, Button } from 'reactstrap';
import { Pagination } from '@mui/material';
import Loader from '../Loader/Loader';
import DeleteModal from '../DeleteModel/DeleteModel';
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { getVehicleCountryGroupMappingAction, getCountryGroupDropdownAction, getVehicleModelByVehicleGroupDropdownAction, insertOrUpdateVehicleCountryGroupMappingAction, deleteVehicleCountryGroupMappingAction, getAllVehicleGroupDropdownAction } from '../../store/actions';


const VehicleCountryGroupMapping = (props: any) => {
    document.title = "VehicleCountryGroupMapping";
    const { vehicleCountryGroupMapping, getCountryGroupDropdown, insertOrUpdateVehicleCountryGroupMapping, deleteVehicleCountryGroupMapping, getVehicleGroupDropdown, VehicleModelByVehicleGroupDropdown, getLoginData, loading } = props;
    const errors: any = {}
    const [formValidation, setFormValiodation] = useState<any>({});
    const [selectedCountryGroup, setSelectedCountryGroup] = useState(null);
    const [selectedVehicleModel, setSelectedVehicleModel] = useState(null);
    const [selectedVehicleGroup, setSelectedVehicleGroup] = useState(null);
    const [openModel, setOpenModel] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [todo, setTodo] = useState(null);
    const [getTargetMatrixList, setGetTargetMatrixList] = useState<any>(null);
    const [countryResponse, setCountryResponse] = useState<any>(null);
    const [vehicleModelDropdownList, setVehicleModelDropdownList] = useState<any>(null);
    const [vehicleGroupDropdownList, setVehicleGroupDropdownList] = useState<any>(null);
    const [VehicleId, setVehicleModelId] = useState<any>("");
    const [countryGroupId, setCountryGroupId] = useState<any>("");
    const [vehicleGroupId, setVehicleGroupId] = useState<any>("");
    const [editId, seteditId] = useState<any>("");
    const [totalCount, setTotalCount] = useState<any>("");
    const [currentPage, setCurrentPage] = useState<any>(1);
    const [searchKeyword, setSearchKeyword] = useState("");
    const [sort, setSort] = useState("");
    const recordsPerPage = 10;
    const totalPages = Math.ceil(Number(totalCount) / recordsPerPage);
    const LoginRoleData = localStorage.getItem("loginRoleAccess")
    const RoleResponse = JSON.parse(LoginRoleData);
    const Role = RoleResponse?.find(data => data?.name === "Master")?.subModules?.find(val => val?.submenuName === "VehicleCountryGroupMapping")?.access

    const Country = countryResponse?.map((item) => (
        {
            label: item.value,
            value: item.value,
            id: item.id,
            isSelected: item.isSelected
        }
    )
    ) || [];

    const Model = vehicleModelDropdownList?.map((item) => (
        {
            label: item.value,
            value: item.value,
            id: item.id,
            isSelected: item.isSelected
        }
    )
    ) || [];

    const Group = vehicleGroupDropdownList?.map((item) => (
        {
            label: item.value,
            value: item.value,
            id: item.id,
            isSelected: item.isSelected
        }
    )
    ) || [];

    const handleEdit = (matrix: any) => {
        setVehicleModelId(matrix?.vehicleId);
        setCountryGroupId(matrix?.countryGroupId);
        setVehicleGroupId(matrix?.vehicleGroupId);
        seteditId(matrix?.id);
        setOpenModel(true);
        setSelectedCountryGroup({
            id: matrix?.countryGroupId,
            label: matrix?.countryGroupValue,
            value: matrix?.countryGroupValue,
            isSelected: matrix?.isActive,
        });
        setSelectedVehicleModel({
            id: matrix?.vehicleId,
            label: matrix?.vehicleModelValue,
            value: matrix?.vehicleModelValue,
            isSelected: matrix?.isActive,
        });
        setSelectedVehicleGroup({
            id: matrix?.vehicleGroupId,
            label: matrix?.vehicleGroupValue,
            value: matrix?.vehicleGroupValue,
            isSelected: matrix?.isActive,
        });
        window.scrollTo({ top: 0, behavior: 'smooth' });
        props.getVehicleModelByVehicleGroupDropdownAction(matrix?.vehicleId)
    }

    const handleClear = () => {
        setVehicleModelId('');
        setCountryGroupId('');
        setVehicleGroupId('');
        setSelectedVehicleModel(null);
        setSelectedCountryGroup(null);
        setSelectedVehicleGroup(null);
        setVehicleModelDropdownList(null);
        setFormValiodation({});
        seteditId("");
    }

    const handleAdd = () => {
        setOpenModel(true);
    }

    const handleSave = () => {
        if (!VehicleId) {
            errors.VehicleId = 'Please select tonnage'
        }
        if (!countryGroupId) {
            errors.countryGroupId = 'Please select country'
        }
        if (!vehicleGroupId) {
            errors.vehicleGroupId = 'Please select vehicle group'
        }
        const datas = {
            id: editId ? editId : 0,
            VehicleId: VehicleId,
            countryGroupId: countryGroupId,
        };
        setCurrentPage(editId ? currentPage : 1)
        setFormValiodation(errors);
        if (VehicleId && countryGroupId && vehicleGroupId) {
            props.insertOrUpdateVehicleCountryGroupMappingAction(datas)
        }
    }

    useEffect(() => {
        if (insertOrUpdateVehicleCountryGroupMapping?.httpStatusCode === 200) {
            setOpenModel(false);
            handleClear();
            props.getVehicleCountryGroupMappingAction({ page: currentPage, search: searchKeyword, sort: sort, OrderByColumnName: "" })
        }
    }, [insertOrUpdateVehicleCountryGroupMapping])

    function handleSelectCountryGroup(selectedCountryGroup) {
        setCountryGroupId(selectedCountryGroup?.id);
        setSelectedCountryGroup(selectedCountryGroup);
    }

    function handleSelectVehicleModel(selectedVehicleModel) {
        setVehicleModelId(selectedVehicleModel?.id);
        setSelectedVehicleModel(selectedVehicleModel);
    }

    function handleSelectVehicleGroup(selectedVehicleGroup) {
        setVehicleGroupId(selectedVehicleGroup?.id);
        setSelectedVehicleGroup(selectedVehicleGroup);
        props.getVehicleModelByVehicleGroupDropdownAction(selectedVehicleGroup?.id);
        setVehicleModelId("");
        setSelectedVehicleModel(null);
        setVehicleModelDropdownList(null);
    }

    const handleDeleteTodo = () => {
        props.deleteVehicleCountryGroupMappingAction(todo);
        setCurrentPage(1)
        setDeleteModal(false);
    };

    const onClickTodoDelete = (todo: any) => {
        setTodo(todo);
        setDeleteModal(true);
    };

    const onClickPagination = (event, value) => {
        setCurrentPage(value);
    };

    const onSearchChange = (event: any) => {
        setSearchKeyword(event.target.value);
        props.getVehicleCountryGroupMappingAction({ page: "1", search: event.target.value, sort: sort, OrderByColumnName: "" })
        setCurrentPage(1);
    };

    const onSortChange = (keys: any) => {
        const newSortOrder = sort === 'asc' ? 'desc' : 'asc';
        setSort(newSortOrder);
        props.getVehicleCountryGroupMappingAction({ page: currentPage, search: searchKeyword, sort: newSortOrder, OrderByColumnName: keys, SortDirection: newSortOrder });
    };

    useEffect(() => {
        props.getVehicleCountryGroupMappingAction({ page: currentPage, search: searchKeyword, sort: sort, OrderByColumnName: "" });
        setTotalCount(vehicleCountryGroupMapping?.totalRowsCount);
    }, [currentPage, deleteVehicleCountryGroupMapping])

    useEffect(() => {
        setTotalCount(vehicleCountryGroupMapping?.totalRowsCount);
    }, [vehicleCountryGroupMapping])

    useEffect(() => {
        setGetTargetMatrixList(vehicleCountryGroupMapping)
    }, [vehicleCountryGroupMapping])

    useEffect(() => {
        props.getCountryGroupDropdownAction();
        props.getAllVehicleGroupDropdownAction();
    }, [])

    useEffect(() => {
        setCountryResponse(getCountryGroupDropdown?.data)
        setVehicleGroupDropdownList(getVehicleGroupDropdown?.data)
    }, [getCountryGroupDropdown, getVehicleGroupDropdown])

    useEffect(() => {
        setVehicleModelDropdownList(VehicleModelByVehicleGroupDropdown?.data)
    }, [VehicleModelByVehicleGroupDropdown])

    return (
        <React.Fragment>
            {loading && <Loader />}
            <DeleteModal
                show={deleteModal}
                onDeleteClick={() => handleDeleteTodo()}
                onCloseClick={() => setDeleteModal(false)}
            />
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Vehicle Country Group Mapping" pageTitle="Masters" />
                    {openModel &&
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardHeader className='card-bg'><h4 className="card-title mb-0 ">Vehicle Country Group Mapping</h4> </CardHeader>
                                    <CardBody>
                                        <div>
                                            <div>
                                                <Row>
                                                    <Col lg={4} md={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="choices-single-no-search" className="form-label text-muted">Country Group <span style={{ color: 'red' }}>*</span></Label>
                                                            <Select
                                                                isClearable={true}
                                                                value={selectedCountryGroup}
                                                                onChange={(e) => {
                                                                    handleSelectCountryGroup(e);
                                                                }}
                                                                options={Country}
                                                            />
                                                            <span className='text-validation'>{formValidation.countryGroupId}</span>
                                                        </div>
                                                    </Col>
                                                    <Col lg={4} md={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="choices-single-no-search" className="form-label text-muted">Vehicle Group <span style={{ color: 'red' }}>*</span></Label>
                                                            <Select
                                                                isClearable={true}
                                                                value={selectedVehicleGroup}
                                                                onChange={(e) => {
                                                                    handleSelectVehicleGroup(e);
                                                                }}
                                                                options={Group}
                                                            />
                                                            <span className='text-validation'>{formValidation.vehicleGroupId}</span>
                                                        </div>
                                                    </Col>
                                                    <Col lg={4} md={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="choices-single-no-search" className="form-label text-muted">Vehicle Model <span style={{ color: 'red' }}>*</span></Label>
                                                            <Select
                                                                isClearable={true}
                                                                value={selectedVehicleModel}
                                                                onChange={(e) => {
                                                                    handleSelectVehicleModel(e);
                                                                }}
                                                                options={Model}
                                                            />
                                                            <span className='text-validation'>{formValidation.VehicleId}</span>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </CardBody>
                                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                        <Button className="btn btn-primary mb-2 btn-custom-blue" type="button" color='light' onClick={() => handleSave()}>Save</Button>
                                        <Button className="btn btn-primary me-3 mb-2 btn-custom-blue" type="button" color='light' onClick={handleClear}>Clear</Button>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    }
                    <Row>
                        <Col xl={12}>
                            <Card>
                                <CardHeader className="align-items-center d-flex card-bg">
                                    <h4 className="card-title mb-0 flex-grow-1">Vehicle Country Group Mapping</h4>
                                    <div className="flex-shrink-0">
                                        <div className="form-check form-switch form-switch-right form-switch-md">
                                            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                {Role && Role?.createAccess && <Button className="btn btn-primary btn-custom-blue" type="button" color='light' onClick={() => handleAdd()}>+ Add</Button>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-sm-end">
                                        <div className="search-box ms-2">
                                            <input type="text" className="form-control search" placeholder="Search..." onChange={onSearchChange} />
                                            <i className="ri-search-line search-icon"></i>
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardBody className='card-padding'>
                                    <div className="live-preview">
                                        <div className="table-responsive table-card">
                                            <table className="table align-middle table-nowrap table-striped mb-0">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th scope="col">S.No </th>
                                                        <th scope="col">Vehicle Model <i className=" bx bx-sort me-3" style={{ color: "#182b45" }} onClick={() => onSortChange('vehicleModelName')}></i></th>
                                                        <th scope="col">Vehicle Group <i className=" bx bx-sort me-3" style={{ color: "#182b45" }} onClick={() => onSortChange('vehicleGroupName')}></i></th>
                                                        <th scope="col">Country Group <i className=" bx bx-sort me-3" style={{ color: "#182b45" }} onClick={() => onSortChange('countryGroupName')}></i></th>
                                                        {(Role && Role?.editAccess || Role && Role?.deleteAccess) && <th scope="col" style={{ width: 50 }}>Action</th>}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {getTargetMatrixList?.dataRows && getTargetMatrixList?.dataRows?.length > 0 ? (
                                                        getTargetMatrixList?.dataRows?.map((matrix: any, index: number) => (
                                                            <tr key={matrix?.id}>
                                                                <td>{(currentPage - 1) * recordsPerPage + index + 1}</td>
                                                                <td>{matrix?.vehicleModelValue}</td>
                                                                <td>{matrix?.vehicleGroupValue}</td>
                                                                <td>{matrix?.countryGroupValue}</td>
                                                                {(Role && Role?.editAccess || Role && Role?.deleteAccess) &&
                                                                    <td>
                                                                        <div className="hstack gap-2">
                                                                            {Role && Role?.editAccess &&
                                                                                <button className="btn btn-sm btn-soft-info edit-list" onClick={() => handleEdit(matrix)}>
                                                                                    <i className="ri-pencil-fill align-bottom" />
                                                                                </button>}
                                                                            {Role && Role?.deleteAccess &&
                                                                                <button className="btn btn-sm btn-soft-danger remove-list" onClick={() => onClickTodoDelete(matrix?.id)}>
                                                                                    <i className="ri-delete-bin-5-fill align-bottom" />
                                                                                </button>}
                                                                        </div>
                                                                    </td>
                                                                }
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td className='no-data-foun' colSpan={10} >No data found</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="d-none code-view">
                                        <pre className="language-markup" style={{ "height": "275px" }}>
                                            <code>
                                            </code>
                                        </pre>
                                    </div>
                                    {totalCount > 10 &&
                                        <Row className="g-4 mt-3">
                                            <Col className="col-sm">
                                                <div className="d-flex justify-content-sm-end">
                                                    <Pagination count={totalPages} page={currentPage} onChange={onClickPagination} shape="rounded" />
                                                </div>
                                            </Col>
                                        </Row>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state: any) => ({
    vehicleCountryGroupMapping: state.MasterReducer.vehicleCountryGroupMapping,
    getCountryGroupDropdown: state.MasterReducer.getCountryGroupDropdown,
    getVehicleGroupDropdown: state.MasterReducer.getVehicleGroupDropdown,
    VehicleModelByVehicleGroupDropdown: state.MasterReducer.VehicleModelByVehicleGroupDropdown,
    insertOrUpdateVehicleCountryGroupMapping: state.MasterReducer.insertOrUpdateVehicleCountryGroupMapping,
    deleteVehicleCountryGroupMapping: state.MasterReducer.deleteVehicleCountryGroupMapping,
    getLoginData: state.AuthenticationReducer?.getLoginData?.data?.userRoleAccessList,
    loading: state.MasterReducer.loading
})

const mapDispatchToProps = (dispatch: any) => ({
    getVehicleCountryGroupMappingAction: (data: any) => {
        dispatch(getVehicleCountryGroupMappingAction(data))
    },
    getCountryGroupDropdownAction: (data: any) => {
        dispatch(getCountryGroupDropdownAction(data))
    },
    getAllVehicleGroupDropdownAction: (data: any) => {
        dispatch(getAllVehicleGroupDropdownAction(data))
    },
    getVehicleModelByVehicleGroupDropdownAction: (data: any) => {
        dispatch(getVehicleModelByVehicleGroupDropdownAction(data))
    },
    insertOrUpdateVehicleCountryGroupMappingAction: (data: any) => {
        dispatch(insertOrUpdateVehicleCountryGroupMappingAction(data))
    },
    deleteVehicleCountryGroupMappingAction: (data: any) => {
        dispatch(deleteVehicleCountryGroupMappingAction(data))
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(VehicleCountryGroupMapping);
