import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import { Card, CardBody, Col, Container, Row, CardHeader, Label, Button } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import BreadCrumb from "../../Components/Common/BreadCrumb";

const videos = [
    { id: 1, title: "How to use Code Builder", url: "https://evt-api-uat.izserver8.in//FAQ/Code_Builder.mp4", thumbnail: "https://evt-api-uat.izserver8.in//FAQ/codebuilder.png" },
    { id: 2, title: "How to use codelist search", url: "https://evt-api-uat.izserver8.in//FAQ/CodeListSearch.mp4", thumbnail: "https://evt-api-uat.izserver8.in//FAQ/codelistsearch.png" },
    { id: 3, title: "The process entails updating the version as part of the workflow", url: "https://evt-api-uat.izserver8.in//FAQ/CodeListWorkFlow.mp4", thumbnail: "https://evt-api-uat.izserver8.in//FAQ/workflow.png" },
    { id: 4, title: "How to use version comparsion", url: "https://evt-api-uat.izserver8.in//FAQ/versioncomparsion.mp4", thumbnail: "https://evt-api-uat.izserver8.in//FAQ/versioncomparsiont.png" },
    { id: 5, title: "How to use Country comparsion", url: "https://evt-api-uat.izserver8.in//FAQ/CountryComparsion.mp4", thumbnail: "https://evt-api-uat.izserver8.in//FAQ/countrycomparsion.png" },
    { id: 6, title: "How to use code configurator", url: "https://evt-api-uat.izserver8.in//FAQ/Code_Configurator.mp4", thumbnail: "https://evt-api-uat.izserver8.in//FAQ/CodeConfiguration.jpg" },
];

const Faq = () => {
    document.title = "Faq's";
    const navigate = useNavigate();
    const [selectedVideo, setSelectedVideo] = useState(videos[0].url);
    const [selectedLable, setSelectedLable] = useState(videos[0].title);

    const handleVideoSelect = (url) => {
        setSelectedVideo(url?.url);
        setSelectedLable(url?.title)
    };

    const handleFaqQuestions = () => {
        navigate('/faq');
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Videos" pageTitle="FAQ" />
                    <Row>
                        <Col lg={4} style={{ maxHeight: '80vh', overflowY: 'auto' }}>
                            {videos.map(video => (
                                <Card key={video.id} className="card-animate mb-3">
                                    <div>
                                        <Label className="form-label text-muted ms-3">{video.title}</Label>
                                        <div>
                                            <Button color="link" onClick={() => handleVideoSelect(video)}>
                                                <img src={video.thumbnail} alt={video.title} className="img-fluid" style={{ width: '100%', cursor: 'pointer' }} />
                                            </Button>
                                        </div>
                                    </div>
                                </Card>
                            ))}
                        </Col>

                        <Col lg={8}>
                            {selectedVideo ? (
                                <Card>
                                    <CardHeader className="align-items-center d-flex card-bg">
                                        <h4 className="card-title mb-0 flex-grow-1">
                                            Video Player
                                        </h4>
                                        <div className="flex-shrink-0">
                                            <div className="form-check form-switch form-switch-right form-switch-md">
                                                <div className="d-grid gap-2 d-md-flex justify-content-md-end" onClick={handleFaqQuestions}>
                                                    <p><Link to="#" className="link-success link-offset-2 text-decoration-underline link-underline-opacity-25 link-underline-opacity-100-hover">Back to questions</Link></p>
                                                </div>
                                            </div>
                                        </div>
                                    </CardHeader>
                                    <CardBody>
                                        <ReactPlayer
                                            url={selectedVideo}
                                            playing={true}
                                            width="100%"
                                            height="400px"
                                            controls
                                        />
                                        <h5 className="form-label text-muted mt-3">{selectedLable}</h5>
                                    </CardBody>
                                </Card>
                            ) : (
                                <Card>
                                    <CardBody className="text-center">
                                        <p>Select a video to play</p>
                                    </CardBody>
                                </Card>
                            )}
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Faq;
