import React, { useEffect, useState } from "react";
import {
  Button,
  CardBody,
  Col,
  Container,
  Row,
  CardHeader,
  Card,
} from "reactstrap";
import { Link } from "react-router-dom";
import Dropzone from "react-dropzone";
import { connect } from "react-redux";
import Loader from "../Loader/Loader";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { mergeExcelAction } from "../../store/actions";

const FileUploader = (props) => {
  document.title = "File Uploader";
  const { mergeExcel, loading } = props;
  const errors: any = {};
  const [formValidation, setFormValiodation] = useState<any>({});
  const [selectedFiles1, setSelectedFiles1] = useState([]);
  const [selectedFiles2, setSelectedFiles2] = useState([]);
  const [fileNames1, setFileNames1] = useState([]);
  const [fileNames2, setFileNames2] = useState([]);

  const handleAcceptedFiles1 = (acceptedFiles) => {
    setSelectedFiles1(acceptedFiles);
    const names = acceptedFiles.map((file) => file);
    setFileNames1(names);
  };

  const handleAcceptedFiles2 = (acceptedFiles) => {
    setSelectedFiles2(acceptedFiles);
    const names = acceptedFiles.map((file) => file);
    setFileNames2(names);
  };

  const handleMapFun = () => {
    if (!(fileNames1?.length > 0)) {
      errors.file1 = "Please upload webgui project";
    }
    if (!(fileNames2?.length > 0)) {
      errors.file2 = "Please upload webgui";
    }
    setFormValiodation(errors);
    let formData = new FormData();
    formData.append("File1", fileNames1[0]);
    formData.append("File2", fileNames2[0]);
    if (fileNames1?.length > 0 && fileNames2?.length > 0) {
      props.mergeExcelAction(formData);
    }
  };

  useEffect(() => {
    if (mergeExcel.httpStatusCode === 200) {
      setSelectedFiles1([]);
      setSelectedFiles2([]);
      setFileNames1([]);
      setFileNames2([]);
    }
  });

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="File Uploader" pageTitle="Code Builder" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0">File Uploader</h4>
                </CardHeader>
                <CardBody>
                  <div>
                    <div>
                      <Row>
                        <Col lg={6} md={6}>
                          <Card>
                            <CardHeader className="card-header">
                              <h4 className="card-title mb-0">Webgui Project</h4>
                            </CardHeader>
                            <CardBody>
                              <Dropzone
                                onDrop={(acceptedFiles) =>
                                  handleAcceptedFiles1(acceptedFiles)
                                }
                                multiple={true}
                                accept="image/*"
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <div
                                    className="dropzone dz-clickable"
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />
                                    <div className="dz-message needsclick">
                                      <div className="mb-3">
                                        <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                                      </div>
                                      <h4>
                                        Drop files here or click to upload.
                                      </h4>
                                    </div>
                                  </div>
                                )}
                              </Dropzone>

                              <div
                                className="list-unstyled mb-0"
                                id="file-previews"
                              >
                                {selectedFiles1.map((f, i) => (
                                  <Card
                                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                    key={i + "-file"}
                                  >
                                    <div className="p-2">
                                      <Row className="align-items-center">
                                        <Col>
                                          <Link
                                            to="#"
                                            className="text-muted font-weight-bold"
                                            onClick={() => {
                                              const link =
                                                document.createElement("a");
                                              link.href = f.preview;
                                              link.download = f.name;
                                              link.click();
                                            }}
                                          >
                                            {f.name}
                                          </Link>
                                          <p className="mb-0">
                                            <strong>{f.formattedSize}</strong>
                                          </p>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Card>
                                ))}
                              </div>
                              <span className='text-validation'>{formValidation.file1}</span>
                              {/* <div>
                                <h5>Uploaded File Names:</h5>
                                <ul>
                                  {fileNames1.map((name, index) => (
                                    <li key={index}>{name}</li>
                                  ))}
                                </ul>
                              </div> */}
                            </CardBody>
                          </Card>
                        </Col>
                        <Col lg={6} md={6}>
                          <Card>
                            <CardHeader className="card-header">
                              <h4 className="card-title mb-0">Webgui</h4>
                            </CardHeader>
                            <CardBody>
                              <Dropzone
                                onDrop={(acceptedFiles) =>
                                  handleAcceptedFiles2(acceptedFiles)
                                }
                                multiple={true}
                                accept="image/*"
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <div
                                    className="dropzone dz-clickable"
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />
                                    <div className="dz-message needsclick">
                                      <div className="mb-3">
                                        <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                                      </div>
                                      <h4>
                                        Drop files here or click to upload.
                                      </h4>
                                    </div>
                                  </div>
                                )}
                              </Dropzone>
                              <div
                                className="list-unstyled mb-0"
                                id="file-previews"
                              >
                                {selectedFiles2.map((f, i) => (
                                  <Card
                                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                    key={i + "-file"}
                                  >
                                    <div className="p-2">
                                      <Row className="align-items-center">
                                        <Col>
                                          <Link
                                            to="#"
                                            className="text-muted font-weight-bold"
                                            onClick={() => {
                                              const link =
                                                document.createElement("a");
                                              link.href = f.preview;
                                              link.download = f.name;
                                              link.click();
                                            }}
                                          >
                                            {f.name}
                                          </Link>
                                          <p className="mb-0">
                                            <strong>{f.formattedSize}</strong>
                                          </p>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Card>
                                ))}
                              </div>
                              <span className='text-validation'>{formValidation.file2}</span>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </div>
                    <div>
                      <Row>
                        <div>
                          <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                            <Button
                              className="btn btn-primary"
                              type="button"
                              color="success"
                              onClick={handleMapFun}
                            >
                              Upload
                            </Button>
                          </div>
                        </div>
                      </Row>
                    </div>
                    <div></div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  loading: state.CodeBuilderReducer.loading,
  mergeExcel: state.CodeBuilderReducer.mergeExcel,
});

const mapDispatchToProps = (dispatch) => ({
  mergeExcelAction: (data) => {
    dispatch(mergeExcelAction(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FileUploader);
