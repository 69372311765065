import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CardFooter, Modal, ModalBody, ModalHeader } from "reactstrap";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import * as XLSX from "xlsx-js-style";
import { saveAs } from "file-saver";
import { Card, CardBody, Col, Container, Row, CardHeader, Label, Button } from "reactstrap";
import { Accordion, AccordionDetails, AccordionSummary, TablePagination, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import Loader from "../Loader/Loader";
import { getDropdownCodeListAction, getViewCodeListTableAction, getSubmitCodeListAction, codeListExcelExportAction, codeListPdfExportAction } from "../../store/actions";
import "../CodeConfiguration/style.css";

const CodeList = (props) => {
  document.title = "Code List";
  const { dropdownCodeList, viewTableData, loading, submitResponse, codeListExcelExport } = props;
  const navigate = useNavigate();
  const [selectedVehicleGroup, setSelectedVehicleGroup] = useState<any>(null);
  const [selectedVehicleCategory, setSelectedVehicleCategory] = useState([]);
  const [selectedTonnage, setSelectedTonnage] = useState([]);
  const [selectedVehicleModel, setSelectedVehicleModel] = useState([]);
  const [selectedMarketCluster, setSelectedMarketCluster] = useState([]);
  const [selectedCodeGroup, setSelectedCodeGroup] = useState([]);
  const [selectedCode, setSelectedCode] = useState([]);
  const [showTableList, setShowTableList] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<any>([]);
  const [selectedVehicles, setSelectedVehicles] = useState<any>([]);
  const errors: { [key: string]: string } = {};
  const [modal_center, setmodal_center] = useState<boolean>(false);
  const [selectedCodes, setSelectedCodes] = useState<number[]>([]);
  const [view, setView] = useState<boolean>(false);
  const [gridDataList, setGridDataList] = useState<any>({});
  const [gotoCreatorRequest, setGotoCreatorRequest] = useState<boolean>(false);
  const [formValidation, setFormValiodation] = useState<{
    [key: string]: string;
  }>({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [expanded, setExpanded] = React.useState("");
  const LoginRoleData = localStorage.getItem("loginRoleAccess");
  const RoleResponse = JSON.parse(LoginRoleData);
  const userRole = JSON.parse(localStorage.getItem("RolesRes"));
  const Role = RoleResponse?.find((data) => data?.name === "CodeList")?.subModules?.find((val) => val?.submenuName === "CreatorRequest")?.access;

  const [formState, setFormState] = useState({
    VehicleGroupId: 0,
    VehicleCategory: [],
    Tonnage: [],
    VehicleModel: [],
    MarketCluster: [],
    CodeGroup: [],
    Code: [],
  });

  const [dropDownOptions, setDropDownOptions] = useState<any>({
    vehicleGroup: [],
    vehicleCategory: [],
    tonnage: [],
    vehicalModel: [],
    marketCluster: [],
    codeGroup: [],
    code: [],
  });

  const [dataList, setDataList] = useState<any>({version: "", codeListElement: [] });
  const [fillCol1, setfillCol1] = useState(true);

  const t_fillCol1 = () => {
    setfillCol1(!fillCol1);
  };

  const paginatedData =
    gridDataList &&
    gridDataList.length > 0 &&
    gridDataList?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  useEffect(() => {
    props.getDropdownCodeListAction(formState);
  }, [formState]);

  useEffect(() => {
    if (dropdownCodeList && Object.keys(dropdownCodeList).length !== 0) {
      LoadDropDownList();
    }
  }, [dropdownCodeList]);

  const LoadDropDownList = () => {
    const vehicleGroupOptions = dropdownCodeList.vehicleGroup.map((option) => ({
      value: option.value,
      label: option.value,
      id: option.id,
    }));
    const vehicleCategoryOptions = dropdownCodeList.vehicleCategory.map(
      (option) => ({
        value: option.value,
        label: option.value,
        id: option.id,
      })
    );
    const tonnageOptions = dropdownCodeList.tonnage.map((option) => ({
      value: option.value,
      label: option.value,
      id: option.id,
    }));
    const vehicalModelOptions = dropdownCodeList.vehicalModel.map((option) => ({
      value: option.value,
      label: option.value,
      id: option.id,
    }));
    const marketClusterOptions = dropdownCodeList.marketCluster.map(
      (option) => ({
        value: option.value,
        label: option.value,
        id: option.id,
      })
    );
    const codeGroupOptions = dropdownCodeList.codeGroup.map((option) => ({
      value: option.value,
      label: option.value,
      id: option.id,
    }));
    const codeOptions = dropdownCodeList.code.map((option) => ({
      value: option.value,
      label: option.value,
      id: option.id,
    }));

    setDropDownOptions({
      vehicleGroup: vehicleGroupOptions,
      vehicleCategory: vehicleCategoryOptions,
      tonnage: tonnageOptions,
      vehicalModel: vehicalModelOptions,
      marketCluster: marketClusterOptions,
      codeGroup: codeGroupOptions,
      code: codeOptions,
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleDropdownChange = (selectedOption: any, field: string) => {
    const updatedFormState = { ...formState };

    switch (field) {
      case "VehicleGroupId":
        updatedFormState.VehicleGroupId = selectedOption
          ? selectedOption.id
          : 0;
        updatedFormState.VehicleCategory = [];
        updatedFormState.Tonnage = [];
        updatedFormState.VehicleModel = [];
        updatedFormState.CodeGroup = [];
        updatedFormState.Code = [];
        setSelectedVehicleGroup(selectedOption);
        setSelectedVehicleCategory([]);
        setSelectedTonnage([]);
        setSelectedVehicleModel([]);
        setSelectedCodeGroup([]);
        setSelectedCode([]);
        break;
      case "VehicleCategory":
        updatedFormState.VehicleCategory = selectedOption
          ? selectedOption.map((opt) => opt.id)
          : [];
        updatedFormState.Tonnage = [];
        updatedFormState.VehicleModel = [];
        setSelectedVehicleCategory(selectedOption);
        setSelectedTonnage([]);
        setSelectedVehicleModel([]);
        break;
      case "Tonnage":
        updatedFormState.Tonnage = selectedOption
          ? selectedOption.map((opt) => opt.id)
          : [];
        updatedFormState.VehicleModel = [];
        setSelectedTonnage(selectedOption);
        setSelectedVehicleModel([]);
        break;
      case "VehicleModel":
        updatedFormState.VehicleModel = selectedOption
          ? selectedOption.map((opt) => opt.id)
          : [];
        setSelectedVehicleModel(selectedOption);
        break;
      case "MarketCluster":
        updatedFormState.MarketCluster = selectedOption
          ? selectedOption.map((opt) => opt.id)
          : [];
        setSelectedMarketCluster(selectedOption);
        break;
      case "CodeGroup":
        updatedFormState.CodeGroup = selectedOption
          ? selectedOption.map((opt) => opt.id)
          : [];
        updatedFormState.Code = [];
        setSelectedCodeGroup(selectedOption);
        setSelectedCode([]);
        break;
      case "Code":
        updatedFormState.Code = selectedOption
          ? selectedOption.map((opt) => opt.id)
          : [];
        setSelectedCode(selectedOption);
        break;
      default:
        break;
    }
    setFormState(updatedFormState);
  };

  const handleViewCodeList = () => {
    if (formState.VehicleGroupId === 0) {
      errors.VehicleGroupId = "Please select Vehicle Group";
    }
    if (formState.VehicleCategory.length === 0) {
      errors.VehicleCategory = "Please select Vehicle Category";
    }
    setFormValiodation(errors);
    setSelectedVehicles([]);
    if (Object.keys(errors).length === 0) {
      props.getViewCodeListTableAction(formState);
      setShowTableList(true);
    }
  };

  useEffect(() => {
    if (viewTableData && Object.keys(viewTableData).length !== 0) {
      setDataList(viewTableData);
      setGridDataList(viewTableData?.codeListElement);
    }
  }, [viewTableData]);

  function tog_center() {
    setmodal_center(!modal_center);
  }

  const handleClearDropdown = () => {
    setSelectedVehicleGroup(null);
    setSelectedVehicleCategory([]);
    setSelectedTonnage([]);
    setSelectedVehicleModel([]);
    setSelectedMarketCluster([]);
    setSelectedCodeGroup([]);
    setSelectedCode([]);
    setShowTableList(false);
    setFormState({
      ...formState,
      VehicleGroupId: 0,
      VehicleCategory: [],
      Tonnage: [],
      VehicleModel: [],
      MarketCluster: [],
      CodeGroup: [],
      Code: [],
    });
  };

  const staticHeader = [
    "Code Group",
    "Code",
    "Code Description",
    "Note",
    "Is Implemented",
    "Remarks",
  ];

  const countryGroup = dataList?.codeListElement?.[0]?.codeListVehicleMapping?.flatMap((option) =>
      option.codeListCountryGroupMapping?.map((data) => data.countryGroup)
    ) ?? [];

  const handleCheckbox = (vehicle, countryGroup, isChecked, option) => {
    if (isChecked) {
      setSelectedVehicles((prevState) => [
        ...prevState,
        {
          vehicle,
          countryGroup,
          selectedVehicleGroup: selectedVehicleGroup
            ? selectedVehicleGroup.value
            : null,
          selectedVersion: dataList.version,
          vehicleCountryGroupMappingIdList: [option],
        },
      ]);
      setSelectedOptions((prevState) => {
        const newOption = [...prevState, option];
        return newOption;
      });
    } else {
      setSelectedVehicles(
        selectedVehicles.filter(
          (item) =>
            !(
              item.vehicle === vehicle &&
              item.countryGroup === countryGroup &&
              item.selectedVehicleGroup ===
                (selectedVehicleGroup ? selectedVehicleGroup.value : null) &&
              item.selectedVersion === dataList.version &&
              (item.vehicleCountryGroupMappingIdList
                ? item.vehicleCountryGroupMappingIdList.includes(option)
                : false)
            )
        )
      );
      setSelectedOptions((prevState) =>
        prevState.filter((id) => id !== option)
      );
    }
  };

  const handleSubmit = () => {
    props.getSubmitCodeListAction(selectedOptions);
    setGotoCreatorRequest(true);
    setmodal_center(false);
  };

  useEffect(() => {
    if (codeListExcelExport?.httpStatusCode === 200) {
      window.location.href = codeListExcelExport?.data;
    }
  }, [codeListExcelExport]);

  const hangleViewChange = () => {
    setView(!view);
    setSelectedVehicles([]);
  };

  useEffect(() => {
    if (submitResponse.httpStatusCode === 200 && gotoCreatorRequest) {
      setGotoCreatorRequest(false);
      navigate("/creator-request");
    }
  }, [submitResponse]);

  const handleCheckboxChange = (code: any) => {
    setSelectedCodes((prevSelectedCodes) => {
      if (prevSelectedCodes.includes(code)) {
        return prevSelectedCodes.filter((item) => item !== code);
      } else {
        return [...prevSelectedCodes, code];
      }
    });
  };

  const handleExcel = () => {
    props.codeListExcelExportAction({
      Code: selectedCodes,
      CodeGroup: formState?.CodeGroup,
      MarketCluster: formState?.MarketCluster,
      Tonnage: formState?.Tonnage,
      VehicleCategory: formState?.VehicleCategory,
      VehicleGroupId: formState?.VehicleGroupId,
      VehicleModel: formState?.VehicleModel,
    });
  };

  const downloadExcel = () => {
    const table: any = document.getElementById("table data");
    const worksheet: any = XLSX.utils.table_to_sheet(table);
    const headerRange = XLSX.utils.decode_range(worksheet["!ref"]);
    const data: any = [];
    for (let row of table.rows) {
      const rowData: any = [];
      for (let cell of row.cells) {
        rowData.push(cell.innerText.trim() === "" ? "" : cell.innerText.trim());
      }
      data.push(rowData);
    }
    const maxColumns = headerRange.e.c;
    const maxRows = headerRange.e.r;
    for (let R = 0; R < 3; R++) {
      for (let C = 0; C <= maxColumns; C++) {
        const cellAddress = XLSX.utils.encode_cell({ r: R, c: C });
        if (!worksheet[cellAddress]) worksheet[cellAddress] = { v: " " }; 
        worksheet[cellAddress].s = {
          fill: {
            fgColor: { rgb: "333333" },
          },
          font: {
            color: { rgb: "FFFFFF" },
            bold: true,
          },
          alignment: {
            horizontal: "center",
            vertical: "center",
          },
          border: {
            top: { style: "thin", color: { rgb: "FFFFFF" } },
            bottom: { style: "thin", color: { rgb: "FFFFFF" } },
            left: { style: "thin", color: { rgb: "FFFFFF" } },
            right: { style: "thin", color: { rgb: "FFFFFF" } },
          },
        };
      }
    }
    for (let R = 4; R <= maxRows; R++) {
      for (let C = 6; C <= maxColumns; C++) {
        const cellAddress = XLSX.utils.encode_cell({ r: R, c: C });
        if (!worksheet[cellAddress].v) worksheet[cellAddress] = { v: " " };
        if (
          worksheet[cellAddress].v &&
          worksheet[cellAddress].v.trim() !== ""
        ) {
          worksheet[cellAddress].s = {
            fill: {
              fgColor: { rgb: "D3D3D3" },
            },
            alignment: {
              horizontal: "center",
              vertical: "center",
            },
            border: {
              top: { style: "thin", color: { rgb: "FFFFFF" } },
              bottom: { style: "thin", color: { rgb: "FFFFFF" } },
              left: { style: "thin", color: { rgb: "FFFFFF" } },
              right: { style: "thin", color: { rgb: "FFFFFF" } },
            },
          };
        } else {
          worksheet[cellAddress].s = {
            fill: {
              fgColor: { rgb: "FFC0CB" },
            },
            alignment: {
              horizontal: "center",
              vertical: "center",
            },
            border: {
              top: { style: "thin", color: { rgb: "FFFFFF" } },
              bottom: { style: "thin", color: { rgb: "FFFFFF" } },
              left: { style: "thin", color: { rgb: "FFFFFF" } },
              right: { style: "thin", color: { rgb: "FFFFFF" } },
            },
          };
        }
      }
    }

    for (let R = 2; R <= headerRange.e.r; ++R) {
      for (let C = headerRange.s.c; C <= headerRange.e.c; ++C) {
        const cellAddress = XLSX.utils.encode_cell({ r: R, c: C });
        if (!worksheet[cellAddress]) continue;

        worksheet[cellAddress].s = {
          ...worksheet[cellAddress].s,
          alignment: {
            vertical: "center",
            horizontal: "center",
          },
        };
      }
    }

    for (let R = 3; R <= headerRange.e.r; ++R) {
      for (let C = 0; C <= 5; ++C) {
        const cellAddress = XLSX.utils.encode_cell({ r: R, c: C });
        if (!worksheet[cellAddress]) continue;

        worksheet[cellAddress].s = {
          alignment: {
            vertical: "center",
            horizontal: "center",
            wrapText: true,
          },
        };
      }
    }
    const columnWidths: any = [];
    for (let C = headerRange.s.c; C <= headerRange.e.c; ++C) {
      let maxWidth = 40;
      for (let R = headerRange.s.r; R <= headerRange.e.r; ++R) {
        const cellAddress = XLSX.utils.encode_cell({ r: R, c: C });
        const cell = worksheet[cellAddress];
      }
      columnWidths.push({ wch: maxWidth });
    }
    const now = new Date();
    const dateTimeString = `Generated on: ${now.toLocaleDateString()} ${now.toLocaleTimeString()}`;
    const lastRowIndex = maxRows + 3;
    const dateTimeCellAddress = XLSX.utils.encode_cell({
      r: lastRowIndex,
      c: 0,
    });
    worksheet[dateTimeCellAddress] = { v: dateTimeString, t: "s" };
    worksheet[dateTimeCellAddress].s = {
      font: {
        color: { rgb: "000000" },
        bold: true,
      },
      alignment: {
        horizontal: "left",
        vertical: "center",
      },
    };

    worksheet["!ref"] = XLSX.utils.encode_range({
      s: { r: 0, c: 0 },
      e: { r: lastRowIndex, c: maxColumns },
    });

    worksheet["!cols"] = columnWidths; 
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      workbook,
      worksheet,
      `sheet1-${now.getMonth() + 1}-${now.getDate()}-${now.getFullYear()}`
    );

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(
      blob,
      `Code_Lists_Excel_Report__${
        now.getMonth() + 1
      }-${now.getDate()}-${now.getFullYear()}_${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}.xlsx`
    );
  };

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Code List" pageTitle="Code List" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className="align-items-center d-flex card-bg">
                  <h4 className="card-title mb-0 flex-grow-1">List Search</h4>
                  <div className="flex-shrink-0">
                    <div className="form-check form-switch form-switch-right form-switch-md">
                      <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                        {showTableList && !loading && (
                          <p>
                            <Link
                              onClick={hangleViewChange}
                              to="#"
                              className="link-success link-offset-2 text-decoration-underline link-underline-opacity-25 link-underline-opacity-100-hover"
                            >
                              {view ? "Table View" : "Grid View"}
                            </Link>
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <div>
                    <div>
                      <Row>
                        <Col lg={4} md={6}>
                          <div className="mb-3">
                            <Label
                              htmlFor="choices-single-default"
                              className="form-label text-muted"
                            >
                              Vehicle Group{" "}
                              <span className="text-danger">*</span>
                            </Label>
                            <Select
                              value={selectedVehicleGroup}
                              isMulti={false}
                              onChange={(e) => {
                                handleDropdownChange(e, "VehicleGroupId");
                              }}
                              options={dropDownOptions.vehicleGroup}
                            />
                            <span className="text-validation">
                              {formValidation.VehicleGroupId}
                            </span>
                          </div>
                        </Col>
                        <Col lg={4} md={6}>
                          <div className="mb-3">
                            <Label
                              htmlFor="choices-single-groups"
                              className="form-label text-muted"
                            >
                              Vehicle Category{" "}
                              <span className="text-danger">*</span>
                            </Label>
                            <Select
                              value={selectedVehicleCategory}
                              isMulti={true}
                              closeMenuOnSelect={false}
                              styles={{
                                valueContainer: (base) => ({
                                  ...base,
                                  maxHeight: 50,
                                  overflowY: "auto",
                                }),
                              }}
                              onChange={(e) => {
                                handleDropdownChange(e, "VehicleCategory");
                              }}
                              options={dropDownOptions.vehicleCategory}
                            />
                            <span className="text-validation">
                              {formValidation.VehicleCategory}
                            </span>
                          </div>
                        </Col>
                        <Col lg={4} md={6}>
                          <div className="mb-3">
                            <Label
                              htmlFor="choices-single-no-search"
                              className="form-label text-muted"
                            >
                              Tonnage
                            </Label>
                            <Select
                              isClearable={true}
                              isMulti={true}
                              closeMenuOnSelect={false}
                              styles={{
                                valueContainer: (base) => ({
                                  ...base,
                                  maxHeight: 50,
                                  overflowY: "auto",
                                }),
                              }}
                              value={selectedTonnage}
                              onChange={(e) => {
                                handleDropdownChange(e, "Tonnage");
                              }}
                              options={dropDownOptions.tonnage}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div>
                      <Row>
                        <Col lg={4} md={6}>
                          <div className="mb-3">
                            <Label
                              htmlFor="choices-single-default"
                              className="form-label text-muted"
                            >
                              Vehicle Model
                            </Label>
                            <Select
                              value={selectedVehicleModel}
                              closeMenuOnSelect={false}
                              isMulti={true}
                              styles={{
                                valueContainer: (base) => ({
                                  ...base,
                                  maxHeight: 50,
                                  overflowY: "auto",
                                }),
                              }}
                              onChange={(e) => {
                                handleDropdownChange(e, "VehicleModel");
                              }}
                              options={dropDownOptions.vehicalModel}
                            />
                          </div>
                        </Col>
                        <Col lg={4} md={6}>
                          <div className="mb-3">
                            <Label
                              htmlFor="choices-single-groups"
                              className="form-label text-muted"
                            >
                              Market cluster
                            </Label>
                            <Select
                              value={selectedMarketCluster}
                              isMulti={true}
                              closeMenuOnSelect={false}
                              styles={{
                                valueContainer: (base) => ({
                                  ...base,
                                  maxHeight: 50,
                                  overflowY: "auto",
                                }),
                              }}
                              onChange={(e) => {
                                handleDropdownChange(e, "MarketCluster");
                              }}
                              options={dropDownOptions.marketCluster}
                            />
                          </div>
                        </Col>
                        <Col lg={4} md={6}>
                          <div className="mb-3">
                            <Label
                              htmlFor="choices-single-no-search"
                              className="form-label text-muted"
                            >
                              Code Group
                            </Label>
                            <Select
                              isClearable={true}
                              isMulti={true}
                              closeMenuOnSelect={false}
                              styles={{
                                valueContainer: (base) => ({
                                  ...base,
                                  maxHeight: 50,
                                  overflowY: "auto",
                                }),
                              }}
                              value={selectedCodeGroup}
                              onChange={(e) => {
                                handleDropdownChange(e, "CodeGroup");
                              }}
                              options={dropDownOptions.codeGroup}
                            />
                          </div>
                        </Col>
                        <Col lg={4} md={6}>
                          <div className="mb-3">
                            <Label
                              htmlFor="choices-single-no-sorting"
                              className="form-label text-muted"
                            >
                              Code
                            </Label>
                            <Select
                              value={selectedCode}
                              isMulti={true}
                              closeMenuOnSelect={false}
                              styles={{
                                valueContainer: (base) => ({
                                  ...base,
                                  maxHeight: 50,
                                  overflowY: "auto",
                                }),
                              }}
                              onChange={(e) => {
                                handleDropdownChange(e, "Code");
                              }}
                              options={dropDownOptions.code}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </CardBody>
                <CardFooter>
                  <div className="d-flex gap-3 justify-content-md-end ">
                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                      <Button
                        className="btn btn-primary"
                        type="button"
                        color="light"
                        onClick={() => handleClearDropdown()}
                      >
                        Clear
                      </Button>
                    </div>
                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                      <Button
                        className="btn btn-primary"
                        type="button"
                        color="success"
                        onClick={() => handleViewCodeList()}
                      >
                        Search Code List
                      </Button>
                    </div>
                  </div>
                </CardFooter>
              </Card>
            </Col>
          </Row>
          {showTableList && !loading && (
            <>
              {!view && (
                <Row>
                  <Col xl={12}>
                    {dataList && dataList.codeListElement === null ? (
                      <Card>
                        <CardHeader className="align-items-center d-flex card-bg">
                          <h4 className="card-title mb-0 flex-grow-1">
                            View Code Lists
                          </h4>
                          <div className="flex-shrink-0">
                            <div className="form-check form-switch form-switch-right form-switch-md">
                              <div className="input-group gap-3 justify-content-md-end ">
                                <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                  <Button
                                    className="btn btn-primary btn-custom-blue"
                                    type="button"
                                    color="light"
                                  >
                                    Export Excel
                                  </Button>
                                </div>
                                <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                  <Button
                                    className="btn btn-primary btn-custom-blue"
                                    type="button"
                                    color="light"
                                  >
                                    Export PDF
                                  </Button>
                                </div>
                                <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                  <Button
                                    className="btn btn-primary btn-custom-blue"
                                    type="button"
                                    color="light"
                                    style={{
                                      display:
                                        selectedVehicles.length > 0
                                          ? ""
                                          : "none",
                                    }}
                                    onClick={() => tog_center()}
                                  >
                                    Submit
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </CardHeader>
                        <CardBody>
                          <table className="table align-middle table-bordered  table-striped-columns table-nowrap border-dark table-striped mb-0">
                            <thead>
                              <tr>
                                <th className="border-dark">Code Group</th>
                                <th className="border-dark">Code</th>
                                <th className="border-dark">
                                  Code Description
                                </th>
                                <th className="border-dark">Notes</th>
                                <th className="border-dark">Is Implemented</th>
                                <th className="border-dark">Remarks</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td colSpan={6} className="text-center">
                                  No Data Found
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </CardBody>
                      </Card>
                    ) : (
                      <Card>
                        <CardHeader className="align-items-center d-flex card-bg">
                          <h4 className="card-title mb-0 flex-grow-1">
                            View Code Lists
                          </h4>
                          <div className="flex-shrink-0">
                            <div className="form-check form-switch form-switch-right form-switch-md">
                              <div className="input-group gap-3 justify-content-md-end ">
                                <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                  <button
                                    type="button"
                                    className="btn btn-info"
                                    onClick={() => downloadExcel()}
                                  >
                                    <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                                    Export Excel
                                  </button>
                                </div>
                                <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                  <button
                                    type="button"
                                    className="btn btn-info"
                                    onClick={() => {
                                      props.codeListPdfExportAction(formState);
                                    }}
                                  >
                                    <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                                    Export PDF
                                  </button>
                                </div>
                                {Role &&
                                  Role?.createAccess &&
                                  userRole?.includes("Designer") && (
                                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                      <Button
                                        className="btn btn-primary btn-custom-blue"
                                        type="button"
                                        color="light"
                                        style={{
                                          display:
                                            selectedVehicles.length > 0
                                              ? ""
                                              : "none",
                                        }}
                                        onClick={() => tog_center()}
                                      >
                                        Submit
                                      </Button>
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                        </CardHeader>
                        <CardBody className="card-padding">
                          <div className="live-preview">
                            <div
                              className="table-responsive table-card"
                              style={{ maxHeight: "700px" }}
                            >
                              <table
                                id="table data"
                                className="table align-middle table-bordered  table-striped-columns table-nowrap border-dark table-striped mb-0"
                              >
                                <thead
                                  className="table-light"
                                  style={{
                                    position: "sticky",
                                    top: "0",
                                    zIndex: "1",
                                  }}
                                >
                                  <tr>
                                    {staticHeader.map((data, index) => (
                                      <td
                                        key={index}
                                        rowSpan={4}
                                        className="center"
                                      >
                                        {data}
                                      </td>
                                    ))}
                                    {dataList?.codeListElement[0]?.codeListVehicleMapping?.map(
                                      (option, index) => (
                                        <td
                                          key={index}
                                          colSpan={
                                            option?.codeListCountryGroupMapping
                                              ?.length
                                          }
                                          className="center"
                                        >
                                          {option.vehicleName}
                                        </td>
                                      )
                                    )}
                                  </tr>
                                  <tr>
                                    {countryGroup?.map((data, index) =>
                                      data instanceof Array ? (
                                        data.map((option, index) => (
                                          <td key={index} className="center">
                                            {option}
                                          </td>
                                        ))
                                      ) : (
                                        <td key={index} className="center">
                                          {data}
                                        </td>
                                      )
                                    )}
                                  </tr>
                                  <tr>
                                    {countryGroup?.map((data, index) => (
                                      <td key={index} className="center">
                                        {dataList.version}
                                      </td>
                                    ))}
                                  </tr>
                                  <tr>
                                    {dataList?.codeListElement[0]?.codeListVehicleMapping?.map(
                                      (vehicle, vehicleIndex) =>
                                        vehicle.codeListCountryGroupMapping.map(
                                          (countryGroup, countryIndex) => (
                                            <td
                                              key={countryIndex}
                                              className="center"
                                            >
                                              <input
                                                type="checkbox"
                                                onChange={(e) =>
                                                  handleCheckbox(
                                                    vehicle.vehicleName,
                                                    countryGroup.countryGroup,
                                                    e.target.checked,
                                                    countryGroup.vehicleCountryGroupId
                                                  )
                                                }
                                              />
                                            </td>
                                          )
                                        )
                                    )}
                                  </tr>
                                </thead>
                                <tbody>
                                  {dataList?.codeListElement?.map(
                                    (data, index) => (
                                      <tr key={index}>
                                        <td
                                          className="center"
                                          style={{ whiteSpace: "normal" }}
                                        >
                                          {data.codeGroup}
                                        </td>
                                        <td
                                          className="center"
                                          style={{ whiteSpace: "normal" }}
                                        >
                                          {data.code}
                                        </td>
                                        <td
                                          className="center"
                                          style={{ whiteSpace: "normal" }}
                                        >
                                          {data.codeDescription}
                                        </td>
                                        <td
                                          className="center"
                                          style={{ whiteSpace: "normal" }}
                                        >
                                          {data.notes}
                                        </td>
                                        <td className="center">
                                          {data.isImplemented}
                                        </td>
                                        <td
                                          className="center"
                                          style={{ whiteSpace: "normal" }}
                                        >
                                          {data.remarks}
                                        </td>
                                        {data?.codeListVehicleMapping?.map(
                                          (option, index) =>
                                            option.codeListCountryGroupMapping.map(
                                              (output, indx) => (
                                                <td
                                                  key={indx}
                                                  className="center"
                                                >
                                                  {output.optionValue}
                                                </td>
                                              )
                                            )
                                        )}
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className="d-none code-view">
                            <pre
                              className="language-markup"
                              style={{ height: "275px" }}
                            >
                            </pre>
                          </div>
                        </CardBody>
                      </Card>
                    )}
                  </Col>
                </Row>
              )}
              {view && (
                <Row>
                  {dataList && dataList.codeListElement === null ? (
                    <>
                      <div className="text-center">No Data Found</div>
                    </>
                  ) : (
                    <>
                      <Col lg={12}>
                        <Card>
                          <CardHeader className="align-items-center d-flex card-bg p-2">
                            <Col className="col-sm">
                              <div className="d-flex justify-content-sm-start">
                                <TablePagination
                                  component="div"
                                  count={gridDataList.length}
                                  page={page}
                                  onPageChange={handleChangePage}
                                  rowsPerPage={rowsPerPage}
                                  onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                              </div>
                            </Col>
                            <div className="d-flex justify-content-sm-end">
                              <Row>
                                <Col className="d-flex gap-1">
                                  <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                    <button
                                      type="button"
                                      className="btn btn-info"
                                      onClick={() => {
                                        props.codeListPdfExportAction(
                                          formState
                                        );
                                      }}
                                    >
                                      <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                                      Export PDF
                                    </button>
                                  </div>
                                  {selectedCodes.length > 0 && (
                                    <div>
                                      <button
                                        type="button"
                                        className="btn btn-info"
                                        onClick={handleExcel}
                                      >
                                        <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                                        Export Excel ({selectedCodes?.length})
                                      </button>
                                    </div>
                                  )}
                                  <div>
                                    <Button
                                      className="btn btn-primary button120"
                                      type="button"
                                      color="success"
                                      style={{
                                        display:
                                          selectedVehicles.length > 0
                                            ? ""
                                            : "none",
                                      }}
                                      onClick={() => tog_center()}
                                    >
                                      Submit
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </CardHeader>
                        </Card>
                      </Col>
                      <>
                        {paginatedData
                          ? paginatedData.map((items: any, index: number) => (
                              <Col xl={6} id="card-none1" key={index}>
                                <Card className="card-animate">
                                  <CardHeader>
                                    <div className="d-flex align-items-center justify-content-between">
                                      <div className="flex-grow-1">
                                        <h6 className="card-title mb-0">
                                          {items.codeGroup}
                                        </h6>
                                      </div>
                                      <div className="d-flex align-items-center">
                                        <Label
                                          htmlFor="choices-single-no-search"
                                          className="form-label text-muted mb-0"
                                        >
                                          Export
                                        </Label>
                                        <div className="form-check ms-2">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="checkAll"
                                            value={items.codeId}
                                            checked={selectedCodes.includes(
                                              items.codeId
                                            )}
                                            onChange={() =>
                                              handleCheckboxChange(items.codeId)
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="flex-shrink-0">
                                        <h6 className="mb-0 text-primary">
                                          {items.code}
                                        </h6>
                                      </div>
                                    </div>
                                  </CardHeader>
                                  <div
                                    className="card-body"
                                    id="collapseexample1"
                                  >
                                    <Row>
                                      <Col xl={6}>
                                        <label
                                          htmlFor="cleave-date-format"
                                          className="text-body"
                                        >
                                          Code Description
                                        </label>
                                        <p className="text-muted">
                                          {items.codeDescription
                                            ? items.codeDescription
                                            : "-"}
                                        </p>
                                      </Col>
                                      <Col xl={6}>
                                        <label
                                          htmlFor="cleave-date-format"
                                          className="text-body"
                                        >
                                          Note
                                        </label>
                                        <p className="text-muted">
                                          {items.notes ? items.notes : "-"}
                                        </p>
                                      </Col>
                                      <Col xl={6}>
                                        <label
                                          htmlFor="cleave-date-format"
                                          className="text-body"
                                        >
                                          Is Implemented
                                        </label>
                                        <p className="text-muted">
                                          {items.isImplemented
                                            ? items.isImplemented
                                            : "-"}
                                        </p>
                                      </Col>
                                      <Col xl={6}>
                                        <label
                                          htmlFor="cleave-date-format"
                                          className="text-body"
                                        >
                                          Remarks
                                        </label>
                                        <p className="text-muted">
                                          {items.remarks ? items.remarks : "-"}
                                        </p>
                                      </Col>
                                      <Col xl={12}>
                                        <Accordion
                                          square
                                          expanded={
                                            expanded === `panel${index}`
                                          }
                                          onChange={handleChange(
                                            `panel${index}`
                                          )}
                                        >
                                          <AccordionSummary
                                            aria-controls="panel1d-content"
                                            id="panel1d-header"
                                            expandIcon={<ExpandMoreIcon />}
                                            style={{
                                              backgroundColor:
                                                expanded === `panel${index}`
                                                  ? "lightgreen"
                                                  : "transparent",
                                            }}
                                          >
                                            <Typography>
                                              Collapsible Group Item #{" "}
                                              {index + 1}
                                            </Typography>
                                          </AccordionSummary>
                                          <AccordionDetails>
                                            <div className="live-preview">
                                              <div
                                                className="table-responsive table-card"
                                                style={{ maxHeight: "700px" }}
                                              >
                                                <table
                                                  id="table data"
                                                  className="table align-middle table-bordered  table-striped-columns table-nowrap border-dark table-striped mb-0"
                                                >
                                                  <thead
                                                    className="table-light"
                                                    style={{
                                                      position: "sticky",
                                                      top: "0",
                                                      zIndex: "1",
                                                    }}
                                                  >
                                                    <tr>
                                                      {items?.codeListVehicleMapping?.map(
                                                        (option, index) => (
                                                          <td
                                                            key={index}
                                                            colSpan={
                                                              option
                                                                ?.codeListCountryGroupMapping
                                                                ?.length
                                                            }
                                                            className="center"
                                                          >
                                                            {option.vehicleName}
                                                          </td>
                                                        )
                                                      )}
                                                    </tr>
                                                    <tr>
                                                      {countryGroup?.map(
                                                        (data, index) =>
                                                          data instanceof
                                                          Array ? (
                                                            data.map(
                                                              (
                                                                option,
                                                                index
                                                              ) => (
                                                                <td
                                                                  key={index}
                                                                  className="center"
                                                                >
                                                                  {option}
                                                                </td>
                                                              )
                                                            )
                                                          ) : (
                                                            <td
                                                              key={index}
                                                              className="center"
                                                            >
                                                              {data}
                                                            </td>
                                                          )
                                                      )}
                                                    </tr>
                                                    <tr>
                                                      {countryGroup?.map(
                                                        (data, index) => (
                                                          <td
                                                            key={index}
                                                            className="center"
                                                          >
                                                            {dataList.version}
                                                          </td>
                                                        )
                                                      )}
                                                    </tr>
                                                    <tr>
                                                      {items.codeListVehicleMapping?.map(
                                                        (
                                                          vehicle,
                                                          vehicleIndex
                                                        ) =>
                                                          vehicle.codeListCountryGroupMapping.map(
                                                            (
                                                              countryGroup,
                                                              countryIndex
                                                            ) => (
                                                              <td
                                                                key={
                                                                  countryIndex
                                                                }
                                                                className="center"
                                                              >
                                                                <input
                                                                  type="checkbox"
                                                                  onChange={(
                                                                    e
                                                                  ) =>
                                                                    handleCheckbox(
                                                                      vehicle.vehicleName,
                                                                      countryGroup.countryGroup,
                                                                      e.target
                                                                        .checked,
                                                                      countryGroup.vehicleCountryGroupId
                                                                    )
                                                                  }
                                                                />
                                                              </td>
                                                            )
                                                          )
                                                      )}
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    <tr>
                                                      {items.codeListVehicleMapping?.map(
                                                        (option, index) => {
                                                          return option.codeListCountryGroupMapping.map(
                                                            (output, indx) => (
                                                              <td
                                                                key={indx}
                                                                className="center"
                                                              >
                                                                {
                                                                  output.optionValue
                                                                }
                                                              </td>
                                                            )
                                                          );
                                                        }
                                                      )}
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          </AccordionDetails>
                                        </Accordion>
                                      </Col>
                                    </Row>
                                  </div>
                                </Card>
                              </Col>
                            ))
                          : "null"}
                      </>
                      <Card>
                        <CardHeader className="p-0">
                          <TablePagination
                            component="div"
                            count={gridDataList.length}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        </CardHeader>
                      </Card>
                    </>
                  )}
                </Row>
              )}
            </>
          )}
        </Container>
      </div>
      <Modal
        isOpen={modal_center}
        toggle={() => {
          tog_center();
        }}
        centered
      >
        <ModalHeader className="modal-title" />
        <ModalBody className="text-center p-5">
          <div className="">
            <h4 className="mb-3">
              Are you sure you want to draft the following request?
            </h4>
            <div
              style={{ maxHeight: "200px", overflowY: "scroll" }}
              className="list-group list-group-flush"
            >
              {selectedVehicles.map((item, index) => (
                <li className="list-group-item" key={index}>
                  <b>Vehicle:</b> {item.vehicle} <br />
                  <b>Country Group:</b> {item.countryGroup} <br />
                  <b>Vehicle Group:</b> {item.selectedVehicleGroup} <br />
                  <b>Version:</b> {item.selectedVersion}
                </li>
              ))}
            </div>
            <div className="hstack gap-5 mt-5 justify-content-center">
              <Button color="light" onClick={() => setmodal_center(false)}>
                Close
              </Button>
              <Link to="#" className="btn btn-success" onClick={handleSubmit}>
                Submit
              </Link>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  dropdownCodeList: state.CodeListReducer.dropdownCodeList,
  viewTableData: state.CodeListReducer.viewTableData,
  loading: state.CodeListReducer.loading,
  submitResponse: state.CodeListReducer.submitResponse,
  codeListExcelExport: state.CodeListReducer.codeListExcelExport,
});

const mapDispatchToProps = (dispatch) => ({
  getDropdownCodeListAction: (data) => {
    dispatch(getDropdownCodeListAction(data));
  },
  getViewCodeListTableAction: (data) => {
    dispatch(getViewCodeListTableAction(data));
  },
  getSubmitCodeListAction: (data) => {
    dispatch(getSubmitCodeListAction(data));
  },
  codeListExcelExportAction: (data) => {
    dispatch(codeListExcelExportAction(data));
  },
  codeListPdfExportAction: (data) => {
    dispatch(codeListPdfExportAction(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CodeList);
