import { combineReducers } from "redux";
import Layout from "./layouts/reducer";
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";
import DashboardCRM from "./dashboardCRM/reducer";
import CodeBuilderReducer from "./codeBuilder/reducer";
import CodeConfigurationModelReducer from "./codeConfiguration/reducer";
import MasterReducer from "./masters/reducer";
import CodeListReducer from "./codeLists/reducer";
import AuthenticationReducer from "./authentication/reducer";
import UserManagementReducer from "./userManagement/reducer";

const rootReducer = combineReducers({
    Layout,
    Login,
    Account,
    ForgetPassword,
    Profile,
    DashboardCRM,
    CodeBuilderReducer,
    CodeConfigurationModelReducer,
    MasterReducer,
    CodeListReducer,
    AuthenticationReducer,
    UserManagementReducer
});

export default rootReducer;
