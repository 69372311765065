export const MastersActionTypes = {
  INIT_GET_MASTERS_DETAILS_MODULE: "INIT_GET_MASTERS_DETAILS_MODULE",

  POST_COUNTRYGROUP_INSERT_OR_UPDATE_REQUEST: " POST_COUNTRYGROUP_INSERT_OR_UPDATE_REQUEST",
  POST_COUNTRYGROUP_INSERT_OR_UPDATE_SUCCESS: " POST_COUNTRYGROUP_INSERT_OR_UPDATE_SUCCESS",
  POST_COUNTRYGROUP_INSERT_OR_UPDATE_FAILED: " POST_COUNTRYGROUP_INSERT_OR_UPDATE_FAILED",

  GET_COUNTRYGROUP_BY_ID_REQUEST: "GET_COUNTRYGROUP_BY_ID_REQUEST",
  GET_COUNTRYGROUP_BY_ID_SUCCESS: "GET_COUNTRYGROUP_BY_ID_SUCCESS",
  GET_COUNTRYGROUP_BY_ID_FAILED: "GET_COUNTRYGROUP_BY_ID_FAILED",

  GET_ALL_COUNTRYGROUP_REQUEST: "GET_ALL_COUNTRYGROUP_REQUEST",
  GET_ALL_COUNTRYGROUP_SUCCESS: "GET_ALL_COUNTRYGROUP_SUCCESS",
  GET_ALL_COUNTRYGROUP_FAILED: "GET_ALL_COUNTRYGROUP_FAILED",

  GET_DELETE_COUNTRYGROUP_REQUEST: "GET_DELETE_COUNTRYGROUP_REQUEST",
  GET_DELETE_COUNTRYGROUP_SUCCESS: "GET_DELETE_COUNTRYGROUP_SUCCESS",
  GET_DELETE_COUNTRYGROUP_FAILED: "GET_DELETE_COUNTRYGROUP_FAILED",

  GET_ALL_COUNTRY_REQUEST: "GET_ALL_COUNTRY_REQUEST",
  GET_ALL_COUNTRY_SUCCESS: "GET_ALL_COUNTRY_SUCCESS",
  GET_ALL_COUNTRY_FAILED: "GET_ALL_COUNTRY_FAILED",

  INSERT_OR_UPDATE_COUNTRY_REQUEST: "INSERT_OR_UPDATE_COUNTRY_REQUEST",
  INSERT_OR_UPDATE_COUNTRY_SUCCESS: "INSERT_OR_UPDATE_COUNTRY_SUCCESS",
  INSERT_OR_UPDATE_COUNTRY_FAILED: "INSERT_OR_UPDATE_COUNTRY_FAILED",

  DELETE_COUNTRY_REQUEST: "DELETE_COUNTRY_REQUEST",
  DELETE_COUNTRY_SUCCESS: "DELETE_COUNTRY_SUCCESS",
  DELETE_COUNTRY_FAILED: "DELETE_COUNTRY_FAILED",

  GET_COUNTRYGROUP_DROPDOWN_REQUEST: "GET_COUNTRYGROUP_DROPDOWN_REQUEST",
  GET_COUNTRYGROUP_DROPDOWN_SUCCESS: "GET_COUNTRYGROUP_DROPDOWN_SUCCESS",
  GET_COUNTRYGROUP_DROPDOWN_FAILED: "GET_COUNTRYGROUP_DROPDOWN_FAILED",

  GET_ALL_VEHICLETYPE_REQUEST: "GET_ALL_VEHICLETYPE_REQUEST",
  GET_ALL_VEHICLETYPE_SUCCESS: "GET_ALL_VEHICLETYPE_SUCCESS",
  GET_ALL_VEHICLETYPE_FAILED: "GET_ALL_VEHICLETYPE_FAILED",

  INSERT_OR_UPDATE_VEHICLETYPE_REQUEST: "INSERT_OR_UPDATE_VEHICLETYPE_REQUEST",
  INSERT_OR_UPDATE_VEHICLETYPE_SUCCESS: "INSERT_OR_UPDATE_VEHICLETYPE_SUCCESS",
  INSERT_OR_UPDATE_VEHICLETYPE_FAILED: "INSERT_OR_UPDATE_VEHICLETYPE_FAILED",

  DELETE_VEHICLE_TYPE_REQUEST: "DELETE_VEHICLE_TYPE_REQUEST",
  DELETE_VEHICLE_TYPE_SUCCESS: "DELETE_VEHICLE_TYPE_SUCCESS",
  DELETE_VEHICLE_TYPE_FAILED: "DELETE_COUNTRY_FAILED",

  GET_ALL_VEHICLEGROUP_REQUEST: "GET_ALL_VEHICLEGROUP_REQUEST",
  GET_ALL_VEHICLEGROUP_SUCCESS: "GET_ALL_VEHICLEGROUP_SUCCESS",
  GET_ALL_VEHICLEGROUP_FAILED: "GET_ALL_VEHICLEGROUP_FAILED",

  GET_All_VEHICLEGROUP_DROPDOWN_REQUEST: "GET_All_VEHICLEGROUP_DROPDOWN_REQUEST",
  GET_All_VEHICLEGROUP_DROPDOWN_SUCCESS: "GET_All_VEHICLEGROUP_DROPDOWN_SUCCESS",
  GET_All_VEHICLEGROUP_DROPDOWN_FAILED: "GET_All_VEHICLEGROUP_DROPDOWN_FAILED",

  INSERT_OR_UPDATE_VEHICLEGROUP_REQUEST: "INSERT_OR_UPDATE_VEHICLEGROUP_REQUEST",
  INSERT_OR_UPDATE_VEHICLEGROUP_SUCCESS: "INSERT_OR_UPDATE_VEHICLEGROUP_SUCCESS",
  INSERT_OR_UPDATE_VEHICLEGROUP_FAILED: "INSERT_OR_UPDATE_VEHICLEGROUP_FAILED",

  DELETE_VEHICLE_GROUP_REQUEST: "DELETE_VEHICLE_GROUP_REQUEST",
  DELETE_VEHICLE_GROUP_SUCCESS: "DELETE_VEHICLE_GROUP_SUCCESS",
  DELETE_VEHICLE_GROUP_FAILED: "DELETE_VEHICLE_GROUP_FAILED",

  GET_ALL_CODEGROUP_REQUEST: "GET_ALL_CODEGROUP_REQUEST",
  GET_ALL_CODEGROUP_SUCCESS: "GET_ALL_CODEGROUP_SUCCESS",
  GET_ALL_CODEGROUP_FAILED: "GET_ALL_CODEGROUP_FAILED",

  INSERT_OR_UPDATE_CODE_GROUP_REQUEST: "INSERT_OR_UPDATE_CODE_GROUP_REQUEST",
  INSERT_OR_UPDATE_CODE_GROUP_SUCCESS: "INSERT_OR_UPDATE_CODE_GROUP_SUCCESS",
  INSERT_OR_UPDATE_CODE_GROUP_FAILED: "INSERT_OR_UPDATE_CODE_GROUP_FAILED",

  DELETE_CODE_GROUP_REQUEST: "DELETE_CODE_GROUP_REQUEST",
  DELETE_CODE_GROUP_SUCCESS: "DELETE_CODE_GROUP_SUCCESS",
  DELETE_CODE_GROUP_FAILED: "DELETE_CODE_GROUP_FAILED",

  GET_ALL_VEHICLE_CATEGORT_REQUEST: "GET_ALL_VEHICLE_CATEGORT_REQUEST",
  GET_ALL_VEHICLE_CATEGORT_SUCCESS: "GET_ALL_VEHICLE_CATEGORT_SUCCESS",
  GET_ALL_VEHICLE_CATEGORT_FAILED: "GET_ALL_VEHICLE_CATEGORT_FAILED",

  INSERT_OR_UPDATE_VEHICLE_CATEGORY_REQUEST: "INSERT_OR_UPDATE_VEHICLE_CATEGORY_REQUEST",
  INSERT_OR_UPDATE_VEHICLE_CATEGORY_SUCCESS: "INSERT_OR_UPDATE_VEHICLE_CATEGORY_SUCCESS",
  INSERT_OR_UPDATE_VEHICLE_CATEGORY_FAILED: "INSERT_OR_UPDATE_VEHICLE_CATEGORY_FAILED",

  DELETE_VEHICLE_CATEGORY_REQUEST: "DELETE_VEHICLE_CATEGORY_REQUEST",
  DELETE_VEHICLE_CATEGORY_SUCCESS: "DELETE_VEHICLE_CATEGORY_SUCCESS",
  DELETE_VEHICLE_CATEGORY_FAILED: "DELETE_VEHICLE_CATEGORY_FAILED",

  GET_ALL_OPTIONS_REQUEST: "GET_ALL_OPTIONS_REQUEST",
  GET_ALL_OPTIONS_SUCCESS: "GET_ALL_OPTIONS_SUCCESS",
  GET_ALL_OPTIONS_FAILED: "GET_ALL_OPTIONS_FAILED",

  INSERT_OR_UPDATE_OPTIONS_REQUEST: "INSERT_OR_UPDATE_OPTIONS_REQUEST",
  INSERT_OR_UPDATE_OPTIONS_SUCCESS: "INSERT_OR_UPDATE_OPTIONS_SUCCESS",
  INSERT_OR_UPDATE_OPTIONS_FAILED: "INSERT_OR_UPDATE_OPTIONS_FAILED",

  DELETE_OPTIONS_REQUEST: "DELETE_OPTIONS_REQUEST",
  DELETE_OPTIONS_SUCCESS: "DELETE_OPTIONS_SUCCESS",
  DELETE_OPTIONS_FAILED: "DELETE_OPTIONS_FAILED",

  GET_ALL_VEHICLE_MODEL_REQUEST: "GET_ALL_VEHICLE_MODEL_REQUEST",
  GET_ALL_VEHICLE_MODEL_SUCCESS: "GET_ALL_VEHICLE_MODEL_SUCCESS",
  GET_ALL_VEHICLE_MODEL_FAILED: "GET_ALL_VEHICLE_MODEL_FAILED",

  GET_TONNAGE_BY_VEHICLE_CATEGORY_DROPDOWN_REQUEST: " GET_TONNAGE_BY_VEHICLE_CATEGORY_DROPDOWN_REQUEST",
  GET_TONNAGE_BY_VEHICLE_CATEGORY_DROPDOWN_SUCCESS: " GET_TONNAGE_BY_VEHICLE_CATEGORY_DROPDOWN_SUCCESS",
  GET_TONNAGE_BY_VEHICLE_CATEGORY_DROPDOWN_FAILED: " GET_TONNAGE_BY_VEHICLE_CATEGORY_DROPDOWN_FAILED",

  GET_All_CATEGORIES_BY_VEHICLEGROUP_DROPDOWN_REQUEST: "GET_All_CATEGORIES_BY_VEHICLEGROUP_DROPDOWN_REQUEST",
  GET_All_CATEGORIES_BY_VEHICLEGROUP_DROPDOWN_SUCCESS: "GET_All_CATEGORIES_BY_VEHICLEGROUP_DROPDOWN_SUCCESS",
  GET_All_CATEGORIES_BY_VEHICLEGROUP_DROPDOWN_FAILED: "GET_All_CATEGORIES_BY_VEHICLEGROUP_DROPDOWN_FAILED",

  GET_All_VEHICLE_GROUP_BY_CATEGORY_DROPDOWN_REQUEST: "GET_All_VEHICLE_GROUP_BY_CATEGORY_DROPDOWN_REQUEST",
  GET_All_VEHICLE_GROUP_BY_CATEGORY_DROPDOWN_SUCCESS: "GET_All_VEHICLE_GROUP_BY_CATEGORY_DROPDOWN_SUCCESS",
  GET_All_VEHICLE_GROUP_BY_CATEGORY_DROPDOWN_FAILED: "GET_All_VEHICLE_GROUP_BY_CATEGORY_DROPDOWN_FAILED",

  INSERT_OR_UPDATE_VEHICLE_MODEL_REQUEST: "INSERT_OR_UPDATE_VEHICLE_MODEL_REQUEST",
  INSERT_OR_UPDATE_VEHICLE_MODEL_SUCCESS: "INSERT_OR_UPDATE_VEHICLE_MODEL_SUCCESS",
  INSERT_OR_UPDATE_VEHICLE_MODEL_FAILED: "INSERT_OR_UPDATE_VEHICLE_MODEL_FAILED",

  DELETE_VEHICLE_MODEL_REQUEST: "DELETE_VEHICLE_MODEL_REQUEST",
  DELETE_VEHICLE_MODEL_SUCCESS: "DELETE_VEHICLE_MODEL_SUCCESS",
  DELETE_VEHICLE_MODEL_FAILED: "DELETE_VEHICLE_MODEL_FAILED",

  GET_ALL_TARGET_MATRIX_REQUEST: "GET_ALL_TARGET_MATRIX_REQUEST",
  GET_ALL_TARGET_MATRIX_SUCCESS: "GET_ALL_TARGET_MATRIX_SUCCESS",
  GET_ALL_TARGET_MATRIX_FAILED: "GET_ALL_TARGET_MATRIX_FAILED",

  GET_All_VEHICLE_MODEL_DROPDOWN_REQUEST: "GET_All_VEHICLE_MODEL_DROPDOWN_REQUEST",
  GET_All_VEHICLE_MODEL_DROPDOWN_SUCCESS: "GET_All_VEHICLE_MODEL_DROPDOWN_SUCCESS",
  GET_All_VEHICLE_MODEL_DROPDOWN_FAILED: "GET_All_VEHICLE_MODEL_DROPDOWN_FAILED",

  GET_VEHICLE_MODEL_BY_VEHICLE_GROUP_REQUEST: "GET_VEHICLE_MODEL_BY_VEHICLE_GROUP_REQUEST",
  GET_VEHICLE_MODEL_BY_VEHICLE_GROUP_SUCCESS: "GET_VEHICLE_MODEL_BY_VEHICLE_GROUP_SUCCESS",
  GET_VEHICLE_MODEL_BY_VEHICLE_GROUP_FAILED: "GET_VEHICLE_MODEL_BY_VEHICLE_GROUP_FAILED",

  GET_ALL_TONNAGE_REQUEST: "GET_ALL_TONNAGE_REQUEST",
  GET_ALL_TONNAGE_SUCCESS: "GET_ALL_TONNAGE_SUCCESS",
  GET_ALL_TONNAGE_FAILED: "GET_ALL_TONNAGE_FAILED",

  GET_ALL_VEHICLE_CATEGORY_DROPDOWN_REQUEST: "GET_ALL_VEHICLE_CATEGORY_DROPDOWN_REQUEST",
  GET_ALL_VEHICLE_CATEGORY_DROPDOWN_SUCCESS: "GET_ALL_VEHICLE_CATEGORY_DROPDOWN_SUCCESS",
  GET_ALL_VEHICLE_CATEGORY_DROPDOWN_FAILED: "GET_ALL_VEHICLE_CATEGORY_DROPDOWN_FAILED",

  GET_VEHICLE_GROUP_BY_VEHICLE_CATEGORY_REQUEST: "GET_VEHICLE_GROUP_BY_VEHICLE_CATEGORY_REQUEST",
  GET_VEHICLE_GROUP_BY_VEHICLE_CATEGORY_SUCCESS: "GET_VEHICLE_GROUP_BY_VEHICLE_CATEGORY_SUCCESS",
  GET_VEHICLE_GROUP_BY_VEHICLE_CATEGORY_FAILED: "GET_VEHICLE_GROUP_BY_VEHICLE_CATEGORY_FAILED",

  INSERT_OR_UPDATE_TONNAGE_REQUEST: "INSERT_OR_UPDATE_TONNAGE_REQUEST",
  INSERT_OR_UPDATE_TONNAGE_SUCCESS: "INSERT_OR_UPDATE_TONNAGE_SUCCESS",
  INSERT_OR_UPDATE_TONNAGE_FAILED: "INSERT_OR_UPDATE_TONNAGE_FAILED",

  INSERT_OR_UPDATE_TARGET_MATRIX_REQUEST: "INSERT_OR_UPDATE_TARGET_MATRIX_REQUEST",
  INSERT_OR_UPDATE_TARGET_MATRIX_SUCCESS: "INSERT_OR_UPDATE_TARGET_MATRIX_SUCCESS",
  INSERT_OR_UPDATE_TARGET_MATRIX_FAILED: "INSERT_OR_UPDATE_TARGET_MATRIX_FAILED",

  DELETE_TARGET_MATRIX_REQUEST: "DELETE_TARGET_MATRIX_REQUEST",
  DELETE_TARGET_MATRIX_SUCCESS: "DELETE_TARGET_MATRIX_SUCCESS",
  DELETE_TARGET_MATRIX_FAILED: "DELETE_TARGET_MATRIX_FAILED",

  DELETE_TONNAGE_REQUEST: "DELETE_TONNAGE_REQUEST",
  DELETE_TONNAGE_SUCCESS: "DELETE_TONNAGE_SUCCESS",
  DELETE_TONNAGE_FAILED: "DELETE_TONNAGE_FAILED",

  GET_ALL_PACKAGE_MASTER_REQUEST: "GET_ALL_PACKAGE_MASTER_REQUEST",
  GET_ALL_PACKAGE_MASTER_SUCCESS: "GET_ALL_PACKAGE_MASTER_SUCCESS",
  GET_ALL_PACKAGE_MASTER_FAILED: "GET_ALL_PACKAGE_MASTER_FAILED",

  GET_ALL_PACKAGE_SUBITEM_REQUEST: "GET_ALL_PACKAGE_SUBITEM_REQUEST",
  GET_ALL_PACKAGE_SUBITEM_SUCCESS: "GET_ALL_PACKAGE_SUBITEM_SUCCESS",
  GET_ALL_PACKAGE_SUBITEM_FAILED: "GET_ALL_PACKAGE_SUBITEM_FAILED",

  GET_ALL_PACKAGE_MASTER_DROPDOWN_REQUEST: "GET_ALL_PACKAGE_MASTER_DROPDOWN_REQUEST",
  GET_ALL_PACKAGE_MASTER_DROPDOWN_SUCCESS: "GET_ALL_PACKAGE_MASTER_DROPDOWN_SUCCESS",
  GET_ALL_PACKAGE_MASTER_DROPDOWN_FAILED: "GET_ALL_PACKAGE_MASTER_DROPDOWN_FAILED",

  GET_VEHICLE_GROUP_BY_PACKAGE_REQUEST: "GET_VEHICLE_GROUP_BY_PACKAGE_REQUEST",
  GET_VEHICLE_GROUP_BY_PACKAGE_SUCCESS: "GET_VEHICLE_GROUP_BY_PACKAGE_SUCCESS",
  GET_VEHICLE_GROUP_BY_PACKAGE_FAILED: "GET_VEHICLE_GROUP_BY_PACKAGE_FAILED",

  INSERT_OR_UPDATE_PACKAGE_SUBITEM_REQUEST: "INSERT_OR_UPDATE_PACKAGE_SUBITEM_REQUEST",
  INSERT_OR_UPDATE_PACKAGE_SUBITEM_SUCCESS: "INSERT_OR_UPDATE_PACKAGE_SUBITEM_SUCCESS",
  INSERT_OR_UPDATE_PACKAGE_SUBITEM_FAILED: "INSERT_OR_UPDATE_PACKAGE_SUBITEM_FAILED",

  DELETE_PACTAGE_SUBITEM_REQUEST: "DELETE_PACTAGE_SUBITEM_REQUEST",
  DELETE_PACTAGE_SUBITEM_SUCCESS: "DELETE_PACTAGE_SUBITEM_SUCCESS",
  DELETE_PACTAGE_SUBITEM_FAILED: "DELETE_PACTAGE_SUBITEM_FAILED",

  GET_ALL_CODE_REQUEST: "GET_ALL_CODE_REQUEST",
  GET_ALL_CODE_SUCCESS: "GET_ALL_CODE_SUCCESS",
  GET_ALL_CODE_FAILED: "GET_ALL_CODE_FAILED",

  GET_All_CODE_GROUP_DROPDOWN_REQUEST: "GET_All_CODE_GROUP_DROPDOWN_REQUEST",
  GET_All_CODE_GROUP_DROPDOWN_SUCCESS: "GET_All_CODE_GROUP_DROPDOWN_SUCCESS",
  GET_All_CODE_GROUP_DROPDOWN_FAILED: "GET_All_CODE_GROUP_DROPDOWN_FAILED",

  INSERT_OR_UPDATE_CODE_REQUEST: "INSERT_OR_UPDATE_CODE_REQUEST",
  INSERT_OR_UPDATE_CODE_SUCCESS: "INSERT_OR_UPDATE_CODE_SUCCESS",
  INSERT_OR_UPDATE_CODE_FAILED: "INSERT_OR_UPDATE_CODE_FAILED",

  DELETE_CODE_REQUEST: "DELETE_CODE_REQUEST",
  DELETE_CODE_SUCCESS: "DELETE_CODE_SUCCESS",
  DELETE_CODE_FAILED: "DELETE_CODE_FAILED",

  GET_ALL_VEHICLE_COUNTRYGROUP_MAPPING_REQUEST: "GET_ALL_VEHICLE_COUNTRYGROUP_MAPPING_REQUEST",
  GET_ALL_VEHICLE_COUNTRYGROUP_MAPPING_SUCCESS: "GET_ALL_VEHICLE_COUNTRYGROUP_MAPPING_SUCCESS",
  GET_ALL_VEHICLE_COUNTRYGROUP_MAPPING_FAILED: "GET_ALL_VEHICLE_COUNTRYGROUP_MAPPING_FAILED",

  INSERT_OR_UPDATE_VEHICLE_COUNTRYGROUP_MAPPING_REQUEST: "INSERT_OR_UPDATE_VEHICLE_COUNTRYGROUP_MAPPING_REQUEST",
  INSERT_OR_UPDATE_VEHICLE_COUNTRYGROUP_MAPPING_SUCCESS: "INSERT_OR_UPDATE_VEHICLE_COUNTRYGROUP_MAPPING_SUCCESS",
  INSERT_OR_UPDATE_VEHICLE_COUNTRYGROUP_MAPPING_FAILED: "INSERT_OR_UPDATE_VEHICLE_COUNTRYGROUP_MAPPING_FAILED",

  DELETE_VEHICLE_COUNTRYGROUP_MAPPING_REQUEST: "DELETE_VEHICLE_COUNTRYGROUP_MAPPING_REQUEST",
  DELETE_VEHICLE_COUNTRYGROUP_MAPPING_SUCCESS: "DELETE_VEHICLE_COUNTRYGROUP_MAPPING_SUCCESS",
  DELETE_VEHICLE_COUNTRYGROUP_MAPPING_FAILED: "DELETE_VEHICLE_COUNTRYGROUP_MAPPING_FAILED",

  INSERT_OR_UPDATE_PACKAGE_MASTER_REQUEST: "INSERT_OR_UPDATE_PACKAGE_MASTER_REQUEST",
  INSERT_OR_UPDATE_PACKAGE_MASTER_SUCCESS: "INSERT_OR_UPDATE_PACKAGE_MASTER_SUCCESS",
  INSERT_OR_UPDATE_PACKAGE_MASTER_FAILED: "INSERT_OR_UPDATE_PACKAGE_MASTER_FAILED",

  DELETE_PACKAGE_MASTER_REQUEST: "DELETE_PACKAGE_MASTER_REQUEST",
  DELETE_PACKAGE_MASTER_SUCCESS: "DELETE_PACKAGE_MASTER_SUCCESS",
  DELETE_PACKAGE_MASTER_FAILED: "DELETE_PACKAGE_MASTER_FAILED",

  GET_ALL_VEHICLE_TYPE_DROPDOWN_REQUEST: "GET_ALL_VEHICLE_TYPE_DROPDOWN_REQUEST",
  GET_ALL_VEHICLE_TYPE_DROPDOWN_SUCCESS: "GET_ALL_VEHICLE_TYPE_DROPDOWN_SUCCESS",
  GET_ALL_VEHICLE_TYPE_DROPDOWN_FAILED: "GET_ALL_VEHICLE_TYPE_DROPDOWN_FAILED",

  GET_ALL_CODE_REQUEST_REQUEST: "GET_ALL_CODE_REQUEST_REQUEST",
  GET_ALL_CODE_REQUEST_SUCCESS: "GET_ALL_CODE_REQUEST_SUCCESS",
  GET_ALL_CODE_REQUEST_FAILED: "GET_ALL_CODE_REQUEST_FAILED",

  INSERT_OR_UPDATE_CODE_REQUEST_REQUEST: "INSERT_OR_UPDATE_CODE_REQUEST_REQUEST",
  INSERT_OR_UPDATE_CODE_REQUEST_SUCCESS: "INSERT_OR_UPDATE_CODE_REQUEST_SUCCESS",
  INSERT_OR_UPDATE_CODE_REQUEST_FAILED: "INSERT_OR_UPDATE_CODE_REQUEST_FAILED",

  REJECT_CODE_REQUEST_REQUEST: "REJECT_CODE_REQUEST_REQUEST",
  REJECT_CODE_REQUEST_SUCCESS: "REJECT_CODE_REQUEST_SUCCESS",
  REJECT_CODE_REQUEST_FAILED: "REJECT_CODE_REQUEST_FAILED",

  GET_CODE_GROUP_BY_VEHICLE_GROUP_REQUEST: "GET_CODE_GROUP_BY_VEHICLE_GROUP_REQUEST",
  GET_CODE_GROUP_BY_VEHICLE_GROUP_SUCCESS: "GET_CODE_GROUP_BY_VEHICLE_GROUP_SUCCESS",
  GET_CODE_GROUP_BY_VEHICLE_GROUP_FAILED: "GET_CODE_GROUP_BY_VEHICLE_GROUP_FAILED",

  GET_ALL_CODE_REQUEST_MAILID_REQUEST: "GET_ALL_CODE_REQUEST_MAILID_REQUEST",
  GET_ALL_CODE_REQUEST_MAILID_SUCCESS: "GET_ALL_CODE_REQUEST_MAILID_SUCCESS",
  GET_ALL_CODE_REQUEST_MAILID_FAILED: "GET_ALL_CODE_REQUEST_MAILID_FAILED",

};
