import { takeEvery, call, put } from "redux-saga/effects";
import { baseAPI } from "../../helpers/api_helper.js"
import { toast } from 'react-toastify';
import {
  getAllCountryGroupSuccess,
  getAllCountryGroupFailure,
  insertOrUpdateCountryGroupSuccess,
  insertOrUpdateCountryGroupFailure,
  deleteCountryGroupSuccess,
  deleteCountryGroupFailure,
  getCountryGroupByIdSuccess,
  getCountryGroupByIdFailure,
  getAllCountrySuccess,
  getAllCountryFailure,
  insertOrUpdateCountrySuccess,
  insertOrUpdateCountryFailure,
  deleteCountrySuccess,
  deleteCountryFailure,
  getCountryGroupDropdownSuccess,
  getCountryGroupDropdownFailure,
  getAllVehicleTypeSuccess,
  getAllVehicleTypeFailure,
  insertOrUpdateVehicleTypeSuccess,
  insertOrUpdateVehicleTypeFailure,
  deleteVehicleTypeSuccess,
  deleteVehicleTypeFailure,
  getAllVehicleGroupSuccess,
  getAllVehicleGroupFailure,
  getAllVehicleGroupDropdownSuccess,
  getAllVehicleGroupDropdownFailure,
  insertOrUpdateVehicleGroupSuccess,
  insertOrUpdateVehicleGroupFailure,
  deleteVehicleGroupSuccess,
  deleteVehicleGroupFailure,
  getAllCodeGroupSuccess,
  getAllCodeGroupFailure,
  insertOrUpdateCodeGroupSuccess,
  insertOrUpdateCodeGroupFailure,
  deleteCodeGroupSuccess,
  deleteCodeGroupFailure,
  getAllVehicleCategorySuccess,
  getAllVehicleCategoryFailure,
  insertOrUpdateVehicleCategorySuccess,
  insertOrUpdateVehicleCategoryFailure,
  deleteVehicleCategorySuccess,
  deleteVehicleCategoryFailure,
  getAllOptionsSuccess,
  getAllOptionsFailure,
  insertOrUpdateVehicleOptionsSuccess,
  insertOrUpdateVehicleOptionsFailure,
  deleteOptionsSuccess,
  getAllVehicleModelSuccess,
  getAllVehicleModelFailure,
  getTonnageByVehicleCategoryDropdownSuccess,
  getTonnageByVehicleCategoryDropdownFailure,
  getAllCategoriesByVehicleGroupDropdownSuccess,
  getAllCategoriesByVehicleGroupDropdownFailure,
  getAllVehicleGroupByCategoryDropdownSuccess,
  getAllVehicleGroupByCategoryDropdownFailure,
  insertOrUpdateVehicleModelSuccess,
  insertOrUpdateVehicleModelFailure,
  deleteVehicleModelSuccess,
  deleteVehicleModelFailure,
  getTargetMatrixSuccess,
  getTargetMatrixFailure,
  getAllVehicleModelDropdownSuccess,
  getAllVehicleModelDropdownFailure,
  getVehicleModelByVehicleGroupDropdownSuccess,
  getVehicleModelByVehicleGroupDropdownFailure,
  getAllTonnageSuccess,
  getAllTonnageFailure,
  getAllVehicleCategoryDropDownSuccess,
  getAllVehicleCategoryDropDownFailure,
  getVehicleGroupByVehicleCategorySuccess,
  getVehicleGroupByVehicleCategoryFailure,
  insertOrUpdateTonnageSuccess,
  insertOrUpdateTonnageFailure,
  insertOrUpdateTargrtMatrixSuccess,
  insertOrUpdateTargrtMatrixFailure,
  deleteTargetMatrixSuccess,
  deleteTargetMatrixFailure,
  deleteTonnageSuccess,
  deleteTonnageFailure,
  getAllPackageMasterSuccess,
  getAllPackageMasterFailure,
  getAllPackageSubitemSuccess,
  getAllPackageSubitemFailure,
  getAllPackageMasterDropdownSuccess,
  getAllPackageMasterDropdownFailure,
  getVehicleGroupByPackageSuccess,
  getVehicleGroupByPackageFailure,
  insertOrUpdatePackageSubitemSuccess,
  insertOrUpdatePackageSubitemFailure,
  deletePackageSubitemSuccess,
  deletePackageSubitemFailure,
  getAllCodeSuccess,
  getAllCodeFailure,
  getAllCodeGroupDropdownSuccess,
  getAllCodeGroupDropdownFailure,
  insertOrUpdateCodeSuccess,
  insertOrUpdateCodeFailure,
  deleteCodeSuccess,
  deleteCodeFailure,
  getVehicleCountryGroupMappingSuccess,
  getVehicleCountryGroupMappingFailure,
  insertOrUpdateVehicleCountryGroupMappingSuccess,
  insertOrUpdateVehicleCountryGroupMappingFailure,
  deleteVehicleCountryGroupMappingSuccess,
  deleteVehicleCountryGroupMappingFailure,
  insertOrUpdatePackageMasterSuccess,
  insertOrUpdatePackageMasterFailure,
  deletePackageMasterSuccess,
  deletePackageMasterFailure,
  getAllVehicleTypeDropdownSuccess,
  getAllVehicleTypeDropdownFailure,
  getAllCodeRequestSuccess,
  getAllCodeRequestFailure,
  insertOrUpdateCodeRequestSuccess,
  insertOrUpdateCodeRequestFailure,
  rejectCodeRequestSuccess,
  rejectCodeRequestFailure,
  getCodeGroupByVehicleGroupSuccess,
  getCodeGroupByVehicleGroupFailure,
  getAllCodeRequestMailIdSuccess,
  getAllCodeRequestMailIdFailure

} from "./action";
import { MastersActionTypes } from "./actionType";
import { ConfigAPI } from "../../helpers/url_helper.js";

function* workerGetAllCountryGroupDetails(action) {
  const { sort, page, search, OrderByColumnName } = action.data;
  try {
    const uri = `${ConfigAPI.GET_ALL_COUNTRYGROUP_URL}?PageNumber=${page}&SortDirection=${sort}&GridFilterKey=${search}&OrderByColumnName=${OrderByColumnName}`;
    const { data } = yield call(baseAPI.get, uri);
    if (data.httpStatusCode === 200) {
      yield put(
        getAllCountryGroupSuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.error(data.httpStatusMessage, { position: "bottom-right" });
      yield put(getAllCountryGroupFailure(data));
    }
  } catch (error) {
    yield put(getAllCountryGroupFailure(error));
  }
}

function* workerGetCountryGroupByIdDetails(action) {
  const { id } = action.data;
  try {
    const uri = `${ConfigAPI.GET_COUNTRYGROUP_BY_ID_URL}?countryGroupId=${id}`;
    const { data } = yield call(baseAPI.get, uri);
    if (data.httpStatusCode === 200) {
      toast.success(data.httpStatusMessage, { position: "bottom-right" });
      yield put(
        getCountryGroupByIdSuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.error(data.httpStatusMessage, { position: "bottom-right" });
      yield put(getCountryGroupByIdFailure(data));
    }
  } catch (error) {
    yield put(getCountryGroupByIdFailure(error));
  }
}

function* workerInsertOrUpdateCountryGroupDetails(action) {
  const request = action.data;
  try {
    const uri = ConfigAPI.INSERT_OR_UPDATE_COUNTRYGROUP_URL;
    const { data } = yield call(baseAPI.post, `${uri}`, request);
    if (data.httpStatusCode === 200) {
      toast.success(data.httpStatusMessage, { position: "bottom-right" });
      yield put(
        insertOrUpdateCountryGroupSuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.error(data.httpStatusMessage, { position: "bottom-right" });
      yield put(insertOrUpdateCountryGroupFailure(data));
    }
  } catch (error) {
    yield put(insertOrUpdateCountryGroupFailure(error));
  }
}

function* workerDeleteCountryGroupDetails(action) {
  const { id } = action.data;
  try {
    const uri = `${ConfigAPI.DELETE_COUNTRYGROUP_URL}?countryGroupId=${id}`;
    const { data } = yield call(baseAPI.get, uri);
    if (data.httpStatusCode === 200) {
      toast.success(data.httpStatusMessage, { position: "bottom-right" });
      yield put(
        deleteCountryGroupSuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.error(data.httpStatusMessage, { position: "bottom-right" });
      yield put(deleteCountryGroupFailure(data));
    }
  } catch (error) {
    yield put(deleteCountryGroupFailure(error));
  }
}

function* workerGetAllCountryDetails(action) {
  const { sort, page, search, OrderByColumnName } = action.data;
  try {
    const uri = `${ConfigAPI.GET_ALL_COUNTRY_URL}?GridFilterKey=${search}&PageNumber=${page}&OrderByColumnName=${OrderByColumnName}&SortDirection=${sort}`;
    const { data } = yield call(baseAPI.get, uri);
    if (data.httpStatusCode === 200) {
      yield put(
        getAllCountrySuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.error(data.httpStatusMessage, { position: "bottom-right" });
      yield put(getAllCountryFailure(data));
    }
  } catch (error) {
    yield put(getAllCountryFailure(error));
  }
}

function* workerInsertOrUpdateCountryDetails(action) {
  const request = action.data;
  try {
    const uri = ConfigAPI.INSERT_OR_UPDATE_COUNTRY_URL;
    const { data } = yield call(baseAPI.post, `${uri}`, request);
    if (data.httpStatusCode === 200) {
      toast.success(data.httpStatusMessage, { position: "bottom-right" });
      yield put(
        insertOrUpdateCountrySuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.error(data.httpStatusMessage, { position: "bottom-right" });
      yield put(insertOrUpdateCountryFailure(data));
    }
  } catch (error) {
    yield put(insertOrUpdateCountryFailure(error));
  }
}

function* workerDeleteCountryDetails(action) {
  const request = action.data;
  try {
    const uri = `${ConfigAPI.DELETE_COUNTRY_URL}?countryId=${request}`;
    const { data } = yield call(baseAPI.get, uri);
    if (data.httpStatusCode === 200) {
      toast.success(data.httpStatusMessage, { position: "bottom-right" });
      yield put(
        deleteCountrySuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.error(data.httpStatusMessage, { position: "bottom-right" });
      yield put(deleteCountryFailure(data));
    }
  } catch (error) {
    yield put(deleteCountryFailure(error));
  }
}

function* workerGetCountryGroupDropdownDetails(action) {
  try {
    const uri = `${ConfigAPI.GET_COUNTRY_GROUP_GROPDOWN_URL}`;
    const { data } = yield call(baseAPI.get, uri);

    if (data.httpStatusCode === 200) {
      yield put(
        getCountryGroupDropdownSuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.error(data.httpStatusMessage, { position: "bottom-right" });
      yield put(getCountryGroupDropdownFailure(data));
    }
  } catch (error) {
    yield put(getCountryGroupDropdownFailure(error));
  }
}

function* workerGetAllVehicleTypeDetails(action) {
  const { sort, page, search, OrderByColumnName } = action.data;
  try {
    const uri = `${ConfigAPI.GET_ALL_COUNTRY_TYPE_URL}?PageNumber=${page}&SortDirection=${sort}&GridFilterKey=${search}&OrderByColumnName=${OrderByColumnName}`;
    const { data } = yield call(baseAPI.get, uri);
    if (data.httpStatusCode === 200) {
      yield put(
        getAllVehicleTypeSuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.error(data.httpStatusMessage, { position: "bottom-right" });
      yield put(getAllVehicleTypeFailure(data));
    }
  } catch (error) {
    yield put(getAllVehicleTypeFailure(error));
  }
}

function* workerInsertOrUpdateVehicleTypeDetails(action) {
  const request = action.data;
  try {
    const uri = ConfigAPI.INSERT_OR_UPDATE_VEHICLE_TYPE_URL;
    const { data } = yield call(baseAPI.post, `${uri}`, request);
    if (data.httpStatusCode === 200) {
      toast.success(data.httpStatusMessage, { position: "bottom-right" });
      yield put(
        insertOrUpdateVehicleTypeSuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.error(data.httpStatusMessage, { position: "bottom-right" });
      yield put(insertOrUpdateVehicleTypeFailure(data));
    }
  } catch (error) {
    yield put(insertOrUpdateVehicleTypeFailure(error));
  }
}

function* workerVehicleTypeDetails(action) {
  const request = action.data;
  try {
    const uri = `${ConfigAPI.DELETE_VEHICLE_TYPE_URL}?vehicleTypeId=${request.id}`;
    const { data } = yield call(baseAPI.get, uri);
    if (data.httpStatusCode === 200) {
      toast.success(data.httpStatusMessage, { position: "bottom-right" });
      yield put(
        deleteVehicleTypeSuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.error(data.httpStatusMessage, { position: "bottom-right" });
      yield put(deleteVehicleTypeFailure(data));
    }
  } catch (error) {
    yield put(deleteVehicleTypeFailure(error));
  }
}

function* workerGetAllVehicleGroupDetails(action) {
  const { sort, page, search, OrderByColumnName } = action.data;
  try {
    const uri = `${ConfigAPI.GET_ALL_VEHICLE_GROUP_URL}?OrderByColumnName=${OrderByColumnName}&PageNumber=${page}&SortDirection=${sort}&GridFilterKey=${search}`;
    const { data } = yield call(baseAPI.get, uri);
    if (data.httpStatusCode === 200) {
      yield put(
        getAllVehicleGroupSuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.error(data.httpStatusMessage, { position: "bottom-right" });
      yield put(getAllVehicleGroupFailure(data));
    }
  } catch (error) {
    yield put(getAllVehicleGroupFailure(error));
  }
}

function* workerGetVehicleGroupDropdownDetails(action) {
  try {
    const uri = `${ConfigAPI.GET_VEHICLE_GROUP_DROPDOWN_URL}`;
    const { data } = yield call(baseAPI.get, uri);

    if (data.httpStatusCode === 200) {
      yield put(
        getAllVehicleGroupDropdownSuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.error(data.httpStatusMessage, { position: "bottom-right" });
      yield put(getAllVehicleGroupDropdownFailure(data));
    }
  } catch (error) {
    yield put(getAllVehicleGroupDropdownFailure(error));
  }
}

function* workerInsertOrUpdateVehicleGroupDetails(action) {
  const request = action.data;
  try {
    const uri = ConfigAPI.INSERT_OR_UPDATE_VEHICLE_GROUP_URL;
    const { data } = yield call(baseAPI.post, `${uri}`, request);
    if (data.httpStatusCode === 200) {
      toast.success(data.httpStatusMessage, { position: "bottom-right" });
      yield put(
        insertOrUpdateVehicleGroupSuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.error(data.httpStatusMessage, { position: "bottom-right" });
      yield put(insertOrUpdateVehicleGroupFailure(data));
    }
  } catch (error) {
    yield put(insertOrUpdateVehicleGroupFailure(error));
  }
}

function* workerDeleteVehicleGroupDetails(action) {
  const request = action.data;
  try {
    const uri = `${ConfigAPI.DELETE_VEHICLE_GROUP_URL}?vehicleGroupId=${request}`;
    const { data } = yield call(baseAPI.get, uri);
    if (data.httpStatusCode === 200) {
      toast.success(data.httpStatusMessage, { position: "bottom-right" });
      yield put(
        deleteVehicleGroupSuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.error(data.httpStatusMessage, { position: "bottom-right" });
      yield put(deleteVehicleGroupFailure(data));
    }
  } catch (error) {
    yield put(deleteVehicleGroupFailure(error));
  }
}

function* workerGetAllCodeGroupDetails(action) {
  const { sort, page, search, OrderByColumnName } = action.data;
  try {
    const uri = `${ConfigAPI.GET_ALL_CODE_GROUP_URL}?OrderByColumnName=${OrderByColumnName}&PageNumber=${page}&SortDirection=${sort}&GridFilterKey=${search}`;
    const { data } = yield call(baseAPI.get, uri);
    if (data.httpStatusCode === 200) {
      yield put(
        getAllCodeGroupSuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.error(data.httpStatusMessage, { position: "bottom-right" });
      yield put(getAllCodeGroupFailure(data));
    }
  } catch (error) {
    yield put(getAllCodeGroupFailure(error));
  }
}

function* workerInsertOrUpdateCodeGroupDetails(action) {
  const request = action.data;
  try {
    const uri = ConfigAPI.INSERT_OR_UPDATE_CODE_GROUP_URL;
    const { data } = yield call(baseAPI.post, `${uri}`, request);
    if (data.httpStatusCode === 200) {
      toast.success(data.httpStatusMessage, { position: "bottom-right" });
      yield put(
        insertOrUpdateCodeGroupSuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.error(data.httpStatusMessage, { position: "bottom-right" });
      yield put(insertOrUpdateCodeGroupFailure(data));
    }
  } catch (error) {
    yield put(insertOrUpdateCodeGroupFailure(error));
  }
}

function* workerDeleteCodeGroupDetails(action) {
  const request = action.data;
  try {
    const uri = `${ConfigAPI.DELETE_CODE_GROUP_URL}?codeGroupId=${request}`;
    const { data } = yield call(baseAPI.get, uri);
    if (data.httpStatusCode === 200) {
      toast.success(data.httpStatusMessage, { position: "bottom-right" });
      yield put(
        deleteCodeGroupSuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.error(data.httpStatusMessage, { position: "bottom-right" });
      yield put(deleteCodeGroupFailure(data));
    }
  } catch (error) {
    yield put(deleteCodeGroupFailure(error));
  }
}

function* workerGetAllVehicleCategoryDetails(action) {
  const { sort, page, search, OrderByColumnName } = action.data;
  try {
    const uri = `${ConfigAPI.GET_ALL_VEHICLE_CATEGORY_URL}?OrderByColumnName=${OrderByColumnName}&PageNumber=${page}&SortDirection=${sort}&GridFilterKey=${search}`;
    const { data } = yield call(baseAPI.get, uri);
    if (data.httpStatusCode === 200) {
      yield put(
        getAllVehicleCategorySuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.error(data.httpStatusMessage, { position: "bottom-right" });
      yield put(getAllVehicleCategoryFailure(data));
    }
  } catch (error) {
    yield put(getAllVehicleCategoryFailure(error));
  }
}

function* workerInsertOrUpdateVehicleCategoryDetails(action) {
  const request = action.data;
  try {
    const uri = ConfigAPI.INSERT_OR_UPDATE_VEHICLE_CATEGORY_URL;
    const { data } = yield call(baseAPI.post, `${uri}`, request);
    if (data.httpStatusCode === 200) {
      toast.success(data.httpStatusMessage, { position: "bottom-right" });
      yield put(
        insertOrUpdateVehicleCategorySuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.error(data.httpStatusMessage, { position: "bottom-right" });
      yield put(insertOrUpdateVehicleCategoryFailure(data));
    }
  } catch (error) {
    yield put(insertOrUpdateVehicleCategoryFailure(error));
  }
}

function* workerDeleteVehicleCategoryDetails(action) {
  const request = action.data;
  try {
    const uri = `${ConfigAPI.DELETE_VEHICLE_CATEGORY_URL}?vehicleCategoryId=${request}`;
    const { data } = yield call(baseAPI.get, uri);
    if (data.httpStatusCode === 200) {
      toast.success(data.httpStatusMessage, { position: "bottom-right" });
      yield put(
        deleteVehicleCategorySuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.error(data.httpStatusMessage, { position: "bottom-right" });
      yield put(deleteVehicleCategoryFailure(data));
    }
  } catch (error) {
    yield put(deleteVehicleCategoryFailure(error));
  }
}

function* workerGetAllOptionsDetails(action) {
  const { sort, page, search, OrderByColumnName } = action.data;
  try {
    const uri = `${ConfigAPI.GET_ALL_OPTIONS_URL}?OrderByColumnName=${OrderByColumnName}&PageNumber=${page}&SortDirection=${sort}&GridFilterKey=${search}`;
    const { data } = yield call(baseAPI.get, uri);
    if (data.httpStatusCode === 200) {
      yield put(
        getAllOptionsSuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.error(data.httpStatusMessage, { position: "bottom-right" });
      yield put(getAllOptionsFailure(data));
    }
  } catch (error) {
    yield put(getAllOptionsFailure(error));
  }
}

function* workerInsertOrUpdateOptionsDetails(action) {
  const request = action.data;
  try {
    const uri = ConfigAPI.INSERT_OR_UPDATE_OPTIONS_URL;
    const { data } = yield call(baseAPI.post, `${uri}`, request);
    if (data.httpStatusCode === 200) {
      toast.success(data.httpStatusMessage, { position: "bottom-right" });
      yield put(
        insertOrUpdateVehicleOptionsSuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.error(data.httpStatusMessage, { position: "bottom-right" });
      yield put(insertOrUpdateVehicleOptionsFailure(data));
    }
  } catch (error) {
    yield put(insertOrUpdateVehicleOptionsFailure(error));
  }
}

function* workerDeleteOptionsDetails(action) {
  const request = action.data;
  try {
    const uri = `${ConfigAPI.DELETE_OPTIONS_URL}?optionsId=${request}`;
    const { data } = yield call(baseAPI.get, uri);
    if (data.httpStatusCode === 200) {
      toast.success(data.httpStatusMessage, { position: "bottom-right" });
      yield put(
        deleteOptionsSuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.error(data.httpStatusMessage, { position: "bottom-right" });
      yield put(getAllVehicleModelFailure(data));
    }
  } catch (error) {
    yield put(getAllVehicleModelFailure(error));
  }
}

function* workerGetAllVehicleModelDetails(action) {
  const { sort, page, search, OrderByColumnName } = action.data;
  try {
    const uri = `${ConfigAPI.GET_ALL_VEHICLE_MODEL_URL}?OrderByColumnName=${OrderByColumnName}&PageNumber=${page}&SortDirection=${sort}&GridFilterKey=${search}`;
    const { data } = yield call(baseAPI.get, uri);
    if (data.httpStatusCode === 200) {
      yield put(
        getAllVehicleModelSuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.error(data.httpStatusMessage, { position: "bottom-right" });
      yield put(getAllVehicleCategoryFailure(data));
    }
  } catch (error) {
    yield put(getAllVehicleCategoryFailure(error));
  }
}

function* workerGetTonnageByVehicleCategoryDropdownDetails(action) {
  const request = action.data;
  try {
    const uri = `${ConfigAPI.GET_TONNAGE_BY_VEHICLE_CATEGORY_DROPDOWN_URL}?vehiclecategoryId=${request}`;
    const { data } = yield call(baseAPI.get, uri);
    if (data.httpStatusCode === 200) {
      yield put(
        getTonnageByVehicleCategoryDropdownSuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.error(data.httpStatusMessage, { position: "bottom-right" });
      yield put(getTonnageByVehicleCategoryDropdownFailure(data));
    }
  } catch (error) {
    yield put(getTonnageByVehicleCategoryDropdownFailure(error));
  }
}

function* workerGetCategoriesByVehicleGroupDropdownDetails(action) {
  const request = action.data;
  try {
    const uri = `${ConfigAPI.GET_All_CATEGORY_BY_VEHICLEGROUP_DROPDOWN_URL}?vehicleGroupId=${request}`;
    const { data } = yield call(baseAPI.get, uri);
    if (data.httpStatusCode === 200) {
      yield put(
        getAllCategoriesByVehicleGroupDropdownSuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.error(data.httpStatusMessage, { position: "bottom-right" });
      yield put(getAllCategoriesByVehicleGroupDropdownFailure(data));
    }
  } catch (error) {
    yield put(getAllCategoriesByVehicleGroupDropdownFailure(error));
  }
}

function* workerVehicleGroupByCategoryDropdownDetails(action) {
  const request = action.data;
  try {
    const uri = `${ConfigAPI.GET_VEHICALE_GROUP_BY_CATEGORY_DROPDOWN_URL}?vehicle_category=${request}`;
    const { data } = yield call(baseAPI.get, uri);
    if (data.httpStatusCode === 200) {
      yield put(
        getAllVehicleGroupByCategoryDropdownSuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.error(data.httpStatusMessage, { position: "bottom-right" });
      yield put(getAllVehicleGroupByCategoryDropdownFailure(data));
    }
  } catch (error) {
    yield put(getAllVehicleGroupByCategoryDropdownFailure(error));
  }
}

function* workerinsertOrUpdateVehicleModelDetails(action) {
  const request = action.data;
  try {
    const uri = ConfigAPI.INSERT_OR_UPDATE_VEHICLE_MODEL_URL;
    const { data } = yield call(baseAPI.post, `${uri}`, request);
    if (data.httpStatusCode === 200) {
      toast.success(data.httpStatusMessage, { position: "bottom-right" });
      yield put(
        insertOrUpdateVehicleModelSuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.error(data.httpStatusMessage, { position: "bottom-right" });
      yield put(insertOrUpdateVehicleModelFailure(data));
    }
  } catch (error) {
    yield put(insertOrUpdateVehicleModelFailure(error));
  }
}

function* workerDeleteVehicleModelDetails(action) {
  const request = action.data;
  try {
    const uri = `${ConfigAPI.DELETE_VEHICLE_MODEL_URL}?vehicleModelId=${request}`;
    const { data } = yield call(baseAPI.get, uri);
    if (data.httpStatusCode === 200) {
      toast.success(data.httpStatusMessage, { position: "bottom-right" });
      yield put(
        deleteVehicleModelSuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.error(data.httpStatusMessage, { position: "bottom-right" });
      yield put(deleteVehicleModelFailure(data));
    }
  } catch (error) {
    yield put(deleteVehicleModelFailure(error));
  }
}

function* workerGetTargetMatrixDetails(action) {
  const { sort, page, search, OrderByColumnName } = action.data;
  try {
    const uri = `${ConfigAPI.GET_ALL_TARGET_MATRIX_URL}?OrderByColumnName=${OrderByColumnName}&PageNumber=${page}&SortDirection=${sort}&GridFilterKey=${search}`;
    const { data } = yield call(baseAPI.get, uri);
    if (data.httpStatusCode === 200) {
      yield put(
        getTargetMatrixSuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.error(data.httpStatusMessage, { position: "bottom-right" });
      yield put(getTargetMatrixFailure(data));
    }
  } catch (error) {
    yield put(getTargetMatrixFailure(error));
  }
}

function* workerGetAllVehicleModelDropdownDetails(action) {
  try {
    const uri = `${ConfigAPI.GET_VEHICLE_MODEL_DROPDOWN_URL}`;
    const { data } = yield call(baseAPI.get, uri);
    if (data.httpStatusCode === 200) {
      yield put(
        getAllVehicleModelDropdownSuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.error(data.httpStatusMessage, { position: "bottom-right" });
      yield put(getAllVehicleModelDropdownFailure(data));
    }
  } catch (error) {
    yield put(getAllVehicleModelDropdownFailure(error));
  }
}

function* workerVehicleModelByVehicleGroupDropdownDetails(action) {
  const request = action.data;
  try {
    const uri = `${ConfigAPI.GET_VEHICALE_MODEL_BY_VEHICLE_GROUP_DROPDOWN_URL}?vehicleGroupId=${request}`;
    const { data } = yield call(baseAPI.get, uri);
    if (data.httpStatusCode === 200) {
      yield put(
        getVehicleModelByVehicleGroupDropdownSuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.error(data.httpStatusMessage, { position: "bottom-right" });
      yield put(getVehicleModelByVehicleGroupDropdownFailure(data));
    }
  } catch (error) {
    yield put(getVehicleModelByVehicleGroupDropdownFailure(error));
  }
}

function* workerGetAllTonnageDetails(action) {
  const { sort, page, search, OrderByColumnName } = action.data;
  try {
    const uri = `${ConfigAPI.GET_ALL_TONNAGE_URL}?OrderByColumnName=${OrderByColumnName}&PageNumber=${page}&SortDirection=${sort}&GridFilterKey=${search}`;
    const { data } = yield call(baseAPI.get, uri);
    if (data.httpStatusCode === 200) {
      yield put(
        getAllTonnageSuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.error(data.httpStatusMessage, { position: "bottom-right" });
      yield put(getAllTonnageFailure(data));
    }
  } catch (error) {
    yield put(getAllTonnageFailure(error));
  }
}

function* workerGetAllVehicleCategoryDropdownDetails(action) {
  try {
    const uri = `${ConfigAPI.GET_ALL_VEHICLE_CATEGORY_DROPDOWN_URL}`;
    const { data } = yield call(baseAPI.get, uri);
    if (data.httpStatusCode === 200) {
      yield put(
        getAllVehicleCategoryDropDownSuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.error(data.httpStatusMessage, { position: "bottom-right" });
      yield put(getAllVehicleCategoryDropDownFailure(data));
    }
  } catch (error) {
    yield put(getAllVehicleCategoryDropDownFailure(error));
  }
}

function* workerGeVehicleGroupByVehicleCategoryDetails(action) {
  const request = action.data;
  try {
    const uri = `${ConfigAPI.GET_VEHICLE_GROUP_BY_VEHICLE_CATEGORY_URL}?vehicle_category=${request}`;
    const { data } = yield call(baseAPI.get, uri);
    if (data.httpStatusCode === 200) {
      yield put(
        getVehicleGroupByVehicleCategorySuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.error(data.httpStatusMessage, { position: "bottom-right" });
      yield put(getVehicleGroupByVehicleCategoryFailure(data));
    }
  } catch (error) {
    yield put(getVehicleGroupByVehicleCategoryFailure(error));
  }
}

function* workerInsertOrUpdateTonnageDetails(action) {
  const request = action.data;
  try {
    const uri = ConfigAPI.INSERT_OR_UPDATE_TONNAGE_URL;
    const { data } = yield call(baseAPI.post, `${uri}`, request);
    if (data.httpStatusCode === 200) {
      toast.success(data.httpStatusMessage, { position: "bottom-right" });
      yield put(
        insertOrUpdateTonnageSuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.error(data.httpStatusMessage, { position: "bottom-right" });
      yield put(insertOrUpdateTonnageFailure(data));
    }
  } catch (error) {
    yield put(insertOrUpdateTonnageFailure(error));
  }
}

function* workerInsertOrUpdateTargrtMatrixDetails(action) {
  const request = action.data;
  try {
    const uri = ConfigAPI.INSERT_OR_UPDATE_TARGET_MATRIX_URL;
    const { data } = yield call(baseAPI.post, `${uri}`, request);
    if (data.httpStatusCode === 200) {
      toast.success(data.httpStatusMessage, { position: "bottom-right" });
      yield put(
        insertOrUpdateTargrtMatrixSuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.error(data.httpStatusMessage, { position: "bottom-right" });
      yield put(insertOrUpdateTargrtMatrixFailure(data));
    }
  } catch (error) {
    yield put(insertOrUpdateTargrtMatrixFailure(error));
  }
}

function* workerDeleteTargetMatrixDetails(action) {
  const request = action.data;
  try {
    const uri = `${ConfigAPI.DELETE_TARGET_MATRIX_URL}?targetMatrixMasterId=${request}`;
    const { data } = yield call(baseAPI.get, uri);
    if (data.httpStatusCode === 200) {
      toast.success(data.httpStatusMessage, { position: "bottom-right" });
      yield put(
        deleteTargetMatrixSuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.error(data.httpStatusMessage, { position: "bottom-right" });
      yield put(deleteTargetMatrixFailure(data));
    }
  } catch (error) {
    yield put(deleteTargetMatrixFailure(error));
  }
}

function* workerDeleteTonnageDetails(action) {
  const request = action.data;
  try {
    const uri = `${ConfigAPI.DELETE_TONNAGE_URL}?tonnageId=${request}`;
    const { data } = yield call(baseAPI.get, uri);
    if (data.httpStatusCode === 200) {
      toast.success(data.httpStatusMessage, { position: "bottom-right" });
      yield put(
        deleteTonnageSuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.error(data.httpStatusMessage, { position: "bottom-right" });
      yield put(deleteTonnageFailure(data));
    }
  } catch (error) {
    yield put(deleteTonnageFailure(error));
  }
}

function* workerGetAllPackageMasterDetails(action) {
  const { sort, page, search, OrderByColumnName } = action.data;
  try {
    const uri = `${ConfigAPI.GET_ALL_PACKAGE_MASTER_URL}?OrderByColumnName=${OrderByColumnName}&PageNumber=${page}&SortDirection=${sort}&GridFilterKey=${search}`;
    const { data } = yield call(baseAPI.get, uri);
    if (data.httpStatusCode === 200) {
      yield put(
        getAllPackageMasterSuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.error(data.httpStatusMessage, { position: "bottom-right" });
      yield put(getAllPackageMasterFailure(data));
    }
  } catch (error) {
    yield put(getAllPackageMasterFailure(error));
  }
}

function* workerGetAllPackageSubitemDetails(action) {
  const { sort, page, search, OrderByColumnName } = action.data;
  try {
    const uri = `${ConfigAPI.GET_ALL_PACKAGE_SUBITEM_URL}?OrderByColumnName=${OrderByColumnName}&PageNumber=${page}&SortDirection=${sort}&GridFilterKey=${search}`;
    const { data } = yield call(baseAPI.get, uri);
    if (data.httpStatusCode === 200) {
      yield put(
        getAllPackageSubitemSuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.error(data.httpStatusMessage, { position: "bottom-right" });
      yield put(getAllPackageSubitemFailure(data));
    }
  } catch (error) {
    yield put(getAllPackageSubitemFailure(error));
  }
}

function* workerGetAllPackageMasterDropdownDetails(action) {
  try {
    const uri = `${ConfigAPI.GET_ALL_PACKAGE_MASTER_DROPDOWN_URL}`;
    const { data } = yield call(baseAPI.get, uri);
    if (data.httpStatusCode === 200) {
      yield put(
        getAllPackageMasterDropdownSuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.error(data.httpStatusMessage, { position: "bottom-right" });
      yield put(getAllPackageMasterDropdownFailure(data));
    }
  } catch (error) {
    yield put(getAllPackageMasterDropdownFailure(error));
  }
}

function* workerGetVehicleGroupByPackageDetails(action) {
  const request = action.data;
  try {
    const uri = `${ConfigAPI.GET_VEHICLE_GROUP_BY_PACKAGE_URL}?packageId=${request}`;
    const { data } = yield call(baseAPI.get, uri);
    if (data.httpStatusCode === 200) {
      yield put(
        getVehicleGroupByPackageSuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.error(data.httpStatusMessage, { position: "bottom-right" });
      yield put(getVehicleGroupByPackageFailure(data));
    }
  } catch (error) {
    yield put(getVehicleGroupByPackageFailure(error));
  }
}

function* workerInsertOrUpdatePackageSubitemDetails(action) {
  const request = action.data;
  try {
    const uri = ConfigAPI.INSERT_OR_UPDATE_PACKAGE_SUBITEM_URL;
    const { data } = yield call(baseAPI.post, `${uri}`, request);
    if (data.httpStatusCode === 200) {
      toast.success(data.httpStatusMessage, { position: "bottom-right" });
      yield put(
        insertOrUpdatePackageSubitemSuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.error(data.httpStatusMessage, { position: "bottom-right" });
      yield put(insertOrUpdatePackageSubitemFailure(data));
    }
  } catch (error) {
    yield put(insertOrUpdatePackageSubitemFailure(error));
  }
}

function* workerDeletePackageSubitemDetails(action) {
  const request = action.data;
  try {
    const uri = `${ConfigAPI.DELETE_PACKAGE_SUBITEM_URL}?packageSubItemId=${request}`;
    const { data } = yield call(baseAPI.get, uri);
    if (data.httpStatusCode === 200) {
      toast.success(data.httpStatusMessage, { position: "bottom-right" });
      yield put(
        deletePackageSubitemSuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.error(data.httpStatusMessage, { position: "bottom-right" });
      yield put(deletePackageSubitemFailure(data));
    }
  } catch (error) {
    yield put(deletePackageSubitemFailure(error));
  }
}

function* workerGetAllCodeDetails(action) {
  const { sort, page, search, OrderByColumnName } = action.data;
  try {
    const uri = `${ConfigAPI.GET_ALL_CODE_URL}?OrderByColumnName=${OrderByColumnName}&PageNumber=${page}&SortDirection=${sort}&GridFilterKey=${search}`;
    const { data } = yield call(baseAPI.get, uri);
    if (data.httpStatusCode === 200) {
      yield put(
        getAllCodeSuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.error(data.httpStatusMessage, { position: "bottom-right" });
      yield put(getAllCodeFailure(data));
    }
  } catch (error) {
    yield put(getAllCodeFailure(error));
  }
}

function* workerGetAllCodeGroupDropdownDetails(action) {
  try {
    const uri = `${ConfigAPI.GET_ALL_CODE_GROUP_DROPDOWN_URL}`;
    const { data } = yield call(baseAPI.get, uri);
    if (data.httpStatusCode === 200) {
      yield put(
        getAllCodeGroupDropdownSuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.error(data.httpStatusMessage, { position: "bottom-right" });
      yield put(getAllCodeGroupDropdownFailure(data));
    }
  } catch (error) {
    yield put(getAllCodeGroupDropdownFailure(error));
  }
}

function* workerInsertOrUpdateCodeDetails(action) {
  const request = action.data;
  try {
    const uri = ConfigAPI.INSERT_OR_UPDATE_CODE_URL;
    const { data } = yield call(baseAPI.post, `${uri}`, request);
    if (data.httpStatusCode === 200) {
      toast.success(data.httpStatusMessage, { position: "bottom-right" });
      yield put(
        insertOrUpdateCodeSuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.error(data.httpStatusMessage, { position: "bottom-right" });
      yield put(insertOrUpdateCodeFailure(data));
    }
  } catch (error) {
    yield put(insertOrUpdateCodeFailure(error));
  }
}

function* workerDeleteCodeDetails(action) {
  const request = action.data;
  try {
    const uri = `${ConfigAPI.DELETE_CODE_URL}?codeId=${request}`;
    const { data } = yield call(baseAPI.get, uri);
    if (data.httpStatusCode === 200) {
      toast.success(data.httpStatusMessage, { position: "bottom-right" });
      yield put(
        deleteCodeSuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.error(data.httpStatusMessage, { position: "bottom-right" });
      yield put(deleteCodeFailure(data));
    }
  } catch (error) {
    yield put(deleteCodeFailure(error));
  }
}

function* workerGetVehicleCountryGroupMappingDetails(action) {
  const { sort, page, search, OrderByColumnName } = action.data;
  try {
    const uri = `${ConfigAPI.GET_ALL_VEHICLE_COUNTRY_GROUP_MAPPING_URL}?OrderByColumnName=${OrderByColumnName}&PageNumber=${page}&SortDirection=${sort}&GridFilterKey=${search}`;
    const { data } = yield call(baseAPI.get, uri);
    if (data.httpStatusCode === 200) {
      yield put(
        getVehicleCountryGroupMappingSuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.error(data.httpStatusMessage, { position: "bottom-right" });
      yield put(getVehicleCountryGroupMappingFailure(data));
    }
  } catch (error) {
    yield put(getVehicleCountryGroupMappingFailure(error));
  }
}

function* workerInsertOrUpdateVehicleCountryGroupMappingDetails(action) {
  const request = action.data;
  try {
    const uri = ConfigAPI.INSERT_OR_UPDATE_VEHICLE_COUNTRY_GROUP_MAPPING_URL;
    const { data } = yield call(baseAPI.post, `${uri}`, request);
    if (data.httpStatusCode === 200) {
      toast.success(data.httpStatusMessage, { position: "bottom-right" });
      yield put(
        insertOrUpdateVehicleCountryGroupMappingSuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.error(data.httpStatusMessage, { position: "bottom-right" });
      yield put(insertOrUpdateVehicleCountryGroupMappingFailure(data));
    }
  } catch (error) {
    yield put(insertOrUpdateVehicleCountryGroupMappingFailure(error));
  }
}

function* workerDeleteVehicleCountryGroupMappingDetails(action) {
  const request = action.data;
  try {
    const uri = `${ConfigAPI.DELETE_VEHICLE_COUNTRY_GROUP_MAPPING_URL}?vehicleCountryGroupMappingId=${request}`;
    const { data } = yield call(baseAPI.get, uri);
    if (data.httpStatusCode === 200) {
      toast.success(data.httpStatusMessage, { position: "bottom-right" });
      yield put(
        deleteVehicleCountryGroupMappingSuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.error(data.httpStatusMessage, { position: "bottom-right" });
      yield put(deleteVehicleCountryGroupMappingFailure(data));
    }
  } catch (error) {
    yield put(deleteVehicleCountryGroupMappingFailure(error));
  }
}

function* workerInsertOrUpdatePackageMasterDetails(action) {
  const request = action.data;
  try {
    const uri = ConfigAPI.INSERT_OR_UPDATE_PACKAGE_MASTER_URL;
    const { data } = yield call(baseAPI.post, `${uri}`, request);
    if (data.httpStatusCode === 200) {
      toast.success(data.httpStatusMessage, { position: "bottom-right" });
      yield put(
        insertOrUpdatePackageMasterSuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.error(data.httpStatusMessage, { position: "bottom-right" });
      yield put(insertOrUpdatePackageMasterFailure(data));
    }
  } catch (error) {
    yield put(insertOrUpdatePackageMasterFailure(error));
  }
}

function* workerDeletePackageMasterDetails(action) {
  const request = action.data;
  try {
    const uri = `${ConfigAPI.DELETE_PACKAGE_MASTER_URL}?packageMasterId=${request}`;
    const { data } = yield call(baseAPI.get, uri);
    if (data.httpStatusCode === 200) {
      toast.success(data.httpStatusMessage, { position: "bottom-right" });
      yield put(
        deletePackageMasterSuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.error(data.httpStatusMessage, { position: "bottom-right" });
      yield put(deletePackageMasterFailure(data));
    }
  } catch (error) {
    yield put(deletePackageMasterFailure(error));
  }
}

function* workerGetAllVehicleTypeDropdownDetails(action) {
  try {
    const uri = `${ConfigAPI.GET_ALL_VEHICLE_TYPE_DROPDOWN_URL}`;
    const { data } = yield call(baseAPI.get, uri);
    if (data.httpStatusCode === 200) {
      yield put(
        getAllVehicleTypeDropdownSuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.error(data.httpStatusMessage, { position: "bottom-right" });
      yield put(getAllVehicleTypeDropdownFailure(data));
    }
  } catch (error) {
    yield put(getAllVehicleTypeDropdownFailure(error));
  }
}

function* workerGetAllCodeRequestDetails(action) {
  const { sort, page, search, OrderByColumnName, SortDirection } = action.data;
  try {
    const uri = `${ConfigAPI.GET_ALL_CODE_REQUEST_URL}?GridFilterKey=${search}&PageNumber=${page}&OrderByColumnName=${OrderByColumnName}&SortDirection=${sort}`;
    const { data } = yield call(baseAPI.get, uri);
    if (data.httpStatusCode === 200) {
      yield put(
        getAllCodeRequestSuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.error(data.httpStatusMessage, { position: "bottom-right" });
      yield put(getAllCodeRequestFailure(data));
    }
  } catch (error) {
    yield put(getAllCodeRequestFailure(error));
  }
}

function* workerInsertOrUpdateCodeRequestDetails(action) {
  const request = action.data;
  try {
    const uri = ConfigAPI.INSERT_OR_UPDATE_CODE_REQUEST_URL;
    const { data } = yield call(baseAPI.post, `${uri}`, request);
    if (data.httpStatusCode === 200) {
      toast.success(data.httpStatusMessage, { position: "bottom-right" });
      yield put(
        insertOrUpdateCodeRequestSuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.error(data.httpStatusMessage, { position: "bottom-right" });
      yield put(insertOrUpdateCodeRequestFailure(data));
    }
  } catch (error) {
    yield put(insertOrUpdateCodeRequestFailure(error));
  }
}

function* workerDeleteRejectCodeRequestDetails(action) {
  const request = action.data;
  try {
    const uri = ConfigAPI.DELETE_CODE_REQUEST_URL;
    const { data } = yield call(baseAPI.post, `${uri}`, request);
    if (data.httpStatusCode === 200) {
      toast.success(data.httpStatusMessage, { position: "bottom-right" });
      yield put(
        rejectCodeRequestSuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.error(data.httpStatusMessage, { position: "bottom-right" });
      yield put(rejectCodeRequestFailure(data));
    }
  } catch (error) {
    yield put(rejectCodeRequestFailure(error));
  }
}

function* workerGetCodeGroupByVehicleGroupDetails(action) {
  const request = action.data?.vehicleGroupIds;
  try {
    const uri = `${ConfigAPI.GET_CODE_GROUP_BY_VEHICLE_GROUP_URL}`;
    const { data } = yield call(baseAPI.post, uri, request);
    if (data.httpStatusCode === 200) {
      yield put(
        getCodeGroupByVehicleGroupSuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.error(data.httpStatusMessage, { position: "bottom-right" });
      yield put(getCodeGroupByVehicleGroupFailure(data));
    }
  } catch (error) {
    yield put(getCodeGroupByVehicleGroupFailure(error));
  }
}

function* workerGetAllCodeRequestMailIdDetails(action) {
  const { mailId } = action.data;
  try {
    const uri = `${ConfigAPI.GET_MAIL_CODE_REQUEST_URL}?codeRequestId=${mailId}`;
    const { data } = yield call(baseAPI.get, uri);
    if (data.httpStatusCode === 200) {
      yield put(
        getAllCodeRequestMailIdSuccess({
          payload: data,
          data: data.data,
        })
      );
    } else {
      toast.error(data.httpStatusMessage, { position: "bottom-right" });
      yield put(getAllCodeRequestMailIdFailure(data));
    }
  } catch (error) {
    yield put(getAllCodeRequestMailIdFailure(error));
  }
}

function* MasterSaga() {
  yield takeEvery(MastersActionTypes.GET_ALL_COUNTRYGROUP_REQUEST, workerGetAllCountryGroupDetails);
  yield takeEvery(MastersActionTypes.GET_COUNTRYGROUP_BY_ID_REQUEST, workerGetCountryGroupByIdDetails);
  yield takeEvery(MastersActionTypes.POST_COUNTRYGROUP_INSERT_OR_UPDATE_REQUEST, workerInsertOrUpdateCountryGroupDetails);
  yield takeEvery(MastersActionTypes.GET_DELETE_COUNTRYGROUP_REQUEST, workerDeleteCountryGroupDetails);
  yield takeEvery(MastersActionTypes.GET_ALL_COUNTRY_REQUEST, workerGetAllCountryDetails);
  yield takeEvery(MastersActionTypes.INSERT_OR_UPDATE_COUNTRY_REQUEST, workerInsertOrUpdateCountryDetails);
  yield takeEvery(MastersActionTypes.DELETE_COUNTRY_REQUEST, workerDeleteCountryDetails);
  yield takeEvery(MastersActionTypes.GET_COUNTRYGROUP_DROPDOWN_REQUEST, workerGetCountryGroupDropdownDetails);
  yield takeEvery(MastersActionTypes.GET_ALL_VEHICLETYPE_REQUEST, workerGetAllVehicleTypeDetails);
  yield takeEvery(MastersActionTypes.INSERT_OR_UPDATE_VEHICLETYPE_REQUEST, workerInsertOrUpdateVehicleTypeDetails);
  yield takeEvery(MastersActionTypes.DELETE_VEHICLE_TYPE_REQUEST, workerVehicleTypeDetails);
  yield takeEvery(MastersActionTypes.GET_ALL_VEHICLEGROUP_REQUEST, workerGetAllVehicleGroupDetails);
  yield takeEvery(MastersActionTypes.GET_All_VEHICLEGROUP_DROPDOWN_REQUEST, workerGetVehicleGroupDropdownDetails);
  yield takeEvery(MastersActionTypes.INSERT_OR_UPDATE_VEHICLEGROUP_REQUEST, workerInsertOrUpdateVehicleGroupDetails);
  yield takeEvery(MastersActionTypes.DELETE_VEHICLE_GROUP_REQUEST, workerDeleteVehicleGroupDetails);
  yield takeEvery(MastersActionTypes.GET_ALL_CODEGROUP_REQUEST, workerGetAllCodeGroupDetails);
  yield takeEvery(MastersActionTypes.INSERT_OR_UPDATE_CODE_GROUP_REQUEST, workerInsertOrUpdateCodeGroupDetails);
  yield takeEvery(MastersActionTypes.DELETE_CODE_GROUP_REQUEST, workerDeleteCodeGroupDetails);
  yield takeEvery(MastersActionTypes.GET_ALL_VEHICLE_CATEGORT_REQUEST, workerGetAllVehicleCategoryDetails);
  yield takeEvery(MastersActionTypes.INSERT_OR_UPDATE_VEHICLE_CATEGORY_REQUEST, workerInsertOrUpdateVehicleCategoryDetails);
  yield takeEvery(MastersActionTypes.DELETE_VEHICLE_CATEGORY_REQUEST, workerDeleteVehicleCategoryDetails);
  yield takeEvery(MastersActionTypes.GET_ALL_OPTIONS_REQUEST, workerGetAllOptionsDetails);
  yield takeEvery(MastersActionTypes.INSERT_OR_UPDATE_OPTIONS_REQUEST, workerInsertOrUpdateOptionsDetails);
  yield takeEvery(MastersActionTypes.DELETE_OPTIONS_REQUEST, workerDeleteOptionsDetails);
  yield takeEvery(MastersActionTypes.GET_ALL_VEHICLE_MODEL_REQUEST, workerGetAllVehicleModelDetails);
  yield takeEvery(MastersActionTypes.GET_TONNAGE_BY_VEHICLE_CATEGORY_DROPDOWN_REQUEST, workerGetTonnageByVehicleCategoryDropdownDetails);
  yield takeEvery(MastersActionTypes.GET_All_CATEGORIES_BY_VEHICLEGROUP_DROPDOWN_REQUEST, workerGetCategoriesByVehicleGroupDropdownDetails);
  yield takeEvery(MastersActionTypes.GET_All_VEHICLE_GROUP_BY_CATEGORY_DROPDOWN_REQUEST, workerVehicleGroupByCategoryDropdownDetails);
  yield takeEvery(MastersActionTypes.INSERT_OR_UPDATE_VEHICLE_MODEL_REQUEST, workerinsertOrUpdateVehicleModelDetails);
  yield takeEvery(MastersActionTypes.DELETE_VEHICLE_MODEL_REQUEST, workerDeleteVehicleModelDetails);
  yield takeEvery(MastersActionTypes.GET_ALL_TARGET_MATRIX_REQUEST, workerGetTargetMatrixDetails);
  yield takeEvery(MastersActionTypes.GET_All_VEHICLE_MODEL_DROPDOWN_REQUEST, workerGetAllVehicleModelDropdownDetails);
  yield takeEvery(MastersActionTypes.GET_VEHICLE_MODEL_BY_VEHICLE_GROUP_REQUEST, workerVehicleModelByVehicleGroupDropdownDetails);
  yield takeEvery(MastersActionTypes.GET_ALL_TONNAGE_REQUEST, workerGetAllTonnageDetails);
  yield takeEvery(MastersActionTypes.GET_ALL_VEHICLE_CATEGORY_DROPDOWN_REQUEST, workerGetAllVehicleCategoryDropdownDetails);
  yield takeEvery(MastersActionTypes.GET_VEHICLE_GROUP_BY_VEHICLE_CATEGORY_REQUEST, workerGeVehicleGroupByVehicleCategoryDetails);
  yield takeEvery(MastersActionTypes.INSERT_OR_UPDATE_TONNAGE_REQUEST, workerInsertOrUpdateTonnageDetails);
  yield takeEvery(MastersActionTypes.INSERT_OR_UPDATE_TARGET_MATRIX_REQUEST, workerInsertOrUpdateTargrtMatrixDetails);
  yield takeEvery(MastersActionTypes.DELETE_TARGET_MATRIX_REQUEST, workerDeleteTargetMatrixDetails);
  yield takeEvery(MastersActionTypes.DELETE_TONNAGE_REQUEST, workerDeleteTonnageDetails);
  yield takeEvery(MastersActionTypes.GET_ALL_PACKAGE_MASTER_REQUEST, workerGetAllPackageMasterDetails);
  yield takeEvery(MastersActionTypes.GET_ALL_PACKAGE_SUBITEM_REQUEST, workerGetAllPackageSubitemDetails);
  yield takeEvery(MastersActionTypes.GET_ALL_PACKAGE_MASTER_DROPDOWN_REQUEST, workerGetAllPackageMasterDropdownDetails);
  yield takeEvery(MastersActionTypes.GET_VEHICLE_GROUP_BY_PACKAGE_REQUEST, workerGetVehicleGroupByPackageDetails);
  yield takeEvery(MastersActionTypes.INSERT_OR_UPDATE_PACKAGE_SUBITEM_REQUEST, workerInsertOrUpdatePackageSubitemDetails);
  yield takeEvery(MastersActionTypes.DELETE_PACTAGE_SUBITEM_REQUEST, workerDeletePackageSubitemDetails);
  yield takeEvery(MastersActionTypes.GET_ALL_CODE_REQUEST, workerGetAllCodeDetails);
  yield takeEvery(MastersActionTypes.GET_All_CODE_GROUP_DROPDOWN_REQUEST, workerGetAllCodeGroupDropdownDetails);
  yield takeEvery(MastersActionTypes.INSERT_OR_UPDATE_CODE_REQUEST, workerInsertOrUpdateCodeDetails);
  yield takeEvery(MastersActionTypes.DELETE_CODE_REQUEST, workerDeleteCodeDetails);
  yield takeEvery(MastersActionTypes.GET_ALL_VEHICLE_COUNTRYGROUP_MAPPING_REQUEST, workerGetVehicleCountryGroupMappingDetails);
  yield takeEvery(MastersActionTypes.INSERT_OR_UPDATE_VEHICLE_COUNTRYGROUP_MAPPING_REQUEST, workerInsertOrUpdateVehicleCountryGroupMappingDetails);
  yield takeEvery(MastersActionTypes.DELETE_VEHICLE_COUNTRYGROUP_MAPPING_REQUEST, workerDeleteVehicleCountryGroupMappingDetails); yield takeEvery(MastersActionTypes.INSERT_OR_UPDATE_PACKAGE_MASTER_REQUEST, workerInsertOrUpdatePackageMasterDetails);
  yield takeEvery(MastersActionTypes.DELETE_PACKAGE_MASTER_REQUEST, workerDeletePackageMasterDetails);
  yield takeEvery(MastersActionTypes.GET_ALL_VEHICLE_TYPE_DROPDOWN_REQUEST, workerGetAllVehicleTypeDropdownDetails);
  yield takeEvery(MastersActionTypes.GET_ALL_CODE_REQUEST_REQUEST, workerGetAllCodeRequestDetails);
  yield takeEvery(MastersActionTypes.INSERT_OR_UPDATE_CODE_REQUEST_REQUEST, workerInsertOrUpdateCodeRequestDetails);
  yield takeEvery(MastersActionTypes.REJECT_CODE_REQUEST_REQUEST, workerDeleteRejectCodeRequestDetails);
  yield takeEvery(MastersActionTypes.GET_CODE_GROUP_BY_VEHICLE_GROUP_REQUEST, workerGetCodeGroupByVehicleGroupDetails);
  yield takeEvery(MastersActionTypes.GET_ALL_CODE_REQUEST_MAILID_REQUEST, workerGetAllCodeRequestMailIdDetails);

}

export default MasterSaga;
