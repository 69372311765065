import React, { useState, useEffect } from 'react';
import { Input, Badge } from 'reactstrap';
import { connect } from "react-redux";
import Select from "react-select";
import { Card, CardBody, Col, Container, Row, CardHeader, Label, Button } from 'reactstrap';
import { Pagination } from '@mui/material';
import Loader from '../Loader/Loader';
import DeleteModal from '../DeleteModel/DeleteModel';
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { getAllCreateUserAction, getAllUserDropDownAction, insertOrUpdateCreateUserAction } from '../../store/actions';

const CreateUser = (props: any) => {
    document.title = "CreateUser";
    const { getAllCreateUser, getAllUserDropdown, insertOrUpdateCreateUser, loading } = props;
    const [selectedGroup1, setSelectedGroup1] = useState<any>(null);
    const [selectedGroup2, setSelectedGroup2] = useState<any>(null);
    const [selectedGroup3, setSelectedGroup3] = useState<any>(null);
    const [openModel, setOpenModel] = useState<boolean>(false);
    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [searchKeyword, setSearchKeyword] = useState("");
    const [sort, setSort] = useState("asc");
    const [currentPage, setCurrentPage] = useState<number>(1);
    const errors: any = {}
    const [formValidation, setFormValiodation] = useState<any>({});
    const recordsPerPage = 10;
    const totalPages = Math.ceil(Number(totalCount) / recordsPerPage);
    const [vehicleGroup, setVehicleGroup] = useState<any>(null);
    const [approvalType, setApprovalType] = useState<any>(null);
    const [department, setDepartment] = useState<any>(null);
    const [editId, seteditId] = useState<any>("");
    const [editData, setEditData] = useState<any>({});
    const [vechileGroupId, setVechileGroupId] = useState<any>([]);
    const [departmentId, setDepartmentId] = useState(null);
    const [roleId, setRoleId] = useState<any>([]);
    const LoginRoleData = localStorage.getItem("loginRoleAccess")
    const RoleResponse = JSON.parse(LoginRoleData);
    const Role = RoleResponse?.find(data => data?.name === "UserManagement")?.subModules?.find(val => val?.submenuName === "User")?.access

    const Vehicle = vehicleGroup?.map((item: any) => (
        {
            label: item.value,
            value: item.value,
            id: item.id,
            isSelected: item.isSelected
        }
    )
    ) || [];

    const Approval = approvalType?.map((item: any) => (
        {
            label: item.value,
            value: item.value,
            id: item.id,
            isSelected: item.isSelected
        }
    )
    ) || [];

    const departmentList = department?.map((item: any) => (
        {
            label: item.value,
            value: item.value,
            id: item.id,
            isSelected: item.isSelected
        }
    )
    ) || [];

    const handleEdit = (user: any) => {
        seteditId(user?.id);
        setSelectedGroup3({
            id: user?.departmentList[0].id,
            label: user?.departmentList[0].value,
            value: user?.departmentList[0].value,
            isSelected: user?.departmentList[0].isSelected,
        })
        setDepartmentId(user?.departmentList[0].id)
        setSelectedGroup2({
            id: user?.roleList?.map((item: any) => (item?.id)),
            label: user?.roleList?.map((item: any) => (item?.value)),
            value: user?.roleList?.map((item: any) => (item?.value)),
            isSelected: user?.roleList?.map((item: any) => (item?.isSelected)),
        })
        setRoleId(user?.roleList?.map((item: any) => (item?.id)))
        setSelectedGroup1(
            user?.vechileGroupList.map((val: any) => ({
                id: val?.id,
                label: val?.value,
                value: val?.value,
                isSelected: val?.isSelected,
            })) || []
        );
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setVechileGroupId(user?.vechileGroupList?.map((item: any) => (item?.id)))
        setOpenModel(true);
        setEditData(user)
    }

    const handleAdd = () => {
        setOpenModel(true);
    }

    const handleSave = () => {
        if (!editData?.name) {
            errors.fullName = 'Please fill the field'
        }
        if (!editData.shortId) {
            errors.shortId = 'Please fill the field'
        }
        if (!editData.email) {
            errors.emailId = 'Please fill the field'
        }
        if (!departmentId) {
            errors.departmentId = 'Please select department'
        }
        if (!roleId?.length > 0) {
            errors.roleId = 'Please select roleId'
        }
        if (!vechileGroupId?.length > 0) {
            errors.vechileGroupId = 'Please select vehicle group'
        }
        setFormValiodation(errors);
        const data = {
            id: editId ? editId : 0,
            name: editData.name,
            shortId: editData.shortId,
            roleId: roleId,
            vechileGroupId: vechileGroupId,
            departmentId: departmentId,
            email: editData.email
        };
        if (editData.name && editData.shortId && editData.email && departmentId && vechileGroupId?.length > 0 && roleId?.length > 0) {
            props.insertOrUpdateCreateUserAction(data);
        }
    }

    function handleSelectGroups1(selectedGroup1) {
        setVechileGroupId(selectedGroup1?.map((item: any) => (item.id)))
        setSelectedGroup1(selectedGroup1);
    }

    function handleSelectGroups2(selectedGroup2) {
        setRoleId([selectedGroup2?.id]);
        setSelectedGroup2(selectedGroup2);
    }

    function handleSelectGroups3(selectedGroup3) {
        setDepartmentId(selectedGroup3?.id)
        setSelectedGroup3(selectedGroup3);
    }

    const handleDeleteTodo = () => {
        setDeleteModal(false);
    };

    const onClickPagination = (event, value) => {
        setCurrentPage(value);
    };

    const onSearchChange = (event: any) => {
        setSearchKeyword(event.target.value);
        props.getAllCreateUserAction({ page: "1", search: event.target.value, sort: sort })
        setCurrentPage(1);
    };

    const onSortChange = (keys: any) => {
        const newSortOrder = sort === 'asc' ? 'desc' : 'asc';
        setSort(newSortOrder);
        props.getAllCreateUserAction({ page: currentPage, search: searchKeyword, sort: newSortOrder, OrderByColumnName: keys, SortDirection: newSortOrder });
    };

    useEffect(() => {
        if (insertOrUpdateCreateUser?.httpStatusCode === 200) {
            setOpenModel(false);
            handleClear();
            props.getAllCreateUserAction({ page: currentPage, search: searchKeyword, sort: sort, OrderByColumnName: "" })
        }
    }, [insertOrUpdateCreateUser])

    const handleClear = () => {
        setEditData({});
        setSelectedGroup1(null);
        setSelectedGroup3(null);
        setFormValiodation({});
        seteditId("");
        setVechileGroupId([]);
        setDepartmentId(null);
        setSelectedGroup2(null);
        setRoleId(null);
    }

    useEffect(() => {
        props.getAllUserDropDownAction()
    }, [])

    useEffect(() => {
        setVehicleGroup(getAllUserDropdown?.vechileGroupList)
        setApprovalType(getAllUserDropdown?.roleList)
        setDepartment(getAllUserDropdown?.departmentList)
    }, [getAllUserDropdown])

    useEffect(() => {
        props.getAllCreateUserAction({ page: currentPage, search: searchKeyword, sort: sort, OrderByColumnName: "" });
        setTotalCount(getAllCreateUser?.totalRowsCount);
    }, [currentPage])

    useEffect(() => {
        setTotalCount(getAllCreateUser?.totalRowsCount);
    }, [getAllCreateUser])

    return (
        <React.Fragment>
            {loading && <Loader />}
            <DeleteModal
                show={deleteModal}
                onDeleteClick={() => handleDeleteTodo()}
                onCloseClick={() => setDeleteModal(false)}
            />
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Create User" pageTitle="Masters" />
                    {openModel &&
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardHeader className='card-bg'><h4 className="card-title mb-0 ">Create User</h4> </CardHeader>
                                    <CardBody>
                                        <div>
                                            <div>
                                                <Row>
                                                    <Col lg={4} md={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="choices-single-no-search" className="form-label text-muted">Short ID <span style={{ color: 'red' }}>*</span></Label>
                                                            <Input type="text" className="form-control" id="placeholderInput" placeholder="Enter shortId" value={editData.shortId ? editData.shortId : ""} onChange={(e) => setEditData({ ...editData, shortId: e.target.value })} />
                                                            <span className='text-validation'>{formValidation.shortId}</span>
                                                        </div>
                                                    </Col>
                                                    <Col lg={4} md={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="choices-single-no-search" className="form-label text-muted">Vehicle Group <span style={{ color: 'red' }}>*</span></Label>
                                                            <Select
                                                                isClearable={true}
                                                                value={selectedGroup1}
                                                                isMulti
                                                                closeMenuOnSelect={false}
                                                                styles={{
                                                                    valueContainer: (base) => ({ ...base, maxHeight: 50, overflowY: 'auto' }),
                                                                }}
                                                                onChange={(e) => {
                                                                    handleSelectGroups1(e);
                                                                }}
                                                                options={Vehicle}
                                                            />
                                                            <span className='text-validation'>{formValidation.vechileGroupId}</span>
                                                        </div>
                                                    </Col>

                                                    <Col lg={4} md={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="choices-single-no-search" className="form-label text-muted">Role <span style={{ color: 'red' }}>*</span></Label>
                                                            <Select
                                                                isClearable={true}
                                                                value={selectedGroup2}
                                                                onChange={(e) => {
                                                                    handleSelectGroups2(e);
                                                                }}
                                                                options={Approval}
                                                            />
                                                            <span className='text-validation'>{formValidation.roleId}</span>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg={4} md={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="choices-single-no-search" className="form-label text-muted">Department <span style={{ color: 'red' }}>*</span></Label>
                                                            <Select
                                                                isClearable={true}
                                                                value={selectedGroup3}
                                                                onChange={(e) => {
                                                                    handleSelectGroups3(e);
                                                                }}
                                                                options={departmentList}
                                                            />
                                                            <span className='text-validation'>{formValidation.departmentId}</span>
                                                        </div>
                                                    </Col>
                                                    <Col lg={4} md={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="choices-single-no-search" className="form-label text-muted">Full Name <span style={{ color: 'red' }}>*</span></Label>
                                                            <Input type="text" className="form-control" id="placeholderInput" placeholder="Enter full name" value={editData.name ? editData.name : ""} onChange={(e) => setEditData({ ...editData, name: e.target.value })} />
                                                            <span className='text-validation'>{formValidation.fullName}</span>
                                                        </div>
                                                    </Col>
                                                    <Col lg={4} md={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="choices-single-no-search" className="form-label text-muted">Email <span style={{ color: 'red' }}>*</span></Label>
                                                            <Input type="text" className="form-control" id="placeholderInput" placeholder="Enter e-mail address" value={editData.email ? editData.email : ""} onChange={(e) => setEditData({ ...editData, email: e.target.value })} />
                                                            <span className='text-validation'>{formValidation.emailId}</span>
                                                        </div>
                                                    </Col>

                                                    <div>
                                                        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                            <Button className="btn btn-primary mb-2" type="button" color='success' onClick={() => handleSave()}>Save</Button>
                                                            <Button className="btn btn-primary me-2 mb-2" type="button" color='light' onClick={handleClear}>Clear</Button>
                                                        </div>
                                                    </div>

                                                </Row>

                                            </div>

                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    }
                    <Row>
                        <Col xl={12}>
                            <Card>
                                <CardHeader className="align-items-center d-flex card-bg">
                                    <h4 className="card-title mb-0 flex-grow-1">User List</h4>
                                    <div className="flex-shrink-0">
                                        <div className="form-check form-switch form-switch-right form-switch-md">
                                            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                {Role && Role?.createAccess && <Button className="btn btn-primary" type="button" color='success' onClick={() => handleAdd()}>+ Add</Button>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-sm-end">
                                        <div className="search-box ms-2">
                                            <input type="text" className="form-control search" placeholder="Search..." onChange={onSearchChange} />
                                            <i className="ri-search-line search-icon"></i>
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardBody className='card-padding'>
                                    <div className="live-preview">
                                        <div className="table-responsive table-card">
                                            <table className="table align-middle table-nowrap table-striped mb-0">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th scope="col">S.No </th>
                                                        <th scope="col">Name <i className=" bx bx-sort me-3" style={{ color: "#182b45" }} onClick={() => onSortChange('fullName')}  ></i></th>
                                                        <th scope="col">Email ID <i className=" bx bx-sort me-3" style={{ color: "#182b45" }} onClick={() => onSortChange('emailId')}  ></i></th>
                                                        <th scope="col">Short ID <i className=" bx bx-sort me-3" style={{ color: "#182b45" }} onClick={() => onSortChange('shortId')}  ></i></th>
                                                        <th scope="col">IsActive <i className=" bx bx-sort me-3" style={{ color: "#182b45" }} onClick={() => onSortChange('isActive')}  ></i></th>
                                                        {Role && Role?.editAccess && <th scope="col" style={{ width: 50 }}>Action</th>}
                                                    </tr>
                                                </thead>
                                                <tbody> {getAllCreateUser?.dataRows && getAllCreateUser?.dataRows?.length > 0 ? (
                                                    getAllCreateUser?.dataRows?.map((user: any, index: number) => (
                                                        <tr key={user?.id}>
                                                            <td>{index + 1}</td>
                                                            <td>{user?.name}</td>
                                                            <td>{user?.email}</td>
                                                            <td>{user?.shortId}</td>
                                                            <td>{user?.isActive === true ? <Badge color="success" pill>Active </Badge> : <Badge color="danger" pill>InActive </Badge>}</td>
                                                            {Role && Role?.editAccess && <td>
                                                                <div className="hstack gap-2">
                                                                    <button className="btn btn-sm btn-soft-info edit-list" onClick={() => handleEdit(user)}>
                                                                        <i className="ri-pencil-fill align-bottom" />
                                                                    </button>
                                                                </div>
                                                            </td>}
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td className='no-data-foun' colSpan={10} >No data found</td>
                                                    </tr>
                                                )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="d-none code-view">
                                        <pre className="language-markup" style={{ "height": "275px" }}>
                                            <code>
                                            </code>
                                        </pre>
                                    </div>
                                    {totalCount > 10 &&
                                        <Row className="g-4 mt-3">
                                            <Col className="col-sm">
                                                <div className="d-flex justify-content-sm-end">
                                                    <Pagination count={totalPages} page={currentPage} onChange={onClickPagination} shape="rounded" />
                                                </div>
                                            </Col>
                                        </Row>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state: any) => ({
    getAllCreateUser: state.UserManagementReducer.getAllCreateUser,
    getAllUserDropdown: state.UserManagementReducer.getAllUserDropdown,
    insertOrUpdateCreateUser: state.UserManagementReducer.insertOrUpdateCreateUser,
    loading: state.UserManagementReducer.loading
})

const mapDispatchToProps = (dispatch: any) => ({
    getAllCreateUserAction: (data: any) => {
        dispatch(getAllCreateUserAction(data))
    },
    getAllUserDropDownAction: (data: any) => {
        dispatch(getAllUserDropDownAction(data))
    },
    insertOrUpdateCreateUserAction: (data: any) => {
        dispatch(insertOrUpdateCreateUserAction(data))
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateUser);
